(function () {
    'use strict';

    var PageEditControl = {
    	restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/page-edit.html',
        controllerAs: 'vm',
        bindings: {
            pageData: '=',
            pageIndex: '=',
            pageNumber: '<',
            that: '=',
            elementOn: '=',
            moveCanvasObj: '=',
            imgSize: '<',
            imgDpi: '<',
            editorType: '<',
            onRatioinfo:'&'
        },

        controller: ['$scope','FontManagement','$timeout', '$http','MessageService', function ($scope,FontManagement,$timeout,$http,MessageService) {
            var vm = this;
            //像素不足提醒
            var throttledResize = angular.throttle(function (item) {
                return pxInsufficient(item, vm.imgSize, vm.imgDpi)
            }, 1000);

            $scope.pxInsufficient = function (item) {
                // return throttledResize(item);
                return pxInsufficient(item, vm.imgSize, vm.imgDpi);
            }

            vm.$onInit = function() {
                document.addEventListener("keydown", onKeyDown)
            }

            vm.$postLink = function () {

            }

            vm.$onDestroy = function () {
                document.removeEventListener("keydown", onKeyDown);
            }
            /***********************  图片框  ****************************/
            $scope.imageboxClick = function($event, imagebox, index, pageIndex){
                if(vm.that.levelInif1){vm.that.levelInif1()};
                if(vm.that.ctrlKey){
                    imagebox.ctrlKey = true;
                }else{
                    if(!imagebox.ctrlKey){
                        if(vm.that.levelInif){vm.that.levelInif()}
                    }
                }
                vm.that.elementInit(index,(pageIndex ? pageIndex : vm.pageIndex),imagebox,'imagebox');
                fn($event);
            }
            //缩放
            $scope.resizeStartRigth = function($event, item,pro,index,slide){
                if(item.lock){return}
                fn($event);
                editorBoxZoom($event, item, 'rightBottom', pro, $($event.target).parents('.selected'), function (it) {
                    vm.pageData[vm.pageIndex].levels.level[vm.elementOn.index].imagebox = it;
                    $scope.$apply();
                }, function (it) {
                    if(it.image && it.image.resource&& it.image.resource.identifier){
                        getBackgroundSource3(it, it.geometry.width,it.geometry.height, it.image, it.bokehtype, $scope, function () {
                            // vm.pageData[vm.pageIndex].levels.level[vm.elementOn.index].imagebox = it;
                        });
                        vm.that.cookie();
                    }
                });
            }
            $scope.resizeStartLeft = function ($event, item,pro,index,slide) {
                if(item.lock){return}
                fn($event);
                editorBoxZoom($event, item, 'leftTop', pro, $($event.target).parents('.selected'), function (it) {
                    vm.pageData[vm.pageIndex].levels.level[vm.elementOn.index].imagebox = it;
                    $scope.$apply();
                }, function (it) {
                    if(it.image && it.image.resource&& it.image.resource.identifier){
                        getBackgroundSource3(it, it.geometry.width,it.geometry.height, it.image, it.bokehtype, $scope, function () {});
                        vm.that.cookie();
                    }
                });
            }
            $scope.resizeStartTop = function ($event, item,pro,index,slide) {
                if(item.lock){return}
                fn($event);
                editorBoxZoom($event, item, 'rightTop', pro, $($event.target).parents('.selected'), function (it) {
                    vm.pageData[vm.pageIndex].levels.level[vm.elementOn.index].imagebox = it;
                    $scope.$apply();
                }, function (it) {
                    if(it.image && it.image.resource&& it.image.resource.identifier){
                        getBackgroundSource3(it, it.geometry.width,it.geometry.height, it.image, it.bokehtype, $scope, function () {});
                        vm.that.cookie();
                    }
                });
            }
            $scope.resizeStartBottom = function ($event, item,pro,index,slide) {
                if(item.lock){return}
                fn($event);
                editorBoxZoom($event, item, 'leftBottom', pro, $($event.target).parents('.selected'), function (it) {
                    vm.pageData[vm.pageIndex].levels.level[vm.elementOn.index].imagebox = it;
                    $scope.$apply();
                }, function (it) {
                    if(it.image && it.image.resource&& it.image.resource.identifier){
                        getBackgroundSource3(it, it.geometry.width,it.geometry.height, it.image, it.bokehtype, $scope, function () {});
                        vm.that.cookie();
                    }
                });
            }
            $scope.resizeStartLeft1 = function ($event, item,pro,index,slide) {
                if(item.lock){return}
                fn($event);
                editorBoxZoom($event, item, 'left', pro, $($event.target).parents('.selected'), function (it) {
                    vm.pageData[vm.pageIndex].levels.level[vm.elementOn.index].imagebox = it;
                    $scope.$apply();
                }, function (it) {
                    if(it.image && it.image.resource&& it.image.resource.identifier){
                        it.image.offsetx = 0;
                        it.image.offsety = 0;
                        getBackgroundSource3(it, it.geometry.width,it.geometry.height, it.image, it.bokehtype, $scope, function () {});
                    }
                    vm.that.cookie();
                });
            }
            $scope.resizeStartTop1 = function ($event, item,pro,index,slide) {
                if(item.lock){return}
                fn($event);
                editorBoxZoom($event, item, 'top', pro, $($event.target).parents('.selected'), function (it) {
                    vm.pageData[vm.pageIndex].levels.level[vm.elementOn.index].imagebox = it;
                    $scope.$apply();
                }, function (it) {
                    if(it.image && it.image.resource&& it.image.resource.identifier){
                        it.image.offsetx = 0;
                        it.image.offsety = 0;
                        getBackgroundSource3(it, it.geometry.width,it.geometry.height, it.image, it.bokehtype, $scope, function () {});
                    }
                    vm.that.cookie();
                });
            }
            $scope.resizeStartRight1 = function ($event, item,pro,index,slide) {
                if(item.lock){return}
                fn($event);
                editorBoxZoom($event, item, 'right', pro, $($event.target).parents('.selected'), function (it) {
                    vm.pageData[vm.pageIndex].levels.level[vm.elementOn.index].imagebox = it;
                    $scope.$apply();
                }, function (it) {
                    if(it.image && it.image.resource&& it.image.resource.identifier){
                        it.image.offsetx = 0;
                        it.image.offsety = 0;
                        getBackgroundSource3(it, it.geometry.width,it.geometry.height, it.image, it.bokehtype, $scope, function () {});
                    }
                    vm.that.cookie();
                });
            }
            $scope.resizeStartBottom1 = function ($event, item,pro,index,slide) {
                if(item.lock){return}
                fn($event);
                editorBoxZoom($event, item, 'bottom', pro, $($event.target).parents('.selected'), function (it) {
                    vm.pageData[vm.pageIndex].levels.level[vm.elementOn.index].imagebox = it;
                    $scope.$apply();
                }, function (it) {
                    if(it.image && it.image.resource&& it.image.resource.identifier){
                        it.image.offsetx = 0;
                        it.image.offsety = 0;
                        getBackgroundSource3(it, it.geometry.width,it.geometry.height, it.image, it.bokehtype, $scope, function () {});
                    }
                    vm.that.cookie();
                });
            }
            $scope.imageboxdownRotate = function ($event, item) {
                if(item.lock){return}
                fn($event);
                var _top = $($event.target).parents('.imageboxView').offset().top + (item.geometry.height * vm.pageData[vm.pageIndex].custom.ratio / 2);
                var _left = $($event.target).parents('.imageboxView').offset().left + (item.geometry.width * vm.pageData[vm.pageIndex].custom.ratio / 2);
                webRotate($scope, $event, item, _top, _left, function () {
                    vm.that.cookie()
                })
            }

            //双击编辑图片框图片
            $scope.$on('doubleClickImageboxCallback', function(event, item) {
                $scope.doubleClickImagebox(item.event, item.index, item.item, item.pageIndex)
            })
            $scope.doubleClickImagebox = function ($event,index, item, pageIndex) {
                fn($event);
                // if(vm.pageData[vm.pageIndex].scene){
                //     if(vm.onRatioinfo){
                //         vm.onRatioinfo();
                //     }
                //     return
                // }
                if((pageIndex>=0 && pageIndex == vm.pageIndex) || pageIndex == null){
                    if(item.image && item.image.resource && item.image.resource.identifier){
                        vm.imageboxDouble1 = index;
                        vm.imageboxDouble = angular.copy(item);
                        vm.isImageboxTrim = true;
                        scaleViewRange(vm.imageboxDouble.image.scale);
                        scaleViewRangeInfo();
                    }
                }
            }
            $scope.imageboxFalseClick = function () {
                vm.isImageboxTrim = false;
            };
            $scope.imageboxTrueClick = function () {
                vm.pageData[vm.pageIndex].levels.level[vm.imageboxDouble1].imagebox = vm.imageboxDouble;
                vm.isImageboxTrim = false;
                vm.that.cookie();
            };
            var isImageboxMouseDown = false;
            $scope.imageboxMouseDown = function ($event) {
                fn($event)
                if (vm.imageboxDouble.bokehtype != 'None') {
                    return
                }
                var x = $event.clientX;
                var y = $event.clientY;
                var proW = vm.pageData[vm.pageIndex].custom.ratio*vm.imageboxDouble.image.scale;
                var proH = vm.pageData[vm.pageIndex].custom.ratio*vm.imageboxDouble.image.scale;

                var l = vm.imageboxDouble.image.offsetx * proW * vm.imageboxDouble.image.scale;
                var t = vm.imageboxDouble.image.offsety * proH * vm.imageboxDouble.image.scale;
                var r = -(vm.imageboxDouble.image.width * vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.width) * proW;
                var b = - (vm.imageboxDouble.image.height * vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.height) * proH;
                var angle = Number(vm.imageboxDouble.rotation.angle);
                //开关打开
                isImageboxMouseDown = true;
                //鼠标移动
                window.onmousemove = function (e) {
                    if (isImageboxMouseDown == false) {
                        return;
                    }
                    fn(e);
                    var cx = (l + r) / 2;
                    var cy = (t + b) / 2;
                    var pt0 = rotatePoint({x: x, y: y}, {x: cx, y: cy}, -angle);
                    var pt1 = rotatePoint({x: e.clientX, y: e.clientY}, {x: cx, y: cy}, -angle);
                    var deltaX = pt1.x - pt0.x;
                    var deltaY = pt1.y - pt0.y;
                    var nl = l + deltaX;
                    var nt = t + deltaY;
                    if (nl < r) {
                        nl = r
                    };
                    if (nl > 0) {
                        nl = 0
                    };
                    if (nt < b) {
                        nt = b
                    };
                    if (nt > 0) {
                        nt = 0
                    };

                    vm.imageboxDouble.image.offsetx = nl/proW/vm.imageboxDouble.image.scale;
                    vm.imageboxDouble.image.offsety = nt/proH/vm.imageboxDouble.image.scale;
                    $scope.$apply();
                }
                //鼠标抬起事件
                window.onmouseup = function ($event) {
                    if(isImageboxMouseDown){
                        $scope.$apply();
                    }
                    isImageboxMouseDown = false;
                }
            }
            $scope.rotateImagebox = function () {
                imageboxRotate($http,$scope, vm.imageboxDouble);
            }
            $scope.changeBlurred = function (e) {
                fn(e);
                if(e && e.keyCode){
                    return
                }
                if (vm.imageboxDouble.bokehtype == 'None') {
                    vm.imageboxDouble.bokehtype = (vm.that.article && vm.that.article.bokehtype && vm.that.article.bokehtype != 'None') ? angular.copy(vm.that.article.bokehtype) : 'Transparent';
                } else {
                    vm.imageboxDouble.bokehtype = 'None';
                }
                vm.imageboxDouble.image.scale = 1;
                if(vm.imageboxDouble.blurredrectangle){
                    vm.imageboxDouble.blurredrectangle.x = 0;
                    vm.imageboxDouble.blurredrectangle.y = 0;
                }
                if(vm.imageboxDouble.image.resource && vm.imageboxDouble.image.resource.identifier){
                    getBackgroundSource3(vm.imageboxDouble, vm.imageboxDouble.geometry.width, vm.imageboxDouble.geometry.height, vm.imageboxDouble.image, vm.imageboxDouble.bokehtype);
                }
            }
            $scope.deleteImage = function(){
                vm.imageboxDouble.image.resourceid = "";
                vm.imageboxDouble.image.resource.identifier = "";
                vm.imageboxDouble.image.resource.adaptation = "";
                vm.imageboxDouble.image.resource.provider = "";
                vm.imageboxDouble.image.resource.offsetx = 0;
                vm.imageboxDouble.image.resource.offsety = 0;
                vm.imageboxDouble.image.resource.height = "";
                vm.imageboxDouble.image.resource.width = "";
                vm.imageboxDouble.image.resource.scale = 1;
                vm.imageboxDouble.image.resource.imgSize = "";
                vm.pageData[vm.pageIndex].levels.level[vm.imageboxDouble1].imagebox = vm.imageboxDouble;
                vm.pageData[vm.pageIndex].updateThumbnail = true;
                vm.isImageboxTrim = false;
                vm.that.photosNum();
                vm.that.imageSelectInit();
                vm.that.magnitudeBox();
            }
            //居中
            $scope.imageboxSite = function (e) {
                fn(e);
                if(e && e.keyCode){
                    return
                }
                if (!vm.imageboxDouble || vm.imageboxDouble.bokehtype != 'None') {
                    return
                }
                vm.imageboxDouble.image.offsetx = -(vm.imageboxDouble.image.width * vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.width) / 2 / vm.imageboxDouble.image.scale;
                vm.imageboxDouble.image.offsety = -(vm.imageboxDouble.image.height * vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.height) / 2 / vm.imageboxDouble.image.scale;
                if (vm.imageboxDouble.image.offsetx > 0) {
                    vm.imageboxDouble.image.offsetx = 0;
                }
                if (vm.imageboxDouble.image.offsety > 0) {
                    vm.imageboxDouble.image.offsety = 0;
                }
            }
            // 滚动放大
            function scaleViewRangeInfo(){
                $timeout(function () {
                    $('#scaleViewRange' + vm.pageIndex).on('input propertychange',function(e){
                        var val=($('#scaleViewRange' + vm.pageIndex).val()/100 +1).toFixed(2);
                        vm.imageboxDouble.image.scale = val;
                        scaleViewRange(vm.imageboxDouble.image.scale);
                        $scope.$apply();
                    })
                })
                $scope.scale = 1;
                window.bbimg = function (delta) {
                    if (vm.imageboxDouble.bokehtype != 'None' || vm.that.ctrlKey) {
                        return
                    }
                    if (delta < 0 || delta == undefined && event.wheelDelta > 0) {
                        vm.imageboxDouble.image.scale *= 1.01;
                    } else {
                        vm.imageboxDouble.image.scale /= 1.01;
                        if (vm.imageboxDouble.image.scale <= 1) {
                            vm.imageboxDouble.image.scale = 1;
                        }
                    }
                    scaleViewRange(vm.imageboxDouble.image.scale);
                    $scope.$digest();
                    return false;
                };
            }
            function scaleViewRange(scale){
                $('#scaleViewRange' + vm.pageIndex).val(((scale-1)*100).toFixed(0));

                var x = vm.imageboxDouble.image.offsetx*vm.imageboxDouble.image.scale;
                var y = vm.imageboxDouble.image.offsety*vm.imageboxDouble.image.scale;

                //向上滑动;
                if (-y >= (vm.imageboxDouble.image.height*vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.height)) {
                    y = -(vm.imageboxDouble.image.height*vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.height);
                }
                //向左滑动;        <-----
                if (-x >= (vm.imageboxDouble.image.width*vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.width)) {
                    x = -(vm.imageboxDouble.image.width*vm.imageboxDouble.image.scale - vm.imageboxDouble.geometry.width);
                }
                //向下滑动;
                if (y >= 0) {
                    y = 0;
                }
                // 向右滑动;    ---->
                if (x >= 0) {
                    x = 0;
                }
                vm.imageboxDouble.image.offsetx = x/vm.imageboxDouble.image.scale;
                vm.imageboxDouble.image.offsety = y/vm.imageboxDouble.image.scale;
            }


            // 交换占位框图片
            $scope.changeMaskImage = function (e, i) {
                fn(e);
                // 需要切换的占位框
                var box0 = vm.pageData[vm.pageIndex].levels.level[vm.elementOn.index].imagebox;
                var img1={
                    imageInfo: box0.image.imageInfo,
                    height: box0.image.height,
                    id: box0.image.resourceid,
                    identifier: box0.image.resource.identifier,
                    imgsize: box0.image.imgsize,
                    name: null,
                    provider: "qiniu",
                    width: box0.image.width,
                    brightness:box0.image.brightness,
                    contrast: box0.image.contrast,
                    saturate: box0.image.saturate,
                    temperature: box0.image.temperature,
                    hue: box0.image.hue
                };
                box0.image.imageInfo = null;
                box0.image.resourceid = null;
                box0.image.resource.identifier = null;
                box0.image.imgsize = null;
                box0.image.height = null;
                box0.image.width = null;

                // 被切换的占位框
                var box = vm.pageData[vm.pageIndex].levels.level[i].imagebox;
                var img={
                    imageInfo: box.image.imageInfo,
                    height: box.image.height,
                    id: box.image.resourceid,
                    identifier: box.image.resource.identifier,
                    imgsize: box.image.imgsize,
                    name: null,
                    provider: "qiniu",
                    width: box.image.width,
                    brightness:box.image.brightness,
                    contrast: box.image.contrast,
                    saturate: box.image.saturate,
                    temperature: box.image.temperature,
                    hue: box.image.hue
                }
                box.image.imageInfo = null;
                box.image.resourceid = null;
                box.image.resource.identifier = null;
                box.image.imgsize = null;
                box.image.height = null;
                box.image.width = null;
                swapBox(box0,img);
                swapBox(box,img1);
                vm.pageData[vm.pageIndex].levels.level.forEach(function (level) {
                    if(level.imagebox){
                        level.imagebox.exchange = false;
                    }
                })
            };
            function swapBox(imagebox, img){
                imagebox.image.resourceid = img ? img.id : '';
                imagebox.image.resource.identifier = img ? img.identifier : '';
                imagebox.image.resource.provider = img ? img.provider : 'qiniu';
                imagebox.image.width = img ? img.width : '';
                imagebox.image.height = img ? img.height : '';
                imagebox.image.imageInfo = img ? img.imageInfo : '';
                imagebox.image.imgSize = img ? img.imgSize : '';
                imagebox.image.index = img ? img.seq : '';
                imagebox.image.parameter = img ? angular.fromJson(img.parameter) : [];
                imagebox.image.scale = 1;
                imagebox.image.brightness = img ? img.brightness : 0;
                imagebox.image.contrast = img ? img.contrast : 0;
                imagebox.image.saturate = img ? img.saturate : 0;
                imagebox.image.temperature = img ? img.temperature : 0;
                imagebox.image.hue = img ? img.hue : 0;
                if(imagebox.image.resource.identifier){
                    addImagebox($http, imagebox, function () {})
                }
            }
            /***********************  图片框end  ****************************/
            /***********************  二维码  ****************************/
            $scope.codeClick = function($event, code, index, pageIndex){
                if(vm.that.levelInif1){vm.that.levelInif1()};
                if(vm.that.ctrlKey){
                    code.ctrlKey = true;
                }else{
                    if(!code.ctrlKey){
                        if(vm.that.levelInif){vm.that.levelInif()}
                    }
                }
                vm.that.elementInit(index,(pageIndex ? pageIndex : vm.pageIndex),code,'code');
                vm.that.code={
                    width: transitionMm(code.geometry.width),
                    height: transitionMm(code.geometry.height),
                    value: code.value
                };
                fn($event)
            }
            $scope.codeDblclick = function($event, code, index, pageIndex){
                if(vm.pageData[vm.pageIndex].scene){
                    if(vm.onRatioinfo){
                        vm.onRatioinfo();
                    }
                    return
                }
                vm.that.elementInit(index,(pageIndex ? pageIndex : vm.pageIndex),code,'code');
                vm.that.code={
                    width: transitionMm(code.geometry.width),
                    height: transitionMm(code.geometry.height),
                    value: code.value
                };
                vm.that.nav='qrcode';
                fn($event)
            }
            /***********************  二维码end  ****************************/
            /***********************  素材  ****************************/
            $scope.imageartClick = function($event, imageart, index, pageIndex){
                if(imageart.onlyshow){
                    return
                }
                if(vm.that.levelInif1){vm.that.levelInif1()};
                if(vm.that.ctrlKey){
                    imageart.ctrlKey = true;
                }else{
                    if(!imageart.ctrlKey){
                        if(vm.that.levelInif){vm.that.levelInif()}
                    }
                }
                vm.that.elementInit(index,(pageIndex ? pageIndex : vm.pageIndex),imageart,'imageart');
                fn($event)
            }
            //素材缩放right
            $scope.imageartdownZoomRight = function($event, item){
                editorBoxZoom($event, item, 'rightBottom', vm.pageData[vm.pageIndex].custom.ratio, $($event.target).parents('.selected'), function (it) {
                    calMonthBoxW()
                    $scope.$apply();
                }, function (it) {
                    calMonthBoxW()
                    vm.that.cookie()
                });
                fn($event)
            }
            //素材缩放left
            $scope.imageartdownZoomLeft = function ($event, item) {
                editorBoxZoom($event, item, 'leftTop', vm.pageData[vm.pageIndex].custom.ratio, $($event.target).parents('.selected'), function (it) {
                    calMonthBoxW()
                    $scope.$apply();
                }, function (it) {
                    calMonthBoxW()
                    vm.that.cookie()
                });
                fn($event)
            }
            //素材缩放top
            $scope.imageartdownZoomTop = function ($event, item) {
                editorBoxZoom($event, item, 'rightTop', vm.pageData[vm.pageIndex].custom.ratio, $($event.target).parents('.selected'), function (it) {
                    calMonthBoxW()
                    $scope.$apply();
                }, function (it) {
                    calMonthBoxW()
                    vm.that.cookie()
                });
                fn($event)
            }
            //素材缩放bottom
            $scope.imageartdownZoomBottom = function ($event, item) {
                editorBoxZoom($event, item, 'leftBottom', vm.pageData[vm.pageIndex].custom.ratio, $($event.target).parents('.selected'), function (it) {
                    calMonthBoxW()
                    $scope.$apply();
                }, function (it) {
                    calMonthBoxW()
                    vm.that.cookie()
                });
                fn($event)
            }
            function calMonthBoxW(item){
                if(item && item.geometry && vm.elementOn && vm.elementOn.name && vm.elementOn.name == 'calMonthBox'){
                    var _width = angular.copy(item.geometry.width);
                    var _height = angular.copy(item.geometry.height);
                    item.geometry.width = Math.floor(item.geometry.width/7) * 7;
                    item.geometry.height = item.geometry.width*_height/_width;
                }
            }
            //素材旋转
            $scope.imageartdownRotate = function($event, item){
                if(item.lock){return}
                var _top = $($event.target).parents('.c-xml-page-material').offset().top + (item.geometry.height * vm.pageData[vm.pageIndex].custom.ratio / 2);
                var _left = $($event.target).parents('.c-xml-page-material').offset().left + (item.geometry.width * vm.pageData[vm.pageIndex].custom.ratio / 2);
                webRotate($scope, $event, item, _top, _left, function () {
                    vm.that.cookie()
                })
                fn($event);
            }
            //双击素材裁切
            $scope.imageartDblclick = function($event, item){
                fn($event)
                // if(vm.pageData[vm.pageIndex].scene){
                //     if(vm.onRatioinfo){
                //         vm.onRatioinfo();
                //     }
                //     return
                // }
                if(item.lock){return}
                var x = $event.clientX;
                var y = $event.clientY;
                vm.isImageartTrim = true;
                vm.isImageartTrimIndex = angular.copy(vm.elementOn.index);
                vm.imageartTrim = angular.copy(item);
                vm.imageartTrim.geometry.width1 = angular.copy(item.geometry.width);
                vm.imageartTrim.geometry.height1 = angular.copy(item.geometry.height);
                vm.imageartTrim.geometry.x1 = angular.copy(item.geometry.x);
                vm.imageartTrim.geometry.y1 = angular.copy(item.geometry.y);
                if(vm.imageartTrim.geometry.width * vm.pageData[vm.pageIndex].custom.ratio == vm.imageartTrim.geometry.width1 * vm.pageData[vm.pageIndex].custom.ratio * vm.imageartTrim.scale){
                    vm.imageartTrim.geometry.width = vm.imageartTrim.geometry.width * 0.8;
                    vm.imageartTrim.geometry.height = vm.imageartTrim.geometry.height * 0.8;
                    vm.imageartTrim.geometry.x = Number(vm.imageartTrim.geometry.x) + Number(vm.imageartTrim.geometry.width / 0.8 * 0.2 / 2);
                    vm.imageartTrim.geometry.y = Number(vm.imageartTrim.geometry.y) + Number(vm.imageartTrim.geometry.height / 0.8 * 0.2 / 2);
                }
            }

            //拖动素材裁切
            var isImageartTrim = false;
            $scope.imageartTrimdown = function ($event) {
                var $event = $event || window.event;
                if($event && $event.preventDefault){
                    $event.preventDefault();
                }
                else {
                    window.event.returnValue = false;
                    return false;
                }
                // $event.stopPropagation();
                var x = $event.clientX;
                var y = $event.clientY;
                var l = vm.imageartTrim.geometry.x * vm.pageData[vm.pageIndex].custom.ratio;
                var t = vm.imageartTrim.geometry.y * vm.pageData[vm.pageIndex].custom.ratio;
                var r = (vm.imageartTrim.geometry.width1 * vm.imageartTrim.scale - vm.imageartTrim.geometry.width) * vm.pageData[vm.pageIndex].custom.ratio;
                var b = (($("#c-xml-imageart-trim-img").find('img').height()) / vm.pageData[vm.pageIndex].custom.ratio - vm.imageartTrim.geometry.height) * vm.pageData[vm.pageIndex].custom.ratio;

                var x1 = (Number(vm.imageartTrim.geometry.x1) + Number(vm.imageartTrim.offsetx)) * vm.pageData[vm.pageIndex].custom.ratio;
                var y1 = (Number(vm.imageartTrim.geometry.y1) + Number(vm.imageartTrim.offsety)) * vm.pageData[vm.pageIndex].custom.ratio;

                var x2 = vm.imageartTrim.geometry.x1 * vm.pageData[vm.pageIndex].custom.ratio;
                var y2 = vm.imageartTrim.geometry.y1 * vm.pageData[vm.pageIndex].custom.ratio;
                var x3 = vm.imageartTrim.offsetx * vm.pageData[vm.pageIndex].custom.ratio;
                var y3 = vm.imageartTrim.offsety * vm.pageData[vm.pageIndex].custom.ratio;
                //开关打开
                isImageartTrim = true;
                //鼠标移动
                window.onmousemove = function (e) {
                    if (isImageartTrim == false) {
                        return;
                    }
                    //获取x和y
                    var nx = e.clientX;
                    var ny = e.clientY;
                    //计算移动后的左偏移量和顶部的偏移量
                    var nl = nx - (x - l);
                    var nt = ny - (y - t);

                    if(nl< x1){ nl = x1};
                    if(nl > Number(r) + Number(x1)){ nl = Number(r) + Number(x1)};
                    if(nt < y1){ nt = y1};
                    if(nt > Number(b) + Number(y1)){ nt = Number(b) + Number(y1)};

                    vm.imageartTrim.geometry.x = nl / vm.pageData[vm.pageIndex].custom.ratio;
                    vm.imageartTrim.geometry.y = nt / vm.pageData[vm.pageIndex].custom.ratio;
                    $scope.$apply();
                }
                //鼠标抬起事件
                window.onmouseup = function ($event) {
                    isImageartTrim = false;
                    $event.stopPropagation();
                }
            }

            //素材裁切放大缩小
            var isImageartTrimRight = false;
            $scope.imageartTrimRight = function($event, item){
                var $event = $event || window.event;                  // IE、火狐获取事件对象
                //防止浏览器默认行为(W3C)
                if($event && $event.preventDefault){
                    $event.preventDefault();
                }
                //IE中组织浏览器行为
                else {
                    window.event.returnValue = false;
                    return false;
                }
                $event.stopPropagation();
                var x = $event.clientX;
                var y = $event.clientY;
                //获取w和h
                var nw = Number(vm.imageartTrim.geometry.width * vm.pageData[vm.pageIndex].custom.ratio) + Number(vm.imageartTrim.geometry.x * vm.pageData[vm.pageIndex].custom.ratio);
                var nh = Number(vm.imageartTrim.geometry.height * vm.pageData[vm.pageIndex].custom.ratio) + Number(vm.imageartTrim.geometry.y * vm.pageData[vm.pageIndex].custom.ratio);
                var _l = vm.imageartTrim.geometry.x * vm.pageData[vm.pageIndex].custom.ratio;
                var _t = vm.imageartTrim.geometry.y * vm.pageData[vm.pageIndex].custom.ratio;

                var r = Number(vm.imageartTrim.geometry.x1 * vm.pageData[vm.pageIndex].custom.ratio) + Number(vm.imageartTrim.offsetx * vm.pageData[vm.pageIndex].custom.ratio) + Number(vm.imageartTrim.geometry.width1 * vm.pageData[vm.pageIndex].custom.ratio * vm.imageartTrim.scale);
                var b = Number(vm.imageartTrim.geometry.y1 * vm.pageData[vm.pageIndex].custom.ratio) + Number(vm.imageartTrim.offsety * vm.pageData[vm.pageIndex].custom.ratio) + Number($("#imageartTrimRight1").height());
                //开关打开
                isImageartTrimRight = true;

                //鼠标移动
                window.onmousemove = function (e) {
                    if (isImageartTrimRight == false) {
                        return;
                    }
                    //获取x和y
                    var nx = e.clientX;
                    var ny = e.clientY;
                    //计算移动后的左偏移量和顶部的偏移量
                    var nl = Number(nw) + Number(nx - x);
                    var nt = Number(nh) + Number(ny - y);

                    if(nl >= r){nl = r}
                    if(nt >= b){ nt = b}

                    item.geometry.width = (nl - _l) / vm.pageData[vm.pageIndex].custom.ratio;
                    item.geometry.height = (nt - _t) / vm.pageData[vm.pageIndex].custom.ratio;
                    $scope.$apply();
                }
                //鼠标抬起事件
                window.onmouseup = function ($event) {
                    isImageartTrimRight = false;
                    $event.stopPropagation();
                }
            }
            $scope.imageartTrimRight1 = function($event, item){
                var $event = $event || window.event;                  // IE、火狐获取事件对象
                //防止浏览器默认行为(W3C)
                if($event && $event.preventDefault){
                    $event.preventDefault();
                }
                //IE中组织浏览器行为
                else {
                    window.event.returnValue = false;
                    return false;
                }
                $event.stopPropagation();
                var x = $event.clientX;
                var y = $event.clientY;
                //获取w和h
                var nw = item.geometry.width1 * vm.pageData[vm.pageIndex].custom.ratio * vm.imageartTrim.scale;
                var nh = item.geometry.height1 * vm.pageData[vm.pageIndex].custom.ratio * vm.imageartTrim.scale;

                var r = Number($("#c-xml-imageart-div").offset().left) + Number(vm.pageData[vm.pageIndex].levels.level[vm.elementOn.index].imageart.geometry.width * vm.pageData[vm.pageIndex].custom.ratio);
                var x2 = (Number(item.geometry.x1 * vm.pageData[vm.pageIndex].custom.ratio) + Number($("#c-xml-imageart-trim-div").width()) - (Number(item.geometry.x * vm.pageData[vm.pageIndex].custom.ratio) + Number(item.geometry.width * vm.pageData[vm.pageIndex].custom.ratio)));
                var y2 = (Number(item.geometry.y1 * vm.pageData[vm.pageIndex].custom.ratio) + Number($("#c-xml-imageart-trim-div").height()) - (Number(item.geometry.y * vm.pageData[vm.pageIndex].custom.ratio) + Number(item.geometry.height * vm.pageData[vm.pageIndex].custom.ratio)));
                if(x2 < y2){
                    var r1 = Number($("#c-xml-imageart-div").offset().left) + Number(item.geometry.x * vm.pageData[vm.pageIndex].custom.ratio) + Number(item.geometry.width * vm.pageData[vm.pageIndex].custom.ratio);
                }else {
                    var r1 = Number($("#c-xml-imageart-div").offset().left) + Number(item.geometry.x * vm.pageData[vm.pageIndex].custom.ratio) + Number(item.geometry.width * vm.pageData[vm.pageIndex].custom.ratio) + (x2 - y2);
                }
                //开关打开
                isImageartTrimRight = true;

                //鼠标移动
                window.onmousemove = function (e) {
                    if (isImageartTrimRight == false) {
                        return;
                    }
                    //获取x和y
                    var nx = e.clientX;
                    var ny = e.clientY;
                    if(nx >= r){nx = r}
                    if(nx <= r1){nx = r1}

                    //计算移动后的左偏移量和顶部的偏移量
                    var nl = nx - x;
                    var nt = ny - y;

                    item.geometry.width1 = ((Number(nw) + Number(nl))) / vm.pageData[vm.pageIndex].custom.ratio / vm.imageartTrim.scale;
                    item.geometry.height1 = (Number(nh) + Number(nt)) / vm.pageData[vm.pageIndex].custom.ratio / vm.imageartTrim.scale;
                    $scope.$apply();
                }
                //鼠标抬起事件
                window.onmouseup = function ($event) {
                    isImageartTrimRight = false;
                    $event.stopPropagation();
                }
            }
            $scope.imageartTrimTrueClick = function(){
                vm.isImageartTrim = false;
                vm.imageartTrim.scale = (vm.imageartTrim.geometry.width1 * vm.pageData[vm.pageIndex].custom.ratio * vm.imageartTrim.scale)/(vm.imageartTrim.geometry.width * vm.pageData[vm.pageIndex].custom.ratio);
                vm.imageartTrim.offsetx = (Number(-(vm.imageartTrim.geometry.x - vm.imageartTrim.geometry.x1)) + Number(vm.imageartTrim.offsetx)).toFixed(2);
                vm.imageartTrim.offsety = (Number(-(vm.imageartTrim.geometry.y - vm.imageartTrim.geometry.y1)) + Number(vm.imageartTrim.offsety)).toFixed(2);
                if(vm.imageartTrim.offsetx > 0){
                    vm.imageartTrim.offsetx = 0;
                }
                if(vm.imageartTrim.offsety > 0){
                    vm.imageartTrim.offsety = 0;
                }
                if(vm.imageartTrim.scale < 1){
                    vm.imageartTrim.scale = 1;
                }
                // if(vm.imageartTrim.geometry.width > vm.imageartTrim.geometry.width1 * vm.imageartTrim.scale){
                //     vm.imageartTrim.geometry.width = vm.imageartTrim.geometry.width1 * vm.imageartTrim.scale;
                // }
                vm.imageartTrim.geometry.x = vm.pageData[vm.pageIndex].levels.level[vm.isImageartTrimIndex].imageart.geometry.x;
                vm.imageartTrim.geometry.y = vm.pageData[vm.pageIndex].levels.level[vm.isImageartTrimIndex].imageart.geometry.y;
                vm.pageData[vm.pageIndex].levels.level[vm.isImageartTrimIndex].imageart = vm.imageartTrim;
                vm.that.cookie();
            }
            $scope.imageartTrimFalseClick = function(){
                vm.isImageartTrim = false;
            }
            /***********************  素材end  ****************************/
            /***********************  背景  ****************************/

            //双击作品框
            $scope.editboxDblclick = function($event){
                fn($event);
                if(vm.pageData[vm.pageIndex].scene){
                    if(vm.onRatioinfo){
                        vm.onRatioinfo();
                    }
                    return
                }
                if(vm.pageData[vm.pageIndex].background.resource.identifier){
                    vm.bgImg = angular.copy(vm.pageData[vm.pageIndex].background);
                    vm.isXmlDblclick = true;
                }
            }
            //拖动背景
            var isBgImgMouseDown = false;
            var oldX = 0;
            var oldY = 0;
            //鼠标按下事件
            $scope.bgImgMouseDown = function ($event) {
                var $event = $event || window.event;
                if($event && $event.preventDefault){
                    $event.preventDefault();
                }
                else {
                    window.event.returnValue = false;
                    return false;
                }
                $event.stopPropagation();
                var x = $event.clientX;
                var y = $event.clientY;
                var l = $event.target.offsetParent.offsetLeft;
                var t = $event.target.offsetParent.offsetTop;

                var x1 = $event.target.offsetLeft;
                var y1 = $event.target.offsetTop;
                oldX = $event.target.offsetLeft;
                oldY = $event.target.offsetTop;

                var l1 = -($($event.target).width() - vm.pageData[vm.pageIndex].mediabox.width * vm.pageData[vm.pageIndex].custom.ratio);
                var t1 = -($($event.target).height() - vm.pageData[vm.pageIndex].mediabox.height * vm.pageData[vm.pageIndex].custom.ratio);
                //开关打开
                isBgImgMouseDown = true;
                //鼠标移动
                window.onmousemove = function (e) {
                    if (isBgImgMouseDown == false) {
                        return;
                    }
                    //获取x和y
                    var nx = e.clientX;
                    var ny = e.clientY;
                    //计算移动后的左偏移量和顶部的偏移量
                    var nl = Number(nx - (x - l)) + Number(x1);
                    var nt = Number(ny - (y - t)) + Number(y1);

                    if(vm.bgImg.resource.adaptation == 'Height'){
                        // if(nl == 0){ return;}
                        if(nl < l1){nl = Number(l1) + Number(1);}
                        if(nl > 0){nl = 0;}
                        vm.bgImg.offsetx = nl / vm.pageData[vm.pageIndex].custom.ratio;
                        $($event.target).css({
                            left: nl + 'px'
                        });
                    }
                    if(vm.bgImg.resource.adaptation == 'Width'){
                        // if(nt == 0){ return;}
                        if(nt <= t1){nt = Number(t1) + Number(1);}
                        if(nt > 0){nt = 0;}
                        vm.bgImg.offsety = nt / vm.pageData[vm.pageIndex].custom.ratio;
                        $($event.target).css({
                            top: nt + 'px'
                        });
                    }
                    $scope.$apply();
                }
                //鼠标抬起事件
                window.onmouseup = function ($event) {
                    if(isBgImgMouseDown){
                        vm.that.cookie();
                    }
                    isBgImgMouseDown = false;
                    $event.stopPropagation();
                }
            }
            $scope.bgImgTrueClick = function () {
                vm.isXmlDblclick = false;
                vm.pageData[vm.pageIndex].background = vm.bgImg;
                vm.pageData[vm.pageIndex].background.color="";
                vm.that.cookie();
            }
            $scope.bgImgFalseClick = function () {
                vm.isXmlDblclick = false;
            }
            /***********************  背景end  ****************************/
            /***********************  文字  ****************************/

            $scope.writingmodeInit = function (item) {
                if(item == 'vertical-lr' || item == 'vertical-rl'){
                    return true
                }else{
                    return false
                }
            }
            //选中文字
            $scope.textboxClick = function(e, item, index, pageIndex){
                if (vm.that.textFont) {
                    return
                }
                if(vm.that.levelInif1){vm.that.levelInif1()};
                if(vm.that.ctrlKey){
                    item.ctrlKey = true;
                }else{
                    if(!item.ctrlKey){
                        if(vm.that.levelInif){vm.that.levelInif()}
                    }
                }
                if (e && e.stopPropagation) {
                    e.stopPropagation();
                } else {
                    window.event.cancelBubble = true;
                }
                // if(vm.elementOn.name=='textbox' && vm.elementOn.index == index){
                //     return
                // }
                vm.that.elementInit(index,(pageIndex ? pageIndex : vm.pageIndex),item,'textbox');
                vm.that.fontStyleCb(item.fontuuid);
            }
            //文字放大缩小
            $scope.textboxZoomRight = function($event, item){
                editorBoxZoom($event, item, 'right', vm.pageData[vm.pageIndex].custom.ratio, $($event.target).parents('.selected'), function (it) {
                    vm.elementOn.item = it;
                    vm.that.sizeHeight();
                    $scope.$apply();
                }, function (it) {
                    vm.that.cookie()
                });
            }
            $scope.textboxZoomLeft = function($event, item){
                editorBoxZoom($event, item, 'left', vm.pageData[vm.pageIndex].custom.ratio, $($event.target).parents('.selected'), function (it) {
                    vm.elementOn.item = it;
                    vm.that.sizeHeight();
                    $scope.$apply();
                }, function (it) {
                    vm.that.cookie()
                });
            }

            $scope.textboxZoomTop = function($event, item){
                editorBoxZoom($event, item, 'top', vm.pageData[vm.pageIndex].custom.ratio, $($event.target).parents('.selected'), function (it) {
                    vm.elementOn.item = it;
                    vm.that.sizeHeight();
                    $scope.$apply();
                }, function (it) {
                    vm.that.cookie()
                });
            }
            $scope.textboxZoomBottom = function($event, item){
                editorBoxZoom($event, item, 'bottom', vm.pageData[vm.pageIndex].custom.ratio, $($event.target).parents('.selected'), function (it) {
                    vm.elementOn.item = it;
                    vm.that.sizeHeight();
                    $scope.$apply();
                }, function (it) {
                    vm.that.cookie()
                });
            }
            $scope.textboxZoomLeft1 = function($event, item){
                editorBoxZoom($event, item, 'leftTop', vm.pageData[vm.pageIndex].custom.ratio, $($event.target).parents('.selected'), function (it) {
                    vm.elementOn.item = it;
                    vm.that.sizeHeight();
                    $scope.$apply();
                }, function (it) {
                    vm.that.cookie()
                });
            };
            $scope.textboxZoomTop1 = function($event, item){
                editorBoxZoom($event, item, 'rightTop', vm.pageData[vm.pageIndex].custom.ratio, $($event.target).parents('.selected'), function (it) {
                    vm.elementOn.item = it;
                    vm.that.sizeHeight();
                    $scope.$apply();
                }, function (it) {
                    vm.that.cookie()
                });
            };
            $scope.textboxZoomRight1 = function($event, item){
                editorBoxZoom($event, item, 'rightBottom', vm.pageData[vm.pageIndex].custom.ratio, $($event.target).parents('.selected'), function (it) {
                    vm.elementOn.item = it;
                    vm.that.sizeHeight();
                    $scope.$apply();
                }, function (it) {
                    vm.that.cookie()
                });
            };
            $scope.textboxZoomBottom1 = function($event, item){
                editorBoxZoom($event, item, 'leftBottom', vm.pageData[vm.pageIndex].custom.ratio, $($event.target).parents('.selected'), function (it) {
                    vm.elementOn.item = it;
                    vm.that.sizeHeight();
                    $scope.$apply();
                }, function (it) {
                    vm.that.cookie()
                });
            };
            //文字旋转
            $scope.textboxRotate = function($event, item){
                if(item.lock){return}
                var _top = $($event.target).parents('.c-xml-page-textboxe').offset().top + (item.geometry.height * vm.pageData[vm.pageIndex].custom.ratio / 2);
                var _left = $($event.target).parents('.c-xml-page-textboxe').offset().left + (item.geometry.width * vm.pageData[vm.pageIndex].custom.ratio / 2);
                webRotate($scope, $event, item, _top, _left, function () {
                    vm.that.cookie()
                })
                fn($event)
            }

            $scope.doubleClicktext = function (e, item) {
                fn(e);
                if (item.lock || !item.editable || vm.that.textFont) {
                    return
                }
                $timeout(function () {
                    vm.that.textFont = true;
                    item.edit = true;
                    vm.that.fontOn = $(e.target).parents('.c-xml-page-textboxe');
                    var span = vm.that.fontOn.find('span');
                    span[0].innerHTML = '';
                    span.focus();
                    span[0].innerHTML = item.text;
                    $(span[0]).css({'cursor':'text'});
                    span[0].setAttribute("contenteditable", "true");

                    if (document.selection) {
                        var range = document.body.createTextRange();
                        range.moveToElementText(span[0]);
                        range.select();
                    } else if (window.getSelection) {
                        var range = document.createRange();
                        range.selectNodeContents(span[0]);
                        window.getSelection().removeAllRanges();
                        window.getSelection().addRange(range);
                    }
                    // $scope.$apply();
                })
            }
            $scope.blur = function (e, item, i) {
                fn(e);
                item.edit = false;
                var fontOn = $(e.target).parents('.c-xml-page-textboxe');
                var span = fontOn.find('span');
                $(span[0]).css({'cursor':'pointer'});
                span[0].setAttribute("contenteditable", "false");
                item.text = $(e.target).text();
                item.text = item.text.replace(/&nbsp;/g,' ').replace(reg1,'').replace(/<br>/g,'\n').replace(reg,'');
                FontSupport2(FontManagement, item, function (res) {
                    var msg = res.message;
                    if(res.status==200){
                        if(msg.success){
                            $timeout(function () {
                                item.text = (!msg.passed || msg.passed == "") ? "请输入文字" : msg.passed;
                                $(e.target).html(item.text);
                                if (!msg.supported) {
                                    $("#pageCanvas").css({'z-index': 9998})
                                    $(".editor-body").css({'z-index': 9998})
                                    vm.editFailedFont = true;
                                    vm.failedFontArr = {
                                        failed: msg.failed,
                                        e: e,
                                        textbox: item
                                    }
                                } else {
                                    $("#pageCanvas").css({'z-index': 9})
                                    $(".editor-body").css({'z-index': 9})
                                    vm.editFailedFont = false;
                                    vm.failedFontArr = null;
                                }
                                $timeout(function () {
                                    vm.that.sizeHeight();
                                    vm.that.textFont = false;
                                    $timeout(function () {
                                        vm.that.cookie();
                                    },500)
                                }, 200)
                            })
                        }else {
                            item.text = "请输入文字";
                            $(e.target).html("请输入文字");
                            MessageService.error("当前字体不可用，请切换字体");
                            $timeout(function () {
                                vm.that.sizeHeight();
                                vm.that.textFont = false;
                                $timeout(function () {
                                    vm.that.cookie();
                                },500)
                            },200)
                        }
                    }else{
                        vm.that.textFont = false;
                    }
                })
            }

            $scope.editFont = function (e) {
                fn(e);
                vm.editFailedFont = false;
                $("#pageCanvas").css({'z-index': 9});
                $(".editor-body").css({'z-index': 9})
                // $scope.doubleClicktext(vm.failedFontArr.e,vm.failedFontArr.textbox)
            }
            //去除所有html标签，只保留文字
            var reg=/<\/?.+?\/?>/g;
            // 过滤style行内标签
            var reg1=/ style\s*?=\s*?(['"])[\s\S]*?\1/g;
            window.textPaste = function (e) {
                console.log('textPaste')
                if(vm.elementOn.name == "textbox"){
                    // textPaste1(e);
                    // $scope.$apply();
                }
            }
            $scope.changeHeight = function(e,item){
                if(vm.elementOn.item){
                    vm.elementOn.item.text = $(e.target).text();
                    vm.elementOn.item.text = vm.elementOn.item.text.replace(/&nbsp;/g,' ').replace(reg1,'').replace(/<br>/g,'\n').replace(reg,'');
                    vm.that.sizeHeight();
                }
                fn(e)
            }
            document.body.onpaste=function(e){
                var data = e.clipboardData.getData("Text");
                if(vm.elementOn.name == "textbox"){
                    vm.elementOn.item.text = data;
                    vm.elementOn.item.text = vm.elementOn.item.text.replace(/&nbsp;/g,' ').replace(reg1,'').replace(/<br>/g,'\n').replace(reg,'');
                    vm.that.sizeHeight();
                    $scope.$apply();
                }
            }
            /***********************  文字end  ****************************/
            /***********************  文字模板  ****************************/
            $scope.textdocumentClick = function($event, item, index, pageIndex){
                vm.that.elementInit(index,(pageIndex ? pageIndex : vm.pageIndex),item,'textdocument');
                fn($event)
            };
            var dragDocFlag = false, dragDoc = {}, textdocW = 1, textdocH = 1, textdocument = null;
            window.resizeDocStart = function (e) {
                fn(e);
                var targ = e.target ? e.target : e.srcElement;
                vm.elementOn.index = targ.dataset.index;
                textdocument = angular.copy(vm.pageData[vm.pageIndex].levels.level[vm.elementOn.index].textdocument);
                if (textdocument.lock) {
                    return
                }
                dragDoc.clientX = e.clientX || 0;          //鼠标到页面左边的距离
                dragDoc.clientY = e.clientY || 0;          //鼠标到页面顶部的距离
                dragDoc.coordX = targ.offsetParent.offsetLeft + targ.offsetLeft;        //鼠标到模板左边的距离
                dragDoc.coordY = targ.offsetParent.offsetTop + targ.offsetTop;         //鼠标到模板顶部的距离
                dragDoc.maxX = vm.pageData[vm.pageIndex].trimbox.width * vm.pageData[vm.pageIndex].custom.ratio;
                dragDoc.maxY = vm.pageData[vm.pageIndex].trimbox.height * vm.pageData[vm.pageIndex].custom.ratio;
                dragDocFlag = true;
                textdocW = angular.copy(textdocument.geometry.width);
                textdocH = angular.copy(textdocument.geometry.height);

                document.addEventListener("mousemove", resizeDocOn, true);
                document.addEventListener("mouseup", resizeDocEnd, true);
                // setZindex(e.target.dataset.index);
            };
            function resizeDocOn(e) {
                if (dragDocFlag) {
                    fn(e);
                    // 是否按住shift键
                    dragDoc.shiftKey = e.shiftKey;
                    var targ = e.target ? e.target : e.srcElement;
                    if (targ == null) {
                        return;
                    }
                    var pressImg = vm.pageData[vm.pageIndex].levels.level[vm.elementOn.index].textdocument;
                    if (pressImg == null) {
                        return;
                    }

                    var w = (dragDoc.coordX + 10 + e.clientX - dragDoc.clientX - pressImg.geometry.x * vm.pageData[vm.pageIndex].custom.ratio) / vm.pageData[vm.pageIndex].custom.ratio;
                    var h = w / pressImg.geometry.width * pressImg.geometry.height
                    if (w < 40 || h < 40) {
                        return
                    }

                    var moveW = (dragDoc.maxX - pressImg.geometry.x * vm.pageData[vm.pageIndex].custom.ratio) / (vm.pageData[vm.pageIndex].custom.ratio);
                    var moveH = (dragDoc.maxY - pressImg.geometry.y * vm.pageData[vm.pageIndex].custom.ratio) / (vm.pageData[vm.pageIndex].custom.ratio);

                    if (w < moveW && h < moveH) {
                        pressImg.geometry.width = w;
                        pressImg.geometry.height = h;
                    }

                    // if (e.clientX < dragDoc.maxX && e.clientY < dragDoc.maxY) {

                    // }

                    resizeLevelWaH();
                }
                $scope.$apply();
                return false;
            }
            function resizeDocEnd() {
                if(dragDocFlag){
                    vm.that.cookie();
                }
                dragDocFlag = false;
                dragDoc = {};
                document.removeEventListener("mousemove", resizeDocOn, true);
                document.removeEventListener("mouseup", resizeDocEnd, true);
                $scope.$apply();
            }
            function resizeLevelWaH() {
                var doc = vm.pageData[vm.pageIndex].levels.level[vm.elementOn.index].textdocument;
                doc.background.offsetx = textdocument.background.offsetx * doc.geometry.width / textdocW;
                doc.background.offsety = textdocument.background.offsety * doc.geometry.height / textdocH;
                // doc.background.width = textdocument.background.width * doc.geometry.width / textdocW;
                // doc.background.height = textdocument.background.height * doc.geometry.height / textdocH;

                angular.forEach(doc.levels.level, function (level, l) {
                    var textdoc = textdocument.levels.level[l];
                    if (level.imageart) {
                        var geo = {
                            width: textdoc.imageart.geometry.width * doc.geometry.width / textdocW,
                            height: textdoc.imageart.geometry.height * doc.geometry.height / textdocH,
                            x: textdoc.imageart.geometry.x * doc.geometry.width / textdocW,
                            y: textdoc.imageart.geometry.y * doc.geometry.height / textdocH,
                        }
                        level.imageart.geometry = geo;
                        level.imageart.offsetx = textdoc.imageart.offsetx * doc.geometry.width / textdocW;
                        level.imageart.offsety = textdoc.imageart.offsety * doc.geometry.height / textdocH;
                    }
                    if (level.textbox) {
                        var textGeo = {
                            width: textdoc.textbox.geometry.width * doc.geometry.width / textdocW,
                            height: textdoc.textbox.geometry.height * doc.geometry.height / textdocH,
                            x: textdoc.textbox.geometry.x * doc.geometry.width / textdocW,
                            y: textdoc.textbox.geometry.y * doc.geometry.height / textdocH,
                        }
                        level.textbox.geometry = textGeo;
                        level.textbox.style.size = textdoc.textbox.style.size * doc.geometry.width / textdocW;
                        level.textbox.style.lineheight = textdoc.textbox.style.lineheight * doc.geometry.width / textdocW;
                        level.textbox.style.letterspacing = textdoc.textbox.style.letterspacing * doc.geometry.width / textdocW;
                    }
                })
            }
            /***********************  文字end  ****************************/
            /***********************  日历  ****************************/
            $scope.calMonthBoxClick = function($event, item, index, pageIndex){
                if(item.onlyshow){
                    return
                }
                if(vm.that.levelInif1){vm.that.levelInif1()};
                if(vm.that.ctrlKey){
                    item.ctrlKey = true;
                }else{
                    if(!item.ctrlKey){
                        if(vm.that.levelInif){vm.that.levelInif()}
                    }
                }
                vm.that.elementInit(index,(pageIndex ? pageIndex : vm.pageIndex),item,'calMonthBox');
                fn($event)
            }
            /***********************  日历end  ****************************/
            /***********************  日历标题  ****************************/
            $scope.calTitleBoxClick = function($event, item, index, pageIndex){
                if(item.onlyshow){
                    return
                }
                if(vm.that.levelInif1){vm.that.levelInif1()};
                if(vm.that.ctrlKey){
                    item.ctrlKey = true;
                }else{
                    if(!item.ctrlKey){
                        if(vm.that.levelInif){vm.that.levelInif()}
                    }
                }
                vm.that.elementInit(index,(pageIndex ? pageIndex : vm.pageIndex),item,'calTitleBox');
                fn($event)
            }
            /***********************  日历标题end  ****************************/
            //解锁
            $scope.locking = function(e){
                vm.that.locking(e);
                fn(e)
            }
            //图片框、素材、文字等元素事件
            $scope.moveWebdown = function ($event, item) {
                if (vm.that.textFont) {
                    return
                }
                if(!item.ctrlKey){
                    if(item.lock || item.edit){return}
                    moveWebdown($scope, $event,item, vm.pageData[vm.pageIndex].trimbox,vm.pageData[vm.pageIndex].custom.ratio, function () {
                        vm.that.cookie()
                        vm.that.hint = false;
                    }, function () {
                        vm.that.hint = !editboxIn(vm.pageData[vm.pageIndex].editboxes.editbox , item, item.rotation.angle);
                        $scope.$apply();
                    })
                }else{
                    vm.that.levelMove($event)
                }
                fn($event)
            }
            $scope.canvasCb = function (e) {
                $scope.bgImgFalseClick();
                $scope.imageartTrimFalseClick();
                $scope.imageboxFalseClick();
                fn(e)
            }

            //元素精度和位置
            $scope.activeImageDpi = function (item) {
                return activeImageDpi(item)
            }
            $scope.transitionMm = function (item) {
                return transitionMm(item).toFixed(0) > -1 ? Math.abs(transitionMm(item).toFixed(0)) : transitionMm(item).toFixed(0)
            }
            //边框
            $scope.imageboxW = function (imagebox) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    if (imagebox.border.type == 'Color') {
                        return Number(imagebox.geometry.width) + Number(imagebox.border.lineWidth) * 2
                    }
                    if (imagebox.border.type == 'Pic') {
                        return imagebox.geometry.width
                        // return Number(imagebox.geometry.width) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100)
                    }
                } else {
                    return imagebox.geometry.width
                }
            }
            $scope.imageboxH = function (imagebox) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    if (imagebox.border.type == 'Color') {
                        return Number(imagebox.geometry.height) + Number(imagebox.border.lineWidth) * 2
                    }
                    if (imagebox.border.type == 'Pic') {
                        return imagebox.geometry.height
                        // return Number(imagebox.geometry.height) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100)
                    }
                } else {
                    return imagebox.geometry.height
                }
            }
            $scope.imageboxL = function (imagebox) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    if (imagebox.border.type == 'Color') {
                        return Number(imagebox.geometry.x) - Number(imagebox.border.lineWidth)
                    }
                    if (imagebox.border.type == 'Pic') {
                        return imagebox.geometry.x
                        // return Number(imagebox.geometry.x) - (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100)
                    }
                } else {
                    return imagebox.geometry.x
                }
            }
            $scope.imageboxT = function (imagebox) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    if (imagebox.border.type == 'Color') {
                        return Number(imagebox.geometry.y) - Number(imagebox.border.lineWidth)
                    }
                    if (imagebox.border.type == 'Pic') {
                        return imagebox.geometry.y
                        // return Number(imagebox.geometry.y) - (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100)
                    }
                } else {
                    return imagebox.geometry.y
                }
            }
            $scope.imageboxWEdge = function (imagebox, edge) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    if (imagebox.border.type == 'Color') {
                        return Number(imagebox.border.lineWidth)
                    }
                    if (imagebox.border.type == 'Pic') {
                        if (edge == 'left') {
                            return Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100
                        }
                        if (edge == 'top') {
                            return Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100
                        }
                        if (edge == 'right') {
                            return Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100
                        }
                        if (edge == 'bottom') {
                            return Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100
                        }
                    }
                } else {
                    return 0
                }
            }
            $scope.imageboxWRadius = function (imagebox, radius, ratio, ratio1) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    var _ratio = 1;
                    if(ratio && ratio1){
                        if(imagebox.geometry.width > imagebox.geometry.height){
                            _ratio = ratio1
                        }else{
                            _ratio = ratio
                        }
                    }
                    if(ratio && !ratio1){
                        _ratio = ratio
                    }
                    if (imagebox.border.shape == 'Ellipse') {
                        return '50%'
                    }
                    if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                        var _radius = imagebox.border.radius[radius];
                        return _radius > 0 ?  (_radius + Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                    }else{
                        var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                        radiusW = radiusW + imagebox.border.lineWidth;
                        var _r = radiusW * imagebox.border.radius[radius] / 100;
                        return _r > 0 ? (_r + Number(imagebox.border.lineWidth)/2) * _ratio : 0
                    }
                } else {
                    return 0
                }
            }
            $scope.imageboxWRadius1 = function (imagebox, radius, ratio, ratio1) {
                if (!imagebox) {
                    return
                }
                if (imagebox.border) {
                    var _ratio = 1;
                    if(ratio && ratio1){
                        if(imagebox.geometry.width > imagebox.geometry.height){
                            _ratio = ratio1
                        }else{
                            _ratio = ratio
                        }
                    }
                    if(ratio && !ratio1){
                        _ratio = ratio
                    }
                    if (imagebox.border.shape == 'Ellipse') {
                        return '50%'
                    }
                    if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                        var _radius = imagebox.border.radius[radius];
                        return  (_radius - Number(imagebox.border.lineWidth)/2) > 0 ? (_radius - Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                    }else{
                        var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                        radiusW = radiusW + imagebox.border.lineWidth;
                        var _r = radiusW * imagebox.border.radius[radius] / 100;
                        return (_r - Number(imagebox.border.lineWidth)/2) > 0 ? (_r - Number(imagebox.border.lineWidth)/2) * _ratio : 0
                    }
                } else {
                    return 0
                }
            }
            $scope.dropShadow = function(imagebox,ratio){
                if (imagebox.shadow == null) {
                    imagebox.shadow={
                        color:'#777777',
                        x:0,
                        y:0,
                        blur:0
                    }
                }
                var width = $scope.imageboxW(imagebox) * ratio;
                var height =$scope.imageboxH(imagebox) * ratio;
                return dropShadow(imagebox,width,height)
            }
            //移除画布透明效果
            $scope.opacityElement = function(mediabox, geometry){
                var opacity = 1;
                if(!geometry || !mediabox){
                    return 1
                }
                if(geometry.x > -geometry.width && geometry.y > -geometry.height && geometry.x < mediabox.width && geometry.y < mediabox.height){
                    opacity = 1;
                }else {
                    opacity = 0.3;
                }
                return opacity
            }
            //键盘事件
            vm.keyboardItem = null;
            $scope.$on('onKeyDownCallback', function() {
                onKeyDown({keyCode:13})
            });
            function onKeyDown(event) {
                if(!window.event){
                    return
                }
                var key = window.event.keyCode;
                if(vm.pageNumber && vm.pageNumber != vm.pageIndex){
                    return
                }
                if (vm.that.textFont) {
                    return
                }
                if (event.keyCode == 13) {
                    fn(event)
                    $timeout(function () {
                        if (vm.isImageartTrim) {
                            $scope.imageartTrimTrueClick();
                        }
                        if (vm.isImageboxTrim) {
                            $scope.imageboxTrueClick();
                        }
                        if (vm.isXmlDblclick) {
                            $scope.bgImgTrueClick();
                        }
                    })
                    return false;
                }
                $timeout(function () {
                    if (event.keyCode == 27) {
                        if (vm.fullFlag) {
                            vm.fullFlag = false;
                        }
                        $scope.bgImgFalseClick();
                        $scope.imageartTrimFalseClick();
                        $scope.imageboxFalseClick();
                    }
                    if (event.ctrlKey) {
                        vm.that.ctrlKey = true;
                        vm.that.multiSelect();
                        if(vm.elementOn.index >= 0 && typeof vm.elementOn.index === 'number'){
                            if (vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index] && vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index].imagebox && !vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index].imagebox.ctrlKey) {
                                $scope.imageboxClick(event, vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index].imagebox, vm.elementOn.index, vm.elementOn.pageIndex)
                            }
                            if (vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index] && vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index].imageart && !vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index].imageart.ctrlKey) {
                                $scope.imageartClick(event, vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index].imageart, vm.elementOn.index, vm.elementOn.pageIndex)
                            }
                            if (vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index] && vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index].textbox && !vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index].textbox.ctrlKey) {
                                $scope.textboxClick(event, vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index].textbox, vm.elementOn.index, vm.elementOn.pageIndex)
                            }
                            if (vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index] && vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index].textdocument && !vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index].textdocument.ctrlKey) {
                                $scope.textdocumentClick(event, vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index].textdocument, vm.elementOn.index, vm.elementOn.pageIndex)
                            }
                            if (vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index] && vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index].calMonthBox && !vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index].calMonthBox.ctrlKey) {
                                $scope.calMonthBoxClick(event, vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index].calMonthBox, vm.elementOn.index, vm.elementOn.pageIndex)
                            }
                            if (vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index] && vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index].calTitleBox && !vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index].calTitleBox.ctrlKey) {
                                $scope.calTitleBoxClick(event, vm.pageData[vm.elementOn.pageIndex].levels.level[vm.elementOn.index].calTitleBox, vm.elementOn.index, vm.elementOn.pageIndex)
                            }
                        }
                    }
                    //ctrl + c
                    if (event.ctrlKey && key == 67) {
                        var all = vm.that.getAllCtrlKeyArray();
                        var copyArray = {imageboxes: [], textboxes: [], imagearts: []};
                        if(all.length > 0){
                            all.forEach(function(box){
                                if(box.current == "imagebox"){
                                    copyArray.imageboxes.push(box);
                                }
                                if(box.current == "textbox"){
                                    copyArray.textboxes.push(box);
                                }
                                if(box.current == "imageart"){
                                    copyArray.imagearts.push(box);
                                }
                            })
                        }else{
                            if(vm.elementOn.name == "imagebox"){
                                copyArray.imageboxes.push(vm.elementOn.item)
                            }
                            if(vm.elementOn.name == "imageart"){
                                copyArray.imagearts.push(vm.elementOn.item)
                            }
                            if(vm.elementOn.name == "textbox"){
                                copyArray.textboxes.push(vm.elementOn.item)
                            }
                        }

                        vm.that.copyArray = angular.copy(copyArray);
                    }
                    // 删除
                    if (event.keyCode == 46) {
                        vm.that.deleteBox();
                    }
                })
                //ctrl + v
                if (event.ctrlKey && key == 86 && !vm.that.textFont) {
                    vm.that.copyElement()
                    event.preventDefault();
                }
                // 左键
                if(event.keyCode == 37){
                    event.preventDefault();
                    var all = vm.that.getAllCtrlKeyArray();
                    if (all.length > 0) {
                        all.forEach(function(box){
                            keycodeArrow(37, vm.pageData[vm.elementOn.pageIndex], vm.pageData[vm.elementOn.pageIndex].levels.level, box);
                        })
                    }else{
                        keycodeArrow(37, vm.pageData[vm.elementOn.pageIndex], vm.pageData[vm.elementOn.pageIndex].levels.level, vm.elementOn.item);
                    }
                }
                // 上键
                if(event.keyCode == 38){
                    event.preventDefault();
                    var all = vm.that.getAllCtrlKeyArray();
                    if (all.length > 0) {
                        all.forEach(function(box){
                            keycodeArrow(38, vm.pageData[vm.elementOn.pageIndex], vm.pageData[vm.elementOn.pageIndex].levels.level, box);
                        })
                    }else{
                        keycodeArrow(38, vm.pageData[vm.elementOn.pageIndex], vm.pageData[vm.elementOn.pageIndex].levels.level, vm.elementOn.item);
                    }

                }
                // 右键
                if(event.keyCode == 39){
                    event.preventDefault();
                    var all = vm.that.getAllCtrlKeyArray();
                    if (all.length > 0) {
                        all.forEach(function(box){
                            keycodeArrow(39, vm.pageData[vm.elementOn.pageIndex], vm.pageData[vm.elementOn.pageIndex].levels.level, box);
                        })
                    }else{
                        keycodeArrow(39, vm.pageData[vm.elementOn.pageIndex], vm.pageData[vm.elementOn.pageIndex].levels.level, vm.elementOn.item);
                    }

                }
                // 下键
                if(event.keyCode == 40){
                    event.preventDefault();
                    var all = vm.that.getAllCtrlKeyArray();
                    if (all.length > 0) {
                        all.forEach(function(box){
                            keycodeArrow(40, vm.pageData[vm.elementOn.pageIndex], vm.pageData[vm.elementOn.pageIndex].levels.level, box);
                        })
                    }else{
                        keycodeArrow(40, vm.pageData[vm.elementOn.pageIndex], vm.pageData[vm.elementOn.pageIndex].levels.level, vm.elementOn.item);
                    }
                }
            }
            document.onkeyup = function(event){
                $timeout(function () {
                    vm.that.ctrlKey = false;
                })
            }
            //元素四角拖拽
            $scope.cursor = function (angle, source, boolean) {
                return cursorInfo(angle, source, boolean);
            }
            function keycodeArrow(keyCode, currentPage, level, selectBox) {
                if(selectBox.lock  || selectBox.movable==false){
                    return
                }
                // 上
                if (keyCode == 38 && selectBox.geometry.y > 0) {
                    selectBox.geometry.y--;
                }
                // 下
                if (keyCode == 40 && selectBox.geometry.y < currentPage.mediabox.height - selectBox.geometry.height) {
                    selectBox.geometry.y++;
                }
                // 左
                if (keyCode == 37 && selectBox.geometry.x > 0) {
                    selectBox.geometry.x--;
                }
                // 右
                if (keyCode == 39 && selectBox.geometry.x < currentPage.mediabox.width - selectBox.geometry.width) {
                    selectBox.geometry.x++;
                }
            }
        }]
    };

    angular.module('editorApp')
    	.component('pageEdit', PageEditControl);

})();
