(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('CommoditySelectController', CommoditySelectController);

    CommoditySelectController.$inject = ['$scope', 'Principal', '$state', 'MessageService', '$rootScope', 'entity', '$stateParams', '$uibModal',
    'Qinius', '$q', '$timeout', 'ListService', '$localStorage', 'Cart', "Editors", 'CategoryStore', 'StoreConfig', "Product"];

    function CommoditySelectController ($scope, Principal, $state, MessageService, $rootScope, entity, $stateParams, $uibModal,
     Qinius, $q, $timeout, ListService, $localStorage, Cart, Editors, CategoryStore, StoreConfig, Product) {
        var vm = this;
        vm.storeId = $stateParams.storeId;
        vm.pid = $stateParams.pid;
        vm.vid = $stateParams.vid;
        vm.plid = $stateParams.plid;
        vm.orderId = $stateParams.orderId;
        $scope.domain = $localStorage.domain;
        vm.store = $localStorage.store;
        vm.product = entity;
        vm.formatting = formatting;
        vm.from = $stateParams.from;
        vm.page = 1;
        vm.itemsPerPage = 10;
        vm.storeCartConfig = StoreConfig.byStoreIdAndType({storeId:vm.storeId, type:"SHOPPINGCART"});

        $(".navbar").show();
        $(".wrap").show();

        vm.themeId = '全部模板';

        $scope.goEditor = function(productXmlLine){
            if(Editors.belongToEditorAll(vm.itemValue.productEditorType)){
                $state.go("editorAll", {storeId: vm.storeId, pid: vm.pid, vid: vm.vid, xmlId: productXmlLine.id,soid:vm.orderId, plid:vm.plid, returnUrl: vm.store.orderWay == 'orderThenEditor'?'':window.location.href});
            } else if(vm.itemValue.productEditorType == "PICTURE"||vm.itemValue.productEditorType == "PICTURE_PRO"){
                $state.go("editorPhotoWeb", {storeId: vm.storeId, pid: vm.pid, vid: vm.vid, xmlId: productXmlLine.id,soid:vm.orderId, plid:vm.plid, returnUrl: vm.store.orderWay == 'orderThenEditor'?'':window.location.href});
            } else {
                $state.go("page-my-suffix-edit", {storeId: vm.storeId, pid: vm.pid, vid: vm.vid, xmlId: productXmlLine.id,soid:vm.orderId, plid:vm.plid, returnUrl: vm.store.orderWay == 'orderThenEditor'?'':window.location.href});
            }
        }

        //swiper
        function swiper() {
            var swiper = new Swiper('.swiper-container1', {
                slidesPerView: 7,
                slidesPerGroup : 7,
                spaceBetween : 36,
                navigation: {
                  nextEl: '.swiper-button-next1',
                  prevEl: '.swiper-button-prev1',
                },
            });
        }

        $q.all([vm.product.$promise, vm.storeCartConfig.$promise]).then(function () {
            for(var i = 0 ; i<vm.product.itemValues.length; i++){
                var it = vm.product.itemValues[i];
                if (it.id == vm.vid) {
                    vm.itemValue = it;
                }
            }
            if ( vm.itemValue.productType == 'IPDerive') {
                vm.ipDerive = true;
            }
            if ($scope.domain == null || $scope.domain == "") {
                Qinius.getDomain1({storeId: vm.storeId}, function(data){
                    $scope.domain = 'https://' + data.domains[0] + '/';
                });
            }

            if (vm.from == 'commodity') {
                if (vm.itemValue.categoryStoreId) {
                    CategoryStore.get({id:vm.itemValue.categoryStoreId}, function(data){
                        vm.categoryName = data.name;
                        if (data.haveSuperior) {
                            vm.parentCategoryName = data.categoryName;
                        }
                    })
                }
            }

            if (vm.itemValue.productXmlLines == null || vm.itemValue.productXmlLines.length == 0) {
                return;
            }
            var xmlList = [];
            for (var i = 0 ; i<vm.itemValue.productXmlLines.length; i++) {
                var xml = vm.itemValue.productXmlLines[i];
                if (!xml.cancelled) {
                    xmlList.push(xml);
                }
            }
            if ( vm.itemValue.productType == 'CustomPrint' && xmlList.length ==1) {
                if ($scope.$parent && $scope.$parent.fromState && ($scope.$parent.fromState.name =="editorPhotoWeb"||
                    $scope.$parent.fromState.name =="editorAll" || $scope.$parent.fromState.name =="page-my-suffix-edit")) {
                    window.history.go(-1);
                    return;
                }
                $scope.goEditor(xmlList[0]);
                return;
            }
            paging(xmlList);
            $scope.getThemeList();

            setTimeout(function () {
                swiper();
            },300);

        });

        $scope.getThemeList = function(){
            vm.themeList = [{id: '全部模板', name: '全部模板', seq:0}];
            if (vm.itemValue.productXmlLines != null && vm.itemValue.productXmlLines.length>0) {
                for (var i = 0; i < vm.itemValue.productXmlLines.length; i++) {
                    var xmlLine = vm.itemValue.productXmlLines[i];
                    if (!xmlLine.id || xmlLine.cancelled) {
                        continue;
                    }
                    xmlLine.themeClassifies.forEach(function (themeClassifie) {
                        var result = ListService.inList2(themeClassifie, vm.themeList, ["id"], ["id"] );
                        if (!result) {
                            vm.themeList.push({
                                id: themeClassifie.id,
                                name: themeClassifie.name,
                                seq: Number(themeClassifie.seq) + Number(xmlLine.seq)
                            });
                        }
                    })
                }
            }
            vm.themeList.sort(function (a, b) {
                return a.seq - b.seq;
            });
            console.log(vm.themeList)
        }

        $scope.blurInput = function(){
            formatting();
        }
        $scope.themeCb = function (data) {
            if (vm.themeId == data.id) {
                return ;
            }
            vm.themeId = data.id;
            formatting();
        };

        function formatting() {
            vm.productXmlListF = [];
            vm.productXmlList = [];//用来确认是那些 可以展示的 xmllines  将会通过 cancelled、themeId过滤
            if(vm.themeId != '全部模板'){
                if (vm.itemValue.productXmlLines != null && vm.itemValue.productXmlLines.length>0) {
                    for (var i = vm.itemValue.productXmlLines.length - 1; i >= 0; i--) {
                        var xmlLine = vm.itemValue.productXmlLines[i];
                        var _exist = false;
                        xmlLine.themeClassifies.forEach(function (themeClassifie) {
                            if (vm.themeId ==  themeClassifie.id) {
                                _exist = true;
                            }
                        })
                        if (_exist) {
                            vm.productXmlList.push(xmlLine);
                        }
                    }
                }
            } else{
                vm.productXmlList = angular.copy(vm.itemValue.productXmlLines);
            }
            // 查询过滤
            if (!!vm.searchText) {
                vm.productXmlList = vm.productXmlList.filter(function(xml){
                    return xml.name.indexOf(vm.searchText)>=0;
                });
            }

            if(vm.productXmlList.length > 0) {
                // vm.productXmlList.push({notAvailable: true});
                var xmlList = [];
                for (var i = 0 ; i< vm.productXmlList.length; i++) {
                    var xml = vm.productXmlList[i];
                    if (!xml.cancelled) {
                        xmlList.push(xml);
                    }
                }
                paging(xmlList);
            }else{

            }
        }

        function paging(data) {
            if (data && data.length>0) {
                data = data.sort(function(a, b){
                    return a.seq- b.seq;
                })
            }

            vm.totalItems = data.length;
            vm.queryCount = data.length;


            var pages = Math.ceil(data.length/vm.itemsPerPage); //得到总页数
            var thisDate = function(curr){
                //此处只是演示，实际场景通常是返回已经当前页已经分组好的数据
                var str = [], last = curr*vm.itemsPerPage - 1;
                last = last >= data.length ? (data.length-1) : last;
                for(var i = (curr*vm.itemsPerPage - vm.itemsPerPage); i <= last; i++){
                    str.push(data[i]);
                }
                return str;
            };

            vm.productXmlList=thisDate(vm.page);
        }

        //预览
        $scope.commodityPreview = function (productXmlLine, action ,event){
            event.stopPropagation();
            // if ('preview' == action && !productXmlLine.xmlShowPic) {
            //     return;
            // }
            if ('preview' == action) {
                if (!productXmlLine.xmlShowPic) {
                    $scope.goEditor(productXmlLine);
                    return;
                }else{
                    $uibModal.open({
                        templateUrl: 'app/entities/commodity/commodity-preview.html',
                        controller: 'CommodityPreviewController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: [function() {
                                var _obj = {
                                    storeId :vm.storeId,
                                    pid :vm.pid,
                                    productXmlLine: productXmlLine,
                                    vid: vm.vid,
                                    editorType:vm.itemValue.productEditorType
                                }
                                return _obj;
                            }]
                        }
                    }).result.then(function (backData) {
                    });
                }
            }
            // else if ('order' == action) {
            //     $uibModal.open({
            //         templateUrl: 'app/entities/sales-order/confirm-property.html',
            //         controller: 'ConfirmPropertyController',
            //         controllerAs: 'vm',
            //         backdrop: 'static',
            //         size: 'lg',
            //         resolve: {
            //             entity: {title:"立即下单", itemValueId:vm.vid}
            //         }
            //     }).result.then(function (value) {
            //         $state.go("sales-order-reorder", {storeId: vm.storeId,   productId:vm.pid, itemValueId:vm.vid,
            //             itemValueLineId: value.itemValueLineId, lineSku:value.lineSku , lineSkuNumber:value.lineSkuNumber,
            //             copy:value.copy, quantity:1, productXmlId: productXmlLine.productXmlId});
            //     });
            //     return;
            // }
            // else if ('cart' == action){
            //     $uibModal.open({
            //         templateUrl: 'app/entities/sales-order/confirm-property.html',
            //         controller: 'ConfirmPropertyController',
            //         controllerAs: 'vm',
            //         backdrop: 'static',
            //         size: 'lg',
            //         resolve: {
            //             entity: {title:"加入购物车", itemValueId:vm.vid}
            //         }
            //     }).result.then(function (value) {
            //         Cart.addGoods({
            //             productId: vm.pid,
            //             orderWay: vm.store.orderWay,
            //             itemValueLineId: value.itemValueLineId,
            //             lineSku: value.lineSku,
            //             lineSkuNumber: value.lineSkuNumber,
            //             quantity: 1,
            //             copy: value.copy,
            //             itemValueId: vm.vid,
            //             productXmlId: productXmlLine.productXmlId
            //         }, function(res) {
            //             if (res.status == 400) {
            //                 swal({
            //                     title: "失败",
            //                     text: res.message || "加入购物车失败",
            //                     imageUrl: Theme.sweetHintImg(),
            //                     timer: 2000,
            //                     showConfirmButton: false
            //                 });
            //                 return
            //             }
            //             $scope.$emit('editorApp:cartUpdate', {});
            //             $state.go("cart", {
            //                 storeId: vm.storeId
            //             });
            //         },function(data){
            //             MessageService.error("系统错误，请稍后再试");
            //         })
            //     });
            // }
        };
    }
})();
