(function() {
    'use strict';

    angular
        .module('editorApp')
        .controller('EditorTipPicModalController', EditorTipPicModalController);

        EditorTipPicModalController.$inject = ['$uibModalInstance', 'entity','$localStorage'];

    function EditorTipPicModalController ($uibModalInstance, entity, $localStorage) {
        var vm = this;
        vm.url = entity.url;
        vm.dotRemindEditorTipPic = false;
        vm.editorType = entity.editorType;
        vm.save = function(){
            $localStorage.dotRemindEditorTipPic = vm.dotRemindEditorTipPic;
            $uibModalInstance.close();
        }
    }
})();
