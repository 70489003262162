(function () {
    'use strict';

    var ClassifyEcControl = {
    	restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/components/classify-ec.html',
        controllerAs: 'vm',
        bindings: {
            cliparts:'<',
            page:'<',
            domain:'<',
            onSave:'&',
            onClose:'&',
        },

        controller: ['$scope','ThemeClassify','$timeout', '$http', '$translate', 'ClipArt',
            function ($scope,ThemeClassify,$timeout,$http, $translate,ClipArt) {
            var vm = this;
            vm.classify = [];
            vm.isMaterial1 = false;
            vm.$onInit = function (){
                getClassify();
                $('#materialView').on('scroll', function(event){
                    var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
                    if(!vm.isMaterial1){
                        if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                            vm.materialPage++;
                            initMaterial(vm.classifyId)
                        }
                    }
                })
            }
            //分类
            function getClassify(){
                vm.classify = [];
                ThemeClassify.getAllByCondition4({type: 'CLIPART',storeId:vm.storeId}, function (res) {
                    if(vm.cliparts && vm.cliparts.clipart && vm.cliparts.clipart.length>0){
                        vm.classify = [{
                            id: "",
                            name: $translate.instant("editorCommon.recommend"),
                            seq: 0,
                            type: "CLIPART",
                            used: true
                        }]
                    }
                    res.message.forEach(function (item) {
                        vm.classify.push(item)
                    })
                    initMaterial(vm.classify[0].id)
                });
            }
            vm.materialPage = 0;
            vm.materialSum = 20;
            vm.materialData = [];
            $scope.classifyCb = function (id){
                vm.materialPage = 0;
                vm.materialData = [];
                initMaterial(id)
            }
            //获取素材
            function initMaterial(id) {
                vm.classifyId = id;
                var items = [];
                if(vm.classifyId){
                    items.push({key: "themeClassify.id", op: "=", value: id})
                }
                ClipArt.byCondition3({
                    items:items,
                    page: vm.materialPage,
                    size: vm.materialSum,
                    sort: ["seq,asc"]
                }, function (res) {
                    angular.forEach(res, function (resp) {
                        var arr = {
                            name: resp.name,
                            identifier: vm.domain + resp.identifier,
                            provider: resp.provider,
                            themeClassifyName: resp.themeClassifyName,
                            themeClassifyId: resp.themeClassifyId,
                            resource: {
                                identifier: vm.domain + resp.identifier,
                                provider: resp.provider
                            }
                        }
                        vm.materialData.push(arr);
                    })
                    if (res.length < vm.materialSum) {
                        vm.isMaterial1 = true;
                    }else {
                        vm.isMaterial1 = false;
                    }
                });
            };
            vm.repeatAddNumber = 0;
            $scope.addImageart = function (item){
                imageartAdd($http, item, vm.page.trimbox, function(imageart){
                    if(vm.repeatAddNumber>4){
                        vm.repeatAddNumber = 0;
                    }
                    var _num = 10*vm.repeatAddNumber;
                    imageart.geometry.x += _num/vm.page.custom.ratio1;
                    imageart.geometry.y += _num/vm.page.custom.ratio1;
                    var _level = {
                        seq: vm.page.levels.level.length + 1,
                        imageart: imageart
                    }
                    if (vm.onSave) {
                        vm.onSave({text: JSON.stringify(_level)});
                    }
                    vm.repeatAddNumber++;
                }, vm.page.custom.ratio1)
            }
            $scope.materialHide = function (){
                if (vm.onClose) {
                    vm.onClose();
                }
            }
        }]
    };

    angular.module('editorApp')
    	.component('classifyEc', ClassifyEcControl);

})();
