(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('ProductViewController', ProductViewController);

    ProductViewController.$inject = ['$scope', '$stateParams', 'entity', '$q', '$localStorage', 'Qinius', '$state', 'Cart', 'CommonService', 'Store', 'ListService',
        'StoreConfig', '$http', '$rootScope', 'PlatformAbutment', 'WxJS', 'Theme', 'ItemValue', 'PlatformWechatSetting', 'Article', 'Music', 'Product', 'MessageNotification',
        'SalesOrder', 'ProductXml', 'Customer','PreviewSettings', '$uibModal','SkuSellLimits','$timeout','MessageService'];
    function ProductViewController($scope, $stateParams, entity, $q, $localStorage, Qinius, $state, Cart, CommonService, Store, ListService,
                                   StoreConfig, $http, $rootScope, PlatformAbutment, WxJS, Theme, ItemValue, PlatformWechatSetting, Article, Music, Product, MessageNotification,
                                   SalesOrder, ProductXml, Customer,PreviewSettings, $uibModal,SkuSellLimits,$timeout,MessageService) {
        var vm = this;

        CommonService.setDocumentTitle('预览');
        $scope.orderFlag = '立即下单';
        vm.target=null;
        vm.hint = null;
        vm.limitQuantity = null;
        vm.warningQuantity = null;
        // alert(vm.type)
        //区别小程序、公众号
        if (window.location.href.indexOf("?") == -1) {
            vm.type = 'Wechat';
        } else {
            vm.type = 'Mini';
            $(".save-pages").hide();
            var r = window.location.href.split('?')[1];
            var A = r.split('&')[0].split('=')[1];
            var B = r.split('&')[1].split('=')[1];
            var orderOrigin = r.split('&')[4].split('=')[1];
            orderOrigin = decodeURIComponent(orderOrigin);
            var distinguish = decodeURIComponent(A);
            var tempAuthorization = decodeURIComponent(B);
            var Authorization = tempAuthorization.slice(7, tempAuthorization.length);
            if (distinguish == 'weChatApplet' || distinguish == 'weChatAppletMake' || distinguish == 'toutiaoApp' || distinguish == 'jdAppPlugins' || distinguish == 'alipayApp' || distinguish == 'jdApp') {
                localStorage.setItem('orderOrigin', orderOrigin);
                localStorage.setItem('distinguish', distinguish);
                $localStorage.authenticationToken = Authorization;
                vm.target=getQueryVariable('target');
                localStorage.setItem('target', vm.target);
            } else {
                localStorage.setItem('distinguish', '');
            }
        }
        vm.distinguish = localStorage.getItem('distinguish');
        function getQueryVariable(variable) {
            var query = window.location.href.substring(1);
            var vars = query.split("&");
            for (var i=0;i<vars.length;i++) {
                var pair = vars[i].split("=");
                if(pair[0] == variable){return pair[1];}
            }
            return(false);
        }

        //风格切换  引入不同的css文件
        Theme.judge();
        vm.platformSetting = PlatformWechatSetting.get();
        var audio = document.getElementById("audio");
        audio.crossOrigin = 'anonymous';

        vm.orderOrigin = localStorage.getItem('orderOrigin');

        vm.storeCart = StoreConfig.byStoreIdAndType({type:'SHOPPINGCART'});
        var timer = null;
        $(".navbar-gallery").hide();
        $(".wrap").addClass('no-margins');
        vm.storeId = $stateParams.storeId  != null ? $stateParams.storeId: $localStorage.storeId;
        vm.aid = $stateParams.aid  != null ? $stateParams.aid: "";
        vm.soId = $stateParams.soId  != null ? $stateParams.soId: "";
        vm.lineId = $stateParams.lineId  != null ? $stateParams.lineId: "";
        vm.store = Store.get({id: vm.storeId});
        vm.article = entity;
        vm.counts = 0;
        vm.isHideMusicBtn = false;
        vm.requireUserProtocol = false;
        vm.current = Customer.current();
        vm.bcImg = "content/images/view_bg.png";
        if(vm.soId){
            SalesOrder.get({id:vm.soId}, function (res) {
                vm.salesOrder = res;
            })
        }
        PreviewSettings.byStoreId({storeId: vm.storeId},function (res) {
            if(!res.message.default){
                vm.bcImg = res.message.bcImg;
            }
        });
        StoreConfig.byStoreIdAndType({storeId:vm.storeId, type:"USERPROTOCOL"}, function (res) {
            if (res && res.type == "USERPROTOCOL") {
                vm.requireUserProtocol = res.open || false;
            }
        });

        //预览引导页
        var guideStepTt = localStorage.getItem('guide-share');
        if (guideStepTt == 'true') {
            vm.share = true;
        } else if (guideStepTt == 'false' || guideStepTt == '' || guideStepTt == null) {
            vm.share = false;
        }
        $scope.nextStep = function () {
            vm.share = true;
            localStorage.setItem('guide-share', vm.share);
        };
        js_getDPI()
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpiW = arrDPI[0];
            vm.windowDpiH = arrDPI[1];
            // vm.windowDpiW = 300;
            // vm.windowDpiH = 300;
            return arrDPI;
        }

        $scope.action_addCart = 'addCart';
        $scope.action_orderNow = 'orderNow';
        $scope.quantity = 1;
        vm.copy = 1;
        $scope.hideEdit = vm.article.id;
        $scope.price = 0;
        $scope.k = 0;
        $scope.musicPage = false;
        $scope.viewPage = false;
        $scope.isRandomView = false;
        $scope.dHeight = vm.dHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        $scope.dWidth = vm.dWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});

        var $li = $(".templateView2-carousel");
        $scope.domains = Qinius.getDomain1({storeId: vm.storeId});

        function Play() {
            audio.play().then(function () {
                $(".PanelRight").removeClass('hide1');
                timer = window.setInterval(begin, 10);
            }).catch(function (error) {
            });
        }

        var rot = 10 - 0;

        function begin(time) {
            var micon = document.getElementById("music-icon");
            if (micon == null) {
                window.clearInterval(timer);
                return;
            }
            micon.style.transform = "rotate(" + rot + "deg)";
            rot += 1;
        }

        $("#Play").on('click', function () {
            window.clearInterval(timer);
            if (audio.paused) {
                if ($(this).children().hasClass('play')) {
                    $("#Play").children("img").removeClass("play").addClass("pause");
                    Play();
                }
            }
            else {
                $("#Play").children("img").removeClass("pause").addClass("play");
                audio.pause();
            }
        });

        var monthStr = new Date().getMonth() + 1;
        var dayStr = new Date().getDate();
        var timestr = new Date().getFullYear() + "-" + (monthStr > 9 ? monthStr : "0" + monthStr) + "-" + (dayStr > 9 ? dayStr : "0" + dayStr);
        vm.showMessage = false;
        MessageNotification.getAllByCondition2({
            items: [{key: "available", op: "=", value: "true"}, {
                key: "validate",
                op: ">=",
                value: timestr
            }, {key: "pageType", op: "=", value: "View"}]
        }, function (res) {
            if(res.length > 0){
                vm.showMessage = true;
            }
            res.sort(compare('seq'))
            vm.message = angular.copy(res);
            vm.messageText = "\xa0\xa0\xa0\xa0\xa0\xa0";
            angular.forEach(res, function (resp) {
                vm.messageText += resp.content + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
            })
        });

        function compare(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            }
        }

        getAll();
        function getAll() {
            vm.itemValue = ItemValue.get({id: vm.article.itemValueId});
            $q.all([$scope.domains.$promise, vm.article.$promise, vm.platformSetting.$promise, vm.storeCart.$promise, vm.itemValue.$promise, vm.store.$promise]).then(function () {
                if(!vm.itemValue.bindingType){
                    vm.itemValue.bindingType = 'None'
                }
                if(vm.store.orderWay=='orderThenEditor' && vm.soId){
                    getSubmittable();
                }
                $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
                $scope.price = vm.itemValue.price;
                vm.copy = vm.itemValue.minimumOrder;
                if (vm.article.musicIdentify) {
                    audio.src = $scope.domain + vm.article.musicIdentify;
                } else {
                    if (vm.itemValue.musicIdentify) {
                        audio.src = $scope.domain + vm.itemValue.musicIdentify;
                    } else {
                        audio.src = '';
                        vm.isHideMusicBtn = true;
                    }
                }
                $scope.selectServices(vm.itemValue);
                getSalesOrders();

                vm.producxml = ProductXml.get({id:vm.article.productXmlId});


                if (wx) {
                    var url = "";
                    if (!!window.location.hash ) {
                        url = window.location.href.slice(0, -window.location.hash.length)
                    }else{
                        url =  window.location.href;
                    }

                    WxJS.get2({
                        url: url, storeId: vm.storeId
                    }, function (config) {
                        if (window.location.href.indexOf("?") == -1) {
                            wx.config({
                                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                                appId: config.appId, // 必填，公众号的唯一标识
                                timestamp: config.timestamp, // 必填，生成签名的时间戳
                                nonceStr: config.nonceStr, // 必填，生成签名的随机串
                                signature: config.signature,// 必填，签名，见附录1
                                jsApiList: [
                                    "checkJsApi",
                                    "onMenuShareTimeline",
                                    "onMenuShareAppMessage",
                                    "onMenuShareQQ",
                                    "onMenuShareQZone",
                                    'showOptionMenu',
                                    "onMenuShareWeibo"
                                ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                            });

                            // 异步处理，方法堆放一起
                            wx.ready(function () {
                                // 自动播放音乐
                                // Play();
                                WeixinJSBridge.invoke('getNetworkType', {}, function (e) {
                                    Play();
                                });

                                wx.showOptionMenu();

                                wx.checkJsApi({
                                    jsApiList: [
                                        "checkJsApi",
                                        "onMenuShareTimeline",
                                        "onMenuShareAppMessage",
                                        "onMenuShareQQ",
                                        "onMenuShareQZone",
                                        "onMenuShareWeibo"
                                    ],
                                    success: function (res) {
                                        wx.onMenuShareTimeline({
                                            title: '【' + vm.store.name + '】 ' + vm.article.name, // 分享标题
                                            link: vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + $stateParams.aid + "/view2/0?customerId=" + vm.current.id + "&type=WECHAT", // 分享链接
                                            imgUrl: $scope.domain + vm.article.cover,
                                            desc: '随意创作属于自己独一无二的作品',
                                            success: function (res) {
                                                Article.shareCallBack({id:vm.article.id})
                                            },
                                            cancel: function (res) {
                                            },
                                            fail: function (res) {
                                            }
                                        });
                                        //朋友
                                        wx.onMenuShareAppMessage({
                                            title: '【' + vm.store.name + '】 ' + vm.article.name, // 分享标题
                                            desc: '随意创作属于自己独一无二的作品', // 分享描述
                                            link: vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + $stateParams.aid + "/view2/0?customerId=" + vm.current.id + "&type=WECHAT", // 分享链接
                                            imgUrl: $scope.domain + vm.article.cover,
                                            type: 'link', // 分享类型,music、video或link，不填默认为link
                                            dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                                            success: function () {
                                                Article.shareCallBack({id:vm.article.id})
                                            },
                                            cancel: function () {
                                            }
                                        });
                                        wx.onMenuShareQQ({
                                            title: '【' + vm.store.name + '】 ' + vm.article.name, // 分享标题
                                            desc: '随意创作属于自己独一无二的作品', // 分享描述
                                            link: vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + $stateParams.aid + "/view2/0?customerId=" + vm.current.id + "&type=WECHAT", // 分享链接
                                            imgUrl: $scope.domain + vm.article.cover, // 分享图标
                                            success: function () {
                                                // 用户确认分享后执行的回调函数
                                                Article.shareCallBack({id:vm.article.id})
                                            },
                                            cancel: function () {
                                                // 用户取消分享后执行的回调函数
                                            }
                                        });
                                        wx.onMenuShareQZone({
                                            title: '【' + vm.store.name + '】 ' + vm.article.name, // 分享标题
                                            desc: '随意创作属于自己独一无二的作品', // 分享描述
                                            link: vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + $stateParams.aid + "/view2/0?customerId=" + vm.current.id + "&type=WECHAT", // 分享链接
                                            imgUrl: $scope.domain + vm.article.cover, // 分享图标
                                            success: function () {
                                                // 用户确认分享后执行的回调函数
                                                Article.shareCallBack({id:vm.article.id})
                                            },
                                            cancel: function () {
                                                // 用户取消分享后执行的回调函数
                                            }
                                        });
                                        wx.onMenuShareWeibo({
                                            title: '【' + vm.store.name + '】 ' + vm.article.name, // 分享标题
                                            desc: '随意创作属于自己独一无二的作品', // 分享描述
                                            link: vm.platformSetting.editorNotifyUrl + "/#/" + vm.storeId + "/" + $stateParams.aid + "/view2/0?customerId=" + vm.current.id + "&type=WECHAT", // 分享链接
                                            imgUrl: $scope.domain + vm.article.cover, // 分享图标
                                            success: function () {
                                                // 用户确认分享后执行的回调函数
                                                Article.shareCallBack({id:vm.article.id})
                                            },
                                            cancel: function () {
                                                // 用户取消分享后执行的回调函数  
                                            }
                                        });
                                    }
                                });
                            });
                        } else {
                            wx.config({
                                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                                appId: config.appId, // 必填，公众号的唯一标识
                                timestamp: config.timestamp, // 必填，生成签名的时间戳
                                nonceStr: config.nonceStr, // 必填，生成签名的随机串
                                signature: config.signature, // 必填，签名，见附录1
                                jsApiList: ['hideMenuItems'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                            });
                            wx.ready(function () {
                                wx.hideMenuItems({
                                    menuList: ["menuItem:copyUrl", "menuItem:share:timeline", "menuItem:share:appMessage", "menuItem:share:qq", "menuItem:share:appMessage", "menuItem:share:QZone"]
                                });

                                // 自动播放音乐
                                Play();
                            });
                        }

                        wx.error(function (res) {
                        });
                    }, function () {
                        Play();
                    });
                }
                Music.allByCondition({
                    items: [],
                    sort: ["id,asc"]
                }, function (resp) {
                    $scope.Musics = resp;
                });
                document.addEventListener('visibilitychange', startStopBgMedia);
                function startStopBgMedia() {
                    if (document.visibilityState === 'hidden') {
                        //切到后台
                        if (audio.play) {
                            $("#Play").children("img").removeClass("pause").addClass("play");
                            audio.pause();
                        }
                    } else if (document.visibilityState === 'visible') {
                        //切到前台
                        if (audio.paused) {
                            Play();
                        }
                    }
                }
                document.addEventListener('UniAppJSBridgeReady', function() {})
            });
        }

        vm.isSubmittable = false;
        function getSubmittable() {
            SalesOrder.submittable({id: vm.soId},function (res) {
                if(res.status == 200 && res.message == true){
                    vm.isSubmittable = true;
                }
            })
        }
        $scope.submitBackWorks = function () {
            if (vm.store.orderWay == 'orderThenEditor' && vm.requireUserProtocol){
                checkAgreement(vm.soId, function() {
                    requestSubmit(vm.soId);
                });
                return;
            } else {
                requestSubmit(vm.soId);
            }
        }
        $scope.submitBackWorks1 = function(){
            if(vm.distinguish == 'toutiaoApp'){
                tt.miniProgram.redirectTo({
                    url: '/pages/home/home?salesOrderId=' + $stateParams.soId + '&productLineId=' + $stateParams.lineId
                })
            }
            if(vm.distinguish == 'jdAppPlugins'){
                jd.miniProgram.redirectTo({
                    url: '/pages/home/home?salesOrderId=' + $stateParams.soId + '&productLineId=' + $stateParams.lineId
                })
            }
        }

        function requestSubmit(orderId) {
            SalesOrder.submit({
                id: orderId
            }, function(data) {
                swal({
                    title: "提交成功",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                $scope.goTorderList(true);
            }, function() {
                swal({
                    title: "提交失败!",
                    text: error.data.message,
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
            })
        }

        function checkAgreement(orderId, agreeCallback) {
            SalesOrder.get({id: orderId}, function (order) {
                var quantity = 0;
                for (var i = 0; i < order.productLines.length; i++) {
                    var line = order.productLines[i];
                    quantity += (line.copy || 0);
                }
                $uibModal.open({
                    templateUrl: 'app/entities/sales-order/modal/protocol-agreement2.html',
                    controller: 'OrderProtocolAgreement2Controller',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: [function () {
                            var _obj = {
                                number: order.number,
                                quantity: quantity
                            }
                            return _obj;
                        }]
                    }
                }).result.then(function (data) {
                    if (data && data.agree) {
                        if (agreeCallback) {
                            agreeCallback();
                        }
                    }
                });
            });
        }

        vm.orderLine={};
        $scope.selectServices = function (itemValue) {
            if (itemValue.itemValueLines == null || itemValue.itemValueLines.length == 0) {
                $scope.getFreightFee();
                return;
            }
            //拷贝 ItemValue到订单行上
            vm.orderLine.itemValue = angular.copy(itemValue);

            //将itemvalueLine的属性值 做成数组 绑定到 itemValueLine上
            for (var i = vm.orderLine.itemValue.itemValueLines.length - 1; i >= 0; i--) {
                var l = vm.orderLine.itemValue.itemValueLines[i];
                if (l.cancelled||!l.saleable) {
                    continue;
                }
                l.valueArray = l.skuNumber.split("-");
            }

            //将默认属性行 绑定到订单行上 如果没有默认属性行则取第一行
            for (var i = vm.orderLine.itemValue.itemValueLines.length - 1; i >= 0; i--) {
                var l = vm.orderLine.itemValue.itemValueLines[i];
                if (l.cancelled||!l.saleable) {
                    continue;
                }
                if (l.default) {
                    vm.orderLine.itemValueLine = angular.copy(l);
                    if (vm.orderLine.itemValueLine.itemValueObjectValues && vm.orderLine.itemValueLine.itemValueObjectValues.length>0) {
                        for (var z = vm.orderLine.itemValueLine.itemValueObjectValues.length - 1; z >= 0; z--) {
                            var value = vm.orderLine.itemValueLine.itemValueObjectValues[z];
                            value.selected = true;
                            for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                                if (value.propertyValueId == value.propertyValues[j].id) {
                                    value.propertyValues[j].selected = true;
                                }
                            }
                        }
                    }
                    break;
                }
            }
            if (vm.orderLine.itemValueLine == null) {
                for (var i = vm.orderLine.itemValue.itemValueLines.length - 1; i >= 0; i--) {
                    var line = vm.orderLine.itemValue.itemValueLines[i];
                    if (line.cancelled||!line.saleable) {
                        continue;
                    }else{
                        vm.orderLine.itemValueLine = angular.copy(line);
                        break;
                    }
                }

            }

            if (vm.orderLine.itemValueLine == null) {
                swal({
                    title: "商品配置不全!",
                    text: "请联系客服后再下单",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                return;
            }

            var valueArray = vm.orderLine.itemValue.skuNumber.split("-");
            if (vm.orderLine.itemValueLine && vm.orderLine.itemValueLine.itemValueObjectValues && vm.orderLine.itemValueLine.itemValueObjectValues.length>0) {
                for (var i = vm.orderLine.itemValueLine.itemValueObjectValues.length - 1; i >= 0; i--) {
                    var value = vm.orderLine.itemValueLine.itemValueObjectValues[i];
                    for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                        var pv = value.propertyValues[j];
                        if (pv.selected) {
                            valueArray.push(pv.propertyId + "_" + pv.id);
                        }
                    }
                }
            }

            itemValueLineSel = $scope.selectedItemValueLine(valueArray);

            if(!vm.orderLine.itemValueLine){
                swal({
                    title: "该商品已下架!",
                    text: "请重新选择",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                vm.isSaleable = false;
                return
            }

            $scope.restoreAvailable(vm.orderLine.itemValueLine.itemValueObjectValues);
            $scope.getAvailable(vm.orderLine.itemValueLine.itemValueObjectValues);

            $scope.getFreightFee();
            getByItemValueLineId();
        }

        var itemValueLineSel = null;
        //点选属性值事件
        $scope.switchPaper = function (values , value,  pValue) {
            if (!pValue.available) {
                return;
            }

            for (var i = value.propertyValues.length - 1; i >= 0; i--) {
                if (value.propertyValues[i].id == pValue.id) {
                    continue;
                }
                value.propertyValues[i].selected = false;
            }
            pValue.selected  = !pValue.selected;
            if (pValue.selected) {
                value.selected = true;
            }else{
                value.selected = false;
            }
            $scope.restoreAvailable(values);
            $scope.getAvailable(values);

            var valueArray = vm.orderLine.itemValue.skuNumber.split("-");
            for (var i = values.length - 1; i >= 0; i--) {
                var v = values[i];
                for (var j = v.propertyValues.length - 1; j >= 0; j--) {
                    var pv = v.propertyValues[j];
                    if (pv.selected) {
                        valueArray.push(pv.propertyId + "_" + pv.id);
                    }
                }
            }
            itemValueLineSel = $scope.selectedItemValueLine(valueArray);
            getByItemValueLineId()
            $scope.getFreightFee();
        }

        //判断是否已经匹配到ItemValueline了
        $scope.selectedItemValueLine = function(valueArray){
            for (var z = vm.orderLine.itemValue.itemValueLines.length - 1; z >= 0; z--) {
                var l = vm.orderLine.itemValue.itemValueLines[z];
                if (l.cancelled||!l.saleable) {
                    continue;
                }
                if (JSON.stringify(l.valueArray.sort()) === JSON.stringify(valueArray.sort())) {
                    return l;
                }
            }
            return null;

        }

        //先将所有可选项置为可选择的
        $scope.restoreAvailable = function(values) {
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                    value.propertyValues[j].available = true;
                }
            }
        }

        //判断余下属性值是否可以点选
        $scope.getAvailable = function(values) {
            var selectedValue = [];
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                    if (!$scope.isAvailable([value.propertyValues[j].propertyId + "_" + value.propertyValues[j].id])) {
                        value.propertyValues[j].available = false;
                    }
                }
            }

            //先将选择的都拿出来
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                if (value.selected) {
                    for (var j = value.propertyValues.length - 1; j >= 0; j--) {
                        if (value.propertyValues[j].selected) {
                            selectedValue.push(value.propertyValues[j].propertyId + "_" +value.propertyValues[j].id);
                        }
                    }
                }
            }
            //没有选中的行 选项是否可以被选择
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                if (!value.selected) {
                    for (var z = value.propertyValues.length - 1; z >= 0; z--) {
                        var pValue = value.propertyValues[z];
                        if (!pValue.available) {
                            continue;
                        }
                        var selectedValueBack = angular.copy(selectedValue);
                        selectedValueBack.push(pValue.propertyId+ "_" + pValue.id);
                        if (!$scope.isAvailable(selectedValueBack)) {
                            pValue.available = false;
                        }
                    }
                }
            }

            //选中的行  将本行取出 拿本行其他选项与 其他行已经选中的搭配看是否可以选中
            for (var i = values.length - 1; i >= 0; i--) {
                var value = values[i];
                if (value.selected ) {
                    var selectedValueBack = angular.copy(selectedValue);
                    for (var j = selectedValueBack.length - 1; j >= 0; j--) {
                        var selectV = selectedValueBack[j];  //3_10
                        if ((value.propertyId+"_"+  value.propertyValueId) == selectV) {
                            for (var z = value.propertyValues.length - 1; z >= 0; z--) {
                                var pValue = value.propertyValues[z];
                                if ((pValue.propertyId +"_"+ pValue.id) == selectV || !pValue.available) {
                                    continue;
                                }
                                if (!$scope.isAvailable(selectedValueBack)) {
                                    pValue.available = false;
                                }
                            }
                        }
                    }
                }
            }
        }

        //判断属性值是否可以点选
        $scope.isAvailable = function(selectedValueBack) {
            for (var z = vm.orderLine.itemValue.itemValueLines.length - 1; z >= 0; z--) {
                var l = vm.orderLine.itemValue.itemValueLines[z];
                if (l.cancelled||!l.saleable) {
                    continue;
                }

                var includes = true;
                for (var i = selectedValueBack.length - 1; i >= 0; i--) {
                    var el = selectedValueBack[i];
                    if (!$scope.isInclude(el, l.valueArray)) {
                        includes = false;
                        break;
                    }
                }
                if (includes) {
                    return true;
                }
            }
            return false;
        }

        $scope.isInclude = function(elememt, array) {
            if (array.indexOf(elememt)>=0) {
                return true;
            }
            return false;
        }

        $scope.minusCopy = function () {
            if (vm.copy > vm.itemValue.minimumOrder) {
                vm.copy--;
                $scope.getFreightFee();
            }else{
                vm.hint = "起购数是" + vm.itemValue.minimumOrder + "件";
                vm.isHint = true;
                $timeout(function(){
                    vm.isHint = false;
                },1500)
                return;
            }
        }

        $scope.addCopy = function () {
            if (vm.copy >= vm.limitQuantity  && vm.limitQuantity!=null) {
                vm.hint = "最多可购买" + vm.limitQuantity + "件";
                vm.isHint = true;
                $timeout(function(){
                    vm.isHint = false;
                },1500)
                return;
            }
            vm.copy++;
            $scope.getFreightFee();
        }

        $scope.num = function(copy){
            if (copy == 0 || copy == null || copy <= vm.itemValue.minimumOrder) {
                vm.copy = vm.itemValue.minimumOrder
            }
            if(vm.limitQuantity != null && vm.copy > vm.limitQuantity){
                vm.hint = "最多可购买" + vm.limitQuantity + "件";
                vm.isHint = true;
                $timeout(function(){
                    vm.isHint = false;
                },1500)
                vm.copy = vm.limitQuantity
                return;
            }
            vm.copy = Math.ceil(copy)
            $scope.getFreightFee();
        }

        $scope.getFreightFee = function () {
            var parameter = {
                productId: vm.orderLine.itemValueLine.productId,
                itemValueId: vm.orderLine.itemValueLine.itemValueId,
                itemValueLineId: vm.orderLine.itemValueLine.id,
                quantity:vm.article.pageCount,
                copy : vm.copy,
            }
            Product.getCostV2(parameter,function(data){
                if (data.status == 200) {
                    $scope.price = data.message.toFixed(2);
                }
            },function(error){
                swal({
                    title: error.data.errorMessage,
                    text: "",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
            })
        }

        //旋转屏幕
        window.addEventListener('resize', function (event) {
            var status = localStorage.getItem('Production');
            if (status == 'make') {
                return
            }
            // alert(window.location.href);
            if (window.location.href.indexOf('choose') != -1) {
                return
            }

            // if (vm.bindingtype == 'None' || $scope.template.pages.page.length < 2) {
            //     return
            // }

            if (window.orientation == 0) {
                $rootScope.orientations = false;
            }
            setReviewDoc();
        });

        if (window.orientation == 90 || window.orientation == -90) {
            $li.width($scope.dWidth);
            $rootScope.orientation = false;
        } else {
            $rootScope.orientations = true;
        }

        function setReviewDoc() {
            $(".save-page").removeClass('hide1');
            $(".view2-article").addClass('hide1');
            $(".share-wrap").addClass('hide1');
            setTimeout(function () {
                if (window.orientation == 180 || window.orientation == 0) {
                    $rootScope.orientations = true;
                    orientationChange();

                } else if (window.orientation == 90 || window.orientation == -90) {
                    $rootScope.orientations = false;
                    orientationChange();
                }
            }, 500);
        }

        function orientationChange() {
            $scope.k = 0;
            $scope.dHeight = document.documentElement.clientHeight;
            $scope.dWidth = document.documentElement.clientWidth;
            $(".back-to-customer").width($scope.dWidth);
            $li.width($scope.dWidth);
            // var newDoc = angular.copy(entity.document);
            getAll();
            setTimeout(function () {
                $(".save-page").addClass('hide1');
                $(".view2-article").removeClass('hide1');
                $(".share-wrap").removeClass('hide1');
            }, 1500);
        }

        $scope.toggle = {
            now: false
        };

        $scope.goToCustomer = function () {
            if (localStorage.getItem('distinguish') == 'weChatApplet' || localStorage.getItem('distinguish') == 'weChatAppletMake' || localStorage.getItem('distinguish') == 'toutiaoApp' || localStorage.getItem('distinguish') == 'alipayApp' || localStorage.getItem('distinguish') == 'jdApp') {
                if(localStorage.getItem('distinguish') == 'jdApp'){
                    jd.miniProgram.redirectTo({url: '/pages/information/myInformation/myInformation'})
                    return
                }
                uni.redirectTo({
                    url: '/pages/information/myInformation/myInformation'
                })

            } else {
                window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/customer';
                // if (localStorage.getItem('themeFlag') == 'two' || localStorage.getItem('themeFlag') == 'three') {
                //     window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/customerTwo';
                // } else {
                //     window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/customer';
                // }
            }
        };

        $scope.editWork = function () {
            var u = navigator.userAgent;
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
            if(vm.store.navigateRule == "mini" && isAndroid && (localStorage.getItem('distinguish') == 'toutiaoApp' || localStorage.getItem('distinguish') == 'alipayApp' || localStorage.getItem('distinguish') == 'jdApp')){
                if(localStorage.getItem('distinguish') == 'jdApp'){
                    jd.miniProgram.redirectTo({url: '/pages/editor/editor-new/editor-new?articleId=' + vm.article.id + '&salesOrderId=' + vm.soId + '&productLineId=' + vm.lineId})
                    return
                }
                uni.redirectTo({
                    url: '/pages/editor/editor-new/editor-new?articleId=' + vm.article.id + '&salesOrderId=' + vm.soId + '&productLineId=' + vm.lineId
                });
                return
            }
            window.removeEventListener("resize", setReviewDoc, false);

            if (vm.article.editorType == 'BOOK_JUNIOR') {
                $state.go('product.chooses', {storeId: vm.storeId, aid: vm.article.id, soId: vm.soId, lineId: vm.lineId}, {reload: true});
            } else if (vm.article.editorType == null || vm.article.editorType == "" || vm.article.editorType == 'BOOK_SENIOR') {
                $state.go('product.choosesNew', {storeId: vm.storeId, aid: vm.article.id, soId: vm.soId, lineId: vm.lineId}, {reload: true});
            }else if (vm.article.editorType == 'NEW_EDITOR'||vm.article.editorType == 'CALENDAR_EDITOR') {
                $state.go('product.editorPhotoAlbum-edit', {storeId: vm.storeId, aid: vm.article.id, soid: vm.soId, plid: vm.lineId}, {reload: true});
            }

            localStorage.setItem("Production", "view");
            //清除关于作品的localStorage
            localStorage.removeItem("choose");
            localStorage.removeItem("multipartUpload");
            localStorage.removeItem("index");
            localStorage.removeItem("i");
            localStorage.removeItem("marginLeft");
            localStorage.removeItem("single");
            localStorage.removeItem("pages");
            localStorage.removeItem("gallery");
            localStorage.removeItem("galleryCopy");
            localStorage.removeItem("reviewPages");
            localStorage.removeItem("saveOldPage");
            localStorage.removeItem("saveOldPageIndex");
            localStorage.removeItem("pageAllPx");
            localStorage.removeItem("pageAllMm");
            localStorage.removeItem("view-scrollTop");
            localStorage.removeItem("work-save");
            localStorage.removeItem("delPic");
            localStorage.removeItem("picArrChoose");
            localStorage.removeItem("picArrChoose2");
            localStorage.removeItem("gallery2");
            localStorage.removeItem("picArrCopy");
            localStorage.removeItem("picArr");
        };
        function addAlertClass() {
            setTimeout(function () {
                $(".sweet-alert").addClass('folder-alert').addClass('whiteBg');
            })
        }
        function removeAlertClass() {
            setTimeout(function () {
                $(".sweet-alert").removeClass('folder-alert').removeClass('whiteBg');
            },200)
        }

        $scope.deleteWork = function () {
            addAlertClass()
            swal({
                title: "确认删除该作品？",
                text: "删除后我的购物车里面该作品也会删除",
                // imageUrl: Theme.sweetHintImg(),
                showCancelButton: true,
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                closeOnConfirm: false
            }, function () {
                Article.delete({
                    id: vm.article.id
                }, function () {
                    removeAlertClass()
                    swal({
                        title: "删除成功",
                        text: "",
                        imageUrl: Theme.sweetSuccedImg(),
                        timer: 2000,
                        showConfirmButton: false
                    }, function () {
                        if (vm.platformSetting == null || vm.platformSetting.storeNotifyUrl == null) {
                            swal({
                                title: "页面跳转失败",
                                text: "请稍后再试，或者联系管理员",
                                imageUrl: Theme.sweetHintImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                        }
                        if (localStorage.getItem('distinguish') == 'weChatApplet' || localStorage.getItem('distinguish') == 'weChatAppletMake') {
                            wx.miniProgram.navigateBack();
                        } else {
                            window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/article';
                        }
                    });
                }, function () {
                });

            });
        };
        $scope.deleteArticle = function () {
            addAlertClass()
            swal({
                title: "确认删除该作品？",
                text: "删除后我的购物车里面该作品也会删除",
                // imageUrl: Theme.sweetHintImg(),
                showCancelButton: true,
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                closeOnConfirm: false
            }, function () {
                removeAlertClass()
                SalesOrder.deleteArticle({
                    id: vm.soId, articleId:vm.article.id, productLineId:vm.lineId
                }, function () {
                    swal({
                        title: "删除成功",
                        text: "",
                        imageUrl: Theme.sweetSuccedImg(),
                        timer: 2000,
                        showConfirmButton: false
                    }, function () {
                        if (vm.platformSetting == null || vm.platformSetting.storeNotifyUrl == null) {
                            swal({
                                title: "页面跳转失败",
                                text: "请稍后再试，或者联系管理员",
                                imageUrl: Theme.sweetHintImg(),
                                timer: 2000,
                                showConfirmButton: false
                            });
                        }
                        $scope.goTorderList();

                    });
                }, function () {
                });

            });
        };

        $scope.toggleEditMenu = function (event) {
            $scope.selectMenuShow = false;
            event.stopPropagation();
            $('.edit-menu1').removeClass('hide1');
            $scope.showEditMenu = !$scope.showEditMenu;
        };

        $scope.addToCart = function () {
            if (!vm.article.completed) {
                MessageService.hint("该作品还未完成");
                return;
            }
            vm.copy = vm.itemValue.minimumOrder;
            $scope.quantity = 1;
            $scope.selectMenuShow = true;
            $scope.showEditMenu = false;
            $scope.action = $scope.action_addCart;
        };

        $scope.addCart = function () {
            if (!articleCheck(vm.article, vm.itemValue,vm.producxml)) {
                swal({
                    title: "立即购买失败",
                    text: "该作品已经下架，不能再购买",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                return;
            } else if (!vm.article.completed) {
                swal({
                    title: "添加购物车失败",
                    text: "该作品还未完成，不能添加到购物车",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
            } else if (!itemValueLineSel&&vm.orderLine.itemValueLine.itemValueObjectValues.length>0){
                swal({
                    title: "添加购物车失败",
                    text: "请选择扩展属性",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
            } else {
                var arr={
                    articleId: vm.article.id,
                    productId: vm.article.productId,
                    price: $scope.price,
                    copy: vm.copy,
                    quantity: $scope.quantity,
                    itemValueLineId: vm.orderLine.itemValueLine.id,
                    itemValueId: vm.itemValue.id,
                    // lineSku: vm.orderLine.itemValueLine.sku,
                    // lineSkuNumber: vm.orderLine.itemValueLine.skuNumber,
                    orderWay: 'editorThenOrder'
                }
                if(itemValueLineSel){
                    arr.itemValueLineId = itemValueLineSel.id;
                    // arr.lineSku = itemValueLineSel.sku;
                    // arr.lineSkuNumber = itemValueLineSel.skuNumber;
                }
                if (vm.limitQuantity === 0 || (vm.limitQuantity < vm.itemValue.minimumOrder && vm.limitQuantity != null)) {
                    vm.hint = "库存不足，无法购买更多";
                    vm.isHint = true;
                    $timeout(function(){
                        vm.isHint = false;
                    },1500)
                    return
                }
                var verify = false;
                Cart.countByItemValueLineId({itemValueLineId: vm.orderLine.itemValueLine.id},function(res){
                    var goodsNumber = res.message
                    if ((vm.limitQuantity - goodsNumber <= 0 || vm.limitQuantity === 0) && vm.limitQuantity !=null) {
                        verify = true;
                        vm.hint = "库存不足，无法购买更多";
                        vm.isHint = true;
                        $timeout(function(){
                            vm.isHint = false;
                        },1500)
                        return
                    }
                    if ((vm.limitQuantity < goodsNumber + vm.copy) && vm.limitQuantity !=null) {
                        vm.copy = vm.limitQuantity - goodsNumber
                    }
                    if (!verify) {
                        Cart.addGoods({
                            articleId: vm.article.id,
                            copy: vm.copy,
                            quantity: 1,
                            productId: vm.article.productId,
                            itemValueLineId:arr.itemValueLineId,
                            itemValueId: vm.article.itemValueId,
                            orderWay: 'editorThenOrder',
                            productXmlId: vm.article.productXmlId,
                        }, function (res) {
                            if(res.status == 400){
                                swal({
                                    title: "失败",
                                    text: res.message || "加入购物车失败",
                                    imageUrl: Theme.sweetHintImg(),
                                    timer: 2000,
                                    showConfirmButton: false
                                });
                                return
                            }
                            if (vm.platformSetting == null || vm.platformSetting.storeNotifyUrl == null) {
                                swal({
                                    title: "页面跳转失败",
                                    text: "请稍后再试，或者联系管理员",
                                    imageUrl: Theme.sweetHintImg(),
                                    timer: 2000,
                                    showConfirmButton: false
                                });
                            }
                            swal({
                                title: "成功加入购物车",
                                text: "",
                                imageUrl: Theme.sweetSuccedImg(),
                                timer: 1500,
                                showConfirmButton: false
                            });
                            $timeout(function () {
                                if (localStorage.getItem('distinguish') == 'weChatApplet' || localStorage.getItem('distinguish') == 'weChatAppletMake' || localStorage.getItem('distinguish') == 'toutiaoApp' || localStorage.getItem('distinguish') == 'alipayApp' || localStorage.getItem('distinguish') == 'jdApp') {
                                    if(localStorage.getItem('distinguish') == 'jdApp'){
                                        jd.miniProgram.redirectTo({url: '/pages/shop/shoppingCar/shoppingCar'})
                                        return
                                    }
                                    uni.redirectTo({
                                        url: '/pages/shop/shoppingCar/shoppingCar'
                                    })
                                } else {
                                    window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/cart';
                                }
                            },1500)
                        }, function () {
                        })
                    }
                })
            }

        };

        $scope.changeNum = function (type) {
            if (type == 1) {
                vm.copy = vm.copy + 1;
            } else {
                if (vm.copy - 1 < 1) {
                    return;
                }
                vm.copy = vm.copy - 1;
            }
        };

        $scope.purchase = function (arr) {
            if (vm.platformSetting == null || vm.platformSetting.storeNotifyUrl == null) {
                swal({
                    title: "页面跳转失败",
                    text: "请稍后再试，或者联系管理员",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
            }

            window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/sales-order/' + arr.ids + '/' + arr.quantity +'/'+ arr.itemValueLineId +'/'+ arr.itemValueId +'/'+ arr.copy +'/'+ arr.productId + '//new2';

        };
        $scope.orderNow = function () {
            if (!vm.article.completed) {
                MessageService.hint("该作品还未完成");
                return;
            }
            var target = localStorage.getItem('target');
            if(target=='commandMini'){
                commandMiniOrder();
                return
            }
            vm.copy = vm.itemValue.minimumOrder;
            $scope.quantity = 1;
            $scope.selectMenuShow = true;
            $scope.showEditMenu = false;
            $scope.action = $scope.action_orderNow;

        };

        function commandMiniOrder(){
            $scope.selectMenuShow = false;
            $scope.showEditMenu = false;
            $scope.action = $scope.action_orderNow;
            vm.copy = 1;
            if (!articleCheck(vm.article, vm.itemValue,vm.producxml)) {
                swal({
                    title: "立即购买失败",
                    text: "该作品已经下架，不能再购买",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                return;
            }else if (!vm.article.completed) {
                swal({
                    title: "立即购买失败",
                    text: "该作品还未完成，不能购买",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                return;
            }


            if (localStorage.getItem('distinguish') == 'weChatApplet' || localStorage.getItem('distinguish') == 'weChatAppletMake' || localStorage.getItem('distinguish') == 'toutiaoApp' || localStorage.getItem('distinguish') == 'alipayApp' || localStorage.getItem('distinguish') == 'jdApp') {
                $("#Play").children("img").removeClass("pause").addClass("play");
                audio.pause();
                uni.navigateTo({
                    url: "/pages/shop/placeOrder/placeOrder?jsonStr={\"id\":" + vm.article.id + "}&from=productIntroduce"
                });
                return;
            }
        }

        $scope.orderNowIn = function(){
            if (vm.limitQuantity === 0 || (vm.limitQuantity < vm.itemValue.minimumOrder && vm.limitQuantity != null)) {
                vm.hint = "库存不足，无法购买更多";
                vm.isHint = true;
                $timeout(function(){
                    vm.isHint = false;
                },1500)
                return
            }
            if (!articleCheck(vm.article, vm.itemValue,vm.producxml)) {
                swal({
                    title: "立即购买失败",
                    text: "该作品已经下架，不能再购买",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                return;
            }else if (!vm.article.completed) {
                swal({
                    title: "立即购买失败",
                    text: "该作品还未完成，不能购买",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                return;
            }
            if (!itemValueLineSel&&vm.orderLine.itemValueLine.itemValueObjectValues.length>0){
                swal({
                    title: "立即购买失败",
                    text: "请选择扩展属性",
                    imageUrl: Theme.sweetHintImg(),
                    timer: 2000,
                    showConfirmButton: false
                });
                return
            }
            $scope.closeModal();
            $uibModal.open({
                templateUrl: 'app/entities/sales-order/modal/protocol-agreement2.html',
                controller: 'OrderProtocolAgreement2Controller',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: [function () {
                        var _obj = {
                            orderWay: true
                        }
                        return _obj;
                    }]
                }
            }).result.then(function (data) {
                if (data && data.agree) {
                    var arrmMini = {
                        articleId:vm.article.id,
                        itemValueLineId:vm.orderLine.itemValueLine.id,
                        productId: vm.article.productId,
                        itemValueId: vm.itemValue.id,
                        copy:vm.copy,
                        lineSku: vm.orderLine.itemValueLine.sku,
                        lineSkuNumber: vm.orderLine.itemValueLine.skuNumber,
                    }

                    if(itemValueLineSel){
                        arrmMini.itemValueLineId = itemValueLineSel.id;
                        arrmMini.lineSku = itemValueLineSel.sku;
                        arrmMini.lineSkuNumber = itemValueLineSel.skuNumber;
                    }
                    if (localStorage.getItem('distinguish') == 'weChatApplet' || localStorage.getItem('distinguish') == 'weChatAppletMake' || localStorage.getItem('distinguish') == 'toutiaoApp' || localStorage.getItem('distinguish') == 'alipayApp' || localStorage.getItem('distinguish') == 'jdApp') {
                        $("#Play").children("img").removeClass("pause").addClass("play");
                        audio.pause();
                        var str = JSON.stringify({
                            articleId:arrmMini.articleId,
                            itemValueLineId:arrmMini.itemValueLineId,
                            productId: arrmMini.productId,
                            itemValueId: arrmMini.itemValueId,
                            copy:vm.copy
                        });
                        if(localStorage.getItem('distinguish') == 'jdApp'){
                            jd.miniProgram.redirectTo({url: '/pages/shop/placeOrder/placeOrder?jsonStr=' + str})
                            return
                        }
                        uni.redirectTo({
                            url: '/pages/shop/placeOrder/placeOrder?jsonStr=' + str
                        })
                    } else {
                        // productXmlId
                        window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/sales-order/' + vm.article.id + '/1/'+ arrmMini.itemValueLineId +'/'+ vm.itemValue.id +'/'+ vm.copy +'/'+ vm.article.productId + '//new2';
                    }
                }
            })
        }

        $scope.addQuantity = function () {
            vm.copy++;
        };

        $scope.minusQuantity = function () {
            if (vm.copy > 1) {
                vm.copy--;
            }
        };

        $scope.changeQuantity = function () {
            if (vm.copy == 0) {
                vm.copy = 1;
            }
        };

        $scope.closeModal = function () {
            $scope.selectMenuShow = false;
            $scope.showEditMenu = false;
            vm.copy = 1;
        };

        $scope.checkNum = function (copy) {
            if (copy == '' || copy == null || copy < 1) {
                vm.copy = 1;
            }
            if (/[^\d]/.test(vm.copy)) {//替换非数字字符
                vm.copy = vm.copy.replace(/[^\d]/g, '');
            }
        };

        var audioTest = null;
        $scope.openMusicList = function () {
            $scope.musicPage = true;
            audio.pause();
            vm.musicIdentify = vm.article.musicIdentify;
            if (vm.article.musicIdentify) {
                angular.forEach($scope.Musics, function (music, i) {
                    if (music.identify == vm.article.musicIdentify) {
                        $(".music-current").hide();
                        $(".music-current" + i).show();
                        setMusicStyle(i)
                    }
                })
            }

            audioTest = document.getElementById("audition");
            audioTest.crossOrigin = 'anonymous';
        };

        $scope.playList = function (identify, index) {
            // audioTest.pause();
            audioTest.src = $scope.domain + identify;
            vm.musicIdentify = identify;
            audioTest.play();
            setMusicStyle(index);
        };

        function setMusicStyle(index) {
            $(".music-name").removeClass('music-active');
            $(".music-name" + index).addClass('music-active');
            $(".music-icon").hide();
            $(".music-icon" + index).show();

        }

        $scope.backTo = function () {
            $scope.musicPage = false;
            audioTest.pause();
            audio.play();
        };

        $scope.saveMusic = function () {
            if (vm.musicIdentify == null || vm.musicIdentify == "") {
                $scope.musicPage = false;
            } else {
                Article.updateMusic({id: vm.article.id, musicIdentify: vm.musicIdentify}, function (data) {
                    vm.article = data;
                    $scope.musicPage = false;
                    audio.src = $scope.domain + vm.article.musicIdentify;
                    audioTest.pause();
                    audio.play();
                }, function () {

                })
            }

        }

        $scope.isIphoneX = false;
        var windowH = $(window).height();
        var h = localStorage.getItem('windowMinH');
        if (!h) {
            localStorage.setItem('windowMinH', windowH);
            h = windowH;
        }

        $(window).scroll(function () {
            if ($scope.isIphoneX) {
                setTimeout(function () {
                    var wh = $(window).height(); //页面可视化区域高度
                    if (wh > h) {
                        $(".iphoneX-content").height(36);
                    } else {
                        $(".iphoneX-content").height(0);
                    }
                },400);
            }
        })

        vm.productLine={};
        $scope.goTorderList = function (item) {

            // /{storeId}/{soId}/{lineId}/article2
            if (localStorage.getItem('distinguish') == 'weChatApplet' || localStorage.getItem('distinguish') == 'weChatAppletMake' || localStorage.getItem('distinguish') == 'toutiaoApp' || localStorage.getItem('distinguish') == 'alipayApp' || localStorage.getItem('distinguish') == 'jdApp') {
                if(!item){
                    if(localStorage.getItem('distinguish') == 'jdApp'){
                        jd.miniProgram.redirectTo({url: '/pages/portfolio/workManagement/workManagement?productLineId=' + $stateParams.lineId + '&salesOrderId=' + $stateParams.soId})
                        return
                    }
                    uni.redirectTo({
                        url: '/pages/portfolio/workManagement/workManagement?productLineId=' + $stateParams.lineId + '&salesOrderId=' + $stateParams.soId
                    })
                }else{
                    var item = {typeId: 0,name: '全部'};
                    var str = JSON.stringify(item);
                    if(localStorage.getItem('distinguish') == 'jdApp'){
                        jd.miniProgram.redirectTo({url: '/pages/information/orderListPage/orderListPage?jsonStr=' + str})
                        return
                    }
                    uni.redirectTo({
                        url: '/pages/information/orderListPage/orderListPage?jsonStr=' + str
                    });
                }
            } else {
                if(!item){
                    window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/' + $stateParams.soId + '/' + $stateParams.lineId + '/article2';
                }else{
                    window.location.href = vm.platformSetting.storeNotifyUrl + '/#/' + vm.storeId + '/sales-order/All';
                }
            }
        }

        function getSalesOrders() {
            if(vm.store.orderWay=='orderThenEditor' && $stateParams.soId){
                SalesOrder.get({id: $stateParams.soId},function (res) {
                    res.productLines.forEach(function (productLine) {
                        if(productLine.id == $stateParams.lineId){
                            vm.productLine = productLine;
                        }
                    })
                })
            }
        }
        function getByItemValueLineId(){
            SkuSellLimits.getByItemValueLineId({productId:vm.orderLine.itemValueLine.productId,itemValueLineId:vm.orderLine.itemValueLine.id},function(res){
                if (res.message) {
                    vm.limitQuantity = res.message.limitQuantity
                    vm.warningQuantity = res.message.warningQuantity
                }else{
                    vm.limitQuantity = null
                    vm.warningQuantity = null
                }
            })
        }
    }
})();
