(function () {
    'use strict';
//相册 万物编辑器
    angular
        .module('editorApp')
        .controller('EditorAllController', EditorAllController);
    EditorAllController.$inject = ['$scope', 'FontManagement', 'UidService', 'MessageService', '$stateParams', '$q', 'SalesOrder',
    'ThemeClassify', 'ProductXml', 'Store', 'PlatformWechatSetting','Qinius','Folder','$uibModal', 'Gallery', '$localStorage', 'Material', 'ClipArt', 'FontXmlsManger','Backgrounds','Product',
    'ItemValue', 'Convert', '$http', '$timeout', 'FontXmlsFileServes', 'Article', 'entity', '$state', 'SweetAlert', 'Theme','MessageNotification', 'DateUtils','ListService', 'OrderLine','ImageService','OrderArticle', 'CustomerUploadAble','Customer',
    'StoreConfig', 'CustomerStorages', 'FrameConfigs', '$rootScope','Algorithm','$uibModalStack','$websocket'];

    function EditorAllController( $scope, FontManagement, UidService, MessageService, $stateParams, $q, SalesOrder,
        ThemeClassify, ProductXml, Store, PlatformWechatSetting, Qinius, Folder, $uibModal, Gallery, $localStorage, Material, ClipArt, FontXmlsManger,Backgrounds,Product,
      ItemValue, Convert, $http, $timeout, FontXmlsFileServes, Article, entity, $state, SweetAlert, Theme,MessageNotification, DateUtils,ListService, OrderLine,ImageService, OrderArticle, CustomerUploadAble,Customer,
                                  StoreConfig, CustomerStorages, FrameConfigs, $rootScope,Algorithm,$uibModalStack, $websocket) {
        var vm = this;

        $(".navbar").hide();
        $(".save-page").removeClass('hide1');
        $(".wrap").hide();
        vm.leftShow = true;//菜单栏隐藏
        vm.rightShow = true;//菜单栏隐藏
        //风格切换  引入不同的css文件
        Theme.judge();
        vm.Theme = localStorage.getItem('Theme');
        //获取dpi
        js_getDPI(function (dpi) {
            vm.windowDpi = dpi;
        });
        if($stateParams.authorization){
            if($stateParams.source){
                $localStorage.authenticationTokenSource = $stateParams.authorization;
            }else{
                $localStorage.authenticationToken = $stateParams.authorization;
            }
        };
        vm.isEdit = entity.type == 'edit' ? true : false;
        vm.storeId = $stateParams.storeId;
        vm.pid = $stateParams.pid;
        vm.aid = $stateParams.aid;
        vm.vid = $stateParams.vid;
        vm.xmlId = $stateParams.xmlId;
        vm.salesOrderId = $stateParams.soid ? $stateParams.soid : null;
        vm.productLineId = $stateParams.plid ? $stateParams.plid : null;
        vm.source='editorAll';
        if ($localStorage.user != null) {
            vm.userId = $localStorage.user.id
        }
        var arr = localStorage.getItem('save-article');
        if (arr && vm.pid) {
            $state.go("editorAllEdit", {storeId: vm.storeId, aid: arr, soid: vm.salesOrderId, plid: vm.productLineId, returnUrl:$stateParams.returnUrl});
        }
        localStorage.removeItem('save-article');
        $scope.domains = Qinius.getDomain1();
        vm.tokenTime = 360000;
        Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"},function (msg) {
            vm.token = msg.token;
            vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
        });
        vm.platformSetting = PlatformWechatSetting.get();
        vm.store = Store.get({id: vm.storeId});
        vm.photoSelectAll = JSON.parse(localStorage.getItem("photoSelectAll")) || [];
        vm.elementOn = {
            index:0,
            pageIndex:0,
            name:'',
            item:null
        };
        vm.customer = Customer.current();
        vm.levelAll=[];
        vm.maskType='recommend';
        vm.cookies=[];
        vm.pageNumber = 0;
        vm.hint = false;
        vm.threeLoading = true;
        vm.threeLoadingText = '加载中...';

        /***************页面处理************start*****************/
        $q.all([$scope.domains.$promise, vm.platformSetting.$promise, vm.store.$promise, vm.customer.$promise]).then(function () {
            if(!vm.userId){
                vm.userId = vm.customer.id
            }
            $(".navbar").hide();
            $(".save-page").removeClass('hide1');
            $(".wrap").hide();
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            setTimeout(function fn() {
                Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"},function (msg) {
                    vm.token = msg.token;
                    vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
                });
                setTimeout(fn, vm.tokenTime);
            }, vm.tokenTime);
            vm.logo = vm.store.menuLogo ? $scope.domain + vm.store.menuLogo : 'content/images/momentsgo.png';

            //获取字体
            FontManagement.getAll2({family: vm.selectFont}, function (res) {
                vm.fontList = angular.copy(res);
                vm.fontList1 = angular.copy(res);
                $scope.item = vm.fontList[0];
                vm.defaultFont = res[0];
                res.forEach(function (item) {
                    if(item.defaultShow){
                        vm.defaultFont = item;
                    }
                })
            });
            //获取照片文件夹
            loadFolders();

            //消息列表
            vm.messageShow = true;
            vm.messageList = "";
            MessageNotification.getAllByCondition({
                items:[
                    {key:"pageType", op : "=", value:"Edit"},
                    {key:"storeId", op : "=", value:vm.storeId},
                    {key:"available", op : "=", value:"true"},
                    {key:"validate", op : ">=", value: DateUtils.convertLocalDateToServer(new Date())}]
                }, function (res) {
                    res.forEach(function (item, index) {
                        vm.messageList += (index+1) + ":" + item.content + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0";
                    })
                }
            )
            //获取数据
            init();
            getByCustomer();
            websocket();//图库的websocket


            vm.generalMask=[{
                cancelled: false,
                id: 646,
                resource:{
                    identifier: $scope.domain + "Fn-LPd5yTT4zPO-JspyDjsXQKxKw",
                },
                identifier: $scope.domain + "Fn-LPd5yTT4zPO-JspyDjsXQKxKw",
                name: "圆形蒙版",
                opened: true,
                provider: "qiniu",
                themeClassifyId: '',
                themeClassifyName: "",
                type: "masking",
                type1:'round'
            },{
                cancelled: false,
                id: 646,
                resource:{
                    identifier: $scope.domain + "Fi-MC4JZuNy300zbGEUu2VpRDj0i",
                },
                identifier: $scope.domain + "Fi-MC4JZuNy300zbGEUu2VpRDj0i",
                name: "方形蒙版",
                opened: true,
                provider: "qiniu",
                themeClassifyId: '',
                themeClassifyName: "",
                type: "masking",
                type1: "square",
            }]
        }).catch(function(error){
            if(error.data.status === 401){//未登录
                vm.threeLoading = false;
                $scope.login("Login");
            }
        });
        var createModal = function (url, controller, type) {
            if (type == null) {
                type = "Login";
            }
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: {
                        type: type,
                        showTips:true,
                    },
                }
            });
        };
        $scope.login = function (type) {
            $uibModalStack.dismissAll();
            createModal('app/account/register/register.html', 'RegisterController', type).result.then(
                function () {
                    $state.reload();
                });
        };
        $scope.$on("$destroy", function() {
            localStorage.removeItem('save-article');
        });
        function getByCustomer() {
            if(vm.customer && vm.customer.id){
                CustomerStorages.getByCustomer({
                    customerId: vm.customer.id
                }, function (res) {
                    vm.isProgress = true;
                    progressInit(res)
                })
            }
        }
        function progressInit(item){
            vm.sumSize =  Number(item.initSize) + Number(item.buySize);
            vm.useSize = Number(item.usedSize);
            vm.percentage = 0;
            $timeout(function () {
                vm.percentage = vm.useSize / vm.sumSize;
            });
        };
        $scope.progressColor = function (size) {
            return progressColor(size)
        }
        /***********************  编辑区域  ****************************/
        vm.counts = 0;
        vm.current = 0;
        function init(){
            if(!vm.isEdit){
                ItemValue.get({id: vm.vid}, function (res) {
                    vm.bindingtype = res.bindingType;
                    vm.patternType = res.patternType;
                    $scope.status = localStorage.getItem('Production');
                    angular.forEach(res.productXmlLines,function (line) {
                        if(line.id==vm.xmlId){
                            vm.productXmlLine = angular.copy(line);
                            vm.modelBlendentUuid=vm.productXmlLine.modelBlendentUuid;
                        }
                    })
                    vm.article = {
                        modelBlendentUuid: vm.modelBlendentUuid,
                        cover: vm.productXmlLine.coverImg,
                        themeClassifyId: vm.productXmlLine.productXmlThemeId,
                        themeClassifyName: vm.productXmlLine.productXmlThemeName,
                        productXmlId: vm.productXmlLine.productXmlId,
                        productXmlName: vm.productXmlLine.productXmlName,
                        musicIdentify: vm.productXmlLine?vm.productXmlLine.musicIdentify:res.musicIdentify,
                        sku: res.sku,
                        skuNumber: res.skuNumber,
                        name: res.productName,
                        productId: res.productId,
                        productName: res.productName,
                        categoryId: res.categoryId,
                        categoryName: res.categoryName,
                        categoryStoreId: res.categoryStoreId,
                        categoryStoreName: res.categoryStoreName,
                        editorType: res.productEditorType,
                        productBokeh: res.productBokeh,
                        productType: res.productType,
                        productBokehType: res.productBokehType || 'None',
                        itemValueId: res.itemValueId||vm.vid
                    };
                    if(!res.productBokeh){
                        vm.article.bokehtype='None';
                    }else{
                        vm.article.bokehtype=res.productBokehType || 'None';
                    }
                    templateInit(function () {
                        vm.pageSize=[];
                        Convert.xmlToDocument2({
                            identifier: vm.productXmlLine?vm.productXmlLine.productXmlIdentifier:res.productXmlIdentifier,
                            provider: "qiniu",
                            editorType:vm.productXmlLine.productXmlVersion == "4.0" ? "" : vm.article.editorType,
                            patternType:vm.product.patternType,
                        }, function (res) {
                            setUuid(res);
                            vm.counts = 0;
                            vm.current = 0;
                            $scope.pages = res;
                            if($scope.pages.config.productType == 'CALENDAR'&&$scope.pages.config.dynamic){
                                vm.isCalendar = true;
                            }
                            angular.forEach(res.pages.page, function (page, i) {
                                if(!page.backup){
                                    angular.forEach(page.levels.level,function (level) {
                                        if(level.imagebox&&level.imagebox.geometry){
                                            vm.counts++;
                                        }
                                        if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                                            vm.current++;
                                        }
                                    })
                                }
                                var o = {
                                    width:page.mediabox.width,
                                    height:page.mediabox.height
                                }
                                vm.pageSize.push(o);
                            });

                            abook(function () {
                                yearInfo()
                            });
                            loadAll('isNull');
                        });
                    });
                });
            }else {
                vm.counts = 0;
                vm.current = 0;
                if($stateParams.aid){
                    Article.byUser({id: $stateParams.aid}, function (res) {
                        setUuid(res.document);
                        vm.article = res;
                        vm.modelBlendentUuid=vm.article.modelBlendentUuid;
                        templateInit(function () {
                            ItemValue.get({id: res.itemValueId}, function (resp) {
                                vm.bindingtype = resp.bindingType;
                                vm.patternType = resp.patternType;
                                vm.article.skuNumber = resp.skuNumber;
                                vm.article.productBokeh = resp.productBokeh;
                                vm.article.productBokehType = resp.productBokehType || 'None';
                                if (!resp.productBokeh) {
                                    vm.article.bokehtype = 'None';
                                } else {
                                    vm.article.bokehtype = resp.productBokehType || 'None';
                                }
                                angular.forEach(res.document.pages.page, function (page, i) {
                                    if(!page.backup) {
                                        angular.forEach(page.levels.level, function (level) {
                                            if (level.imagebox && level.imagebox.geometry) {
                                                vm.counts++;
                                            }
                                            if (level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier) {
                                                vm.current++;
                                            }
                                        })
                                    }
                                });
                                abook();
                                loadAll('isNull');
                            });
                        })
                    },function (error) {
                        if(error.data.status === 404){//未找到作品
                            $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                        }
                    })
                }else if($stateParams.orderArticleId){
                    OrderArticle.byUser({id: $stateParams.orderArticleId}, function (res) {
                        setUuid(res.document);
                        vm.article = res;
                        vm.modelBlendentUuid=vm.article.modelBlendentUuid;
                        templateInit(function () {
                            ItemValue.get({id: res.itemValueId}, function (resp) {
                                vm.bindingtype = resp.bindingType;
                                vm.patternType = resp.patternType;
                                vm.article.skuNumber = resp.skuNumber;
                                vm.article.productBokeh = resp.productBokeh;
                                vm.article.productBokehType = resp.productBokehType || 'None';
                                if (!resp.productBokeh) {
                                    vm.article.bokehtype = 'None';
                                } else {
                                    vm.article.bokehtype = resp.productBokehType || 'None';
                                }
                                angular.forEach(res.document.pages.page, function (page, i) {
                                    if(!page.backup){
                                        angular.forEach(page.levels.level,function (level) {
                                            if(level.imagebox&&level.imagebox.geometry){
                                                vm.counts++;
                                            }
                                            if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                                                vm.current++;
                                            }
                                        })
                                    }
                                });
                                abook();
                                loadAll('isNull');
                            });
                        })
                    },function (error) {
                        if(error.data.status === 404){//未找到作品
                            $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                        }
                    })
                }
            }
        }

        function setUuid(document) {
            if(document && document.backupPages && document.backupPages.list){
                document.backupPages.list.forEach(function (page) {
                    page.pageUuid = getUuid();
                })
            }
            if(document && document.pages && document.pages.page){
                document.pages.page.forEach(function (page) {
                    page.pageUuid = getUuid();
                })
            }
            console.log(document)
        }
        function productXmlGet(){
            ProductXml.get({id:vm.article.productXmlId}, function (productXml) {
                vm.productXmlData = productXml;
            })
        }
        function abook(callback) {
            productXmlGet()
            if(vm.isEdit){
                $scope.template = angular.copy(vm.article.document);
            }else {
                $scope.template = angular.copy($scope.pages);
            }
            if($scope.template.piclayouts&&$scope.template.piclayouts.piclayout){
                vm.picLayoutXmlsList = setLayoutQuantity($scope.template.piclayouts.piclayout);
                vm.picLayoutXmlGroup = vm.picLayoutXmlsList[0];
            }
            reserveInit($scope.template.backupPages.list);
            var _page=angular.copy($scope.template.pages.page);
            $scope.template.pages.page = [];
            _page.forEach(function (page) {
                if(!page.backup){
                    $scope.template.pages.page.push(page);
                }
            })
            documentAnalysis($scope, $http, vm, $scope.template,FontManagement,'other', function(page){
                ratioInfo(page)
            }, function () {
                vm.editProductXml = false;
                $timeout(function () {
                    vm.pageMaxWidth = $scope.template.pages.page[0].custom.width*$scope.template.pages.page[0].custom.ratio;
                    vm.pageMaxHeight = $scope.template.pages.page[0].custom.height*$scope.template.pages.page[0].custom.ratio;
                    $scope.template.pages.page.forEach(function (page) {
                        if(vm.pageMaxWidth<page.custom.width*page.custom.ratio){
                            vm.pageMaxWidth=page.custom.width*page.custom.ratio
                        }
                        if(vm.pageMaxHeight<page.custom.height*page.custom.ratio){
                            vm.pageMaxHeight=page.custom.height*page.custom.ratio
                        }
                    })
                    vm.pageData = $scope.template.pages.page;
                    vm.pageDataInit = angular.copy($scope.template.pages.page);
                    vm.pageSum = $scope.template.pages.page.length - 1;
                    vm.pageIndex = 0;
                    pageDataInit();
                    vm.partid = $scope.template.config.parts.list[0].id;
                    fontSizeInit(vm.pageData[0].trimbox.height);
                    thumbnailInit();
                    pageListDrag1();
                    reserveQuantity();
                    magnitudeBox();
                    vm.threeLoading = false;
                    $timeout(function () {
                        if(!$scope.template.config.dynamic){
                            pageListDrag();
                        }
                    },1000)
                    if(callback){callback()}
                }, 2000)
            })
        }
        function recommendInit(template) {
            if(template.backupPages && template.backupPages.list && template.backupPages.list.length>0){
                template.backupPages.list.forEach(function (item) {
                    var _page1 = item;
                    getPageType(_page1)
                    _page1.numberOfHoles = 0;
                    backupPagesPartQuantity(item.config.partid);
                    if(_page1.levels && _page1.levels.level && _page1.levels.level.length>0){
                        _page1.levels.level.forEach(function (level){
                            if(level.imagebox && level.imagebox.geometry){
                                _page1.numberOfHoles++;
                            }
                        });
                    }
                })
                template.pages.page.forEach(function (page) {
                    page.numberOfHoles = 0;
                    page.recommendLayout = [];
                    page.levels.level.forEach(function (level){
                        if(level.imagebox && level.imagebox.geometry){
                            page.numberOfHoles++;
                        }
                    });
                    var _page = angular.copy(page);
                    page.recommendLayout.push(_page)
                });
                template.backupPages.list.forEach(function (page) {
                    for(var i=0; i<template.pages.page.length; i++){
                        if(page.uuid != template.pages.page[i].uuid && template.pages.page[i].numberOfHoles === page.numberOfHoles && template.pages.page[i].spread == page.spread && page.config.partid == template.pages.page[i].config.partid){
                            template.pages.page[i].recommendLayout.push(page)
                        }
                    }
                });
            }
        }
        function backupPagesPartQuantity(partid) {
            if($scope.template&&$scope.template.config&&$scope.template.config.parts&&$scope.template.config.parts.list){
                for(var i=0; i<$scope.template.config.parts.list.length; i++){
                    if($scope.template.config.parts.list[i].id == partid){
                        if(!$scope.template.config.parts.list[i].backupPagesQuantity){
                            $scope.template.config.parts.list[i].backupPagesQuantity = 1;
                        }else{
                            $scope.template.config.parts.list[i].backupPagesQuantity++
                        }
                    }
                }
            }
        }
        function pageDataInit() {
            vm.templatePages = [];
            var _pageData = vm.pageData;
            var _pageIndex = 0;
            _pageData.forEach(function (page, index) {
                if(!page.isUse){
                    _pageIndex++
                    if(page.config.partid == 'P' && vm.product.patternType == "BOOK"){
                        if(page.spread || ($scope.template.config.innerOffset == 1 && $scope.getIndex(page) == 1)){
                            page.isUse = true;
                            page.seq = index;
                            ratioInfo(page,true)
                            vm.templatePages.push({
                                page1:page,
                                page2:null,
                                spread: true,
                                pageIndex: _pageIndex
                            })
                        }else{
                            var _page1 = _pageData[index];
                            var _page2 = index+1 >= _pageData.length ? null : _pageData[index+1];
                            _page1.isUse = true;
                            _page1.seq = index;
                            if(_page2 && !_page2.spread && _page2.config.partid == 'P'){
                                _page2.isUse = true;
                                _page2.seq = index+1;
                                ratioInfo(_page1,false)
                                ratioInfo(_page2,false)
                                vm.templatePages.push({
                                    page1:_page1,
                                    page2:_page2,
                                    spread:false,
                                    pageIndex: _pageIndex
                                })
                            }else{
                                ratioInfo(_page1,true)
                                vm.templatePages.push({
                                    page1:_page1,
                                    page2:null,
                                    spread:true,
                                    pageIndex: _pageIndex
                                })
                            }
                        }
                    }else{
                        page.isUse = true;
                        page.seq = index;
                        ratioInfo(page,true)
                        vm.templatePages.push({
                            page1:page,
                            page2:null,
                            spread: true,
                            pageIndex: _pageIndex
                        })
                    }
                }
            })
            if(vm.pageIndex > vm.templatePages.length){
                vm.pageIndex = vm.templatePages.length-1;
            }
            pageNumberInit()
        }
        function pageNumberInit() {
            vm.pageNumber1 = -1;
            vm.pageNumber2 = -1;
            vm.pageNumberMax = -1;
            if(vm.templatePages[vm.pageIndex].page1){
                vm.pageNumber1 = vm.pageNumberMax = vm.templatePages[vm.pageIndex].page1.seq;
            }
            if(vm.templatePages[vm.pageIndex].page2){
                vm.pageNumber2 = vm.pageNumberMax = vm.templatePages[vm.pageIndex].page2.seq;
            }
            if (vm.pageData[vm.pageIndex].scene) {
                ratioInfo(vm.pageData[vm.pageIndex])
            }
        }
        function reserveInit(page) {
            vm.reservePage = angular.copy(page) || [];
            vm.reservePage.forEach(function (page) {
                getPageType(page)
            })
            console.log(vm.reservePage)
        }
        function reserveQuantity() {
            vm.reservePage.forEach(function (page) {
                page.count = 0;
                $scope.template.pages.page.forEach(function (page1) {
                    if(page.uuid == page1.uuid){
                        page.count++
                    }
                })
            });
        }
        $scope.onRatioinfo = function (){
            if(!vm.pageData[vm.pageIndex].isEditboxMagnify){
                vm.pageData[vm.pageIndex].isEditboxMagnify = true;
                vm.pageData[vm.pageIndex].scene = null;
                ratioInfo(vm.pageData[vm.pageIndex])
            }
        }
        $scope.onRatioinfo1 = function (){
            if(vm.pageData[vm.pageIndex].isEditboxMagnify){
                vm.pageData[vm.pageIndex].isEditboxMagnify = false;
                vm.pageData[vm.pageIndex].scene = vm.pageData[vm.pageIndex].sceneCopy;
                ratioInfo(vm.pageData[vm.pageIndex])
            }
        }
        function ratioInfo(page,spread) {
            vm.windowH = $(document).height() - 110;
            vm.windowW = $(document).width() - 600;
            vm.windowW1 = 240;
            if(!spread){
                vm.windowW = vm.windowW/2;
            }
            if (page.scene) {
                page.custom.width = page.scene.geometry.width;
                page.custom.height = page.scene.geometry.height;
            } else {
                page.custom.width = page.mediabox.width;
                page.custom.height = page.mediabox.height;
            }
            if(vm.windowH / page.custom.height >= vm.windowW / page.custom.width ){
                page.custom.ratio = vm.windowW / page.custom.width * 0.8;
            }else {
                page.custom.ratio = vm.windowH / page.custom.height * 0.8;
            }
            page.custom.ratio1 = vm.windowW1 / page.custom.width * 0.8;
            page.custom.ratio2 = Math.round(Number(page.custom.ratio)*100);
            page.custom.ratio3 = angular.copy(page.custom.ratio2);
            page.custom.thumbnailScale = 300 / page.custom.width;
        }

        $scope.pageInfo = function(item){
            $scope.editorBodyView();
            vm.pageNumber = item;
        };
        $scope.pageInfo1 = function(item){
            $scope.editorBodyView();
            vm.pageNumber = $scope.pageInfo(item);
        }
        $scope.previousPage = function (e) {
            e.target.blur();
            if(vm.textFont){
                return
            }
            if (vm.pageData[vm.pageIndex].sceneCopy&&vm.pageData[vm.pageIndex].isEditboxMagnify) {
                vm.pageData[vm.pageIndex].scene = angular.copy(vm.pageData[vm.pageIndex].sceneCopy);
                vm.pageData[vm.pageIndex].isEditboxMagnify = false;
                ratioInfo(vm.pageData[vm.pageIndex])
            }
            if(vm.pageNumber1 > 0){
                vm.isCut = true;
                $rootScope.$broadcast('onKeyDownCallback');
                $timeout(function () {
                    vm.moveCanvasObj = {
                        x:0,
                        y:0
                    };
                    $scope.ratioInit();
                    vm.pageIndex--;
                    pageNumberInit();
                    $scope.template.pages.page = vm.pageData;
                    $scope.editorBodyView();
                    vm.pageNumber = vm.pageNumber1;
                    vm.cookies=[];
                    vm.isCut = false;
                })
            }
        }
        $scope.nextPage = function (e) {
            e.target.blur();
            if(vm.textFont){
                return
            }
            if (vm.pageData[vm.pageIndex].sceneCopy&&vm.pageData[vm.pageIndex].isEditboxMagnify) {
                vm.pageData[vm.pageIndex].scene = angular.copy(vm.pageData[vm.pageIndex].sceneCopy);
                vm.pageData[vm.pageIndex].isEditboxMagnify = false;
                ratioInfo(vm.pageData[vm.pageIndex])
            }
            if(vm.pageNumberMax > -1 && vm.pageNumberMax < vm.pageSum){
                vm.isCut = true;
                $rootScope.$broadcast('onKeyDownCallback');
                $timeout(function () {
                    vm.moveCanvasObj = {
                        x:0,
                        y:0
                    };
                    $scope.ratioInit();
                    vm.pageIndex++;
                    pageNumberInit()
                    $scope.template.pages.page = vm.pageData;
                    $scope.editorBodyView();
                    vm.pageNumber = vm.pageNumber1;
                    vm.cookies=[];
                    vm.isCut = false;
                })
            }
        }
        $scope.turning = function (index) {
            if(vm.textFont){
                return
            }
            if (vm.pageData[vm.pageIndex].sceneCopy&&vm.pageData[vm.pageIndex].isEditboxMagnify) {
                vm.pageData[vm.pageIndex].scene = angular.copy(vm.pageData[vm.pageIndex].sceneCopy);
                vm.pageData[vm.pageIndex].isEditboxMagnify = false;
                ratioInfo(vm.pageData[vm.pageIndex])
            }
            vm.isCut = true;
            $rootScope.$broadcast('onKeyDownCallback');
            $timeout(function () {
                vm.moveCanvasObj = {
                    x:0,
                    y:0
                };
                $scope.ratioInit();
                vm.pageNumber = Number(index);
                vm.templatePages.forEach(function (item, index1) {
                    if(item.page1 && item.page1.seq == index){
                        vm.pageIndex = index1;
                    }
                    if(item.page2 && item.page2.seq == index){
                        vm.pageIndex = index1;
                    }
                })
                pageNumberInit();
                vm.cookies=[];
                vm.isCut = false;
            })
        }

        //拖拽排序
        var sort = null;
        var sortArray = [];
        vm.isSort = false;
        function pageListDrag() {
            $timeout(function () {
                vm.sortingPage = angular.copy(vm.pageData);
                sort = new Sortable(document.getElementById('pageList'), {
                    swap: false,
                    filter: '.filtered',
                    cancel: ".notDrag",
                    preventOnFilter: true,
                    animation: 150,
                    scroll:true,
                    onStart: function(evt){
                        vm.sortPartid = vm.pageData[evt.oldIndex].config.partid;
                        vm.pageSpread = vm.pageData[evt.oldIndex].spread;
                    },
                    onMove: function (/**Event*/evt, /**Event*/originalEvent) {
                        $timeout(function () {
                            var order = sort.toArray();
                            vm.pageData.forEach(function (page, index) {
                                if (!(!page.unreplace&&vm.sortPartid == page.config.partid?true:false)||(vm.pageSpread != page.spread)) {
                                    var _index = order.indexOf((index + 1).toString());
                                    order.splice(_index, 1)
                                }
                            })
                            vm.pageData.forEach(function (page, index) {
                                if (!(!page.unreplace&&vm.sortPartid == page.config.partid?true:false)||(vm.pageSpread != page.spread)) {
                                    order.splice(index, 0, (index + 1).toString())
                                }
                            })
                            sort.sort(order)
                        },150)
                        var _uuid = $(evt.related).attr("data-page");
                        var _page = getPageUuid(_uuid);
                        if(!(!_page.unreplace&&vm.sortPartid == _page.config.partid?true:false)||(vm.pageSpread != _page.spread)){
                            return false
                        }
                    },
                    onEnd: function (/**Event*/evt) {
                        $timeout(function () {
                            var _top = angular.copy($('.c-editor-page-pageList').scrollTop());
                            vm.pageListLoading = true;
                            $timeout(function () {
                                sortArray = [];
                                angular.forEach($('#pageList').find(".sortingPageView"), function (item, index) {
                                    var uuid = item.getAttribute("data-page");
                                    var page = getPageUuid(uuid);
                                    sortArray.push(page);
                                });
                                if(sortArray && sortArray.length>0){
                                    sortArray.forEach(function (value, index) {
                                        value.seq = index;
                                        // ratioInfo(value,vm.pageNumber2 > -1?false:true);
                                    })
                                    vm.pageData = [];
                                    $timeout(function () {
                                        vm.pageData = angular.copy(sortArray);
                                        sortArray = [];
                                        $timeout(function () {
                                            $('.c-editor-page-pageList').scrollTop(_top)
                                            vm.pageListLoading = false;
                                            pageListDrag();
                                        },200)
                                    })
                                }
                            },500)
                        })
                    }
                });
            })
        }
        function getPageUuid(uuid) {
            var page = null;
            for(var i=0; i<vm.pageData.length; i++){
                if(vm.pageData[i].pageUuid == uuid){
                    page = angular.copy(vm.pageData[i]);
                    break
                }
            }
            return page
        }
        function getFontCSS(item){
            FontManagement.getCss({uuid: item.fontuuid}, function (res) {
                var style=document.createElement("style");
                style.type='text/css';
                try{
                    style.appendChild(document.createTextNode(res.message));
                }catch(ex){
                    style.stylesheet.cssText=res.message;
                }
                var Head=document.getElementsByTagName("head")[0];
                Head.appendChild(style);
                document.fonts.ready.then(function() {
                    item.loading = false;
                    $scope.$apply();
                });
            });
        }
        $scope.editorBodyView = function(e){
            vm.isChangePage1 = false;
            if(!vm.textFont){
                fn(e);
                vm.elementOn = {
                    index:"",
                    pageIndex:"",
                    name:"",
                    item:""
                };
                toolbarStatus();
                levelInif();
            }
        }

        //拖拽图片
        $timeout(function () {
            var content = $("#pageCanvas")[0];
            if(content){
                content.ondragenter = ignoreDrag;
                // 拖动文件的鼠标指针位置放置区之上时发生
                content.ondragover = ignoreDrag;
                content.ondrop = drop;
            }
        },1000)
        window.dragStart = function (event) {
            var target = event.target;
            event.dataTransfer.setData("img", target.id);
            if (target.id.indexOf('dragtargetClipArt') != '-1') {
                event.dataTransfer.setData("Art", target.dataset.clipart);
            }
            $timeout(function () {
                vm.dragDom = true;
            })
        };
        window.dragOn = function(event){
            $timeout(function () {
                vm.dragDom = false;
            })
        }
        function ignoreDrag(e) {
            // 确保其他元素不会取得该事件
            e.stopPropagation();
            e.preventDefault();
            var dataset = e.target.dataset;
            if (vm.dragDom && (e.target.nodeName == 'IMG' || ($(e.target).attr('class') && $(e.target).attr('class').indexOf('image-placeholder-class') > -1))&&dataset.imgindex) {
                var textIndex = dataset.imgindex;
                $(".imageboxView").removeClass('selected-blue')
                var _pageIndex = $(e.target).parents('.pageCanvasItem').attr('data-pageIndex');
                $(".imageboxView"+_pageIndex+textIndex).addClass('selected-blue');
            }else{
                $(".imageboxView").removeClass('selected-blue')
            }

        }
        function drop(e) {
            var data = e.dataTransfer.getData("img");
            $(".imageboxView").removeClass('selected-blue')
            if(!data){
                return
            }
            var dataIndex = $('#' + data)[0].getAttribute('data-index');
            $timeout(function () {
                var _pageIndex = $(e.target).parents('.pageCanvasItem').attr('data-pageIndex1');
                var _pageIndex1 = $(e.target).parents('.pageCanvasItem').attr('data-pageIndex');
                if(!_pageIndex){
                    return
                }
                vm.pageNumber = _pageIndex1;
                ignoreDrag(e);
                $(".imageboxView").removeClass('selected-blue');
                var offsetX= e.clientX;
                var offsetY= e.clientY;
                if(_pageIndex == 1){
                    offsetX -= parseFloat($(".pageCanvasItem1").offset().left);
                    offsetY -= parseFloat($(".pageCanvasItem1").offset().top);
                }
                if(_pageIndex == 2){
                    offsetX -= parseFloat($(".pageCanvasItem2").offset().left);
                    offsetY -= parseFloat($(".pageCanvasItem2").offset().top);
                }
                if(vm.pageData[vm.pageNumber].scene){
                    offsetX -= vm.pageData[vm.pageNumber].scene.imagebox.geometry.x * vm.pageData[vm.pageNumber].custom.ratio;
                    offsetY -= vm.pageData[vm.pageNumber].scene.imagebox.geometry.y * vm.pageData[vm.pageNumber].custom.ratio;
                }
                //拖拽图片进入占位框
                if (data.indexOf('dragtargetPhoto') != '-1') {
                    var imgindex = e.target.dataset.imgindex;
                    if(imgindex == null){
                        var item = $('#' + data).attr('data-item');
                        replaceImagebox1(offsetX, offsetY, JSON.parse(item));
                        $timeout(function () {
                            photosNum();

                            cookie();
                        },500)
                    }else {
                        vm.levelIndex = imgindex;
                        var item = JSON.parse($('#' + data).attr('data-item'));
                        var _imagebox = vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox;
                        var identifier = _imagebox.mask.resource.identifier;
                        replaceImagebox(item);
                        $timeout(function () {
                            photosNum();
                            cookie();
                        },500)
                    }
                }
                //边框
                if (data.indexOf('dragtargetFrameConfig') != '-1') {
                    var item = $('#' + data).attr('data-item');
                    var imgindex = e.target.dataset.imgindex;
                    if(imgindex == null){
                        var borderList = vm.borderList[$('#' + data).attr('data-index')];
                        $http({url: borderList.img + '?imageInfo'}).success(function (data) {
                            var imgZoom = 1;
                            if (data.width > data.height) {
                                imgZoom = 200 / data.width
                            } else {
                                imgZoom = 200 / data.height
                            }
                            var _w2 = (imgZoom * data.width) / (1 + borderList.marginLeft / 100 + borderList.marginRight / 100) / vm.pageData[vm.pageNumber].custom.ratio;
                            var _h2 = (imgZoom * data.height) / (1 + borderList.marginBottom / 100 + borderList.marginTop / 100) / vm.pageData[vm.pageNumber].custom.ratio;
                            var _level = {
                                imagebox:{},
                                seq: vm.pageData[vm.pageNumber].levels.level.length + Number(1)
                            }
                            var _imagebox = {
                                blurredrectangle:null,
                                bokehtype:vm.article.bokehtype,
                                bokehtypes:vm.article.bokehtype,
                                geometry:{
                                    width: _w2,
                                    height: _h2,
                                    x:(offsetX/vm.pageData[vm.pageNumber].custom.ratio - _w2 / 2) || 0,
                                    y:(offsetY/vm.pageData[vm.pageNumber].custom.ratio - _h2 / 2) || 0
                                },
                                image: {
                                    resource: {
                                        identifier: item.identifier,
                                        provider: 'qiniu'
                                    },
                                    filterResource: {
                                        identifier: '',
                                        provider: 'qiniu'
                                    },
                                    width: item.width,
                                    height: item.height,
                                    imgSize: item.imgSize,
                                    index: item.seq,
                                    parameter: angular.fromJson(item.parameter),
                                    scale: 1,
                                    angle: 0
                                },
                                mask: {
                                    resource:{
                                        identifier: '',
                                        provider: '',
                                    }
                                },
                                rotation:{
                                    angle: 0
                                },

                                border: {
                                    shape: borderList.shape || 'Rectangle',
                                    radius: {
                                        lt: 0,
                                        rt: 0,
                                        rb: 0,
                                        lb: 0,
                                        all: 0,
                                        filletType: 'Fixed'
                                    },
                                    "edge": {
                                        top: borderList.marginTop,
                                        right: borderList.marginRight,
                                        bottom: borderList.marginBottom,
                                        left: borderList.marginLeft
                                    },
                                    lineWidth: 0,
                                    type: "Pic",
                                    resource: {
                                        "identifier": borderList.img
                                    },
                                    color: '',
                                },
                                transparency:1,
                                shadow:{
                                    color:'#777777',
                                    x:0,
                                    y:0,
                                    blur:0
                                },
                                useradded: true
                            }
                            _level.imagebox = _imagebox;
                            vm.pageData[vm.pageNumber].levels.level.push(_level);
                            magnitudeBox();
                        })
                    }else {
                        vm.levelIndex = imgindex;
                        var borderList = vm.borderList[$('#' + data).attr('data-index')];
                        vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.border = {
                            shape: borderList.shape || 'Rectangle',
                            radius: {
                                lt: 0,
                                rt: 0,
                                rb: 0,
                                lb: 0,
                                all: 0,
                                filletType: 'Fixed'
                            },
                            "edge": {
                                top: borderList.marginTop,
                                right: borderList.marginRight,
                                bottom: borderList.marginBottom,
                                left: borderList.marginLeft
                            },
                            lineWidth: 0,
                            type: "Pic",
                            resource: {
                                "identifier": borderList.img
                            },
                            color: '',
                        }
                    }
                    $timeout(function () {
                        cookie();
                    },500)
                }
                // 判读是否是占位框
                if (data.indexOf('dragtargetMask') != '-1') {
                    var item = $('#' + data).attr('data-item');
                    var imgindex = e.target.dataset.imgindex;
                    if(imgindex == null){
                        var border = null;
                        if (data.indexOf('Round') > '-1') {
                            border = {
                                shape: 'Ellipse',
                                radius: {
                                    lt: 0,
                                    rt: 0,
                                    rb: 0,
                                    lb: 0,
                                    all: 0,
                                    filletType: 'Fixed'
                                },
                                "edge": {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },
                                lineWidth: 0,
                                type: "Color",
                                lineType:"solid",
                                resource: {
                                    "identifier": ''
                                },
                                color: '#000000',
                            };
                        } else if (data.indexOf('Rectangle') > '-1') {
                            border = {
                                shape: 'Rectangle',
                                radius: {
                                    lt: 0,
                                    rt: 0,
                                    rb: 0,
                                    lb: 0,
                                    all: 0,
                                    filletType: 'Fixed'
                                },
                                "edge": {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },
                                lineWidth: 0,
                                type: "Color",
                                lineType:"solid",
                                resource: {
                                    "identifier": ''
                                },
                                color: '#000000',
                            };
                        }else{
                            border = {
                                shape: 'Rectangle',
                                radius: {
                                    lt: 0,
                                    rt: 0,
                                    rb: 0,
                                    lb: 0,
                                    all: 0,
                                    filletType: 'Fixed'
                                },
                                "edge": {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },
                                lineWidth: 0,
                                type: "Color",
                                lineType:"solid",
                                resource: {
                                    "identifier": ''
                                },
                                color: '#000000',
                            };
                        }
                        replaceImagebox1(offsetX, offsetY, JSON.parse(item), border);
                    }else {
                        vm.levelIndex = imgindex;
                        var item = JSON.parse($('#' + data).attr('data-item'));
                        vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.mask.resource.identifier = item.resource.identifier;
                        if (data.indexOf('Round') > '-1') {
                            if(vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.border){
                                vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.border.shape = 'Ellipse';
                            }else{
                                vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.border= {
                                    shape: 'Ellipse',
                                    radius: {
                                        lt: 0,
                                        rt: 0,
                                        rb: 0,
                                        lb: 0,
                                        all: 0,
                                        filletType: 'Fixed'
                                    },
                                    "edge": {
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        left: 0
                                    },
                                    lineWidth: 0,
                                    type: "Color",
                                    lineType:"solid",
                                    resource: {
                                        "identifier": ''
                                    },
                                    color: '#000000',
                                };
                            }

                            vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.shadow= {
                                color:'#777777',
                                x:0,
                                y:0,
                                blur:0
                            }
                        } else if (data.indexOf('Rectangle') > '-1') {
                            if(vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.border){
                                vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.border.shape = 'Rectangle';
                            }else{
                                vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.border= {
                                    shape: 'Rectangle',
                                    radius: {
                                        lt: 0,
                                        rt: 0,
                                        rb: 0,
                                        lb: 0,
                                        all: 0,
                                        filletType: 'Fixed'
                                    },
                                    "edge": {
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        left: 0
                                    },
                                    lineWidth: 0,
                                    type: "Color",
                                    lineType:"solid",
                                    resource: {
                                        "identifier": ''
                                    },
                                    color: '#000000',
                                };
                            }

                            vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.border= {
                                shape: 'Rectangle',
                                radius: {
                                    lt: 0,
                                    rt: 0,
                                    rb: 0,
                                    lb: 0,
                                    all: 0,
                                    filletType: 'Fixed'
                                },
                                "edge": {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },
                                lineWidth: 0,
                                type: "Color",
                                lineType:"solid",
                                resource: {
                                    "identifier": ''
                                },
                                color: '#000000',
                            };
                            vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox.shadow= {
                                color:'#777777',
                                x:0,
                                y:0,
                                blur:0
                            }
                        }
                    }
                    $timeout(function () {
                        cookie();
                    },500)
                }

                //判读是否是素材
                if (data.indexOf('dragtargetClipArt') != '-1') {
                    $scope.materialCb(vm.clipArtsList[dataIndex], offsetX,offsetY, true);
                    $scope.$apply();
                }
            })
        }

        $scope.replaceImagebox1=function(e,data,index){
            if(data.type=="masking"){
                return
            }
            vm.levelIndex = index;
            replaceImagebox(data)
        }
        function replaceImagebox(data){
            var item = angular.copy(data);
            var _imagebox = vm.pageData[vm.pageNumber].levels.level[vm.levelIndex].imagebox;
            _imagebox.image = {
                resource: {
                    identifier: item.identifier,
                    provider: 'qiniu'
                },
                filterResource: {
                    identifier: '',
                    provider: 'qiniu'
                },
                offsetx:0,
                offsety:0,
                resourceid:item.id,
                imageInfo: item.imageInfo,
                width: item.width,
                height: item.height,
                imgSize: item.imgSize,
                index: item.seq,
                parameter: angular.fromJson(item.parameter),
                scale: 1,
                angle: 0,
                border: {
                    shape: 'Ellipse',
                    radius: {
                        lt: 0,
                        rt: 0,
                        rb: 0,
                        lb: 0,
                        all: 0,
                        filletType: 'Fixed'
                    },
                    "edge": {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    },
                    lineWidth: 0,
                    type: "Color",
                    lineType:"solid",
                    resource: {
                        "identifier": ''
                    },
                    color: '#000000',
                },
                shadow:{
                    color:'#777777',
                    x:0,
                    y:0,
                    blur:0
                },
            };
            addImagebox($http, _imagebox, function () {
                thumbnailInit();
            });
            imageSelectInit();
            magnitudeBox();
        }
        vm.imageSelectInit = imageSelectInit;
        function imageSelectInit(){
            vm.photoSelectAll = [];
            for(var i=0; i<vm.pageData.length; i++){
                for(var j=0; j<vm.pageData[i].levels.level.length; j++){
                    var _level = vm.pageData[i].levels.level[j];
                    if(_level.imagebox && _level.imagebox.image && _level.imagebox.image.resourceid){
                        for(var k=0; k<$scope.photos.length; k++){
                            if(_level.imagebox.image.resourceid == $scope.photos[k].id){
                                vm.photoSelectAll.push($scope.photos[k]);
                                break
                            }
                        }
                    }
                }
            }
        }
        function replaceImagebox1(offsetX, offsetY, data, border, value){
            if (!border) {
                border = {
                    shape: 'Rectangle',
                    radius: {
                        lt: 0,
                        rt: 0,
                        rb: 0,
                        lb: 0,
                        all: 0,
                        filletType: 'Fixed'
                    },
                    "edge": {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    },
                    lineWidth: 0,
                    type: "Color",
                    lineType:"solid",
                    resource: {
                        "identifier": ''
                    },
                    color: '#000000',
                };
            }
            var item = angular.copy(data);
            if(item.type=='picture'){
                var _level = {
                    imagebox:{},
                    seq: vm.pageData[vm.pageNumber].levels.level.length + Number(1)
                }
                var _imagebox = {
                    value: value,
                    blurredrectangle:null,
                    bokehtype: angular.copy(vm.article.bokehtype),
                    geometry:{
                        width:0,
                        height:0,
                        x:0,
                        y:0
                    },
                    border: border,
                    shadow:{
                        color:'#777777',
                        x:0,
                        y:0,
                        blur:0
                    },
                    image: {
                        resource: {
                            identifier: item.identifier,
                            provider: 'qiniu'
                        },
                        filterResource: {
                            identifier: '',
                            provider: 'qiniu'
                        },
                        resourceid:item.sourceId,
                        width: item.width,
                        height: item.height,
                        imageInfo: item.imageInfo,
                        imgSize: item.imgSize,
                        index: item.seq,
                        parameter: angular.fromJson(item.parameter),
                        scale: 1,
                        angle: 0,
                    },
                    transparency:1,
                    mask: {
                        resource:{
                            identifier: '',
                            provider: '',
                        }
                    },
                    rotation:{
                        angle: 0
                    }
                }

                var w = 200 / vm.pageData[vm.pageNumber].custom.ratio;
                var h = 200 / vm.pageData[vm.pageNumber].custom.ratio;
                var imgR = w/h;
                var imgR1 = item.width/item.height;

                if(imgR >= imgR1){
                    _imagebox.geometry.width= h/item.height*item.width;
                    _imagebox.geometry.height= h;
                }else {
                    _imagebox.geometry.width= w;
                    _imagebox.geometry.height= w/item.width*item.height;
                }

                _imagebox.geometry.x = (offsetX/vm.pageData[vm.pageNumber].custom.ratio-_imagebox.geometry.width/2);
                _imagebox.geometry.y = (offsetY/vm.pageData[vm.pageNumber].custom.ratio-_imagebox.geometry.height/2);
                _level.imagebox = _imagebox;
                addImagebox($http, _level.imagebox, function () {
                    vm.pageData[vm.pageNumber].levels.level.push(_level);
                    setLayout()
                    magnitudeBox();
                });
            }
            if(item.type=="masking"){
                var newImg = new Image();
                newImg.crossOrigin = "Anonymous";
                newImg.src = item.resource.identifier + '?imageMogr2/strip/rotate';
                newImg.onload = function () {
                    var _level = {
                        imagebox:{},
                        seq: vm.pageData[vm.pageNumber].levels.level.length + Number(1)
                    }
                    var _imagebox = {
                        value: value,
                        blurredrectangle:null,
                        bokehtype: angular.copy(vm.article.bokehtype),
                        geometry:{
                            width:0,
                            height:0,
                            x:0,
                            y:0
                        },
                        border:border,
                        image: {
                            resource: {
                                identifier: '',
                                provider: ''
                            },
                            filterResource: {
                                identifier: '',
                                provider: 'qiniu'
                            },
                            scale: 1,
                            angle: 0
                        },
                        mask: {
                            resource:{
                                identifier: item.resource.identifier,
                                provider: 'qiniu',
                            }
                        },
                        rotation:{
                            angle: 0
                        },
                        transparency:1,
                        shadow:{
                            color:'#777777',
                            x:0,
                            y:0,
                            blur:0
                        },
                    };
                    var w = 200 / vm.pageData[vm.pageNumber].custom.ratio;
                    var h = 200 / vm.pageData[vm.pageNumber].custom.ratio;
                    var imgR = w/h;
                    var imgR1 = newImg.width/newImg.height;

                    if(imgR >= imgR1){
                        _imagebox.geometry.width= h/newImg.height*newImg.width;
                        _imagebox.geometry.height= h;
                    }else {
                        _imagebox.geometry.width= w;
                        _imagebox.geometry.height= w/newImg.width*newImg.height;
                    }
                    _imagebox.geometry.x = (offsetX/vm.pageData[vm.pageNumber].custom.ratio-_imagebox.geometry.width/2);
                    _imagebox.geometry.y = (offsetY/vm.pageData[vm.pageNumber].custom.ratio-_imagebox.geometry.height/2);
                    _level.imagebox = _imagebox;
                    vm.pageData[vm.pageNumber].levels.level.push(_level);
                    // getBackgroundWebSource3($scope,vm.pageData[vm.pageNumber].levels.level[_level.seq].imagebox,vm.pageData[vm.pageNumber].levels.level[_level.seq].imagebox.geometry.width,vm.pageData[vm.pageNumber].levels.level[_level.seq].imagebox.geometry.height,vm.pageData[vm.pageNumber].levels.level[_level.seq].imagebox.image, vm.pageData[vm.pageNumber].levels.level[_level.seq].imagebox.bokehtype);
                    magnitudeBox();
                    $scope.$digest();
                };
            }
        }

        vm.magnitudeBox = magnitudeBox;
        function magnitudeBox(){
            vm.counts = 0;
            vm.current = 0;
            angular.forEach(vm.pageData, function (page, i) {
                angular.forEach(page.levels.level, function (level, i) {
                    if(level.imagebox&&level.imagebox.geometry){
                        vm.counts++;
                    }
                    if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                        vm.current++;
                    }
                });
            });
            thumbnailInit();
            pageWarnExamine1(vm.pageData[vm.pageNumber], vm.imgSize, vm.imgDpi);
            photosNum();
        }
        vm.unusedQuantity = null;

        function unusedQuantity(){
            var galleryIdList = [];
            angular.forEach(vm.pageData, function (page, i) {
                angular.forEach(page.levels.level, function (level, i) {
                    if(level.imagebox && level.imagebox.image && level.imagebox.image.resourceid){
                        galleryIdList.push(level.imagebox.image.resourceid)
                    }
                });
            });
            var newArr = galleryIdList.filter(function(item,index){
                return galleryIdList.indexOf(item) === index;  // 因为indexOf 只能查找到第一个
            });
            Gallery.unUsed({
                galleryIdList:newArr,
                folderId: vm.folderId,
            },function (res) {
                if(res.status == 200){
                    vm.unusedQuantity = res.message;
                    getGallery();
                }
            })
        }
        //双击编辑图片框图片
        $scope.doubleClickImagebox = function ($event,index, item) {
            $rootScope.$broadcast('doubleClickImageboxCallback',{
                event: event,
                index: vm.elementOn.index,
                item: vm.elementOn.item,
            });
        }
        vm.elementInit = elementInit;
        function elementInit(index,pageIndex,item,name){
            vm.clickNum = 0;
            vm.elementOn = {
                index:index,
                pageIndex:pageIndex,
                name:name,
                item:item
            }
            if(vm.pageNumber != pageIndex){
                $rootScope.$broadcast('onKeyDownCallback');
                $timeout(function () {
                    vm.pageNumber = pageIndex;
                    vm.cookies=[];
                })
            }
            vm.isTransp = false;
            scrollIntoView();
            toolbarStatus();
            if(vm.elementOn.name == 'textbox'){
                $timeout(function () {
                    $(".block-width").css({left: vm.elementOn.item.style.letterspacing});
                    $(".block-height").css({left: vm.elementOn.item.style.lineheight});
                })
            }
        }
        /***********************  素材  ****************************/
        //添加素材
        $scope.materialCb = function(item, x, y, isDrop){
            webAddImageart($scope,$http,item,vm.pageData[vm.pageNumber].trimbox, function (_imageart) {
                if(x && y){
                    _imageart.geometry.x = (x-_imageart.geometry.width/2)/vm.pageData[vm.pageNumber].custom.ratio;
                    _imageart.geometry.y = (y-_imageart.geometry.height/2)/vm.pageData[vm.pageNumber].custom.ratio;
                }
                if(!isDrop) {
                    var _num = 0;
                    vm.pageData[vm.pageNumber].levels.level.forEach(function (level) {
                        if (level.imageart) {
                            _num += 10;
                        }
                    });
                    _imageart.geometry.x += _num / vm.pageData[vm.pageNumber].custom.ratio;
                    _imageart.geometry.y += _num / vm.pageData[vm.pageNumber].custom.ratio;
                }
                _imageart.geometry.width = _imageart.geometry.width/vm.pageData[vm.pageNumber].custom.ratio;
                _imageart.geometry.height = _imageart.geometry.height/vm.pageData[vm.pageNumber].custom.ratio;
                var _level ={
                    seq: vm.pageData[vm.pageNumber].levels.level.length+1,
                    imageart: _imageart
                }
                vm.pageData[vm.pageNumber].levels.level.push(_level);
                elementInit(_level.seq-1,vm.pageNumber,_level.imageart,'imageart');
                cookie();
            })
        }

        /***********************  文字  ****************************/
        fontuuid()
        vm.selectFont = '';
        vm.selectFontBtn = fontuuid;
        vm.selectFontName = selectFontName;
        vm.chineseSupport=true;
        function fontuuid(){
            vm.fontuuid = [];
            FontManagement.getAll2({family: vm.selectFont}, function (res) {
                vm.fontuuid = res;
            });
        }
        function selectFontName(item){
            if(item){
                for(var i=0;i<vm.fontuuid.length; i++){
                    if(vm.fontuuid[i].uuid == item){
                        return vm.fontuuid[i].family
                    }
                }
            }
        }
        $scope.updateTextFont = function (data) {
            if (data == undefined) {
                MessageService.error("文字添加失败，请上传字体!");
                return
            }
            if (!data.enable) {
                MessageService.error("当前字体不可用，请更换字体");
                return
            }

            var text = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
            var _fontuuid = angular.copy(text.fontuuid);
            if (text.lock) {
                return
            }
            vm.fontStyle = [];
            for(var i=0; i<vm.fontuuid.length; i++){
                if(vm.fontuuid[i].uuid == data.uuid){
                    for(var j=0; j<vm.fontuuid[i].fontStyles.length; j++){
                        vm.fontStyle.push(vm.fontuuid[i].fontStyles[j].style)
                    }
                }
            }
            text.style.bold = false;
            text.style.italic = false;
            if(vm.fontStyle.indexOf('NORMAL') != '-1'){
                text.style.bold = false;
                text.style.italic = false;
            }else {
                if(vm.fontStyle.indexOf('BOLD_ITALIC') != '-1'){
                    text.style.bold = true;
                    text.style.italic = true;
                }else {
                    if(vm.fontStyle.indexOf('ITALIC') != '-1'){
                        text.style.italic = true;
                        text.style.bold = false;
                    }
                    if(vm.fontStyle.indexOf('BOLD') != '-1'){
                        text.style.bold = true;
                        text.style.italic = false;
                    }
                }
            }
            text.style.font = data.family;
            text.fontuuid = data.uuid;
            text.identifier = $scope.domain + data.fontStyles[0].identifier;
            text.loading = true;
            FontSupport2(FontManagement, text, function (res) {
                var msg = res.message;
                if (res.status == 200) {
                    text.text = (!msg.passed || msg.passed == "") ? "请输入文字" : msg.passed;
                    if (msg.success && !msg.supported) {
                        $timeout(function () {
                            vm.editFailedFont = true;
                            vm.failedFontArr = {
                                failed: msg.failed
                            }
                            $('.selected').find('pre span').html(text.text);
                            text.loading = false;
                            sizeHeight()
                            fontStyle(data.uuid);
                            cookie();
                        })
                    } else {
                        vm.editFailedFont = false;
                        vm.failedFontArr = null;
                        getFontCSS(text);
                        $timeout(function () {
                            sizeHeight()
                            fontStyle(data.uuid);
                            cookie();
                        },200)
                    }
                }

                // if(res.status==200){
                //     if(msg.success){
                //         text.text = (!msg.passed || msg.passed == "") ? "请输入文字" : msg.passed;
                //         if(!msg.supported){
                //             vm.editFailedFont = true;
                //             vm.failedFontArr = {
                //                 failed:msg.failed,
                //                 textbox:text
                //             }
                //         }else{
                //             vm.editFailedFont = false;
                //             vm.failedFontArr = null;
                //         }
                //         getFontCSS(text);
                //         cookie();
                //         $timeout(function () {
                //             sizeHeight()
                //             fontStyle(data.uuid);
                //         },200)
                //     }else {
                //         vm.pageData[vm.pageNumber].levels.level[i].textbox.text = "请输入文字";
                //         $('.selected').find('span').html("请输入文字");
                //         MessageService.error("没有找到当前字体");
                //     }
                // }
            })
        };
        $scope.editFont = function () {
            vm.editFailedFont = false;
            $timeout(function () {
                $('.selected pre').dblclick();
            })
        }
        //添加文字
        $scope.addText = function (size) {
            textboxWebAdd(vm.defaultFont, vm.defaultFont.fontStyles, size, function (_font) {
                var _level ={
                    seq: vm.pageData[vm.pageNumber].levels.level.length+1,
                    textbox: _font
                }
                vm.pageData[vm.pageNumber].levels.level.push(_level);
                elementInit(_level.seq-1,vm.pageNumber,_level.textbox,'textbox');
                $timeout(function () {
                    // vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.width = (Number($("#c-xml-page-material-fontId").width() / (3/4) / vm.pageData[vm.pageNumber].custom.ratio * (1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio)) + Number(5));
                    // vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.height = Number($("#c-xml-page-material-fontId").height() / (3/4) / vm.pageData[vm.pageNumber].custom.ratio) * (1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio) + Number(10);
                    vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.width = Number($("#c-xml-page-material-fontId").width()) + Number(10);
                    vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.height = (Number($("#c-xml-page-material-fontId").height()) + Number(10));
                    vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.x = (vm.pageData[vm.pageNumber].trimbox.width - vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.width) / 2 + vm.pageData[vm.pageNumber].trimbox.x;
                    vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.y = (vm.pageData[vm.pageNumber].trimbox.height - vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.height) / 2 + vm.pageData[vm.pageNumber].trimbox.y;
                    var _num = 0;
                    vm.pageData[vm.pageNumber].levels.level.forEach(function (level) {
                        if(level.textbox){
                            _num+=10;
                        }
                    });
                    vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.x += _num/vm.pageData[vm.pageNumber].custom.ratio;
                    vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.geometry.y += _num/vm.pageData[vm.pageNumber].custom.ratio;
                    getFontCSS(vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox);
                    FontSupport2(FontManagement, vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox)
                    // sizeHeight();
                    cookie();
                    fontStyle(vm.pageData[vm.pageNumber].levels.level[_level.seq-1].textbox.fontuuid)
                })
            })
        }
        vm.fontStyleCb = fontStyle;
        function fontStyle(fontuuid){
            if(vm.elementOn.name != "textbox"){
                return
            }
            var text = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
            vm.fontStyle = [];
            for(var i=0; i<vm.fontuuid.length; i++){
                if(vm.fontuuid[i].uuid == fontuuid){
                    for(var j=0; j<vm.fontuuid[i].fontStyles.length; j++){
                        vm.fontStyle.push(vm.fontuuid[i].fontStyles[j].style)
                    }
                }
            }
            text.style.bold1 = false;
            text.style.italic1= false;
            if(vm.fontStyle.indexOf('NORMAL') == '-1'){
                text.style.normal = false;
            }else {
                text.style.normal = true;
            }
            if(vm.fontStyle.indexOf('BOLD_ITALIC') != '-1'){
                text.style.bold1 = true;
                text.style.italic1= true;
            }else {
                if(vm.fontStyle.indexOf('ITALIC') != '-1'){
                    text.style.italic1= true;
                }
                if(vm.fontStyle.indexOf('BOLD') != '-1'){
                    text.style.bold1 = true;
                }
            }
        }
        vm.sizeHeight = sizeHeight;
        function sizeHeight(){
            $timeout(function () {
                var _h = Number($("#c-xml-page-material-fontId1").height()) + Number(10);
                var _w = Number($("#c-xml-page-material-fontId1").width()) + Number(10);
                if(vm.elementOn.name == 'textbox') {
                    if(!writingmodeInit(vm.elementOn.item.style.writingmode)){
                        vm.elementOn.item.geometry.height = _h;
                    }
                    if(writingmodeInit(vm.elementOn.item.style.writingmode)){
                        vm.elementOn.item.geometry.width = _w;
                    }
                    vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox = vm.elementOn.item;
                    $scope.$apply();
                }
            })
        }
        vm.sizeHeight1 = sizeHeight1;
        function sizeHeight1() {
            $timeout(function () {
                var _h = Number($('.selected').find('.c-xml-page-textboxe-span').height()) + Number(10);
                var _w = Number($('.selected').find('.c-xml-page-textboxe-span').width()) + Number(10);
                if(vm.elementOn.name == 'textbox') {
                    if(!writingmodeInit(vm.elementOn.item.style.writingmode)){
                        vm.elementOn.item.geometry.height = _h / vm.pageData[vm.pageNumber].custom.ratio;
                    }
                    if(writingmodeInit(vm.elementOn.item.style.writingmode)){
                        vm.elementOn.item.geometry.width = _w/vm.pageData[vm.pageNumber].custom.ratio;
                    }
                    vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox = vm.elementOn.item;
                    $scope.$apply();
                }
            })
            // text.geometry.height = h / vm.pageData[vm.pageNumber].custom.ratio * (1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio) + Number(5);
            // text.geometry.height = h + Number(5);
        }

        /***********************  文字模板  ****************************/
        $scope.addTextDoc = function (text,e) {
            FontXmlsFileServes.get({id: text.id}, function (res) {
                var level = {
                    seq: vm.pageData[vm.pageNumber].levels.level.length+1,
                    textdocument: {}
                }
                textDocWebAdd($scope,$http,level, res.textDocument, vm.pageData[vm.pageNumber].trimbox, vm.pageData[vm.pageNumber].mediabox, function (level) {
                    angular.forEach(level.textdocument.levels.level, function (lev1, l1) {
                        (function (lev) {
                            if (lev.textbox && lev.textbox.geometry) {
                                if (lev.textbox.fontuuid) {
                                    //下载字体
                                    getFontCSS(lev.textbox)
                                    FontSupport2(FontManagement, lev.textbox)
                                }
                                if (lev.textbox.style.bold && lev.textbox.style.italic) {
                                    lev.textbox.styles = 'BOLD_ITALIC';
                                } else if (lev.textbox.style.bold) {
                                    lev.textbox.styles = 'BOLD';
                                } else if (lev.textbox.style.italic) {
                                    lev.textbox.styles = 'ITALIC';
                                } else {
                                    lev.textbox.styles = 'NORMAL';
                                }
                            }
                        })(lev1,l1)
                    })
                    vm.pageData[vm.pageNumber].levels.level.push(level);
                    elementInit(level.seq-1,vm.pageNumber,level.textdocument,'textdocument');
                    cookie();
                }, function (text) {
                    getFontCSS(FontManagement, text, $scope)
                })
            })
        };
        //拆分组合
        $scope.slipGroup = function () {
            var textdocument = angular.copy(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textdocument);
            if(textdocument.lock){
                return
            }
            $scope.deleteBox();
            var textGeo = textdocument.geometry;
            if (textdocument.background && textdocument.background.resource && textdocument.background.resource.identifier) {
                $http({url: textdocument.background.resource.identifier + '?imageInfo'}).success(function (data) {
                    var resource = textdocument.background.resource;
                    var angle = 0;
                    if (textdocument.rotation.angle) {
                        angle = textdocument.rotation.angle;
                    }
                    var scale = 1;
                    if (textdocument.background.resource.adaptation == "Height") {
                        // scale = data.width / textdocument.geometry.width;
                        // scale = textdocument.geometry.width/data.width*data.height / textdocument.geometry.height;
                        scale = textdocument.geometry.height/data.height * data.width / textdocument.geometry.width;
                    }
                    var _imageart = {
                        movable: true,
                        editable: true,
                        onlyshow: false,
                        offsetx: textdocument.background.offsetx,
                        offsety: textdocument.background.offsety,
                        transparency: '1',
                        geometry: {
                            width: textdocument.geometry.width,
                            height: textdocument.geometry.height,
                            x: textdocument.geometry.x,
                            y: textdocument.geometry.y
                        },
                        rotation: {
                            angle: angle
                        },
                        resource: {
                            identifier: resource.identifier,
                            provider: 'qiniu',
                        },
                        width: data.width,
                        height: data.height,
                        imageInfo: data,
                        scale: scale,
                        useradded: true
                    }
                    var _level ={
                        seq: vm.pageData[vm.pageNumber].levels.level.length+1,
                        imageart: _imageart
                    }
                    vm.pageData[vm.pageNumber].levels.level.push(_level);
                    elementInit(_level.seq-1,vm.pageNumber,_level.imageart,'imageart');
                }).error(function (error) {});
            }
            angular.forEach(textdocument.levels.level, function (level, i) {
                if (level.imageart) {
                    $http({url: level.imageart.resource.identifier + '?imageInfo'}).success(function (data) {
                        var resource = level.imageart.resource;
                        var angle = 0;
                        if (textdocument.rotation.angle) {
                            angle = textdocument.rotation.angle;
                        }
                        var _imageart = {
                            movable: true,
                            editable: true,
                            onlyshow: false,
                            offsetx: level.imageart.offsetx,
                            offsety: level.imageart.offsety,
                            transparency: '1',
                            geometry: {
                                width: level.imageart.geometry.width,
                                height: level.imageart.geometry.height,
                                x: level.imageart.geometry.x + textGeo.x,
                                y: level.imageart.geometry.y + textGeo.y
                            },
                            rotation: {
                                angle: angle
                            },
                            resource: {
                                identifier: resource.identifier,
                                provider: 'qiniu',
                                width: data.width,
                                height: data.height
                            },
                            scale: level.imageart.scale,
                            useradded: true
                        }
                        var _level ={
                            seq: vm.pageData[vm.pageNumber].levels.level.length+1,
                            imageart: _imageart
                        }
                        vm.pageData[vm.pageNumber].levels.level.push(_level);
                        elementInit(_level.seq-1,vm.pageNumber,_level.imageart,'imageart');
                    }).error(function (error) {});
                }
                if (level.textbox) {
                    var currentLevel ={
                        seq: vm.pageData[vm.pageNumber].levels.level.length+1,
                        textbox: angular.copy(level.textbox)
                    }
                    currentLevel.textbox.movable = textdocument.movable;
                    currentLevel.textbox.useradded = true;
                    currentLevel.textbox.editable = textdocument.editable;
                    currentLevel.textbox.levelI = $scope.template.levelI;
                    currentLevel.textbox.pageI = $scope.pageI;
                    currentLevel.textbox.current = 'textbox';
                    currentLevel.textbox.parentCurrent = null;
                    currentLevel.textbox.lock = false;
                    currentLevel.textbox.geometry.x = level.textbox.geometry.x + textGeo.x;
                    currentLevel.textbox.geometry.y = level.textbox.geometry.y + textGeo.y;
                    currentLevel.textbox.rotation.angle = textdocument.rotation.angle ? textdocument.rotation.angle : 0;

                    vm.pageData[vm.pageNumber].levels.level.push(currentLevel);
                    elementInit(currentLevel.seq-1,vm.pageNumber,currentLevel.textbox,'textbox');
                    $timeout(function () {
                        // vm.pageData[vm.pageNumber].levels.level[currentLevel.seq-1].textbox.geometry.width = (Number($("#c-xml-page-material-fontId").width() / (3/4) / vm.pageData[vm.pageNumber].custom.ratio * (1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio)) + Number(5));
                        // vm.pageData[vm.pageNumber].levels.level[currentLevel.seq-1].textbox.geometry.height = Number($("#c-xml-page-material-fontId").height() / (3/4) / vm.pageData[vm.pageNumber].custom.ratio) * (1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio);

                        vm.pageData[vm.pageNumber].levels.level[currentLevel.seq-1].textbox.geometry.width = Number($("#c-xml-page-material-fontId").width()) + Number(10);
                        vm.pageData[vm.pageNumber].levels.level[currentLevel.seq-1].textbox.geometry.height = (Number($("#c-xml-page-material-fontId").height()) + Number(10));
                        // ModulesService.getFontCSS(vm.pageData[vm.pageNumber].levels.level[currentLevel.seq-1].textbox);
                    })
                }
                $timeout(function () {
                    cookie();
                },500)
            })

        }

        /***********************  背景  ****************************/
        $scope.chooseBgColor = function(color){
            vm.pageData[vm.pageNumber].background.color = color;
            vm.pageData[vm.pageNumber].background.type = "Color";
            vm.pageData[vm.pageNumber].background.resource.identifier = "";
            vm.pageData[vm.pageNumber].background.offsetx = 0;
            vm.pageData[vm.pageNumber].background.offsety = 0;
            cookie();
        }
        $scope.chooseBgColor1 = function(color){
            vm.pageData[vm.pageNumber].background.type = "Color";
            vm.pageData[vm.pageNumber].background.color = "";
            vm.pageData[vm.pageNumber].background.resource.identifier = "";
            vm.pageData[vm.pageNumber].background.offsetx = 0;
            vm.pageData[vm.pageNumber].background.offsety = 0;
            cookie();
        }
        $scope.chooseColor = function () {
            $scope.chooseBgColor(vm.bgColor);
            vm.bgColor = null;
        }
        $scope.chooseBgimg = function(data){
            vm.pageData[vm.pageNumber].background.type = "Pic";
            vm.pageData[vm.pageNumber].background.resource.identifier = data.identifier;
            vm.pageData[vm.pageNumber].background.offsetx = 0;
            vm.pageData[vm.pageNumber].background.offsety = 0;
            vm.pageData[vm.pageNumber].background.imageInfo = null;
            getBackgroundSource($http, vm.pageData[vm.pageNumber].background.resource, vm.pageData[vm.pageNumber].trimbox.width, vm.pageData[vm.pageNumber].trimbox.height, vm.pageData[vm.pageNumber].background, $scope, function () {
                cookie();
            })
        };

        /***********************  左侧菜单  ****************************/
        vm.nav = "picture";
        vm.navSelect = navSelect;
        function navSelect(item) {
            vm.leftShow = true;
            vm.nav = item;
            if(item == 'rahmen'){
                classifyInit('Material')
            }
            if(item == 'material'){
                classifyInit('ClipArt')
            }
            if(item == 'text'){
                classifyInit('FONT')
            }
            if(item == 'bgImg'){
                classifyInit('Background')
            }
            if(item == 'template'){
                templateInit()
            }
            if(item == 'border'){
                classifyInit('BORDER')
            }
            if(item == 'page'){
                vm.partid = vm.pageData[vm.pageNumber].config.partid;
            }
        }
        /***********************  照片  ****************************/
        vm.folderShow = false;
        vm.indexNum = 0;
        vm.itemsPerPage = 40;
        $scope.photos = [];
        $scope.moveGalleryIds = [];
        vm.picLoading = false;
        vm.folderIds = "";
        vm.folderId = "";
        vm.photosTab='all';
        function loadFolders() {
            Folder.getAllByCondition({
                items: [{key: "type", op: "=", value: "Gallery"}],
                sort: ["id,asc"]
            }, function (resp) {
                $scope.Folders = resp;
            })
        }
        $scope.folderOn = function () {
            vm.folderShow = !vm.folderShow;
        };
        // 增加文件夹
        $scope.addFolder = function () {
            $uibModal.open({
                templateUrl: 'app/entities/sales-order/editor-name-dialog.html',
                controller: 'EditorNameDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            name: '',
                            popName:'新建文件夹'
                        };
                    },
                }
            }).result.then(function(name) {
                Folder.save({
                    name: name,
                    type: 'Gallery'
                }, function (resp) {
                    $scope.Folders.push(resp);
                })
            });
        }
        //选择文件夹
        $scope.showFolderContent = function (folder) {
            vm.indexNum = 0;
            $scope.photos = [];
            $scope.gallaryPic = [];
            vm.folderId = folder ? folder.id : '';
            vm.folderName = folder ? folder.name : '';
            vm.totalPhotoItems = null;
            vm.maxId = null;
            vm.minId = null;
            vm.unusedQuantity = null;

            if (folder) {
                loadAll(folder.id);
            } else {
                loadAll('isNull');
            }
        };
        $scope.deleteShow = function (ids,event) {
            fn(event)
            vm.folderIds=ids;
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'删除',
                msg :'删除文件夹会同时删除文件夹内全部照片, 确认删除？'
            }, function() {
                Folder.batchDelete({ids: vm.folderIds}, function () {
                    vm.indexNum = 0;
                    vm.totalPhotoItems = null;
                    vm.maxId = null;
                    vm.minId = null;
                    $scope.photos = [];
                    loadAll('isNull');
                    loadFolders();
                    MessageService.success("删除成功");
                }, function () {
                    MessageService.error("删除失败");
                });
            })
        }
        $scope.updateFloders = function(folder,event){
            event.stopPropagation();
            $uibModal.open({
                templateUrl: 'app/entities/sales-order/editor-name-dialog.html',
                controller: 'EditorNameDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            name: folder.name,
                        };
                    },
                }
            }).result.then(function(name) {
                folder.name = name;
                Folder.update(folder, function (resp) {
                    MessageService.success("修改成功");
                },function () {
                    swal.showInputError("请输入正确的文件名!");
                })
            }, function() {

            });
        }

        $scope.photoCb = function () {
            if(vm.folderId){
                loadAll(vm.folderId);
            }else{
                loadAll('isNull');
            }
        }

        vm.photoSort = '1';
        $scope.photoSortChange = function (num) {
            vm.indexNum = 0;
            vm.photoSort = num;
            vm.totalPhotoItems = null;
            vm.maxId = null;
            vm.minId = null;
            $scope.photos = [];
            if(vm.folderId){
                loadAll(vm.folderId);
            }else{
                loadAll('isNull');
            }
        }
        $timeout(function () {
            $('#navId').on('scroll', function(event){
                if(vm.nav=='picture'){
                    var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
                    if($scope.photos.length < vm.totalPhotoItems && !vm.picLoading){
                        if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                            vm.indexNum++;
                            if(vm.folderId){
                                loadAll(vm.folderId);
                            } else {
                                loadAll('isNull');
                            }
                        }
                    }
                }
            });
        })
        vm.totalPhotoItems = null;
        vm.maxId = null;
        vm.minId = null;
        function loadAll(folderId) {
            vm.picLoading = true;
            var sort = ["id,desc"];
            if(vm.photoSort == 1){
                sort = ["id,desc"]
            }else if(vm.photoSort == 2){
                sort = ["orderBySeq,desc","name,desc"]
            }else if(vm.photoSort == 3){
                sort = ["orderBySeq,asc","name,asc"]
            }
            var items = [];
            if (folderId == 'isNull') {
                items.push({key: "folderId", op: "isNull", value: 1})
            }else{
                items.push({key: "folderId", op: "=",value: folderId});
            }
            if (vm.maxId) {
                items.push({key: "id", op: "<=",value: vm.maxId});
            }
            if (vm.minId) {
                items.push({key: "id", op: "<",value: vm.minId});
            }
            if (folderId == 'isNull') {
                $scope.selectFolder = '已传照片';
                Gallery.getPage({
                    items: items,
                    page: vm.indexNum,
                    size: vm.itemsPerPage,
                    sort: sort
                }, onSuccess, onError);
            } else {
                Gallery.getPage({
                    items: items,
                    page: vm.indexNum,
                    size: vm.itemsPerPage,
                    sort: sort
                }, onSuccess, onError);
            }
        }
        function onSuccess(data, headers) {
            if (vm.totalPhotoItems == null) {
                vm.totalPhotoItems = Number(headers('X-Total-Count'));
            }
            if (data.length > 0 && vm.maxId == null && vm.photoSort == 1) {
                vm.maxId = data[0].id;
            }
            vm.picLoading = false;
            var len = $scope.photos.length;
            angular.forEach(data, function (item, i) {
                item.identifier = $scope.domain + item.identifier;
                item.select = false;
                item.index = i + len;
                item.usenum = 0;
                item.type = 'picture';
                $scope.photos.push(item);
            });
            photosNum();
            if($scope.photos.length < vm.totalPhotoItems){
                vm.isPhotoFinish = false;
            }else {
                vm.isPhotoFinish = true;
            }
            if($scope.photos.length > 0){
                newCreateDate = new Date($scope.photos[0].createdDate).getTime();
            }
        }

        function onError(error) {
            vm.picLoading = false;
            MessageService.error("照片库获取失败");
        }
        function getGallery(){
            if(vm.photosTab!='all' && $scope.photos.length < vm.totalPhotoItems){
                var _photoQuantity = 0;
                $scope.photos.forEach(function (item) {
                    if(item.usenum==0||!item.usenum){
                        _photoQuantity++
                    }
                })
                if(_photoQuantity < vm.itemsPerPage){
                    vm.indexNum++
                    if(vm.folderId){
                        loadAll(vm.folderId);
                    }else{
                        loadAll('isNull');
                    }
                }
            }
        }
        //删除多个文件夹
        // $scope.deleteFolders = function () {
        //     vm.deleteSure = false;
        //     Folder.batchDelete({ids: vm.folderIds}, function () {
        //         vm.indexNum = 0;
        //         $scope.photos = [];
        //         loadAll('isNull');
        //         loadFolders();
        //         MessageService.success("删除成功");
        //     }, function () {
        //         MessageService.error("删除失败");
        //     });
        // };
        $scope.choosePhoto = function (photo) {
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            photo.select = !photo.select;
            angular.forEach($scope.photos, function (p, i) {
                if (p.select) {
                    $scope.gallaryPic.push(p);      //选择导入图片的数组
                    $scope.moveGalleryIds.push(p.id);
                }
            });
        }
        $scope.switchBgTab = function (attr) {
            vm.photosTab = attr;
            if(vm.photosTab!='all'){
                vm.unUsedImageboxsNum = 0;
                angular.forEach($scope.photos,function (p) {
                    if(p.usenum==0){
                        vm.unUsedImageboxsNum++;
                    }
                })
                // getGallery();
            }
            $scope.whetherChoose(false);
        }
        $scope.whetherChoose = function (attr) {
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            $scope.photos.some(function (p, i) {
                if(vm.photosTab!='all'){
                    if(p.usenum==0||!p.usenum){
                        if (attr) {
                            p.select = true;
                            $scope.gallaryPic.push(p);      //选择导入图片的数组
                            $scope.moveGalleryIds.push(p.id);
                        } else {
                            p.select = false;
                            $scope.gallaryPic = [];      //选择导入图片的数组
                            $scope.moveGalleryIds = [];
                        }
                    }
                }else{
                    if (attr) {
                        p.select = true;
                        $scope.gallaryPic.push(p);      //选择导入图片的数组
                        $scope.moveGalleryIds.push(p.id);
                    } else {
                        p.select = false;
                        $scope.gallaryPic = [];      //选择导入图片的数组
                        $scope.moveGalleryIds = [];
                    }
                }
            })
        }
        $scope.deleteBatchPhotos = function () {
            if (!$scope.moveGalleryIds.length) {
                MessageService.error("操作失败,请至少选择一个照片");
                return
            }
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'删除',
                msg :'删除照片将移除作品中正在使用的图片，确认删除么?'
            }, function(){
                angular.forEach($scope.moveGalleryIds, function(id){
                    angular.forEach($scope.template.pages.page, function (item, index) {
                        angular.forEach(item.levels.level, function (level) {
                            if (level.imagebox && level.imagebox.image && level.imagebox.image.resourceid == id) {
                                var box = level.imagebox
                                box.blurredrectangle = {
                                    x: 0,
                                    y: 0,
                                    width: box.geometry.width,
                                    height: box.geometry.height
                                }
                                box.imgUrl = "content/images/mask_souce.png";
                                box.image = {
                                    resource: {
                                        identifier:"",
                                        provider: ''
                                    },
                                    filterResource: {
                                        identifier: '',
                                        provider: ''
                                    },
                                    offsetx: 0,
                                    offsety: 0,
                                    width: '',
                                    height: '',
                                    imgsize: '',
                                    index: '',
                                    parameter: [],
                                    scale: 1,
                                    angle: 0,
                                }
                            }
                        })
                    });
                });
                Gallery.batchDelete($scope.moveGalleryIds, function () {
                    MessageService.success("删除成功！");
                    // 若被选中的pic被删除，就重新生成该数组
                    vm.unusedQuantity = null;

                    vm.maxId = null;
                    vm.minId = null;
                    vm.totalPhotoItems = null;
                    $scope.gallaryPic = [];
                    $scope.moveGalleryIds = [];
                    $scope.photos = [];
                    vm.indexNum = 0;
                    if(vm.folderId){
                        loadAll(vm.folderId);
                    } else {
                        loadAll('isNull');
                    }
                    magnitudeBox();
                }, function (error) {
                    MessageService.error("删除失败！");
                });
            })
        }
        //一键填充
        $scope.directFill= function(){
            // if(vm.forceFill && $scope.gallaryPic.length < (vm.counts-vm.current)){
            //     MessageService.error("请选择" + (vm.counts-vm.current) + "张照片，再尝试一键填充！");
            //     return;
            // }
            vm.sumIndex = 0;
            if($scope.gallaryPic.length < 1){
                return
            }
            directFill1();
            
            if (vm.product.editTip && vm.product.editTipPic) {
                $uibModal.open({
                    templateUrl: 'app/entities/components/controls/editorTipPicModal.html',
                    controller: 'EditorTipPicModalController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                url: vm.product.editTipPic,
                                editorType: vm.product.editorType
                            };
                        },
                    }
                });
            }
        }
        $scope.fillPage = function(){
            directFill1()
        }
        function directFill1(){
            vm.isDirectFill = false;
            if(vm.isLayout && ($scope.gallaryPic.length > vm.counts-vm.current && vm.picLayoutXmlsList.length>0) && (vm.article.editorType == 'BOOK_JUNIOR' || vm.article.editorType == 'BOOK_SENIOR' || vm.article.editorType == 'NEW_EDITOR' || vm.article.editorType == 'CALENDAR_EDITOR')){
                angular.forEach(vm.pageData, function (page) {page.isFill = false;})
                directFillCb1($scope.gallaryPic)
            }else{
                angular.forEach(vm.pageData, function (page) {
                    setLevelImgeboxImg(page, $scope.gallaryPic);
                })
                imageSelectInit();
                magnitudeBox();
                thumbnailInit();
                $scope.whetherChoose(false);
            }
        }
        //随机布局
        $scope.stochastic = function () {
            vm.sumIndex = 0;
            vm.galleriesAll = [];
            angular.forEach(vm.pageData, function (page) {
                page.isFill = false;
                angular.forEach(page.levels.level, function (level, i) {
                    if (level.imagebox) {
                        if (level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier) {
                            vm.galleriesAll.push({
                                imageInfo: level.imagebox.image.imageInfo,
                                height: level.imagebox.image.height,
                                id: level.imagebox.image.resourceid,
                                identifier: level.imagebox.image.resource.identifier,
                                imgsize: level.imagebox.image.imgsize,
                                name: null,
                                provider: "qiniu",
                                width: level.imagebox.image.width
                            })
                            level.imagebox.image.imageInfo = null;
                            level.imagebox.image.resourceid = null;
                            level.imagebox.image.resource.identifier = null;
                            level.imagebox.image.imgsize = null;
                            level.imagebox.image.height = null;
                            level.imagebox.image.width = null;
                        }
                    }
                })
            })
            directFillCb1(vm.galleriesAll)
        }
        function directFillCb1(gallaryPic){
            directFillCb(true, gallaryPic);
            var notPageCounts=0;
            var pages=[];
            angular.forEach(vm.pageData, function (page, index) {
                page.uuid1 = getUuid();
                if(!page.isFill){
                    notPageCounts++;
                    pages.push(page)
                }
            });
            if(pages.length == 0){
                MessageService.error("已填充满，无法查找布局库");
                imageSelectInit();
                magnitudeBox();
                $scope.whetherChoose(false);
                return;
            }
            randomPlacement(Convert,Algorithm, pages,vm.picLayoutXmlsList,gallaryPic, vm.sumIndex, function (pages, notLike) {
                if(notLike){
                    MessageService.error("布局库中未找到匹配的布局");
                }
                angular.forEach(pages, function (page) {
                    page.isFill = true;
                    setLevelImgeboxImg(page, gallaryPic);
                })
                imageSelectInit();
                magnitudeBox();
                $scope.whetherChoose(false);
            })
        }
        function directFillCb(item, gallaryPic){
            angular.forEach(vm.pageData, function (page, index) {
                if(item == page.unreplace){
                    page.isFill = true;
                    setLevelImgeboxImg(page, gallaryPic);
                }else{
                    var _isNotImg = false;
                    var _isNotImg1 = false;
                    angular.forEach(page.levels.level, function (level, i) {
                        if(level.imagebox){
                            if(level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                                _isNotImg = true;
                            }else{
                                _isNotImg1 = true;
                            }
                        }
                    });
                    if(_isNotImg && _isNotImg1){
                        page.isFill = true;
                        setLevelImgeboxImg(page, gallaryPic);
                    }
                    if(_isNotImg && !_isNotImg1){
                        page.isFill = true;
                    }
                }
            })
        }
        function setLevelImgeboxImg(page, gallaryPic){
            page.updateThumbnail = true;
            angular.forEach(page.levels.level, function (level, i) {
                if(level.imagebox){
                    if(!level.imagebox.image){
                        level.imagebox.image= {
                            resource: {
                                identifier: '',
                                provider: ''
                            },
                            filterResource: {
                                identifier: '',
                                provider: 'qiniu'
                            },
                            scale: 1,
                            angle: 0
                        };
                    }
                    if(!level.imagebox.image.resource){
                        level.imagebox.image.resource = {
                            identifier:''
                        }
                    }
                    if(!level.imagebox.image.resource.identifier){
                        var _imagebox = angular.copy(gallaryPic[vm.sumIndex]);
                        if(!_imagebox){
                            return false
                        }
                        vm.sumIndex++
                        level.imagebox.image.resourceid = _imagebox ? _imagebox.id : '';
                        level.imagebox.image.resource.identifier = _imagebox ? _imagebox.identifier : '';
                        level.imagebox.image.resource.provider = _imagebox ? _imagebox.provider : 'qiniu';
                        level.imagebox.image.width = _imagebox ? _imagebox.width : '';
                        level.imagebox.image.height = _imagebox ? _imagebox.height : '';
                        level.imagebox.image.imageInfo = _imagebox ? _imagebox.imageInfo : '';
                        level.imagebox.image.imgSize = _imagebox ? _imagebox.imgSize : '';
                        level.imagebox.image.index = _imagebox ? _imagebox.seq : '';
                        level.imagebox.image.parameter = _imagebox ? angular.fromJson(_imagebox.parameter) : [];
                        if(level.imagebox.image.resource.identifier){
                            addImagebox($http, level.imagebox, function () {})
                        }
                    }
                }
            })
        }

        vm.photosNum=photosNum;
        function photosNum(){
            for(var i=0; i<$scope.photos.length; i++){
                var _photo = $scope.photos[i];
                _photo.usenum=0;
                angular.forEach(vm.pageData, function (page, index) {
                    angular.forEach(page.levels.level, function (level, i) {
                        if(level.imagebox && level.imagebox.image && level.imagebox.image.resourceid) {
                            if (level.imagebox.image.resourceid == _photo.id) {
                                _photo.usenum++
                            }
                        }
                    })
                })
            }
            recommendInit($scope.template);
            unusedQuantity();
        }
        // 背景图和素材的上传
        $scope.uploadVerify = function(){
            uploadVerify(null, CustomerUploadAble, vm.storeId, vm.customer.id, function () {
                $("#uploadImg2").trigger("click");
            }, function () {
                getStorageBuy()
            })
        };

        $scope.getStorageBuy = getStorageBuy;
        function getStorageBuy(){
            $uibModal.open({
                templateUrl: "app/entities/web-gallery/storage-buy.html",
                controller: "StorageBuyController",
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                }
            }).result.then(function() {
                getByCustomer();
            })
        }

        window.updatePic = function ($event) {
            $timeout(function () {
                vm.picLoading = true;
                var len = $scope.photos.length;
                repeatCount = 0;
                var files = [];
                var files1 = [];
                var higherSize = [];
                bigSizePics = [];
                for (var i = 0; i < $event.target.files.length; i++) {
                    var f = $event.target.files[i];
                    f.imageSize = Math.ceil(f.size/1024/1024);
                    files.push(f)
                }
                for (var i = 0; i < files.length; i++) {
                    var file = files[i];
                    if (file.size>=20*1024*1024 ){
                        file.imageSize= Math.ceil(file.size/1024/1024);
                        bigSizePics.push(file);
                    }else{
                        files1.push(files[i])
                    }
                }
                if(files1.length>0){
                    for(var i=0; i<files1.length; i++){
                        (function (index) {
                            getBgImg(files1, index);
                        })(i)
                    }
                }
                $event.srcElement.value = ""
            })
        };
        var repeatCount = 0;
        var bigSizePics = [];
        vm.duplicateList = [];
        vm.isDuplicateList = false;

        function getBgImg(files, i, len) {
            var url = window.URL || window.webkitURL;
            var img = new Image();              //手动创建一个Image对象
            img.src = url.createObjectURL(files[i]);//创建Image的对象的url
            img.crossOrigin = "Anonymous";
            ImageService.backendUploadPics(files[i],$scope.domain, vm.token,function (data,f) {
                Gallery.upload({
                    identifiers: data.key,
                    hash: data.hash,
                    provider: "QINIU",
                    fileName: f.name,
                    imgSize: f.size,
                    folderId: vm.folderId ? vm.folderId : ''
                }, function (resp) {
                    if(resp.duplicate){
                        resp.identifier= $scope.domain + resp.identifier;
                        vm.duplicateList.push(resp)
                    }
                    GalleryUploadFinish(files,i)
                }, function () {
                    GalleryUploadFinish(files,i)
                    MessageService.error("上传失败");
                });
            },function (res) {
                GalleryUploadFinish(files,i)
            })
        }
        function GalleryUploadFinish(files,i) {
            repeatCount++
            if(repeatCount == files.length){
                document.getElementById('uploadImg2').value = '';//上传完清空输入框
                vm.picLoading = false;
                $scope.gallaryPic = [];
                $scope.moveGalleryIds = [];
                if(vm.duplicateList.length>0){
                    MessageService.confirm({
                        title :'提示',
                        cancel :'取消',
                        confirm :'确认',
                        msg :'检测到图库中有'+vm.duplicateList.length+'张重复图片，是否需要自动去重？',
                        html: '<span class="themecolor">查看重复图片</span>'
                    }, function(res) {
                        if(res == 'htmlClick'){
                            vm.isDuplicateList = true;
                        }else{
                            var ids = [];
                            vm.duplicateList.forEach(function (item){
                                ids.push(item.id)
                            })
                            Gallery.batchDelete(ids, function () {
                                MessageService.success("去重成功！");
                                vm.maxId = null;
                                vm.minId = null;
                                vm.totalPhotoItems = null;
                                $scope.photos = [];
                                vm.duplicateList=[];
                                vm.indexNum = 0;
                                if(vm.folderId){
                                    loadAll(vm.folderId);
                                } else {
                                    loadAll('isNull');
                                }
                                if (bigSizePics.length >0) {
                                    thumbnailatorPic();
                                }
                            }, function (error) {
                                MessageService.error("删除失败！");
                                vm.duplicateList=[];
                                if (bigSizePics.length >0) {
                                    thumbnailatorPic();
                                }
                            });
                        }
                    },function (){
                        vm.duplicateList=[];
                        if (bigSizePics.length >0) {
                            thumbnailatorPic();
                        }
                    })
                }else{
                    if (bigSizePics.length >0) {
                        thumbnailatorPic();
                    }
                }
            }
        }
        $scope.duplicateChange = function (){
            vm.isDuplicateList = false;
            vm.duplicateList=[];
            vm.maxId = null;
            vm.minId = null;
            vm.totalPhotoItems = null;
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            $scope.photos = [];
            vm.indexNum = 0;
            if(vm.folderId){
                loadAll(vm.folderId);
            } else {
                loadAll('isNull');
            }
            if (bigSizePics.length >0) {
                thumbnailatorPic();
            }
        }

        function thumbnailatorPic(){
            $uibModal.open({
                templateUrl: 'app/entities/page/thumbnailator.html',
                controller: 'ThumbnailatorController',
                controllerAs: 'vm',
                size: 'md',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {
                            files: bigSizePics
                        };
                    }]
                }
            }).result.then(function (values) {
                bigSizePics=[];
                if(values){
                    var _uploadIndex = 0;
                    for (var j = 0; j < values.length; j++) {
                        var value = values[j];
                        var data = {key:value.key,hash:value.hash};
                        Gallery.upload({
                            hash: data.hash,
                            identifiers: data.key,
                            provider: "QINIU",
                            fileName: value.name,
                            imgSize: value.size,
                            width: value.width,
                            height: value.height,
                            folderId: vm.folderId ? vm.folderId : ''
                        }, function (resp) {
                            if(resp.duplicate){
                                resp.identifier= $scope.domain + resp.identifier;
                                vm.duplicateList.push(resp)
                            }
                            _uploadIndex++
                            if(_uploadIndex == values.length){
                                if(vm.duplicateList.length>0){
                                    MessageService.confirm({
                                        title :'提示',
                                        cancel :'取消',
                                        confirm :'确认',
                                        msg :'检测到图库中有'+vm.duplicateList.length+'张重复图片，是否需要自动去重？',
                                        html: '<span class="themecolor">查看重复图片</span>'
                                    }, function(res) {
                                        if(res == 'htmlClick'){
                                            vm.isDuplicateList = true;
                                        }else{
                                            var ids = [];
                                            vm.duplicateList.forEach(function (item){
                                                ids.push(item.id)
                                            })
                                            Gallery.batchDelete(ids, function () {
                                                MessageService.success("去重成功！");
                                                $scope.gallaryPic = [];
                                                $scope.moveGalleryIds = [];
                                                vm.indexNum = 0;
                                                vm.totalPhotoItems = null;
                                                vm.maxId = null;
                                                vm.minId = null;
                                                $scope.photos = [];
                                                vm.duplicateList=[];
                                                if (vm.folderId) {
                                                    loadAll(vm.folderId);
                                                } else {
                                                    loadAll('isNull');
                                                }
                                            }, function (error) {
                                                MessageService.error("删除失败！");
                                                vm.duplicateList=[];
                                            });
                                        }
                                    },function (){
                                        vm.duplicateList=[];
                                    })
                                }else{
                                    $scope.gallaryPic = [];
                                    $scope.moveGalleryIds = [];
                                    vm.indexNum = 0;
                                    vm.totalPhotoItems = null;
                                    vm.maxId = null;
                                    vm.minId = null;
                                    $scope.photos = [];
                                    if (vm.folderId) {
                                        loadAll(vm.folderId);
                                    } else {
                                        loadAll('isNull');
                                    }
                                }
                            }
                        }, function () {
                            vm.picLoading = false;
                            MessageService.error("上传失败");
                        });
                    }
                    vm.picLoading = false;
                    setTimeout(function () {
                        angular.forEach($scope.photos, function (item, z) {
                            item.index = z;
                        });
                    }, 400)
                }
            }, function(){
                vm.picLoading = false;
            });
        }

        //转移照片
        $scope.moveToFolder = function () {
            if ($scope.gallaryPic.length < 1) {
                MessageService.error("请至少选择一张图片");
                return
            }
            createModal2('app/entities/web-folder/entity-move-folder.html', 'EntityMoveFolderController', $scope.moveGalleryIds, "Gallery", vm.folderId).result.then(
                function() {
                    // vm.page = 0;
                    // loadAll()
                    Folder.getAllByConditionAndQuantity({type:"Gallery"},function(res){
                            $scope.Folders = res.message;
                            angular.forEach($scope.Folders, function (f, i) {
                                f.selected = false;
                            })
                            vm.unusedQuantity = null;

                            vm.maxId = null;
                            vm.minId = null;
                            vm.totalPhotoItems = null;
                            $scope.gallaryPic = [];
                            $scope.moveGalleryIds = [];
                            vm.indexNum = 0;
                            $scope.photos = [];
                            if (vm.folderId) {
                                loadAll(vm.folderId);
                            } else {
                                loadAll('isNull');
                            }
                    });
                });
        }
        var createModal2 = function(url, controller, ids, type, folderId) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: {
                        type: type,
                        ids: ids,
                        folderId: folderId
                    },
                }
            });
        };
        // $scope.chooseFolder = function (folder, index) {
        //     vm.selectFolderId = null;
        //     folder.selected = !folder.selected;
        //     angular.forEach($scope.Folders, function (f, i) {
        //         if (index != i) {
        //             f.selected = false;
        //         }
        //     })
        //     if (folder.selected) {
        //         vm.selectFolderId = folder.id;
        //     }
        // }
        // $scope.saveMoveToFolder = function () {
        //     if (vm.selectFolderId == null) {
        //         MessageService.error("请选择文件夹！");
        //         return
        //     }
        //     $scope.moveGalleryIds = [];
        //     angular.forEach($scope.gallaryPic, function (p, i) {
        //         $scope.moveGalleryIds.push(p.id);
        //     })

        //     Gallery.moveFolder({
        //         galleries: $scope.moveGalleryIds,
        //         folderId: vm.selectFolderId
        //     }, function () {
        //         angular.forEach($scope.Folders, function (f, i) {
        //             f.selected = false;
        //         })
        //         $scope.photos = [];
        //         vm.isMoveToFolder = false;
        //         if (vm.folderId) {
        //             loadAll(vm.folderId);
        //         } else {
        //             loadAll('isNull');
        //         }
        //     })
        // }

        //手机传图
        vm.qrcodeShow = false;
        vm.fid = 0;
        var qrcode = null;
        var phonetimer = null;
        var newCreateDate = 0;
        $scope.uploadByPhone = function () {
            uploadVerify(null, CustomerUploadAble, vm.storeId, vm.customer.id, function () {
                vm.qrcodeShow = !vm.qrcodeShow;
                if (qrcode != null) {
                    // qrcode.clear();
                    $('#qrcode1').html("");
                }

                //vm.folderId -----某一文件夹信息
                if (vm.folderId == undefined || vm.folderId == null) {
                    vm.fid = 0;
                } else {
                    vm.fid = vm.folderId;
                }

                // 手机传图二维码
                qrcode = $('#qrcode1').qrcode({
                    render: "canvas",
                    width: 150,
                    height: 150,
                    text: '' + vm.platformSetting.storeNotifyUrl + "/#/" + vm.storeId + "/" + vm.fid + "/gallery/" + vm.userId
                });
                // (new Date("2012/12/25 20:11:11")).getTime()

                // if (vm.qrcodeShow) {
                //     phonetimer = setInterval(function () {
                //         var gallery_items = [{key: "storeId", op: "=", value: vm.storeId}];
                //         if (vm.folderId) {
                //             gallery_items.push({key: "folderId", op: "=", value: vm.folderId});
                //         } else {
                //             gallery_items.push({key: "folderId", op: "isNull", value: 1});
                //         }
                //         Gallery.getPage({
                //             items: gallery_items,
                //             page: 0,
                //             size: vm.itemsPerPage,
                //             sort: ["createdDate,desc"]
                //         }, onSuccessToPhone, onError);
                //     }, 3000);
                // } else {
                //     clearInterval(phonetimer);
                //     phonetimer = null;
                // }
            }, function () {
                getStorageBuy()
            })
        }

        function onSuccessToPhone(data, headers) {
            vm.totalPhotoItems = headers('X-Total-Count');
            if (data == null || data.length == 0) {
                return;
            }
            var loadCreateDate = new Date(data[0].createdDate).getTime();
            for (var i = 0; i < data.length; i++) {
                var d = data[i];
                if (new Date(d.createdDate).getTime() > newCreateDate) {
                    d.identifier = $scope.domain + d.identifier;
                    d.select = false;
                    d.index = i;
                    $scope.photos.unshift(d);
                } else {
                    newCreateDate = loadCreateDate;
                    break;
                }
            }
            if (newCreateDate == 0) {
                newCreateDate = loadCreateDate;
            }
        }
        $scope.uploadByPhone1 = function(){
            vm.qrcodeShow = false;
            // clearInterval(phonetimer);
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            // vm.indexNum = 0;
            // $scope.photos = [];
            // if (vm.folderId) {
            //     loadAll(vm.folderId);
            // } else {
            //     loadAll('isNull');
            // }
        }
        /***********************  相框  ****************************/
        vm.rahmenPage = 0;
        vm.rahmenInit = rahmenInit;
        vm.masklabelsFulfill = false;
        $scope.showMaskLabels = false;
        vm.maskLoading = false;
        vm.masklabelCb = masklabelCb;
        vm.maskList = [];
        $scope.showMoreMaskLabels = function () {
            $scope.showMaskLabels = !$scope.showMaskLabels;
        }
        function rahmenInit(item) {
            vm.maskLoading = true;
            vm.masklabelChooseId = item;
            Material.byCondition3({
                items: [
                    {key: "themeClassify.id", op: "=", value: item.id}],
                page: vm.rahmenPage,
                size: vm.itemsPerPage
            }, function (res, headers) {
                var _sum = headers('X-Total-Count');
                angular.forEach(res, function (resp) {
                    var arr = {
                        type:'masking',
                        name: resp.name,
                        identifier: $scope.domain + resp.identifier,
                        provider: resp.provider,
                        themeClassifyName: resp.themeClassifyName,
                        themeClassifyId: resp.themeClassifyId,
                        resource: {
                            identifier: $scope.domain + resp.identifier,
                            provider: resp.provider,
                        }
                    }
                    vm.maskList.push(arr);
                })
                vm.maskLoading = false;
                if(vm.maskList.length < _sum){
                    vm.masklabelsFulfill = true;
                }else {
                    vm.masklabelsFulfill = false;
                }
            }, function () {
                vm.maskLoading = false;
            })
        }
        function masklabelCb(item){
            vm.maskList = [];
            vm.rahmenPage = 0;
            rahmenInit(item)
        }
        $scope.loadMoreMasks = function () {
            vm.rahmenPage++;
            rahmenInit(vm.masklabelChooseId)
        }


        /***********************  素材  ****************************/
        vm.clipArtsList = [];
        vm.clipArtsPage = 0;
        vm.clipArtLoading = false;
        vm.clipArtChooseId = "";
        vm.clipArtCb = clipArtCb;
        function clipArtInit(item) {
            if(!item){return}
            vm.clipArtLoading = true;
            vm.clipArtChooseId = item;
            ClipArt.byCondition3({
                items: [
                    {key: "themeClassify.id", op: "=", value: item.id}],
                page: vm.clipArtsPage,
                size: vm.itemsPerPage
            }, function (res, headers) {
                var _sum = headers('X-Total-Count');
                angular.forEach(res, function (resp) {
                    var arr = {
                        name: resp.name,
                        identifier: $scope.domain + resp.identifier,
                        provider: resp.provider,
                        themeClassifyName: resp.themeClassifyName,
                        themeClassifyId: resp.themeClassifyId,
                        resource: {
                            identifier: $scope.domain + resp.identifier,
                            provider: resp.provider,
                        }
                    }
                    vm.clipArtsList.push(arr);
                })
                vm.clipArtLoading = false;
                if(vm.clipArtsList.length < _sum){
                    vm.clipArtFulfill = true;
                }else {
                    vm.clipArtFulfill = false;
                }
            }, function () {
                vm.clipArtLoading = false;
            })
        }
        function clipArtCb(item){
            vm.clipArtsList = [];
            vm.clipArtsPage = 0;
            clipArtInit(item)
        }
        $scope.loadMoreClipArt = function () {
            vm.clipArtsPage++;
            clipArtInit(vm.clipArtChooseId)
        }
        $scope.clipArts = false;
        $scope.showMoreClipArts = function(){
            $scope.clipArts = !$scope.clipArts;
        }
        /***********************  文字  ****************************/
        vm.fontList = [];
        vm.fontPage = 0;
        vm.fontLoading = false;
        vm.fontChooseId = "";
        $scope.showfontdocLabels = false;
        vm.fontlabelCb = fontlabelCb;
        function fontInit(item) {
            if(!item){
                return
            }
            vm.fontLoading = true;
            vm.fontChooseId = item;
            FontXmlsManger.byCondition({
                items: [
                    {key: "themeClassify.id", op: "=", value: item.id}],
                page: vm.fontPage,
                size: vm.itemsPerPage,
                sort: ["id,desc"]
            }, function (res, headers) {
                var _sum = headers('X-Total-Count');
                vm.fontList = res;
                vm.fontLoading = false;
                if(vm.fontList.length < _sum){
                    vm.fontFulfill = true;
                }else {
                    vm.fontFulfill = false;
                }
            }, function () {
                vm.fontLoading = false;
            })
        }
        function fontlabelCb(item){
            vm.fontList = [];
            vm.fontPage = 0;
            fontInit(item)
        }
        // $scope.loadMoreClipArt = function () {
        //     vm.fontPage++;
        //     fontInit(vm.fontChooseId)
        // }
        $scope.showMorefontdocLabels = function () {
            $scope.showfontdocLabels = !$scope.showfontdocLabels;
        }

        vm.textBlur = false;
        /***********************  背景  ****************************/
        vm.colorArr1 = ['#ffffff', '#666666', '#7babaf', '#e69594', '#93a9da', '#eac8fb'];
        vm.colorArr2 = ['#000000', '#666666', '#a8a8a8', '#d9d9d9', '#ffffff', '#647c6e', '#ff5b5c', '#ffbd4a', '#fef952', '#99e264', '#36b72a', '#44d9e5', '#c27171', '#2fb3ff', '#b760a6'];

        vm.bgImgList = [];
        vm.bgImgPage = 0;
        vm.bgImgChooseId = "";
        $scope.showbgImgdocLabels = false;
        vm.bgImglabelCb = bgImglabelCb;
        function bgImgInit(item) {
            vm.bgImgChooseId = item;
            Backgrounds.byCondition3({
                items: [
                    {key: "themeClassify.id", op: "=", value: item.id}],
                page: vm.bgImgPage,
                size: vm.itemsPerPage
            }, function (res, headers) {
                angular.forEach(res, function (resp) {
                    var arr = {
                        name: resp.name,
                        identifier: $scope.domain + resp.identifier,
                        provider: resp.provider,
                        themeClassifyName: resp.themeClassifyName,
                        themeClassifyId: resp.themeClassifyId,
                        resource: {
                            identifier: $scope.domain + resp.identifier,
                            provider: resp.provider,
                        }
                    }
                    vm.bgImgList.push(arr);
                })
                var _sum = headers('X-Total-Count');
                if(vm.bgImgList.length < _sum){
                    vm.bgImgFulfill = true;
                }else {
                    vm.bgImgFulfill = false;
                }
            }, function () {
            })
        }
        function bgImglabelCb(item){
            vm.bgImgList = [];
            vm.bgImgPage = 0;
            bgImgInit(item)
        }
        $scope.loadMoreBgImg = function () {
            vm.bgImgPage++;
            bgImgInit(vm.bgImgChooseId)
        }
        $scope.showMorebgImgdocLabels = function () {
            $scope.showbgImgdocLabels = !$scope.showbgImgdocLabels;
        }
        /***********************  换书  ****************************/
        vm.tempCategories = [];
        vm.tempList = [];
        vm.productTitle = "";
        function templateInit(callback) {
            Product.get({id: vm.article.productId, cancelled: false ,fixXMLLine:true}, function (res) {
                vm.product = res;
                vm.forceFill = res.forceFill;
                vm.productTitle = res.name;
                for (var i = 0; i < res.itemValues.length; i++) {
                    var itemValue = res.itemValues[i];
                    if (itemValue.cancelled) {
                        continue;
                    }
                    if (itemValue.id == vm.article.itemValueId) {
                        vm.tempList = angular.copy(itemValue);
                    }
                }
                angular.forEach(vm.tempList.productXmlLines, function (line, i) {
                    if (line.id && !line.cancelled) {
                        line.themeClassifies.forEach(function (themeClassifie) {
                            if (vm.tempCategories.indexOf(themeClassifie.name) == -1 && themeClassifie.name != null) {
                                vm.tempCategories.push(themeClassifie.name);
                            }
                        })
                    }
                })
                if (vm.tempCategories.length) {
                    $scope.selectCategory(vm.tempCategories[0])
                }
                if (callback){callback()}
            })
        }
        $scope.showCategories = false;
        $scope.showMoreCategories = function () {
            $scope.showCategories = !$scope.showCategories;
        }
        $scope.selectCategory = function (c) {
            $scope.categoryNow = {
                label: c
            };
        };
        $scope.productXmlShow = function(themeClassifies){
            var _exist = false;
            themeClassifies.forEach(function (themeClassifie) {
                if ($scope.categoryNow.label ==  themeClassifie.name) {
                    _exist = true;
                }
            })
            return _exist
        }
        //预览
        $scope.tempPreview = function (temp) {
            $uibModal.open({
                templateUrl: 'app/entities/commodity/commodity-preview.html',
                controller: 'CommodityPreviewController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function () {
                        var _obj = {
                            storeId :vm.storeId,
                            pid :vm.pid,
                            productXmlLine: temp,
                            vid: vm.vid,
                            editorType: vm.article.editorType,
                            returnUrl:$stateParams.returnUrl
                        }
                        return _obj;
                    }]
                }
            }).result.then(function (backData) {
            });
        };
        //分类
        function classifyInit(item){
            ThemeClassify.getAllByCondition4({
                type: item,
                storeId: vm.storeId
            }, function (res) {
                if(res.status == '404'){
                    MessageService.error(res.message);
                    return
                }
                if(item == 'Material'){
                    vm.masklabels = res.message;
                    vm.rahmenPage = 0;
                    vm.maskList = [];
                    rahmenInit(vm.masklabels[0])
                }
                if(item == 'ClipArt'){
                    vm.clipArts = res.message;
                    vm.clipArtsPage = 0;
                    vm.clipArtsList = [];
                    clipArtInit(res.message[0])
                }
                if(item == 'FONT'){
                    vm.fontlabels = res.message;
                    vm.fontlabelPage = 0;
                    vm.fontlabelList = [];
                    fontInit(res.message[0])
                }
                if(item == 'Background'){
                    vm.bgImglabels = res.message;
                    vm.bgImgPage = 0;
                    vm.bgImgList = [];
                    bgImgInit(res.message[0])
                }
                if(item == 'BORDER'){
                    vm.borderLabels = res.message;
                    if($scope.template.frameConfigs && $scope.template.frameConfigs.frameConfig.length>0){
                        vm.borderLabels.unshift({
                            cancelled: false,
                            id: null,
                            name: "推荐",
                            seq: '',
                            storeId: 10,
                            type: "BORDER",
                            used: true
                        })
                    }
                    vm.borderPage = 0;
                    vm.borderList = [];
                    borderInit(vm.borderLabels[0])
                }
            });
        }
        /***********************  边框  ****************************/
        vm.borderUnfold = false;
        $scope.bordersCb = function(label, index){
            vm.borderId = label;
            vm.borderPage = 0;
            vm.borderList = [];
            borderInit(vm.borderId);
        }
        $scope.loadMoreBorder = function () {
            vm.borderPage++;
            borderInit(vm.borderId);
        }
        function borderInit(item){
            vm.borderId = item;
            if(!vm.borderId){
                angular.forEach($scope.template.frameConfigs.frameConfig, function (data) {
                    vm.borderList.push(data);
                })
                return
            }
            vm.borderLoading = true;
            FrameConfigs.byCondition3({
                items: [{key: "themeClassify.id", op: "=", value: item.id},{key: "enable", op: "=", value: true},{key: "status", op: "=", value: 'Approved'}],
                page: vm.borderPage,
                size: vm.itemsPerPage,
                sort: ["seq,asc"]
            }, function (res, headers) {
                vm.borderLoading = false;
                angular.forEach(res, function (resp) {
                    vm.borderList.push(resp);
                })
                var _sum = headers('X-Total-Count');
                if(vm.borderList.length < _sum){
                    vm.borderFulfill = true;
                }else {
                    vm.borderFulfill = false;
                }
            }, function () {
            })
        }
        /***********************  边框end  ****************************/
        /***********************  版式  ****************************/
        $scope.partCb = function(item){
            vm.partid = item.id;
        }
        $scope.layoutCb = function(item){
            if(vm.pageData[vm.pageNumber].config.partid!=item.config.partid){
                MessageService.error("不是相同部件的版式不可替换");
                return
            }
            if(item.uuid != vm.pageData[vm.pageNumber].uuid){
                vm.threeLoading = true;
                vm.threeLoadingText = '布局更换中...';
                item.config = vm.pageData[vm.pageNumber].config;
                var pages = {
                    config:$scope.template.config,
                    pages:{
                        page: [angular.copy(item)]
                    }
                }
                documentAnalysis($scope, $http, vm, pages,FontManagement,'other', function(page){
                    ratioInfo(page,vm.pageNumber2 > -1?false:true)
                }, function () {
                    $timeout(function () {
                        var layoutPage = pages.pages.page[0];
                        layoutPage.backup = false;
                        layoutPage.unreplace = false;
                        pageDataInfo(layoutPage)
                    },200)
                })
            }
        }
        function pageDataInfo(page) {
            vm.threeLoading = true;
            vm.minLoading = true;
            vm.threeLoadingText = '布局更换中...';
            page.pageUuid = getUuid();
            var _pageData = angular.copy(vm.pageData[vm.pageNumber]);
            var _pageDataImageboxImage = [];
            var _pageDataImageboxImageSum = 0;
            for(var i=0; i<_pageData.levels.level.length; i++){
                var _lecel = _pageData.levels.level[i];
                if(_lecel.imagebox && _lecel.imagebox.image && _lecel.imagebox.image.resource && _lecel.imagebox.image.resource.identifier){
                    _lecel.imagebox.imgRatio = _lecel.imagebox.image.width/_lecel.imagebox.image.height;
                    _pageDataImageboxImage.push(_lecel.imagebox)
                }
            }
            if(!page.levels.level){
                page.levels.level=[];
            }
            _pageDataImageboxImage = imageboxAdapt(page.levels.level, _pageDataImageboxImage);
            angular.forEach(page.levels.level, function (level, i) {
                if (level.imagebox) {
                    var _imagebox = null;
                    for(var j=0; j<_pageDataImageboxImage.length; j++){
                        if(_pageDataImageboxImage[j].imageboxIndex == _pageDataImageboxImageSum){
                            _imagebox = _pageDataImageboxImage[j];
                            break
                        }
                    }
                    _pageDataImageboxImageSum++;
                    if (_imagebox) {
                        level.imagebox.bokehtype = level.imagebox.bokehtype || vm.article.bokehtype || 'None';
                        level.imagebox.image = {
                            blurredrectangle: {
                                width: 0,
                                height: 0,
                                x: 0,
                                y: 0
                            },
                            resource: {
                                identifier: _imagebox ? _imagebox.image.resource.identifier : '',
                                provider: _imagebox ? _imagebox.image.resource.provider : 'qiniu'
                            },
                            resourceid:_imagebox ? _imagebox.image.resourceid : '',
                            filterResource: {
                                identifier: '',
                                provider: 'qiniu'
                            },
                            angle: 0,
                            offsetx: 0,
                            offsety: 0,
                            imageInfo: _imagebox? _imagebox.image.imageInfo : '',
                            width: _imagebox ? _imagebox.image.width : '',
                            height: _imagebox ? _imagebox.image.height : '',
                            imgSize: _imagebox ? _imagebox.image.imgSize : '',
                            index: _imagebox ? _imagebox.image.seq : '',
                            parameter: _imagebox ? angular.fromJson(_imagebox.image.parameter) : [],
                            scale: 1,
                            brightness:_imagebox ?_imagebox.image.brightness : 0,
                            contrast: _imagebox ?_imagebox.image.contrast : 0,
                            saturate: _imagebox ?_imagebox.image.saturate : 0,
                            temperature: _imagebox ?_imagebox.image.temperature : 0,
                            hue: _imagebox ?_imagebox.image.hue : 0
                        }
                        if (level.imagebox.image.resource.identifier) {

                            addImagebox($http, level.imagebox, function () {})
                            // getBackgroundSource3(level.imagebox, level.imagebox.geometry.width, level.imagebox.geometry.height, level.imagebox.image, level.imagebox.bokehtype, $scope)
                        }
                    } else {
                        level.imagebox.image = {
                            resource: {
                                identifier: '',
                                provider: ''
                            },
                            resourceid:"",
                            filterResource: {
                                identifier: '',
                                provider: ''
                            },
                            width: '',
                            height: '',
                            imgSize: '',
                            offsetx: 0,
                            offsety: 0,
                            index: '',
                            parameter: [],
                            scale: 1,
                            angle: 0,
                        }
                    }
                }
            })
            vm.pageData[vm.pageNumber] = "";
            vm.isCut = true;
            $timeout(function () {
                vm.pageData[vm.pageNumber] = page;
                vm.pageData.forEach(function (value, index) {
                    value.seq = index;
                    ratioInfo(value,vm.pageNumber2 > -1?false:true);
                })
                vm.isCut = false;
                MessageService.success("版式替换成功！");
                magnitudeBox()
                reserveQuantity();
                $scope.ratioInit();
                vm.threeLoading = false;
                vm.threeLoadingText = '';
                $timeout(function () {
                    vm.minLoading = false;
                },500)
            },200)
        }
        //换一换
        $scope.changePage = function(index){
            vm.isChangePage1 = false;
            $scope.pageInfo(index);
            var _recommendLayout = [];
            vm.pageData[vm.pageNumber].recommendLayout.forEach(function (recommendLayoutItem) {
                if(recommendLayoutItem.uuid != vm.pageData[vm.pageNumber].uuid){
                    _recommendLayout.push(recommendLayoutItem)
                }
            })
            if(_recommendLayout.length > 0){
                var x = _recommendLayout.length-1;
                var y = 0;
                var rand = parseInt(Math.random() * (x - y + 1) + y);
                $scope.layoutCb(_recommendLayout[rand])
            }
        }
        vm.isChangePage1 = false;
        $scope.changePage1 = function(e){
            vm.isChangePage1 = !vm.isChangePage1;
            fn(e)
        }
        /***********************  版式end  ****************************/
        /***********************  布局库  ****************************/
        $scope.selectLayoutLabel = function (label, index) {
            vm.picLayoutXmlGroup = label;
        };
        function setLayout(){
            if(vm.picLayoutXmlsList && vm.picLayoutXmlsList.length>0 && !vm.pageData[vm.pageNumber].unreplace){
                var _imageboxNum = 0;
                vm.pageData[vm.pageNumber].levels.level.forEach(function (level) {
                    if(level.imagebox){
                        _imageboxNum++
                    }
                })
                for(var i=0; i<vm.picLayoutXmlsList.length; i++){
                    if(vm.picLayoutXmlsList[i].quantity == _imageboxNum){
                        if(vm.picLayoutXmlsList[i].data && vm.picLayoutXmlsList[i].data.length>0){
                            $scope.addLayout(vm.picLayoutXmlsList[i].data[0]);
                        }
                        break
                    }
                }
            }
        }
        $scope.addLayout = function(data,isLayout){
            if(vm.pageData[vm.pageNumber].unreplace){
                MessageService.error("当前页不可替换布局");
                return
            }
            if(!vm.isLayout){
                if(isLayout){
                    MessageService.error("未开启自动布局开关");
                }
                return;
            }
            vm.threeLoading = true;
            vm.threeLoadingText = "加载中";
            Convert.xmlToPicLayoutDocument({url: data.resource.identifier}, function (res) {
                var mediabox = {
                    width: transitionPx(res.mediabox.width),
                    height: transitionPx(res.mediabox.height),
                    x: transitionPx(res.mediabox.x),
                    y: transitionPx(res.mediabox.y)
                };
                var _pageData = angular.copy(vm.pageData[vm.pageNumber]);
                var _pageDataImageboxImage = [];
                var _pageDataImageboxImageSum = 0;
                for(var i=0; i<_pageData.levels.level.length; i++){
                    var _lecel = _pageData.levels.level[i];
                    if(_lecel.imagebox && _lecel.imagebox.image && _lecel.imagebox.image.resource && _lecel.imagebox.image.resource.identifier){
                        _lecel.imagebox.imgRatio = _lecel.imagebox.image.width/_lecel.imagebox.image.height;
                        _pageDataImageboxImage.push(_lecel.imagebox)
                    }
                }

                var _ratio = 1;
                if(_pageData.mediabox.height / mediabox.height >= _pageData.mediabox.width / mediabox.width ){
                    _ratio = _pageData.mediabox.width / mediabox.width;
                }else {
                    _ratio = _pageData.mediabox.height / mediabox.height;
                }

                if(!_pageData.levels.level){
                    _pageData.levels.level=[];
                }
                for(var i=_pageData.levels.level.length-1; i>=0; i--){
                    if(_pageData.levels.level[i].imagebox){
                        _pageData.levels.level.splice(i,1)
                    }
                }
                if(res.levels&&res.levels.level){
                    res.levels.level.forEach(function (level) {
                        if(level.imagebox){
                            level.imagebox.geometry={
                                width: transitionPx(level.imagebox.geometry.width*_ratio),
                                height: transitionPx(level.imagebox.geometry.height*_ratio),
                                x: transitionPx(level.imagebox.geometry.x*_ratio),
                                y: transitionPx(level.imagebox.geometry.y*_ratio),
                            }
                            level.seq = _pageData.levels.level.length-1;
                            _pageData.levels.level.push(level)
                        }
                    })
                }
                _pageDataImageboxImage = imageboxAdapt(_pageData.levels.level, _pageDataImageboxImage);
                angular.forEach(_pageData.levels.level, function (level, i) {
                    if (level.imagebox) {
                        var _imagebox = null;
                        for(var j=0; j<_pageDataImageboxImage.length; j++){
                            if(_pageDataImageboxImage[j].imageboxIndex == _pageDataImageboxImageSum){
                                _imagebox = _pageDataImageboxImage[j];
                                break
                            }
                        }
                        _pageDataImageboxImageSum++;
                        if (_imagebox) {
                            level.imagebox.bokehtype = level.imagebox.bokehtype || vm.article.bokehtype || 'None';
                            level.imagebox.image = {
                                blurredrectangle: {
                                    width: 0,
                                    height: 0,
                                    x: 0,
                                    y: 0
                                },
                                resource: {
                                    identifier: _imagebox ? _imagebox.image.resource.identifier : '',
                                    provider: _imagebox ? _imagebox.image.resource.provider : 'qiniu'
                                },
                                resourceid:_imagebox ? _imagebox.image.resourceid : '',
                                filterResource: {
                                    identifier: '',
                                    provider: 'qiniu'
                                },
                                angle: 0,
                                offsetx: 0,
                                offsety: 0,
                                imageInfo: _imagebox? _imagebox.image.imageInfo : '',
                                width: _imagebox ? _imagebox.image.width : '',
                                height: _imagebox ? _imagebox.image.height : '',
                                imgSize: _imagebox ? _imagebox.image.imgSize : '',
                                index: _imagebox ? _imagebox.image.seq : '',
                                parameter: _imagebox ? angular.fromJson(_imagebox.image.parameter) : [],
                                scale: 1,
                                brightness:_imagebox ?_imagebox.image.brightness : 0,
                                contrast: _imagebox ?_imagebox.image.contrast : 0,
                                saturate: _imagebox ?_imagebox.image.saturate : 0,
                                temperature: _imagebox ?_imagebox.image.temperature : 0,
                                hue: _imagebox ?_imagebox.image.hue : 0
                            }
                            if (level.imagebox.image.resource.identifier) {
                                addImagebox($http, level.imagebox, function () {})
                            }
                        } else {
                            level.imagebox.image = {
                                resource: {
                                    identifier: '',
                                    provider: ''
                                },
                                resourceid:"",
                                filterResource: {
                                    identifier: '',
                                    provider: ''
                                },
                                width: '',
                                height: '',
                                imgSize: '',
                                offsetx: 0,
                                offsety: 0,
                                index: '',
                                parameter: [],
                                scale: 1,
                                angle: 0,
                            }
                        }
                    }
                })
                $timeout(function () {
                    vm.pageData[vm.pageNumber] = _pageData;
                    vm.threeLoading = false;
                    vm.threeLoadingText = '';
                    MessageService.success("布局替换成功");
                    magnitudeBox();
                },200)
            })
        }
        /***********************  布局库end  ****************************/

        //全屏
        $scope.toggleFullScreen = function(){
            toggleFullScreen(function (item) {
                vm.fullFlag = item;
            });
        }
        //删除
        $scope.deleteBox = vm.deleteBox = function ($event) {
            if(vm.elementOn.name == ""){
                MessageService.error("请选择删除的内容！");
                return
            }
            if(vm.elementOn.name == "calTitleBox"||vm.elementOn.name == "calMonthBox"){
                MessageService.error("日历不可删除");
                return
            }
            if(vm.elementOn.item.lock){
                return;
            }
            vm.pageData[vm.pageNumber].levels.level.splice(vm.elementOn.index, 1);
            if(vm.elementOn&&vm.elementOn.name == 'imagebox'){
                setLayout();
            }
            $scope.editorBodyView();
            vm.isImageartTrim = false;
            vm.isImageboxTrim = false;
            vm.imageartTrim = null;
            magnitudeBox();
            cookie();
        }
        //复制
        $scope.copyimg = vm.copyimg = function ($event) {
            var all = getAllCtrlKeyArray();
            if(!vm.clickNum){
                vm.clickNum = 0;
            }
            vm.clickNum+=10;
            if(all.length>0){
                all.forEach(function (item) {
                    (function (item1) {
                        var _level = {
                            seq: vm.pageData[vm.pageNumber].levels.level.length + 1,
                        }
                        _level[item1.current] = angular.copy(item1);
                        _level[item1.current].lock = false;
                        _level[item1.current].ctrlKey = false;
                        _level[item1.current].geometry.x += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                        _level[item1.current].geometry.y += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                        vm.pageData[vm.pageNumber].levels.level.push(_level);
                    })(item)
                })
            }else{
                if(!vm.elementOn.item){return}
                var _level = {
                    seq: vm.pageData[vm.pageNumber].levels.level.length + 1
                }
                if(vm.elementOn.name == "imagebox"){
                    _level.imagebox = angular.copy(vm.elementOn.item);
                    _level.imagebox.lock = false;
                    _level.imagebox.geometry.x += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                    _level.imagebox.geometry.y += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                }
                if(vm.elementOn.name == "imageart"){
                    _level.imageart = angular.copy(vm.elementOn.item);
                    _level.imageart.lock = false;
                    _level.imageart.geometry.x += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                    _level.imageart.geometry.y += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                }
                if(vm.elementOn.name == "textbox"){
                    _level.textbox = angular.copy(vm.elementOn.item);
                    _level.textbox.lock = false;
                    _level.textbox.geometry.x += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                    _level.textbox.geometry.y += vm.clickNum/vm.pageData[vm.pageNumber].custom.ratio;
                }
                vm.pageData[vm.pageNumber].levels.level.push(_level);
            }

            cookie();
            magnitudeBox();
        }
        vm.copyArray = {imageboxes: [], textboxes: [], imagearts: [], barcode: [], qrcode: [], textdocuments: []};
        // 复制元素
        vm.copyElement = function(){
            if(vm.copyArray.imageboxes.length == 0 && vm.copyArray.imagearts.length == 0 && vm.copyArray.textboxes.length == 0){
                return;
            }
            copyTogether();
        }

        // 多选调用
        function copyTogether(){
            angular.forEach(vm.copyArray.imageboxes, function (imagebox, i) {
                if(imagebox&&imagebox.geometry){
                    // 当前页所有占位框
                    var box = angular.copy(imagebox);
                    var _level = {
                        seq: vm.pageData[vm.pageNumber].levels.level.length + 1
                    }
                    _level.imagebox = angular.copy(copyDetail(box));
                    vm.pageData[vm.pageNumber].levels.level.push(_level)
                    for (var j = 0; j < $scope.photos.length; j++) {
                        var arr = $scope.photos[j];
                        if (arr.id == box.image.resourceid) {
                            arr.usenum++;
                        }
                    }
                }

            })

            angular.forEach(vm.copyArray.imagearts, function (imageart, i) {
                if(imageart&&imageart.geometry){
                    // 当前页所有素材
                    var box = angular.copy(imageart);
                    var _level = {
                        seq: vm.pageData[vm.pageNumber].levels.level.length + 1
                    }
                    _level.imageart = angular.copy(copyDetail(box));
                    vm.pageData[vm.pageNumber].levels.level.push(_level)
                }

            })

            angular.forEach(vm.copyArray.textboxes, function (textbox, i) {
                if(textbox&&textbox.geometry){
                    // 当前页所有文字
                    var box = angular.copy(textbox);
                    var _level = {
                        seq: vm.pageData[vm.pageNumber].levels.level.length + 1
                    }
                    _level.textbox = angular.copy(copyDetail(box));
                    vm.pageData[vm.pageNumber].levels.level.push(_level)
                }

            })
        }
        function copyDetail(box) {
            box.levelI = vm.pageData[vm.pageNumber].levels.level.length-1;
            box.lock = false;
            box.ctrlKey = false;
            var repeatEle = false;
            vm.pageData[vm.pageNumber].levels.level.forEach(function(item){
                if(item.imagebox && item.imagebox.geometry.x == box.geometry.x && item.imagebox.geometry.y == box.geometry.y){
                    repeatEle = true;
                }
                if(item.imageart && item.imageart.geometry.x == box.geometry.x && item.imageart.geometry.y == box.geometry.y){
                    repeatEle = true;
                }
                if(item.textbox && item.textbox.geometry.x == box.geometry.x && item.textbox.geometry.y == box.geometry.y){
                    repeatEle = true;
                }
                if(item.code && item.code.geometry.x == box.geometry.x && item.code.geometry.y == box.geometry.y){
                    repeatEle = true;
                }
            })
            if(!repeatEle){
                return box
            }
            // 复制上一次的位置
            if (box.current == 'imagebox') {
                vm.copyArray.imageboxes.forEach(function(imagebox){
                    if(imagebox && imagebox.geometry.x == box.geometry.x && imagebox.geometry.y == box.geometry.y){
                        var x = PxTurnMm(imagebox.geometry.x) + 1;
                        var y = PxTurnMm(imagebox.geometry.y) + 1;
                        imagebox.geometry.x = MmTurnPx(x);
                        imagebox.geometry.y = MmTurnPx(y);
                    }
                })
            }
            if (box.current == 'imageart') {
                vm.copyArray.imagearts.forEach(function(imageart){
                    if(imageart && imageart.geometry.x == box.geometry.x && imageart.geometry.y == box.geometry.y){
                        var x = PxTurnMm(imageart.geometry.x) + 1;
                        var y = PxTurnMm(imageart.geometry.y) + 1;
                        imageart.geometry.x = MmTurnPx(x);
                        imageart.geometry.y = MmTurnPx(y);
                    }
                })
            }
            if(box.current == 'textbox'){
                vm.copyArray.textboxes.forEach(function(textbox){
                    if(textbox && textbox.geometry.x == box.geometry.x && textbox.geometry.y == box.geometry.y){
                        var x = PxTurnMm(textbox.geometry.x) + 1;
                        var y = PxTurnMm(textbox.geometry.y) + 1;
                        textbox.geometry.x = MmTurnPx(x);
                        textbox.geometry.y = MmTurnPx(y);
                    }
                })
            }
            // 新元素
            var x = PxTurnMm(box.geometry.x) + 1;
            var y = PxTurnMm(box.geometry.y) + 1;
            box.geometry.x = MmTurnPx(x);
            box.geometry.y = MmTurnPx(y);
            return box
        }
        //锁定
        $scope.locking = vm.locking = function(){
            if(vm.elementOn.item){
                vm.elementOn.item.lock = !vm.elementOn.item.lock;
                if(vm.elementOn.name === 'imagebox'){
                    vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.lock = vm.elementOn.item.lock;
                }
                multiSelect()
            }
        }
        //图层
        vm.isCoverage = false;
        $scope.coverageMove = coverageMove;
        $scope.coverageSelect = function (attr) {
            if(vm.elementOn.item.lock){return}
            toolbarStatus();
            vm.isCoverage = attr;
        }
        function coverageMove(data){
            if(vm.elementOn.item.lock){return}
            var _seq = Number(vm.pageData[vm.pageNumber].levels.level.length);
            if(data == "up"){
                if(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq == _seq){
                    MessageService.error("已在最顶层");
                    return;
                }
                var seq = angular.copy(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq+1);
                vm.pageData[vm.pageNumber].levels.level.forEach(function (level) {
                    if(level.seq == seq){
                        level.seq--
                    }
                })
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq++
            }
            if(data == "down"){
                if(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq <2){
                    MessageService.error("已在最底层");
                    return;
                }
                var seq = angular.copy(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq-1);
                vm.pageData[vm.pageNumber].levels.level.forEach(function (level) {
                    if(level.seq == seq){
                        level.seq++
                    }
                });
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq--
            }
            if(data == "top"){
                if(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq == _seq){
                    MessageService.error("已在最顶层");
                    return;
                }
                var seq = angular.copy(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq);
                for (var i = 0; i < vm.pageData[vm.pageNumber].levels.level.length; i++) {
                    if(vm.pageData[vm.pageNumber].levels.level[i].seq > seq){
                        vm.pageData[vm.pageNumber].levels.level[i].seq--;
                    }
                }
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq = _seq
            }
            if(data == "bottom"){
                if(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq == 0){
                    MessageService.error("已在最底层");
                    return;
                }

                var seq = angular.copy(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq);
                for (var i = 0; i < vm.pageData[vm.pageNumber].levels.level.length; i++) {
                    if(vm.pageData[vm.pageNumber].levels.level[i].seq < seq){
                        vm.pageData[vm.pageNumber].levels.level[i].seq+=1;
                    }
                }
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq = 1;
            }

            var _seq = angular.copy(vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq);
            var _levelI = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].seq - 1;
            vm.pageData[vm.pageNumber].levels.level.sort(compare('seq', true));
            for (var i = 0; i < vm.pageData[vm.pageNumber].levels.level.length; i++) {
                if(vm.pageData[vm.pageNumber].levels.level[i].seq == _seq){
                    _levelI = i;
                    break
                }
            }
            vm.elementOn.index = _levelI;
            cookie();
        }
        function compare(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            }
        }
        // 透明度
        vm.isTransp = false;
        $scope.transparencySelect = function () {
            toolbarStatus()
            if(!vm.isTransp && vm.elementOn.name !='imageart' && vm.elementOn.name !='textbox' && vm.elementOn.name !='imagebox' ){
                MessageService.error("请选择照片框或者素材或者文字");
                return
            }
            vm.isTransp = !vm.isTransp;
            if(vm.isTransp){
                var _transparency = 0;
                if(vm.elementOn.name =='imagebox'){
                    _transparency = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.transparency || 1;
                }
                if(vm.elementOn.name =='imageart'){
                    _transparency = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imageart.transparency || 1;
                }
                if(vm.elementOn.name =='textbox' ){
                    _transparency = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox.transparency || 1;
                }
                $timeout(function () {
                    $(".text-range-140").val(_transparency);
                    $(".transparency-num").val(_transparency * 100);
                    vm.transparency = _transparency * 100;
                })
            }
        }
        var elem = document.querySelector('input[type="range"]');
        var rangeValue = function () {
            $(".transparency-num").val(elem.value * 100);
            vm.transparency = elem.value * 100;
            var all = getAllCtrlKeyArray();
            if(all.length>0){
                levelMove(null, 'transparency')
            }else{
                transparencyInit();
            }
            $scope.$apply();
        };

        elem.addEventListener("input", rangeValue);
        $scope.transparencyChange = function () {
            if (vm.transparency > 100) {
                MessageService.error("透明度有效范围：0~100");
                vm.transparency = 100;
            }
            if (vm.transparency < 0) {
                MessageService.error("透明度有效范围：0~100");
                vm.transparency = 0;
            }
            transparencyInit(vm.transparency)
        }
        function transparencyInit() {
            if(vm.elementOn.name =='imagebox'){
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.transparency = vm.transparency/100;
                return
            }
            if(vm.elementOn.name =='imageart'){
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imageart.transparency = vm.transparency/100;
                return
            }
            if(vm.elementOn.name =='textbox' ){
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox.transparency = vm.transparency/100;
                return
            }
        }

        //字体
        vm.isOperationColor = false;
        vm.colorList = [
            {name: '#000000', id: '#000000'},
            {name: '#666666', id: '#666666'},
            {name: '#a8a8a8', id: '#a8a8a8'},
            {name: '#d9d9d9', id: '#d9d9d9'},
            {name: '#ffffff', id: '#ffffff'},
            {name: '#647c6e', id: '#647c6e'},
            {name: '#ffbd4a', id: '#ffbd4a'},
            {name: '#fef952', id: '#fef952'},
            {name: '#99e264', id: '#99e264'},
            {name: '#36b72a', id: '#36b72a'},
            {name: '#44d9e5', id: '#44d9e5'},
            {name: '#c27171', id: '#c27171'},
            {name: '#2fb3ff', id: '#2fb3ff'},
            {name: '#b760a6', id: '#b760a6'},
        ];
        $scope.operationColorOpen = function ($event) {
            vm.isOperationColor = !vm.isOperationColor;
            fn($event)
        }
        $scope.operationColor = function (item) {
            vm.elementOn.item.style.color = item.id;
            vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox = vm.elementOn.item;
            cookie()
        }
        $scope.chooseTextColor = function () {
            vm.elementOn.item.style.color = vm.textColor;
            vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox = vm.elementOn.item;
            vm.textColor = null;
            cookie()
        }

        function fontSizeInit(size){
            var _size = 101;
            var _size1 = Math.floor(size/2);
            $scope.fontSizes = [];
            for (var f = 6; f < _size; f++) {
                $scope.fontSizes.push(f);
            }
            if(_size1 > _size){
                $scope.fontSizes.push(_size1);
            }
        }
        // 防抖timerTextSize，800ms内重复触发不执行
        var a = null;
        $scope.updateTextSize = function (size) {
            if (a !== null) {
                clearTimeout(a)
            }
            a = setTimeout(function () {
                if (size <= 6) {
                    size = 6
                }
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox.style.size = size;
                vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox.style.lineheight = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox.style.size;
                vm.elementOn.item = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
                $timeout(function () {
                    var fontWeight = vm.elementOn.item.style.bold ? 'bold' : '400';
                    var fontStyle = vm.elementOn.item.style.italic ? 'italic' : 'normal';
                    // if(vm.elementOn.item.geometry.width < getTextWidthInternal(vm.elementOn.item.text, fontWeight, fontStyle, vm.elementOn.item.style.size, vm.elementOn.item.fontuuid)){
                    //     vm.elementOn.item.geometry.width = (Number(getTextWidthInternal(vm.elementOn.item.text, fontWeight, fontStyle, vm.elementOn.item.style.size, vm.elementOn.item.fontuuid)) + Number(5));
                    // }
                    // vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox.geometry.width = (Number($("#c-xml-page-material-fontId").width() / (3/4) / vm.pageData[vm.pageNumber].custom.ratio * (1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio)) + Number(5));
                    // vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox.geometry.height = Number($("#c-xml-page-material-fontId").height() / (3/4) / vm.pageData[vm.pageNumber].custom.ratio) * (1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio) + Number(10);
                    if(vm.elementOn.item.geometry.width < ptToPx( vm.elementOn.item.style.size)){
                       vm.elementOn.item.geometry.width = ptToPx( vm.elementOn.item.style.size)+10;
                    }
                    sizeHeight();
                    cookie();
                    $scope.$apply();
                })
            },500)
        }
        vm.isOperationSpacing = false;
        $scope.operationSpacing = function (item) {
            vm.isOperationSpacing = item;
            if(vm.elementOn.name == 'textbox'){
                $timeout(function () {
                    $(".block-width").css({left: vm.elementOn.item.style.letterspacing});
                    $(".block-height").css({left: vm.elementOn.item.style.lineheight});
                })
            }
        }
        //斜体
        $scope.operationItalic = function () {
            var text = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
            if (text) {
                if(text.style.italic){
                    if(vm.fontStyle.indexOf('NORMAL') > -1){
                        text.style.italic = false;
                        if (vm.fontStyle.indexOf('BOLD') == '-1') {
                            text.style.bold = false;
                        }
                    }else{
                        if(text.style.bold){
                            text.style.italic = false;
                        }
                    }
                }else{
                    if(vm.fontStyle.indexOf('ITALIC') > -1){
                        text.style.italic = true;
                        if(text.style.bold){
                            if(vm.fontStyle.indexOf('BOLD_ITALIC') == -1){
                                text.style.bold = false;
                            }
                        }else{
                            // if( vm.fontStyle.indexOf('BOLD_ITALIC') > -1){
                            //     text.style.bold = true;
                            // }
                        }
                    }else if (vm.fontStyle.indexOf('BOLD_ITALIC') > -1) {
                        text.style.bold = true;
                        text.style.italic = true;
                    }
                }
                $timeout(function () {
                    sizeHeight();
                },200)
            }
        }
        //粗体
        $scope.operationBold = function () {
            var text = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
            if (text) {
                if(text.style.bold){
                    if(vm.fontStyle.indexOf('NORMAL') > -1){
                        text.style.bold = !text.style.bold;
                        if (vm.fontStyle.indexOf('ITALIC') == '-1') {
                            text.style.italic = text.style.bold;
                        }
                    }else{
                        if(text.style.italic){
                            text.style.bold = !text.style.bold;
                        }
                    }
                }else{
                    if(vm.fontStyle.indexOf('BOLD') > -1){
                        text.style.bold = true;
                        if(text.style.italic){
                            if(vm.fontStyle.indexOf('BOLD_ITALIC') == -1){
                                text.style.italic = false;
                            }
                        }else{
                            // if( vm.fontStyle.indexOf('BOLD_ITALIC') > -1){
                            //     text.style.italic = true;
                            // }
                        }
                    }else if (vm.fontStyle.indexOf('BOLD_ITALIC') > -1) {
                        text.style.bold = true;
                        text.style.italic = true;
                    }
                }
                $timeout(function () {
                    sizeHeight();
                },200)
            }
        }

        // 字/行间距鼠标点击移动
        var spacingW = {};
        var flagSpacing = false
        window.resizeSpacingStart = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            spacingW.clientX = e.clientX || 0;
            spacingW.originLeft = targ.offsetLeft;
            spacingW.value = targ.dataset.value;
            var text = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
            if (text.lock) {
                return
            }
            flagSpacing = true;
            document.addEventListener("mousemove", resizeSpacingOn, true);
            document.addEventListener("mouseup", resizeSpacingEnd, true);
        }

        function resizeSpacingOn(e) {
            if (flagSpacing) {
                var text = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
                var l = spacingW.originLeft + e.clientX - spacingW.clientX;
                if (spacingW.value == 'height' && l < text.style.size) {
                    l = text.style.size;
                } else if (spacingW.value == 'width' && l < 0) {
                    l = 0;
                }
                if (l > 110) {
                    l = 110
                }
                if (spacingW.value == 'width') {
                    $(".block-width").css({left: l});
                    text.style.letterspacing = $scope.template.letterspacing = l;
                } else {
                    $(".block-height").css({left: l});
                    text.style.lineheight = $scope.template.lineheight = l;
                }
                sizeHeight();
                // var h = Number($('.selected').find('.c-xml-page-textboxe-text').height());
                // text.geometry.height = h / vm.pageData[vm.pageNumber].custom.ratio * (1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio > 1 ? 1 : 1 / 12 * vm.elementOn.item.style.size * vm.pageData[vm.pageNumber].custom.ratio) + Number(5);
                $scope.$apply();
            }
        }
        function resizeSpacingEnd() {
            if(flagSpacing){
                cookie();
            }
            spacingW = {}
            flagSpacing = false;
            document.removeEventListener("mousemove", resizeSpacingOn, true);
            document.removeEventListener("mouseup", resizeSpacingEnd, true);
        }
        vm.isOperationDir = false;
        $scope.operationDir = function (item) {
            vm.isOperationDir = item;
        }
        //左对齐
        $scope.operationBarDir = function (item) {
            var text = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
            text.style.align = item;
        }
        //横版
        $scope.textHengban = function () {
            var text = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
            var text1 = angular.copy(text);
            text.style.writingmode = 'horizontal-tb';
            $timeout(function () {
                text.geometry.width = text1.geometry.height;
                text.geometry.height = text1.geometry.width;
            },200)
        }
        //竖版
        $scope.textShuban = function (item) {
            var text = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].textbox;
            var text1 = angular.copy(text);
            var _writingmode = angular.copy(text.style.writingmode);
            text.style.writingmode = item;
            $timeout(function () {
                if(!_writingmode || _writingmode == 'horizontal-tb'){
                    text.geometry.width = text1.geometry.height;
                    text.geometry.height = text1.geometry.width;
                }
            },200)
        }

        $scope.writingmodeInit = function (item) {
            if(item == 'vertical-lr' || item == 'vertical-rl'){
                return true
            }else{
                return false
            }
        }

        //缓存每一步
        vm.cookie = cookie;
        function cookie(){
            vm.pageData[vm.pageNumber].updateThumbnail = true;
            var _data = angular.copy(vm.pageData[vm.pageNumber]);
            vm.cookies.push(_data);
            thumbnailInit();
            pageWarnExamine1(vm.pageData[vm.pageNumber], vm.imgSize, vm.imgDpi)
        }
        //上一步
        $scope.rollback = function(){
            if(vm.cookies.length < 2){
                vm.pageData[vm.pageNumber] = "";
                $timeout(function () {
                    vm.pageData[vm.pageNumber] = angular.copy(vm.pageDataInit[vm.pageNumber]);
                    vm.cookies = [];
                    magnitudeBox();
                })
                return
            }
            vm.pageData[vm.pageNumber] = "";
            $timeout(function () {
                vm.pageData[vm.pageNumber] = vm.cookies[vm.cookies.length-2];
                vm.cookies.pop();
                magnitudeBox();
            })
        };
        //恢复
        $scope.xmlNextStep = function(){
            vm.pageData[vm.pageNumber] = "";
            $timeout(function () {
                vm.pageData[vm.pageNumber] = angular.copy(vm.pageDataInit[vm.pageNumber]);
                vm.cookies = [];
                magnitudeBox();
            })
        };

        //换书
        $scope.changeAllTemp = function ($event,line) {
            if(line.productXmlId == vm.article.productXmlId){
                MessageService.success("已是当前模板");
                return
            }
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'确认',
                msg :'更换模板后，所有编辑内容将丢失，确定要更换？'
            }, function() {
                swal.close();
                templateReplace(line);
                return false
            })
        }
        function templateReplace(line) {
            if(vm.isEdit){
                vm.threeLoading = true;
                vm.pageData.forEach(function (page) {
                    if(page.levels.level){
                        page.levels.level.forEach(function (level) {
                            if (level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier) {
                                vm.photoSelectAll.push({
                                    height: level.imagebox.image.resource.height,
                                    id: level.imagebox.image.resourceid,
                                    imageInfo: level.imagebox.image.imageInfo,
                                    identifier: level.imagebox.image.resource.identifier,
                                    imgsize: level.imagebox.image.imgsize,
                                    name: null,
                                    provider: "qiniu",
                                    usenum: 1,
                                    width: level.imagebox.image.resource.width
                                });
                            }
                        })
                    }
                })
                Convert.xmlToDocument2({
                    identifier: line.productXmlIdentifier,
                    provider: "qiniu",
                    editorType:line.productXmlVersion == "4.0" ? "" : vm.article.editorType,
                    patternType:vm.product.patternType,
                }, function (res) {
                    vm.counts = 0;
                    vm.current = 0;
                    $scope.pages = res;
                    angular.forEach(res.pages.page, function (page, i) {
                        if(!page.backup){
                            angular.forEach(page.levels.level,function (level) {
                                if(level.imagebox&&level.imagebox.geometry){
                                    vm.counts++;
                                }
                                if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                                    vm.current++;
                                }
                            })
                        }
                    });
                    vm.editProductXml = true;
                    vm.isHtml2canvas = false;
                    vm.article.document = res;
                    vm.productXmlLine = angular.copy(line);
                    vm.modelBlendentUuid=vm.productXmlLine.modelBlendentUuid;
                    vm.article.modelBlendentUuid= vm.productXmlLine.modelBlendentUuid;
                    vm.article.cover= vm.productXmlLine.coverImg;
                    vm.article.themeClassifyId= vm.productXmlLine.productXmlThemeId;
                    vm.article.themeClassifyName= vm.productXmlLine.productXmlThemeName;
                    vm.article.productXmlId= vm.productXmlLine.productXmlId;
                    vm.article.productXmlName= vm.productXmlLine.productXmlName;
                    vm.article.productXmlLineId= vm.productXmlLine.id;
                    if(vm.productXmlLine && vm.productXmlLine.musicIdentify){
                        vm.article.musicIdentify= vm.productXmlLine.musicIdentify;
                    }
                    abook();
                });
            }else{
                $state.go("editorAll", {
                    storeId: vm.storeId,
                    pid: vm.pid,
                    vid: vm.vid,
                    xmlId: line.id,
                    soid: vm.salesOrderId,
                    plid: vm.productLineId,
                    returnUrl:$stateParams.returnUrl
                });
                localStorage.setItem("photoSelectAll", JSON.stringify(vm.photoSelectAll));
            }
        }

        function articleOverdue(callback){
            if($stateParams.orderArticleId){
                OrderArticle.byUser({id: $stateParams.orderArticleId}, function (article) {
                    if(article.fileName != vm.article.fileName){
                        MessageService.confirm({
                            title:"提示",
                            cancel:"取消",
                            confirm:"刷新",
                            msg:"保存失败，当前作品不是最新版本，请刷新后再操作。"
                        },function(){
                            location.reload();
                        })
                    }else{
                        callback()
                    }
                })
                return
            }
            if(vm.article.id) {
                Article.byUser({id: vm.article.id}, function (article) {
                    if(article.fileName != vm.article.fileName){
                        MessageService.confirm({
                            title:"提示",
                            cancel:"取消",
                            confirm:"刷新",
                            msg:"保存失败，当前作品不是最新版本，请刷新后再操作。"
                        },function(){
                            $state.go('editorAllEdit', {storeId:vm.storeId,aid:vm.article.id, soid:vm.salesOrderId, plid:vm.productLineId, returnUrl:$stateParams.returnUrl},{reload:true})
                        })
                    }else{
                        callback()
                    }
                })
                return
            }
            callback()
        }
        // 即时保存
        $scope.immediateSave = function (e) {
            articleOverdue(function () {
                $scope.template.pages.page = vm.pageData;
                localStorage.setItem("photoSelectAll", JSON.stringify(vm.photoSelectAll));
                $scope.isContinue();
                generateJsonFile(true);
            })
        }
        //保存
        $scope.isSave = false;
        $scope.save = function () {
            articleOverdue(function () {
                $scope.template.pages.page = vm.pageData;
                if(!vm.isPageWarnExamine && pageWarnExamine($scope.template.pages.page, vm.imgSize, vm.imgDpi) && !$stateParams.source){
                    vm.isPageWarnExamine = true;
                    MessageService.confirm({
                        title :'印刷风险检测',
                        cancel :'去修改',
                        confirm :'继续保存',
                        msg :'系统检测到当前作品存在像素不足（不清晰）的图片，请您确认是否保存？'
                    }, function() {
                        $scope.save()
                    })
                    vm.threeLoading = false;
                    return
                }
                if(!documentDetection($scope.template.pages.page).pass && $stateParams.orderArticleId){
                    SweetAlert.swal({
                        title: '温馨提示',
                        imageUrl: Theme.sweetHintImg(),
                        text: "第" + documentDetection($scope.template.pages.page).pageIndex + '页的图片未填充满，请调整',
                        timer: 2000,
                        showConfirmButton: false
                    });
                    return;
                }
                if($scope.isSave){
                    return;
                }

                if(vm.article.ordered){
                    SweetAlert.swal({
                        title: '温馨提示',
                        imageUrl: Theme.sweetHintImg(),
                        text: "该作品已有对应订单，如需修改，则会保存为一个新的作品。",
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
                //判断是否有图片未上传  且是否继续保存
                if (!$scope.isContinue()) {
                    // vm.completed = false;
                    setTimeout(function(){
                        $(".sweet-alert").addClass('whiteBg');
                    })
                    MessageService.confirm({
                        title :'保存',
                        cancel :'取消',
                        confirm :'保存',
                        msg :'当前作品没有制作完成，确认保存？'
                    }, function() {
                        $timeout(function () {
                            generateJsonFile();
                            localStorage.removeItem("photoSelectAll");
                        }, 500);
                        return;
                    })
                } else {
                    // vm.completed = true;
                    generateJsonFile();
                    localStorage.removeItem("photoSelectAll");
                }
            })
        }
        vm.isPageWarnExamine = false;

        vm.documentDetection = {
            pass: true
        };
        vm.documentDetectionTab="text";
        $scope.generateJsonFile = generateJsonFile;
        function generateJsonFile(flag){
            vm.documentDetection = documentTextDetection($scope.template.pages.page);
            if(!vm.documentDetection.pass){
                if(vm.documentDetection.isText){
                    vm.documentDetectionTab="text";
                }else{
                    vm.documentDetectionTab="repetition";
                }
                return;
            }
            vm.deleteList = 0;
            var  gallieryIds = [];
            for (var i = $scope.template.pages.page.length - 1; i >= 0; i--) {
                var page = $scope.template.pages.page[i];
                if (page.backup || page.levels == null || page.levels.level == null || page.levels.level.length == 0) {
                    continue;
                }
                for (var j = page.levels.level.length - 1; j >= 0; j--) {
                    var level = page.levels.level[j];
                    if (level.imagebox == null || level.imagebox.image == null || !level.imagebox.image.resourceid) {
                        continue;
                    }
                    gallieryIds.push(level.imagebox.image.resourceid);
                }
            }
            if (gallieryIds.length>0 && !$stateParams.orderArticleId) {
                Gallery.checkDeleted(gallieryIds, function(list){
                    if (list == null|| list.length ==0) {
                        thumbnailInit1(function (){
                            generateJsonFile1(flag)
                        })
                    }else{
                        vm.deleteList = list;
                        vm.completed = false;
                        MessageService.confirm({
                            title :'提示',
                            cancel :'去修改',
                            confirm :'确认',
                            msg :'检测出'+vm.deleteList.length+'张图片已从图库被删除，作品中相关图片将被清理，是否继续保存作品？'
                        }, function() {
                            $scope.galleryDestroyConfirm(vm.saveflag)
                        },function(){
                            $scope.removeGallery()
                        })
                        return;
                    }
                }, function(){
                    MessageService.error("检测图片失败，请稍后再试。");
                })
            }else{
                thumbnailInit1(function (){
                    generateJsonFile1(flag)
                })
            }
        }

        $scope.removeGallery = function(){
            if (vm.deleteList == null || vm.deleteList.length == 0) {
                return;
            }
            for (var z = vm.deleteList.length - 1; z >= 0; z--) {
                var g = vm.deleteList[z];
                for (var i = $scope.template.pages.page.length - 1; i >= 0; i--) {
                    var page = $scope.template.pages.page[i];
                    if (page.backup || page.levels == null || page.levels.level == null || page.levels.level.length == 0) {
                        continue;
                    }
                    for (var j = page.levels.level.length - 1; j >= 0; j--) {
                        var level = page.levels.level[j];
                        if (level.imagebox == null || level.imagebox.image == null || !level.imagebox.image.resourceid) {
                            continue;
                        }
                        if (level.imagebox.image.resourceid == g.id) {
                            level.imagebox.image.resource.identifier = "";
                            level.imagebox.image.resource.adaptation = "";
                            level.imagebox.image.resource.provider = "";
                            level.imagebox.image.resource.offsetx = 0;
                            level.imagebox.image.resource.offsety = 0;
                            level.imagebox.image.resource.height = "";
                            level.imagebox.image.resource.width = "";
                            level.imagebox.image.resource.scale = 1;
                            level.imagebox.image.resource.imgSize = "";
                            level.imagebox.image.resourceid = null;
                            level.imagebox.image.imageInfo = null;
                            break;
                        }
                    }
                }
            }
            imageSelectInit();
            magnitudeBox();
        }

        $scope.galleryDestroyConfirm = function(){
            $scope.removeGallery();
            thumbnailInit1(function (){
                generateJsonFile1(vm.saveflag);
            })
        };

        function generateJsonFile1(flag){
            vm.saveflag = flag;
            vm.isPageWarnExamine = false;
            vm.threeLoading = true;
            vm.threeLoadingText = '保存中';
            $scope.isSave = true;
            vm.article.document = saveData();
            vm.article.document.hostinfo = hostInfo();
            if (vm.isEdit) {
                vm.article.completed = vm.completed;
                vm.article.unFilledCount = vm.unFilledCount;
                if($stateParams.orderArticleId){
                    OrderArticle.update(vm.article, function (resp) {
                        vm.article.fileName = resp.fileName;
                        MessageService.success("保存成功");
                        $scope.isSave = false;
                        vm.threeLoading = false;
                        window.opener=null;
                        window.open('','_self');
                        window.close();
                        // $state.go("page-view2", {storeId: vm.storeId, orderArticleId: $stateParams.orderArticleId, source: 'external'}, {reload: true});
                    }, function (resp) {
                        $scope.isSave = false;
                        vm.threeLoading = false;
                        MessageService.error("保存失败");
                    });
                    return
                }
                Article.update(vm.article, function (resp) {
                    vm.article.fileName = resp.fileName;
                    localStorage.removeItem("photoSelectAll");
                    $scope.isSave = false;
                    vm.threeLoading = false;
                    if(vm.store.orderWay == 'orderThenEditor'){
                        works(resp)
                    }
                    if (flag) {
                        MessageService.success("保存成功");
                        // localStorage.setItem('save-article',resp.id);
                    } else {
                        swal({
                            title: "修改成功",
                            text: "作品已同步到我的作品",
                            imageUrl: Theme.sweetSuccedImg(),
                            timer: 2000,
                            showConfirmButton: false
                        });
                        $state.go("page-view", {storeId: vm.storeId, aid: resp.id, soId:vm.salesOrderId, plId:vm.productLineId, returnUrl:$stateParams.returnUrl}, {reload: true});
                    }
                }, function () {
                    $scope.isSave = false;
                    vm.threeLoading = false;
                    swal({
                        title: "保存失败",
                        text: "保存出错了，请稍后再试，或者联系工作人员",
                        imageUrl: Theme.sweetHintImg(),
                        timer: 2000,
                        showConfirmButton: false
                    });
                })
            } else {
                //格式化数据
                var data = {
                    id: vm.article.id,
                    name: vm.article.name,
                    completed: vm.completed,
                    sku: vm.article.sku,
                    skuNumber: vm.article.skuNumber,
                    cover: vm.article.cover,
                    document: saveData(),
                    productType:vm.article.productType,
                    fileName: vm.article.fileName,
                    productId: vm.article.productId,
                    productName: vm.article.productName,
                    categoryId: vm.article.categoryId,
                    categoryName: vm.article.categoryName,
                    categoryStoreId: vm.article.categoryStoreId,
                    categoryStoreName: vm.article.categoryStoreName,
                    folderId: vm.article.folderId,
                    itemValueId: vm.article.itemValueId,
                    themeClassifyId: vm.article.themeClassifyId,
                    themeClassifyName: vm.article.themeClassifyName,
                    productXmlId: vm.article.productXmlId,
                    productXmlName: vm.article.productXmlName,
                    musicIdentify: vm.article.musicIdentify,
                    editorType: vm.article.editorType,
                    modelBlendentUuid: vm.article.modelBlendentUuid,
                    unFilledCount: vm.unFilledCount,
                }
                Article.save(data, function (resp) {
                    localStorage.removeItem("photoSelectAll");
                    vm.article.id = resp.id;
                    vm.article.fileName = resp.fileName;
                    MessageService.success("保存成功");
                    $scope.isSave = false;
                    vm.threeLoading = false;
                    if(vm.store.orderWay == 'orderThenEditor'){
                        works(resp)
                    }
                    vm.isEdit = true;
                    if (flag) {
                        localStorage.setItem('save-article', resp.id);
                    } else {
                        $state.go("page-view", {storeId: vm.storeId, aid: resp.id, soId:vm.salesOrderId, plId:vm.productLineId, returnUrl:$stateParams.returnUrl}, {reload: true});
                    }
                }, function (resp) {
                    $scope.isSave = false;
                    vm.threeLoading = false;
                    swal({
                        title: "保存失败",
                        text: "保存出错了，请稍后再试，或者联系工作人员",
                        imageUrl: Theme.sweetHintImg(),
                        timer: 3000,
                        showConfirmButton: false
                    });

                });
            }
        }
        function works(article){
            if (vm.isEdit) {
                SalesOrder.updateByArticle({
                    id: vm.salesOrderId,
                    productLineId: vm.productLineId,
                    articleId: article.id
                })
            }else{
                SalesOrder.updateArticle({
                    id: vm.salesOrderId,
                    productLineId: vm.productLineId,
                    articleId: article.id,
                    copy: 1,
                })
            }
        }
        $scope.isContinue = function () {
            var hasEmpty = true;
            vm.unFilledCount = 0;
            for(var i=0; i<$scope.template.pages.page.length; i++){
                var _page = $scope.template.pages.page[i];
                if(_page.levels.level){
                    for(var j=0; j<_page.levels.level.length; j++){
                        var _level = _page.levels.level[j];
                        if (_level.imagebox) {
                            if (!_level.imagebox.image || !_level.imagebox.image.resource || !_level.imagebox.image.resource.identifier) {
                                hasEmpty = false;
                                vm.unFilledCount++;
                            }
                        }
                    }
                }
            }
            vm.completed = hasEmpty;
            return hasEmpty;
        };
        $scope.exitWork = function (e) {
            // vm.exitSure = true;
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'退出',
                msg :'确认要退出吗？'
            }, function() {
                $scope.exitPage()
            })
        }
        $scope.exitPage = function () {
            localStorage.removeItem("photoSelectAll");
            if($stateParams.orderArticleId){
                window.opener=null;
                window.open('','_self');
                window.close();
                // $state.go("page-view2", {storeId: vm.storeId, orderArticleId: $stateParams.orderArticleId, source: 'external'}, {reload: true});
                return
            }
            if($stateParams.returnUrl){
                window.location = $stateParams.returnUrl;
                return
            }
            if(vm.store.orderWay == 'orderThenEditor'){
                $state.go("sales-order-manager", {id:vm.salesOrderId, plid:vm.productLineId, storeId: vm.storeId});
                return;
            }
            window.history.go(-1);
        }
        function saveData() {
            return documentWebMM($scope.template,$scope.domain)
        }
        //预览
        $scope.previewTab = function (e) {
            fn(e)
            if(vm.productXmlData.modelType && vm.productXmlData.modelType === 'THREE'){
                if(!vm.productXmlData.modelPath){
                    MessageService.error("暂无3D模型");
                    return
                }
                vm.threeView = true;
            }else {
                $scope.template.pages.page = vm.pageData;
                vm.previewArticle = saveData();
                vm.watchSure = true;
            }
        }



        //画布操作
        vm.moveCanvasView = false;
        $scope.ratioJia = function(){
            if(vm.pageData[vm.pageNumber1]){
                vm.pageData[vm.pageNumber1].custom.ratio2 += 2;
                if(vm.pageData[vm.pageNumber1].custom.ratio2 >300){
                    vm.pageData[vm.pageNumber1].custom.ratio2 = 300;
                }
                vm.pageData[vm.pageNumber1].custom.ratio = vm.pageData[vm.pageNumber1].custom.ratio2/100;
            }
            if(vm.pageData[vm.pageNumber2]){
                vm.pageData[vm.pageNumber2].custom.ratio2 += 2;
                if(vm.pageData[vm.pageNumber2].custom.ratio2 >300){
                    vm.pageData[vm.pageNumber2].custom.ratio2 = 300;
                }
                vm.pageData[vm.pageNumber2].custom.ratio = vm.pageData[vm.pageNumber2].custom.ratio2/100;
            }
        }
        $scope.ratioJian = function(){
            if(vm.pageData[vm.pageNumber1]){
                var ratio3 = vm.pageData[vm.pageNumber1].custom.ratio3 > 100 ? 100 : vm.pageData[vm.pageNumber1].custom.ratio3;
                vm.pageData[vm.pageNumber1].custom.ratio2 -= 2;
                if(vm.pageData[vm.pageNumber1].custom.ratio2 < ratio3){
                    vm.pageData[vm.pageNumber1].custom.ratio2 = ratio3;
                }
                vm.pageData[vm.pageNumber1].custom.ratio = vm.pageData[vm.pageNumber1].custom.ratio2/100;
            }
            if(vm.pageData[vm.pageNumber2]){
                var ratio3 = vm.pageData[vm.pageNumber2].custom.ratio3 > 100 ? 100 : vm.pageData[vm.pageNumber2].custom.ratio3;
                vm.pageData[vm.pageNumber2].custom.ratio2 -= 2;
                if(vm.pageData[vm.pageNumber2].custom.ratio2 < ratio3){
                    vm.pageData[vm.pageNumber2].custom.ratio2 = ratio3;
                }
                vm.pageData[vm.pageNumber2].custom.ratio = vm.pageData[vm.pageNumber2].custom.ratio2/100;
            }
        };
        $scope.ratioInit = function(){
            if(vm.pageData[vm.pageNumber1]){
                vm.pageData[vm.pageNumber1].custom.ratio2 = vm.pageData[vm.pageNumber1].custom.ratio3;
                vm.pageData[vm.pageNumber1].custom.ratio = vm.pageData[vm.pageNumber1].custom.ratio2/100;
            }
            if(vm.pageData[vm.pageNumber2]){
                vm.pageData[vm.pageNumber2].custom.ratio2 = vm.pageData[vm.pageNumber2].custom.ratio3;
                vm.pageData[vm.pageNumber2].custom.ratio = vm.pageData[vm.pageNumber2].custom.ratio2/100;
            }
        }
        $scope.ratioOriginal = function(){
            if(vm.pageData[vm.pageNumber1]){
                vm.pageData[vm.pageNumber1].custom.ratio2 = 100;
                vm.pageData[vm.pageNumber1].custom.ratio = vm.pageData[vm.pageNumber1].custom.ratio2/100;
            }
            if(vm.pageData[vm.pageNumber2]){
                vm.pageData[vm.pageNumber2].custom.ratio2 = 100;
                vm.pageData[vm.pageNumber2].custom.ratio = vm.pageData[vm.pageNumber2].custom.ratio2/100;
            }
        }
        $scope.moveCanvasCb = function(){
            vm.moveCanvasView = !vm.moveCanvasView;
        }

        // 保存滚轮事件处理函数的变量
        var mousewheelHandler = function(event){
            if (event.ctrlKey === true || event.metaKey) {
                var wheelDelta = event.wheelDelta ? (event.wheelDelta / 120) : ( - event.detail / 3);
                if(wheelDelta>0){
                    $scope.ratioJia();
                }else{
                    $scope.ratioJian();
                }
                $scope.$apply();
                event.preventDefault();
            }
        }
        vm.textFont = false;
        originalEventRatio();
        function originalEventRatio(){
            $timeout(function () {
                window.addEventListener('mousewheel', mousewheelHandler,{ passive: false});
                document.onkeydown = function (event) {
                    if (event.keyCode === 32 && !vm.moveCanvasView && !vm.textBlur && !vm.textFont) {
                        $timeout(function () {
                            vm.moveCanvasView = true;
                        })
                        return false
                    }
                    if(event.keyCode == 122){
                        $scope.$apply();
                        return false
                    }
                }
                $(document).keyup(function(event){
                    vm.moveCanvasView = false;
                    $scope.$apply();
                    return false
                });
            })
        }
        //移动画布
        vm.moveCanvasObj = {
            x:0,
            y:0
        };
        $scope.moveCanvas = function(e){
            fn(e);
            if(!vm.moveCanvasView){
                return
            }
            var _moveCanvasObj = angular.copy(vm.moveCanvasObj);
            moveCanvas(e, function (x, y) {
                vm.moveCanvasObj.x = _moveCanvasObj.x + x;
                vm.moveCanvasObj.y = _moveCanvasObj.y + y;
                $scope.$apply();
            })
        }
        //移除画布透明效果
        $scope.opacityElement = function(mediabox, geometry){
            var opacity = 1;
            if(!geometry || !mediabox){
                return 1
            }
            if(geometry.x > -geometry.width && geometry.y > -geometry.height && geometry.x < mediabox.width && geometry.y < mediabox.height){
                opacity = 1;
            }else {
                opacity = 0.3;
            }
            return opacity
        }

        //像素不足提醒
        vm.imgSize = 150;
        vm.imgDpi = 150;
        StoreConfig.getAllByTypes({storeId: vm.storeId,type: ["IMGSIZE", "IMGDPI"]}, function (res) {
            if(res.status == 200){
                res.message.forEach(function (item) {
                    if(item.type === 'IMGSIZE'){
                        vm.imgSize = item.value;
                    }
                    if(item.type === 'IMGDPI'){
                        vm.imgDpi = item.value;
                    }
                })
            }
        });
        //生成缩略图
        vm.isHtml2canvas = true;
        vm.pageData1 = [];
        vm.thumbnailInit = thumbnailInit;
        var a = null;
        function thumbnailInit() {
            if(!vm.productXmlData || vm.productXmlData.modelType != 'THREE'){
                return
            }
            vm.isHtml2canvas = false;
            if (a !== null) {
                clearTimeout(a)
            }
            a = setTimeout(function() {
                $timeout(function () {
                    updateThumbnail()
                })
            }, 1000);
        }
        function updateThumbnail() {
            for(var i=0; i<vm.pageData.length; i++){
                if(vm.pageData[i].updateThumbnail){
                    updateThumbnail1(vm.pageData[i],function () {
                        vm.pageData[i].updateThumbnail = false;
                        updateThumbnail()
                    })
                    return false
                }
            }
            vm.pageData1 = angular.copy(vm.pageData);
            vm.isHtml2canvas = true;
            $scope.$apply();
        }
        function updateThumbnail1(page,callback) {
            vm.thumbnailPageData = angular.copy(page);
            pageTrimbox(vm.thumbnailPageData)
            $timeout(function () {
                html2canvas_adapt(document.getElementById('thumbnail-view'), {
                    onrendered: function (canvas) {
                        if(!canvas){
                            callback()
                            return
                        }
                        var url = canvas.toDataURL("image/png");
                        var blob = dataURLtoBlob(url);
                        var formData = new FormData();
                        formData.append('file', blob);
                        formData.append('key', '3dThumbnail-' + UidService.get());
                        formData.append('token', vm.token);
                        $.ajax({
                            cache: true,
                            type: "POST",
                            url: 'https://upload.qiniup.com/',
                            data: formData,
                            async: false,
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                page.pagecover = {
                                    identifier: $scope.domain + data.key,
                                    provider: 'qiniu'
                                }
                                console.log(page.pagecover.identifier)
                                callback();
                                $scope.$apply();
                            }
                        })
                    },
                    letterRendering: true,
                    useCORS: true,
                    width: page.trimbox.width*page.custom.thumbnailScale,
                    height: page.trimbox.height*page.custom.thumbnailScale,
                    windowWidth: document.body.scrollWidth,
                    windowHeight: document.body.scrollHeight,
                    x: 0,
                    y: 0,
                    backgroundColor: "rgba(0, 0, 0, 0)"
                });
            },1000)
        }

        //生成封面图
        function thumbnailInit1(callback) {
            vm.threeLoading = true;
            vm.threeLoadingText = '保存中';
            vm.thumbnailPageData1 = angular.copy(vm.pageData[0]);
            $timeout(function (){
                html2canvas_adapt(document.getElementById('thumbnail-view1'), {
                    onrendered: function (canvas) {
                        if(!canvas){
                            vm.article.covered=false;
                            vm.article.cover=null;
                            callback();
                            return
                        }
                        var url = canvas.toDataURL("image/png");
                        var blob = dataURLtoBlob(url);
                        var formData = new FormData();
                        formData.append('file', blob);
                        formData.append('key', '3dThumbnail-' + UidService.get());
                        formData.append('token', vm.token);
                        $.ajax({
                            cache: true,
                            type: "POST",
                            url: 'https://upload.qiniup.com/',
                            data: formData,
                            async: false,
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                var identifier = data.key;
                                vm.article.covered=true;
                                vm.article.cover=identifier;
                                if(callback){callback()};
                            }
                        })
                    },
                    letterRendering: true,
                    useCORS: true,
                    width: $scope.template.pages.page[0].custom.width*vm.pageData[0].custom.thumbnailScale,
                    height: $scope.template.pages.page[0].custom.height*vm.pageData[0].custom.thumbnailScale,
                    windowWidth: document.body.scrollWidth,
                    windowHeight: document.body.scrollHeight,
                    x: 0,
                    y: 0,
                    backgroundColor: "rgba(0, 0, 0, 0)"
                });
            },1000)
        }

        //元素四角拖拽
        $scope.cursor = function (angle, source, boolean) {
            return cursorInfo(angle, source, boolean);
        }


        //元素精度和位置
        $scope.activeImageDpi = function (item) {
            return activeImageDpi(item)
        }
        $scope.transitionMm = function (item) {
            return transitionMm(item).toFixed(0) > -1 ? Math.abs(transitionMm(item).toFixed(0)) : transitionMm(item).toFixed(0)
        }
        //边框
        $scope.imageboxW = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.width) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.width
                    // return Number(imagebox.geometry.width) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100)
                }
            } else {
                return imagebox.geometry.width
            }
        }
        $scope.imageboxH = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.height) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.height
                    // return Number(imagebox.geometry.height) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100)
                }
            } else {
                return imagebox.geometry.height
            }
        }
        $scope.imageboxL = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.x) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.x
                    // return Number(imagebox.geometry.x) - (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100)
                }
            } else {
                return imagebox.geometry.x
            }
        }
        $scope.imageboxT = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.y) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.y
                    // return Number(imagebox.geometry.y) - (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100)
                }
            } else {
                return imagebox.geometry.y
            }
        }
        $scope.imageboxWEdge = function (imagebox, edge) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    if (edge == 'left') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100
                    }
                    if (edge == 'top') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100
                    }
                    if (edge == 'right') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100
                    }
                    if (edge == 'bottom') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100
                    }
                }
            } else {
                return 0
            }
        }

        //页面元素
        vm.isEditorPageElementBody = true;
        $scope.levelInfo = function (level, index) {
            var item = "";
            index = completeIndex(index);
            var i = index+1;
            if(level.imagebox){
                item = "相框" + i
            }
            if(level.imageart){
                item = "素材" + i
            }
            if(level.textbox){
                item = level.textbox.text
            }
            if(level.textdocument){
                item = "文本模板" + i
            }
            if(level.calMonthBox){
                item = "日历" + i
            }
            if(level.calTitleBox){
                item = "日历标题" + i
            }
            return item
        };
        function levelInfo1(level) {
            var item = "";
            if(level.imagebox){
                item = "imagebox"
            }
            if(level.imageart){
                item = "imageart"
            }
            if(level.textbox){
                item = 'textbox'
            }
            if(level.textdocument){
                item = "textdocument"
            }
            if(level.calTitleBox){
                item = "calTitleBox"
            }
            if(level.calMonthBox){
                item = "calMonthBox"
            }
            return item
        };
        $scope.levelDelete = function (index) {
            var levels = vm.pageData[vm.pageNumber].levels.level.splice(completeIndex(index), 1);
            if(levels && levels[0] && levels[0].imagebox){
                setLayout();
            }
            if (levels && levels[0] && levels[0].imagebox && levels[0].imagebox.image&& levels[0].imagebox.image.resourceid) {
                for (var j = 0; j < $scope.photos.length; j++) {
                    var arr = $scope.photos[j];
                    if (arr.id == levels[0].imagebox.image.resourceid) {
                        arr.usenum == 0 ? 0 : arr.usenum--;
                    }
                }
            }
            magnitudeBox();
            cookie();
        };

        $scope.levelHidden = function (e, level) {
            level.isHidden = !level.isHidden;
            fn(e)
        }

        //页面元素拖拽
        var sort1 = null;
        var sortArray1 = [];
        function pageListDrag1() {
            $timeout(function () {
                vm.sortingLevel = angular.copy(vm.pageData[vm.pageNumber].levels.level);
                sort1 = new Sortable(document.getElementById('pageList1'), {
                    swap: false,
                    filter: '.filtered',
                    cancel: ".notDrag",
                    preventOnFilter: true,
                    animation: 150,
                    onEnd: function (/**Event*/evt) {
                        sortArray1 = [];
                        angular.forEach($('#pageList1').find(".sortingPageView"), function (item, index) {
                            var page = item.getAttribute("data-page");
                            sortArray1.push(JSON.parse(page));
                        });
                        if(sortArray1 && sortArray1.length>0){
                            vm.pageData[vm.pageNumber].levels.level = [];
                            $timeout(function () {
                                vm.pageData[vm.pageNumber].levels.level = angular.copy(sortArray1.reverse());
                                vm.pageData[vm.pageNumber].levels.level.forEach(function (value, index) {
                                    value.seq = index+1;
                                })
                                sortArray1 = [];
                                $scope.editorBodyView();
                            })
                        }
                    }
                });
            })
        }
        $scope.dragCompleteClick = function (e, level, index) {
            fn(e)
            elementInit(completeIndex(index),vm.pageNumber,level[levelInfo1(level)],levelInfo1(level));
        };
        vm.completeIndex = completeIndex;
        function completeIndex(index) {
            return (vm.pageData[vm.pageNumber].levels.level.length-1-index)
        }
        function scrollIntoView() {
            multiSelect();
            $timeout(function () {
                if(vm.elementOn.index>=0 && document.getElementById('editor-page-element-body-view' + vm.elementOn.index)){
                    document.getElementById('editor-page-element-body-view' + vm.elementOn.index).scrollIntoView()
                }
            })
        }

        vm.multiSelect =multiSelect;
        function multiSelect() {
            var all = getAllCtrlKeyArray();
            vm.transparencyBtn = true;//透明度
            vm.lockingBtn = true;//锁定
            vm.generBoxBtn = true;//重复生成
            vm.coverageBtn = true;//图层
            vm.copyBtn = true;//复制
            vm.deleteBtn = true;//删除
            vm.textboxBtn = true;//文字
            vm.toobarAlignmentBtn = false;//对齐
            vm.hasImgBtn = false; //裁切交换
            vm.distribute = false;//分布
            if(all.length>0){
                vm.textboxBtn = false;
                vm.lockingBtn = false;
                vm.coverageBtn = false;
                vm.deleteBtn = false;
                if (all.length>1) {
                    vm.toobarAlignmentBtn = true;
                }
                if (all.length>2) {
                    vm.distribute = true;
                }
                angular.forEach(all, function (level) {
                    if (level.code && level.code.ctrlKey) {
                        vm.transparencyBtn = false;
                    }
                })
            }else{
                if(!vm.elementOn || !vm.elementOn.name || vm.elementOn.name == 'calTitleBox' || vm.elementOn.name == 'calMonthBox'){
                    vm.transparencyBtn = false;
                    vm.lockingBtn = false;
                    vm.coverageBtn = false;
                    vm.copyBtn = false;
                    vm.deleteBtn = false;
                    vm.generBoxBtn = false;
                }
                if(vm.elementOn && vm.elementOn.item && vm.elementOn.item.lock){
                    vm.textboxBtn = false;
                    vm.coverageBtn = false;
                    vm.deleteBtn = false;
                }
                if(vm.elementOn && vm.elementOn.item && vm.elementOn.name == 'textbox' && !vm.elementOn.item.lock){
                    vm.textboxBtn = true;
                }
                if(vm.elementOn && vm.elementOn.name == 'imagebox' && vm.elementOn.item && vm.elementOn.item.image && vm.elementOn.item.image.resource && vm.elementOn.item.image.resource.identifier){
                    vm.hasImgBtn = true;
                }
            }
        }
        //元素全选
        $scope.levelInif = vm.levelInif = levelInif;
        function levelInif() {
            angular.forEach($scope.template.pages.page, function (page) {
                angular.forEach(page.levels.level, function (level, i) {
                    if(level.code&&level.code.geometry){
                        level.code.ctrlKey = false;
                    }
                    if(level.imageart){
                        level.imageart.ctrlKey = false;
                    }
                    if(level.imagebox){
                        level.imagebox.exchange = false;
                        level.imagebox.ctrlKey = false;
                    }
                    if(level.textbox){
                        level.textbox.ctrlKey = false;
                    }
                    if (level.textdocument && level.textdocument.geometry) {
                        level.textdocument.ctrlKey = false;
                    }
                    if (level.calMonthBox && level.calMonthBox.geometry) {
                        level.calMonthBox.ctrlKey = false;
                    }
                    if (level.calTitleBox && level.calTitleBox.geometry) {
                        level.calTitleBox.ctrlKey = false;
                    }
                })
            })
            multiSelect();
        }
        vm.levelInif1 = levelInif1;
        function levelInif1() {
            $timeout(function () {
                angular.forEach($scope.template.pages.page, function (page, index) {
                    if(index != vm.pageNumber){
                        angular.forEach(page.levels.level, function (level, i) {
                            if(level.code&&level.code.geometry){
                                level.code.ctrlKey = false;
                            }
                            if(level.imageart){
                                level.imageart.ctrlKey = false;
                            }
                            if(level.imagebox){
                                level.imagebox.exchange = false;
                                level.imagebox.ctrlKey = false;
                            }
                            if(level.textbox){
                                level.textbox.ctrlKey = false;
                            }
                            if (level.textdocument && level.textdocument.geometry) {
                                level.textdocument.ctrlKey = false;
                            }
                            if (level.calTitleBox && level.calTitleBox.geometry) {
                                level.calTitleBox.ctrlKey = false;
                            }
                            if (level.calMonthBox && level.calMonthBox.geometry) {
                                level.calMonthBox.ctrlKey = false;
                            }
                        })
                    }
                })
                multiSelect();
            })
        };

        vm.levelMove = levelMove;
        function levelMove(event, operation) {
            angular.forEach(vm.pageData, function (page) {
                angular.forEach(page.levels.level, function (level, i) {
                    if(level.imageart && level.imageart.ctrlKey && !level.imageart.edit){
                        if(operation == 'imageart'){
                            level.imageart.transparency = parseFloat(vm.transparency/100);
                            return
                        }
                        if(!level.imageart.lock){
                            moveWebdown($scope, event,level.imageart, vm.pageData[vm.pageNumber].trimbox,vm.pageData[vm.pageNumber].custom.ratio, function () {
                                cookie()
                            })
                        }
                    }
                    if(level.imagebox && level.imagebox.ctrlKey){
                        if(operation == 'imagebox'){
                            level.imagebox.transparency = parseFloat(vm.transparency/100);
                            return
                        }
                        if(!level.imagebox.lock){
                            moveWebdown($scope, event,level.imagebox, vm.pageData[vm.pageNumber].trimbox,vm.pageData[vm.pageNumber].custom.ratio, function () {
                                cookie()
                            })
                        }
                    }
                    if(level.textbox && level.textbox.ctrlKey){
                        if(operation == 'textbox'){
                            level.textbox.transparency = parseFloat(vm.transparency/100);
                            return
                        }
                        if(!level.textbox.lock){
                            moveWebdown($scope, event,level.textbox, vm.pageData[vm.pageNumber].trimbox,vm.pageData[vm.pageNumber].custom.ratio, function () {
                                cookie()
                            })
                        }
                    }
                    if (level.textdocument && level.textdocument.ctrlKey) {
                        if(operation == 'transparency'){
                            level.textdocument.transparency = parseFloat(vm.transparency/100);
                            return
                        }
                        if(!level.textdocument.lock){
                            moveWebdown($scope, event,level.textdocument, vm.pageData[vm.pageNumber].trimbox,vm.pageData[vm.pageNumber].custom.ratio, function () {
                                cookie()
                            })
                        }
                    }
                    if (level.calMonthBox && level.calMonthBox.ctrlKey) {
                        if(operation == 'transparency'){
                            level.calMonthBox.transparency = parseFloat(vm.transparency/100);
                            return
                        }
                        if(!level.calMonthBox.lock){
                            moveWebdown($scope, event,level.calMonthBox, vm.pageData[vm.pageNumber].trimbox,vm.pageData[vm.pageNumber].custom.ratio, function () {
                                cookie()
                            })
                        }
                    }
                    if (level.calTitleBox && level.calTitleBox.ctrlKey) {
                        if(operation == 'transparency'){
                            level.calTitleBox.transparency = parseFloat(vm.transparency/100);
                            return
                        }
                        if(!level.calTitleBox.lock){
                            moveWebdown($scope, event,level.calTitleBox, vm.pageData[vm.pageNumber].trimbox,vm.pageData[vm.pageNumber].custom.ratio, function () {
                                cookie()
                            })
                        }
                    }
                })
            })
        }
        //对齐
        vm.isAlignHover = false;
        $scope.alignHover = function () {
            vm.isAlignHover = !vm.isAlignHover;
        }
        //裁切
        $scope.doubleClickImagebox = function (event) {
            $rootScope.$broadcast('doubleClickImageboxCallback',{
                event: event,
                index: vm.elementOn.index,
                item: vm.elementOn.item,
                pageIndex: vm.elementOn.pageIndex,
            });
        };
        //交换
        $scope.exchange = function () {
            vm.pageData[vm.pageNumber].levels.level.forEach(function (level, index) {
                if(level.imagebox){
                    if(index!=vm.elementOn.index){
                        level.imagebox.exchange = true;
                    }else{
                        level.imagebox.exchange = false;
                    }
                }
            })
        };

        function compare2(property, attr) {
            return function (a, b) {
                var value1 = a.geometry[property];
                var value2 = b.geometry[property];
                if (attr) {
                    return value1 - value2;
                }
                else {
                    return value2 - value1;
                }
            }
        }
        vm.getAllCtrlKeyArray=getAllCtrlKeyArray;
        function getAllCtrlKeyArray() {
            var all = [];
            angular.forEach(vm.pageData, function (page) {
                angular.forEach(page.levels.level, function (level, i) {
                    if(level.imageart && level.imageart.ctrlKey){
                        level.imageart.levelI = i;
                        level.imageart.current = 'imageart';
                        all.push(level.imageart);
                    }
                    if(level.imagebox && level.imagebox.ctrlKey){
                        level.imagebox.levelI = i;
                        level.imagebox.current = 'imagebox';
                        all.push(level.imagebox);
                    }
                    if(level.textbox && level.textbox.ctrlKey){
                        level.textbox.levelI = i;
                        level.textbox.current = 'textbox';
                        all.push(level.textbox);
                    }
                    if (level.textdocument && level.textdocument.ctrlKey) {
                        level.textdocument.levelI = i;
                        level.textdocument.current = 'textdocument';
                        all.push(level.textdocument);
                    }
                    if (level.calTitleBox && level.calTitleBox.ctrlKey) {
                        level.calTitleBox.levelI = i;
                        level.calTitleBox.current = 'calTitleBox';
                        all.push(level.calTitleBox);
                    }
                    if (level.calMonthBox && level.calMonthBox.ctrlKey) {
                        level.calMonthBox.levelI = i;
                        level.calMonthBox.current = 'calMonthBox';
                        all.push(level.calMonthBox);
                    }
                });
            })
            return all;
        }
        $scope.boxAlignment = function (attr) {
            var levels = vm.pageData[vm.pageNumber].levels;
            var all = getAllCtrlKeyArray();

            if (attr == 'left') {
                all.sort(compare2('x', true));
            } else if (attr == 'right') {
                all.sort(compare2('width', false));
                all.sort(compare2('x', false));
            } else if (attr == 'top') {
                all.sort(compare2('y', true));
            } else if (attr == 'bottom') {
                all.sort(compare2('height', false));
                all.sort(compare2('y', false));
            }
            if(attr == 'vertical' && all.length>0){
                var verticalY = horizontalAndVerticalCenter(attr, all);
            }
            if(attr == 'standard' && all.length>0){
                var standardX = horizontalAndVerticalCenter(attr, all);
            }

            for (var i = 0; i < all.length; i++) {
                if(!all[i].lock){
                    if (attr == 'left') {
                        all[i].geometry.x = all[0].geometry.x;

                    } else if (attr == 'right') {
                        all[i].geometry.x = all[0].geometry.x + all[0].geometry.width - all[i].geometry.width;

                    } else if (attr == 'top') {
                        all[i].geometry.y = all[0].geometry.y;

                    } else if (attr == 'bottom') {
                        all[i].geometry.y = all[0].geometry.y + all[0].geometry.height - all[i].geometry.height;

                    } else if (attr == 'vertical') {
                        all[i].geometry.y = verticalY - all[i].geometry.height / 2;
                    } else {
                        all[i].geometry.x = standardX - all[i].geometry.width / 2;
                    }


                    if (all[i].current == 'imagebox') {
                        levels.level[all[i].levelI].imagebox = all[i];
                    } else if (all[i].current == 'textbox') {
                        levels.level[all[i].levelI].textbox = all[i];
                    } else {
                        levels.level[all[i].levelI].imageart = all[i];
                    }
                }
            }
            // levelInif()
            cookie()
        }
        $scope.canvasCb = function (e) {
            $scope.bgImgFalseClick();
            $scope.imageartTrimFalseClick();
            $scope.imageboxFalseClick();
            fn(e)
        }

        $scope.changeFilletType = function(type){
            if (vm.elementOn.item.border.radius.filletType == type) {
                return
            }
            vm.elementOn.item.border.radius.filletType = type;
            switchToRoundedMode(vm.elementOn.item,vm.borderRadiusAllbtn);//切换圆角显示模式
        }

        vm.borderRadiusAllbtn = false
        $scope.borderRadiusAll = function () {
            vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.border.radius.lt = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.border.radius.all;
            vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.border.radius.rt = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.border.radius.all;
            vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.border.radius.rb = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.border.radius.all;
            vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.border.radius.lb = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.border.radius.all;
        }
        $scope.borderRadiusAllbtn = function(){
            vm.borderRadiusAllbtn = true;
            vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.border.radius.rt = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.border.radius.lt;
            vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.border.radius.rb = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.border.radius.lt;
            vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.border.radius.lb = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.border.radius.lt;
            vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.border.radius.all = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.border.radius.lt
        }
        // 圆角设置
        vm.isRoundedSet = false;
        $scope.roundedSet = function(attr){
            toolbarStatus();
            vm.borderRadiusAllbtn = false;
            vm.isRoundedSet = attr;
        }

        // 边框设置
        vm.isBorderSet = false;
        $scope.borderSet = function(attr){
            toolbarStatus();
            vm.isBorderSet = attr;
        }

        // 阴影设置
        vm.isShadowSet = false;
        $scope.shadowSet = function(attr){
            toolbarStatus();
            vm.isShadowSet = attr;
        }

        function toolbarStatus(){
            vm.isTransp = false;
            vm.isRoundedSet = false;
            vm.isBorderSet = false;
            vm.isShadowSet = false;
            vm.isCoverage = false;
            vm.isAlign = false;
            vm.isOperationSpacing = false;
            vm.isOperationDir = false;
            vm.isOperationColor = false;
        }

        $scope.lineTypeChenge = function(lineType){
            vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index].imagebox.border.lineType = lineType;
        }
        $scope.dropShadow = function(imagebox,ratio){
            if (imagebox.shadow == null) {
                return;
            }
            var width = $scope.imageboxW(imagebox) * ratio;
            var height =$scope.imageboxH(imagebox) * ratio;
            return dropShadow(imagebox,width,height)
        }

        vm.editorMiniPage = 'page';
        $scope.editorMiniPageCb = function (item) {
            vm.editorMiniPage = item;
        }
        $scope.getIndex = function(page){
            if(page){
                var _index = 0;
                for(var i=0; i<vm.pageData.length; i++){
                    var _page = vm.pageData[i];
                    if(_page && _page.config && _page.config.partid && page.config.partid == _page.config.partid){
                        _index+=1;
                        if(_page.uuid == page.uuid){
                            break
                        }
                    }
                }
                if (_index < 10) {
                    return "0"+ _index;
                }
                return _index;
            }
        }
        $scope.getIndex1 = function(index){
            if(vm.pageData && vm.pageData.length>0){
                var _index = 0;
                for(var i=0; i<vm.pageData.length; i++){
                    var _page = vm.pageData[i];
                    if(_page && _page.config && _page.config.partid == 'P'){
                        _index+=1;
                        if(index == i){
                            break
                        }
                    }
                }
                if (_index < 10) {
                    return "0"+ _index;
                }
                return _index;
            }
        }
        //部件名
        $scope.partNamt = function (partid) {
            var partName = "";
            for(var i=0; i<$scope.template.config.parts.list.length; i++){
                if($scope.template.config.parts.list[i].id == partid){
                    partName = $scope.template.config.parts.list[i].name;
                }
            }
            return partName
        }

        // 单个元素对齐
        vm.isAlign = false;
        $scope.alignHover2 = function () {
            if(vm.elementOn.name == ''){
                MessageService.error("请选中一个元素");
                return
            }
            // if (vm.elementOn.item.lock) {
            //     MessageService.error("请先解锁该元素");
            //     return
            // }
            toolbarStatus();
            vm.isAlign = !vm.isAlign;
        }

        $scope.singleBoxAlignment = function (attr) {
            if(vm.toobarAlignmentBtn){
                $scope.boxAlignment(attr);
                return
            }
            if (vm.elementOn.item.lock) {
                MessageService.error("请先解锁该元素");
                return
            }
            if (attr == 'left') {
                vm.elementOn.item.geometry.x = elementAlign(vm.elementOn.item.geometry , vm.elementOn.item.rotation.angle , 'left' , vm.pageData[vm.pageIndex].custom.width , vm.pageData[vm.pageIndex].custom.height);
            } else if (attr == 'right') {
                vm.elementOn.item.geometry.x = elementAlign(vm.elementOn.item.geometry , vm.elementOn.item.rotation.angle , 'right' , vm.pageData[vm.pageIndex].custom.width , vm.pageData[vm.pageIndex].custom.height);
            } else if (attr == 'top') {
                vm.elementOn.item.geometry.y = elementAlign(vm.elementOn.item.geometry , vm.elementOn.item.rotation.angle , 'top' , vm.pageData[vm.pageIndex].custom.width , vm.pageData[vm.pageIndex].custom.height);
            } else if (attr == 'bottom') {
                vm.elementOn.item.geometry.y = elementAlign(vm.elementOn.item.geometry , vm.elementOn.item.rotation.angle , 'bottom' , vm.pageData[vm.pageIndex].custom.width , vm.pageData[vm.pageIndex].custom.height);
            } else if (attr == 'vertical') {
                vm.elementOn.item.geometry.y = (vm.pageData[vm.pageIndex].custom.height - vm.elementOn.item.geometry.height) / 2;
            } else {
                vm.elementOn.item.geometry.x = (vm.pageData[vm.pageIndex].custom.width - vm.elementOn.item.geometry.width) / 2;
            }
            cookie()
        }

        // 元素水平垂直分布
        vm.isDistributeHover = false;
        $scope.distribute = function(){
            vm.isDistributeHover = !vm.isDistributeHover;
            vm.isAlignHover = false;
        }
        $scope.boxDistribute = function (direction){
            var all1 = getAllCtrlKeyArray();
            var all = [];
            all1.forEach(function(box){
                if (!box.lock) {
                    all.push(box)
                }
            })
            if (all.length < 3) {
                MessageService.error('请选择3个以上非锁定状态的元素！');
                return
            }
            if(direction == 'horizontal'){
                setAllCtrlKeyArray(all, 'x')
            }
            if(direction == 'vertical'){
                setAllCtrlKeyArray(all, 'y')
            }
        }
        function setAllCtrlKeyArray(all, attr) {
            var len = all.length - 1;
            all.sort(compare2(attr, true));
            if (attr == 'x') {
                var elementAllWidth = 0;
                for (var i = 1; i < len; i++) {
                    elementAllWidth+=all[i].geometry.width;
                }
                var spacingPx = (all[len].geometry.x - all[0].geometry.x - all[0].geometry.width - elementAllWidth) / len;
                for (var i = 1; i < len; i++) {
                    all[i].geometry.x = all[i-1].geometry.x + all[i-1].geometry.width + spacingPx;
                }
            }
            if (attr == 'y') {
                var elementAllHeight = 0;
                for (var i = 1; i < len; i++) {
                    elementAllHeight+=all[i].geometry.height;
                }
                var spacingPx = (all[len].geometry.y - all[0].geometry.y - all[0].geometry.height - elementAllHeight) / len;
                for (var i = 1; i < len; i++) {
                    all[i].geometry.y = all[i-1].geometry.y + all[i-1].geometry.height + spacingPx;
                }
            }
        }
        vm.isFocus=isFocus
        function isFocus(){
            var inputFocus = 0;
            document.querySelectorAll('input[type="text"]').forEach(function(input){
                if(input == document.activeElement){
                    inputFocus++;
                }
            })
            document.querySelectorAll('input[type="number"]').forEach(function(input){
                if(input == document.activeElement){
                    inputFocus++;
                }
            })
            document.querySelectorAll('textarea').forEach(function(textarea){
                if(textarea == document.activeElement){
                    inputFocus++;
                }
            })
            if(inputFocus!=0){
                return true
            }
            return false
        }

        vm.isWatchName = false;
        $scope.watchGalleryName = function(state){
            vm.isWatchName = state;
        }


        $scope.generBox = function(e){
            fn(e)
            if(!vm.elementOn.item){
                MessageService.error('请先选择一个图片、素材、文字或者二维码！');
                return
            }
            if(vm.elementOn.name!='imagebox'&&vm.elementOn.name!='imageart'&&vm.elementOn.name!='textbox'&&vm.elementOn.name!='code'){
                MessageService.error('请先选择一个图片、素材、文字或者二维码！');
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/page/box-copy.html',
                controller: 'BoxCopyController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('editorWeb');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return {
                            box: vm.elementOn.item,
                            pageI:vm.pageNumber
                        };
                    }]
                }
            }).result.then(function (data) {
                if(data){
                    var all = getAllCtrlKeyArray();
                    if(all.length>0){
                        all.forEach(function (item) {
                            (function (item1) {
                                var _level = {
                                    seq: vm.pageData[vm.pageNumber].levels.level.length + 1,
                                }
                                _level[item1.current] = angular.copy(item1);
                                _level[item1.current].lock = false;
                                _level[item1.current].ctrlKey = false;
                                BoxCopyInit(_level,data)
                            })(item)
                        })
                    }else{
                        var _level = vm.pageData[vm.pageNumber].levels.level[vm.elementOn.index];
                        BoxCopyInit(_level,data)
                    }
                }
            });
        }
        function BoxCopyInit(level, data) {
            var _level = angular.copy(level);
            vm.pageData.forEach(function (page, index) {
                if(data.range == 'this'){
                    if(data.generLoc == 'all'){
                        if(vm.pageNumber<index){
                            _level.seq = vm.pageData[vm.pageNumber].levels.level.length + 1;
                            vm.pageData[index].levels.level.push(angular.copy(_level))
                        }
                    }
                    if(data.generLoc == 'next'){
                        if(vm.pageNumber<index && index%2===vm.pageNumber%2){
                            _level.seq = vm.pageData[vm.pageNumber].levels.level.length + 1;
                            vm.pageData[index].levels.level.push(angular.copy(_level))
                        }
                    }
                }
                if(data.range == 'first' && vm.pageNumber != index){
                    if(data.generLoc == 'all'){
                        _level.seq = vm.pageData[vm.pageNumber].levels.level.length + 1;
                        vm.pageData[index].levels.level.push(angular.copy(_level))
                    }
                    if(data.generLoc == 'next'){
                        if(index%2===0%2){
                            _level.seq = vm.pageData[vm.pageNumber].levels.level.length + 1;
                            vm.pageData[index].levels.level.push(angular.copy(_level))
                        }
                    }
                }
            });
            magnitudeBox();
        }


        $scope.buySize = function() {
            var createModal2 = function(url, controller) {
                return $uibModal.open({
                    templateUrl: url,
                    controller: controller,
                    backdrop: 'static',
                    keyboard: true,
                    windowClass: "jh-modal-md",
                    controllerAs: 'vm',
                    resolve: {
                    }
                });
            };
            createModal2('app/entities/web-gallery/storage-buy.html', 'StorageBuyController').result.then(function() {
                if(vm.userId){
                    CustomerStorages.getByCustomer({customerId: vm.userId}, function(data){
                        progressInit(data);
                    });
                }
            });
        }
        /*
        * 动态日历
        * */
        vm.years=[];
        vm.months=[1,2,3,4,5,6,7,8,9,10,11,12];
        vm.yearIndex=1;
        vm.monthIndex=0;
        vm.isCalendar=false;
        vm.monthIndex1 = 12;
        function yearInfo() {
            if($scope.pages.config.productType == 'CALENDAR'&&$scope.pages.config.dynamic){
                $scope.pages.pages.page.forEach(function (page) {
                    if(vm.monthIndex1 < page.config.monthIndex){
                        vm.monthIndex1 = page.config.monthIndex
                    }
                })
                var date = new Date();
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                for(var i=-1;i<6;i++){
                    vm.years.push(year+i)
                }
                if(month>5){
                    vm.yearIndex = 2;
                }
                // var now = new Date();
                // var year1 = now.getFullYear();
                // vm.yearIndex = vm.years.indexOf(year1) < 0 ? 1 : vm.years.indexOf(year1);
                // vm.monthIndex = now.getMonth();
            }
        }
        $scope.getEndYear = function () {
            return getDay('year',vm.years[vm.yearIndex],vm.months[vm.monthIndex],vm.monthIndex1)
        }
        $scope.getEndMonth = function () {
            return getDay('month',vm.years[vm.yearIndex],vm.months[vm.monthIndex],vm.monthIndex1)
        }
        $scope.yearIndex = function(item){
            if(item == 'subtract'){
                vm.yearIndex = vm.yearIndex-1<0?0:vm.yearIndex-1;
            }
            if(item == 'add'){
                vm.yearIndex = vm.yearIndex+1>vm.years.length-1?vm.years.length-1:vm.yearIndex+1;
            }
        }
        $scope.calendarSave = function () {
            $scope.template.config.startYear = vm.years[vm.yearIndex];
            $scope.template.config.startMonth = vm.months[vm.monthIndex];
            $scope.template.pages.page.forEach(function (page) {
                page.levels.level.forEach(function (level) {
                    if (level.calMonthBox && level.calMonthBox.geometry) {
                        var calMonthBox = level.calMonthBox;
                        calMonthBox.obj=null;
                        calMonthBox.sampleYear = getDay('year',$scope.template.config.startYear,$scope.template.config.startMonth,page.config.monthIndex);
                        calMonthBox.sampleMonth = getDay('month',$scope.template.config.startYear,$scope.template.config.startMonth,page.config.monthIndex);
                    }
                    if (level.calTitleBox && level.calTitleBox.geometry) {
                        var calTitleBox = level.calTitleBox;
                        calTitleBox.obj=null;
                        calTitleBox.sampleYear = getDay('year',$scope.template.config.startYear,$scope.template.config.startMonth,page.config.monthIndex);
                        calTitleBox.sampleMonth = getDay('month',$scope.template.config.startYear,$scope.template.config.startMonth,page.config.monthIndex);
                    }
                })
            })
            vm.isCalendar=false;
            vm.minLoading = true;
            $timeout(function () {
                vm.minLoading = false;
            },2000)
        }

        vm.showCanvasImageFilter = false;
        // 图片优化
        $scope.optimizationSelect = function (attr) {
            if (!vm.elementOn.name=='imagebox') {
                MessageService.error('请选中一个照片孔');
                return
            }
            if(vm.elementOn.item.image && vm.elementOn.item.image.resourceid) {
                // 获取原图信息
                Gallery.getOne({id:vm.elementOn.item.image.resourceid},function (res){
                    vm.elementOn.item.image.resource.primary = res.identifier;
                    // 图片优化
                    if (vm.elementOn.item.image && vm.elementOn.item.image.resource && vm.elementOn.item.image.resource.identifier) {
                        toolbarStatus();
                        var current = vm.elementOn.item;
                        if (current.image) {
                            vm.showCanvasImageFilter = true;
                            window.removeEventListener('mousewheel', mousewheelHandler);
                            if(vm.elementOn.item.optimizations){
                                vm.optimizations = vm.elementOn.item.optimizations;
                            }
                        } else {
                            MessageService.error('请选中一个照片孔');
                        }
                    }
                })
            }
        }

        $rootScope.$on('editorApp.closeImageFilter', function () {
            vm.showCanvasImageFilter = false;
            window.addEventListener('mousewheel', mousewheelHandler,{ passive: false});
        });
        $rootScope.$on('editorApp.saveImageFilter', function (event,imgUrl) {
            vm.showCanvasImageFilter = false;
            window.addEventListener('mousewheel', mousewheelHandler,{ passive: false});
            filterImgUpdate(imgUrl);
        });

        function filterImgUpdate(img){
            var url = img
            var blob = dataURLtoBlob(url);
            var formData = new FormData();
            formData.append('file', blob);
            formData.append('key', '3dThumbnail-' + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: false,
                contentType: false,
                processData: false,
                success: function (data) {
                    $.ajax({
                        type: "GET",
                        url: vm.elementOn.item.image.resource.identifier + "?imageInfo",
                        async: false,
                        success: function (va) {
                            var identifier = $scope.domain + data.key;
                            vm.elementOn.item.image.resource.identifier = identifier;
                            cookie();
                            if(va.orientation){
                                imageboxImgInit();
                            }
                        }
                    })
                }
            })
        }
        function imageboxImgInit(){
            $.ajax({
                type: "GET",
                url: $scope.domain + vm.elementOn.item.image.resource.primary + "?imageInfo",
                async: false,
                success: function (va1) {
                    var angle = (vm.elementOn.item.image.angle + 360 - orientationInit1(va1.orientation).angle) % 360;
                    if (vm.elementOn.item.flip) {
                        vm.elementOn.item.image.angle = -angle;
                        vm.elementOn.item.flip = false;
                    }else{
                        vm.elementOn.item.image.angle = angle;
                    }
                }
            });
        }

        function websocket(){
            var urlws = vm.platformSetting.editorNotifyUrl.split("://")[1];
            urlws = urlws.split("/")[0];
            // var ws = $websocket("ws://localhost:8096/ws/websocket?uid=Gallery"+vm.userId)
            var ws = $websocket("wss://"+ urlws +"/widget/ws/websocket?uid=Gallery"+vm.userId);

            ws.onOpen(function() {
                console.log('WebSocket连接已打开, url:' + ws.url);
            });

            ws.onMessage(function(message) {
                var photo = JSON.parse(message.data);
                console.log(photo);
                if (photo.folderId == vm.folderId || (photo.folderId == null && vm.folderId == '')) {
                    vm.totalPhotoItems++;
                    vm.unusedQuantity++;
                    var len = $scope.photos.length;
                    photo.identifier = $scope.domain + photo.identifier;
                    photo.select = false;
                    photo.index = len;
                    photo.usenum = 0;
                    photo.type = 'picture';
                    $scope.photos.unshift(photo);
                    if (vm.photoSort != 1 && vm.minId==null) {
                        vm.minId = photo.id;
                    }
                }
            });

            ws.onError(function(error) {
                console.log('WebSocket错误：', error);
            });

            ws.onClose(function() {
                console.log('WebSocket连接已关闭');
            });
        }


    }

})();
