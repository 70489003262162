(function () {
    'use strict';
    //冲印编辑
    angular
        .module('editorApp')
        .controller('EditorPhotoWebController', EditorPhotoWebController);
    EditorPhotoWebController.$inject = ['$scope', 'FontManagement', 'UidService', 'MessageService', '$stateParams', '$q', 'SalesOrder',
    'ThemeClassify', 'ProductXml', 'Store', 'PlatformWechatSetting','Qinius','Folder','$uibModal', 'Gallery', '$localStorage', 'Material', 'ClipArt', 'FontXmlsManger','Backgrounds','Product',
    'ItemValue', 'Convert', '$http', '$timeout', 'FontXmlsFileServes', 'Article', 'entity', '$state', 'SweetAlert', 'Theme','MessageNotification', 'DateUtils','ListService', 'OrderLine','ProductLine',
        'ImageService', 'OrderArticle', 'CustomerUploadAble','Customer','StoreConfig', 'CustomerStorages','$uibModalStack','$websocket'];

    function EditorPhotoWebController( $scope, FontManagement, UidService, MessageService, $stateParams, $q, SalesOrder,
        ThemeClassify, ProductXml, Store, PlatformWechatSetting, Qinius, Folder, $uibModal, Gallery, $localStorage, Material, ClipArt, FontXmlsManger,Backgrounds,Product,
      ItemValue, Convert, $http, $timeout, FontXmlsFileServes, Article, entity, $state, SweetAlert, Theme,MessageNotification, DateUtils,ListService, OrderLine,ProductLine,
                                       ImageService,OrderArticle, CustomerUploadAble,Customer,StoreConfig, CustomerStorages,$uibModalStack, $websocket) {
        var vm = this;
        $(".navbar").hide();
        $(".save-page").removeClass('hide1');
        $(".wrap").hide();
        //风格切换  引入不同的css文件
        Theme.judge();
        vm.Theme = localStorage.getItem('Theme');
        if($stateParams.authorization){
            if($stateParams.source){
                $localStorage.authenticationTokenSource = $stateParams.authorization;
            }else{
                $localStorage.authenticationToken = $stateParams.authorization;
            }
        };
        vm.isEdit = entity.type == 'edit' ? true : false;
        vm.storeId = $stateParams.storeId;
        vm.pid = $stateParams.pid;
        vm.aid = $stateParams.aid;
        vm.vid = $stateParams.vid;
        vm.xmlId = $stateParams.xmlId;
        vm.salesOrderId = $stateParams.soid ? $stateParams.soid : null;
        vm.productLineId = $stateParams.plid ? $stateParams.plid : null;
        vm.photoSelectAll= [];
        if ($localStorage.user != null) {
            vm.userId = $localStorage.user.id
        }
        var arr = localStorage.getItem('save-article');
        if (arr && vm.pid) {
            $state.go("editorAllEdit", {storeId: vm.storeId, aid: arr, soid: vm.salesOrderId, plid: vm.productLineId, returnUrl:$stateParams.returnUrl});
        }
        localStorage.removeItem('save-article');
        $scope.domains = Qinius.getDomain1();
        vm.tokenTime = 360000;
        Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"},function (msg) {
            vm.token = msg.token;
            vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
        },function (error) {
            if(error.data.status === 401){//未登录
                vm.threeLoading = false;
                $scope.login("Login");
            }
        });
        vm.platformSetting = PlatformWechatSetting.get();
        vm.store = Store.get({id: vm.storeId});
        $scope.gallaryPic = JSON.parse(localStorage.getItem("photoSelectAll")) || [];
        vm.pageIndex = 0;
        vm.data = [];
        vm.customer = Customer.current();
        vm.threeLoading = true;
        vm.threeLoadingText = '加载中...';


        /***************页面处理************start*****************/
        $q.all([$scope.domains.$promise, vm.platformSetting.$promise, vm.store.$promise]).then(function () {
            $(".navbar").hide();
            $(".save-page").removeClass('hide1');
            $(".wrap").hide();
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            setTimeout(function fn() {
                Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"},function (msg) {
                    vm.token = msg.token;
                    vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
                });
                setTimeout(fn, vm.tokenTime);
            }, vm.tokenTime);
            vm.logo = vm.store.menuLogo ? $scope.domain + vm.store.menuLogo : 'content/images/momentsgo.png';
            vm.defaultFont = FontManagement.getDefaultShow();
            //获取照片文件夹
            loadFolders();
            js_getDPI()
            vm.messageShow = true;
            //消息列表
            vm.messageList = "";
            MessageNotification.getAllByCondition({
                items:[
                    {key:"pageType", op : "=", value:"Edit"},
                    {key:"storeId", op : "=", value:vm.storeId},
                    {key:"available", op : "=", value:"true"},
                    {key:"validate", op : ">=", value: DateUtils.convertLocalDateToServer(new Date())}]
                }, function (res) {
                    res.forEach(function (item, index) {
                        vm.messageList += (index+1) + ":" + item.content + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0";
                    })
                }
            )
            //获取数据
            if(vm.store.orderWay == 'orderThenEditor' && !$stateParams.orderArticleId){
                if(vm.productLineId){
                    ProductLine.get({id: vm.productLineId}, function (res) {
                        vm.productLine = res;
                        init()
                    })
                }
            }else{
                init()
            }
            getByCustomer();
            websocket();//图库的websocket
        });
        var createModal = function (url, controller, type) {
            if (type == null) {
                type = "Login";
            }
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: {
                        type: type,
                        showTips:true,
                    },
                }
            });
        };

        $scope.login = function (type) {
            $uibModalStack.dismissAll();
            createModal('app/account/register/register.html', 'RegisterController', type).result.then(
                function () {
                    $state.reload();
                });
        };
        function getByCustomer() {
            if(vm.customer && vm.customer.id){
                CustomerStorages.getByCustomer({
                    customerId: vm.customer.id
                }, function (res) {
                    vm.isProgress = true;
                    progressInit(res)
                })
            }
        }
        function progressInit(item){
            vm.sumSize =  Number(item.initSize) + Number(item.buySize);
            vm.useSize = Number(item.usedSize);
            vm.percentage = 0;
            $timeout(function () {
                vm.percentage = vm.useSize / vm.sumSize;
            });
        };
        $scope.progressColor = function (size) {
            return progressColor(size)
        }
        vm.navSelect = function(item){
            vm.nav = item;
        }

        /***********************  编辑区域  ****************************/
        vm.finalArray = [];
        vm.currentNavtab = 0;
        vm.productXmlLines = [];
        vm.product = null;
        function init(){
            if(!vm.isEdit){
                Product.get({id: vm.pid, cancelled: false}, function (res) {
                    vm.pictureType = res.pictureType || 'single';
                    vm.product = res;
                    ItemValue.get({id: vm.vid}, function (resp) {
                        vm.productXmlLines = resp.productXmlLines;
                    });
                    var product = res;
                    vm.typeImageData=res;
                    var defaultItem = null;
                    for (var i = 0; i < product.itemValues.length; i++) {
                        var tempItem= product.itemValues[i];
                        if(tempItem.id == vm.vid){
                            defaultItem = tempItem;
                            vm.productActiveSku = defaultItem;
                            vm.currentNavtab= i;
                        }
                        var name='';
                        for (var j = 0; j < tempItem.objectValues.length; j++) {
                            var object=tempItem.objectValues[j];
                            name+=object.propertyValueValue;
                        }
                        vm.finalArray.push({name:name,item:tempItem});
                    }
                    if (!defaultItem) {
                        defaultItem = vm.finalArray[0].item;
                        vm.productActiveSku = defaultItem;
                    }
                    for (var i = 0; i< defaultItem.productXmlLines.length; i++) {
                        var xmlline = defaultItem.productXmlLines[i];
                        if (xmlline.id == vm.xmlId) {
                            var articleName = defaultItem.productName;
                            if (defaultItem.sku) {
                                articleName = articleName + " " + defaultItem.sku;
                            }
                            if (xmlline.productXmlName) {
                                articleName += (" (" + xmlline.productXmlName + ")");
                            }
                            vm.article = {
                                cover: xmlline.coverImg,
                                themeClassifyId: xmlline.productXmlThemeId,
                                themeClassifyName: xmlline.productXmlThemeName,
                                productXmlId: xmlline.productXmlId,
                                productXmlName: xmlline.productXmlName,
                                musicIdentify: xmlline ? xmlline.musicIdentify : defaultItem.musicIdentify,
                                sku: defaultItem.sku,
                                skuNumber: defaultItem.skuNumber,
                                name: articleName,
                                productId: defaultItem.productId,
                                productName: defaultItem.productName,
                                categoryId: defaultItem.categoryId,
                                categoryName: defaultItem.categoryName,
                                categoryStoreId: defaultItem.categoryStoreId,
                                categoryStoreName: defaultItem.categoryStoreName,
                                editorType: defaultItem.productEditorType,
                                productBokeh: defaultItem.productBokeh,
                                productBokehType: defaultItem.productBokehType || 'None',
                                itemValueId: defaultItem.itemValueId || vm.vid
                            };
                            if (!defaultItem.productBokeh) {
                                vm.article.bokehtype = 'None';
                            } else {
                                vm.article.bokehtype = defaultItem.productBokehType || 'None';
                            }
                            getXmlToDucoment(xmlline);
                            break;
                        }
                    }
                })
            }else{
                if($stateParams.aid){
                    Article.byUser({id: $stateParams.aid}, function (res) {
                        vm.article = res;
                        ItemValue.get({id: res.itemValueId}, function (resp) {
                            vm.typeImageData=res;
                            $scope.template = angular.copy(res.document);
                            vm.photoSelectAll =  angular.copy(res.document.galleries.gallery) || [];
                            vm.productXmlLines = resp.productXmlLines;

                            vm.pictureType = resp.pictureType || 'single';
                            documentAnalysis($scope, $http, vm, $scope.template,FontManagement,'other', function(page){
                                ratioInfo(page)
                            }, function () {
                                $timeout(function () {
                                    vm.article.skuNumber = resp.skuNumber;
                                    vm.article.productBokeh = resp.productBokeh;
                                    vm.article.productBokehType = resp.productBokehType || 'None';
                                    vm.article.bokehtype = 'None';
                                    var _top = vm.messageList > 0 ? 132 :92;
                                    // vm.windowH = window.innerHeight - _top;
                                    // vm.windowW = window.innerWidth - 460;
                                    // vm.windowW1 = 140;
                                    vm.windowH = 668;
                                    vm.windowW = 1132;
                                    vm.windowW1 = 213;
                                    vm.placeOrder = true;
                                    vm.signalPage1 = getPage('P0',$scope.template.pages.page);
                                    vm.signalPage2 = getPage('P1',$scope.template.pages.page);
                                    vm.signalPage1.backup = false;
                                    vm.signalPage2.backup = false;
                                    var windowRatio = 0.95;
                                    var custom = $scope.template.pages.page[0].custom;
                                    vm.pageWidth = custom.width;
                                    vm.pageHeight = custom.height;
                                    if(vm.windowH / vm.pageHeight >= vm.windowW / vm.pageWidth ){
                                        vm.ratio = vm.windowW / vm.pageWidth * windowRatio;
                                    }else {
                                        vm.ratio = vm.windowH / vm.pageHeight * windowRatio;
                                    }
                                    vm.ratio1 = vm.windowW1 / vm.pageWidth;
                                    var documentPage = $scope.template.pages.page;
                                    vm.data = [];
                                    if(vm.pictureType == 'single'){
                                        for (var i = 0; i < documentPage.length; i++) {
                                            if(!documentPage[i].backup){
                                                var isRepetition = false;
                                                for(var j=0; j<vm.data.length; j++){
                                                    if (documentPage[i].uuid === vm.data[j].uuid) {
                                                        isRepetition = true;
                                                        vm.data[j].quantity++
                                                        break;
                                                    }
                                                }
                                                if(!isRepetition){
                                                    documentPage[i].quantity = 1;
                                                    vm.data.push(documentPage[i])
                                                }
                                            }
                                        };
                                    }
                                    if(vm.pictureType == 'spread'){
                                        var _data = [];
                                        for (var i = 0; i < documentPage.length; i++) {
                                            if(!documentPage[i].backup){
                                                var _documentPage2 = documentPage[i];
                                                var isRepetition1 = false;
                                                if (i % 2 === 0){
                                                    //偶数
                                                    for(var j=0; j<vm.data.length; j++){
                                                        if (_documentPage2.uuid === vm.data[j][0].uuid) {
                                                            vm.data[j][0].quantity++;
                                                            isRepetition1 = true;
                                                            break;
                                                        }
                                                    }
                                                    if(!isRepetition1){
                                                        _documentPage2.quantity = 1;
                                                        _data.push(_documentPage2);
                                                    }
                                                } else if (i % 2 === 1){
                                                    //奇数
                                                    if(_data.length>0){
                                                        _data.push(_documentPage2);
                                                        vm.data.push(_data);
                                                        _data = [];
                                                    }
                                                }
                                            }
                                        };
                                    }
                                    vm.pageSum = vm.data.length - 1;
                                    pageWarnExamine(vm.data, vm.imgSize, vm.imgDpi)
                                    vm.threeLoading = false;
                                    photosNum();
                                }, 1000)
                            })
                            loadAll('isNull');
                        });
                        getProductActiveSku()
                    },function (error) {
                        if(error.data.status === 404){//未找到作品
                            $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                        }
                    })

                }else if($stateParams.orderArticleId){
                    OrderArticle.byUser({id: $stateParams.orderArticleId}, function (res) {
                        vm.article = res;
                        ItemValue.get({id: res.itemValueId}, function (resp) {
                            vm.typeImageData=res;
                            $scope.template = angular.copy(res.document);
                            vm.photoSelectAll =  angular.copy(res.document.galleries.gallery) || [];
                            vm.productXmlLines = resp.productXmlLines;

                            vm.pictureType = resp.pictureType || 'single';
                            documentAnalysis($scope, $http, vm, $scope.template,FontManagement,'other', function(page){
                                ratioInfo(page)
                            }, function () {
                                $timeout(function () {
                                    vm.article.skuNumber = resp.skuNumber;
                                    vm.article.productBokeh = resp.productBokeh;
                                    vm.article.productBokehType = resp.productBokehType || 'None';
                                    vm.article.bokehtype = 'None';
                                    var _top = vm.messageList > 0 ? 132 :92;
                                    // vm.windowH = window.innerHeight - _top;
                                    // vm.windowW = window.innerWidth - 460;
                                    // vm.windowW1 = 140;
                                    vm.windowH = 668;
                                    vm.windowW = 1132;
                                    vm.windowW1 = 213;
                                    vm.placeOrder = true;
                                    vm.signalPage1 = getPage('P0',$scope.template.pages.page);
                                    vm.signalPage2 = getPage('P1',$scope.template.pages.page);
                                    vm.signalPage1.backup = false;
                                    vm.signalPage2.backup = false;
                                    var windowRatio = 0.95;
                                    var custom = $scope.template.pages.page[0].custom;
                                    vm.pageWidth = custom.width;
                                    vm.pageHeight = custom.height;
                                    if(vm.windowH / vm.pageHeight >= vm.windowW / vm.pageWidth ){
                                        vm.ratio = vm.windowW / vm.pageWidth * windowRatio;
                                    }else {
                                        vm.ratio = vm.windowH / vm.pageHeight * windowRatio;
                                    }
                                    vm.ratio1 = vm.windowW1 / vm.pageWidth;
                                    var documentPage = $scope.template.pages.page;
                                    vm.data = [];
                                    if(vm.pictureType == 'single'){
                                        for (var i = 0; i < documentPage.length; i++) {
                                            if(!documentPage[i].backup){
                                                var isRepetition = false;
                                                for(var j=0; j<vm.data.length; j++){
                                                    if (documentPage[i].uuid === vm.data[j].uuid) {
                                                        isRepetition = true;
                                                        vm.data[j].quantity++
                                                        break;
                                                    }
                                                }
                                                if(!isRepetition){
                                                    documentPage[i].quantity = 1;
                                                    vm.data.push(documentPage[i])
                                                }
                                            }
                                        };
                                    }
                                    if(vm.pictureType == 'spread'){
                                        var _data = [];
                                        for (var i = 0; i < documentPage.length; i++) {
                                            if(!documentPage[i].backup){
                                                var _documentPage2 = documentPage[i];
                                                var isRepetition1 = false;
                                                if (i % 2 === 0){
                                                    //偶数
                                                    for(var j=0; j<vm.data.length; j++){
                                                        if (_documentPage2.uuid === vm.data[j][0].uuid) {
                                                            vm.data[j][0].quantity++;
                                                            isRepetition1 = true;
                                                            break;
                                                        }
                                                    }
                                                    if(!isRepetition1){
                                                        _documentPage2.quantity = 1;
                                                        _data.push(_documentPage2);
                                                    }
                                                } else if (i % 2 === 1){
                                                    //奇数
                                                    if(_data.length>0){
                                                        _data.push(_documentPage2);
                                                        vm.data.push(_data);
                                                        _data = [];
                                                    }
                                                }
                                            }
                                        };
                                    }
                                    vm.pageSum = vm.data.length - 1;
                                    pageWarnExamine(vm.data, vm.imgSize, vm.imgDpi)
                                    vm.threeLoading = false;
                                    photosNum();
                                }, 1000)
                            })
                            loadAll('isNull');
                        });
                        getProductActiveSku()
                    },function (error) {
                        if(error.data.status === 404){//未找到作品
                            $state.go('404',{storeId:vm.storeId,tips:error.data.message});
                        }
                    })
                }
            }
        }

        function getProductActiveSku(){
            Product.get({id: vm.article.productId, cancelled: false}, function (resp) {
                var product = resp;
                vm.product = resp;
                var defaultItem = null;
                for (var i = 0; i < product.itemValues.length; i++) {
                    var tempItem= product.itemValues[i];
                    if(tempItem.id == vm.article.itemValueId){
                        defaultItem = tempItem;
                        vm.productActiveSku = defaultItem;
                    }
                }
            })
        }

        function ratioInfo(page) {
            page.custom.thumbnailScale = 300 / page.custom.width;
        }
        //获取模版信息
        function getXmlToDucoment(productXml,callback){
            Convert.xmlToDocument2({
                identifier: productXml.productXmlIdentifier,
                provider: "qiniu",
                editorType:productXml.productXmlVersion == "4.0" ? "" : vm.article.editorType,
                patternType:vm.typeImageData.patternType,
                pictureType:vm.pictureType,
            }, function (res) {
                $scope.template = angular.copy(res);
                documentAnalysis($scope, $http, vm, $scope.template,FontManagement,'other', function(page){
                    ratioInfo(page)
                }, function () {
                    $timeout(function () {
                        var _top = vm.messageList > 0 ? 132 :92;
                        // vm.windowH = window.innerHeight - _top;
                        // vm.windowW = window.innerWidth - 460;
                        // vm.windowW1 = 140;
                        vm.windowH = 668;
                        vm.windowW = 1132;
                        vm.windowW1 = 213;
                        vm.placeOrder = true;
                        $scope.template.pages.page.forEach(function (page) {
                            page.backup = true;
                        })

                        vm.signalPage1 = getPage('P0',$scope.template.pages.page);
                        vm.signalPage2 = getPage('P1',$scope.template.pages.page);
                        vm.signalPage1.backup = false;
                        vm.signalPage2.backup = false;
                        var windowRatio = 0.8;
                        var custom = $scope.template.pages.page[0].custom;
                        vm.pageWidth = custom.width;
                        vm.pageHeight = custom.height;
                        if(vm.windowH / vm.pageHeight >= vm.windowW / vm.pageWidth ){
                            vm.ratio = vm.windowW / vm.pageWidth * windowRatio;
                        }else {
                            vm.ratio = vm.windowH / vm.pageHeight * windowRatio;
                        }
                        vm.ratio1 = vm.windowW1 / vm.pageWidth;
                        if(callback){
                            callback()
                        }else{
                            abook();
                        }
                        vm.threeLoading = false;
                    }, 1000)
                })
            });
            loadAll('isNull');
        }

        function abook() {
            var _data = [];
            var _isSignalPage = true;
            var _isImageboxNull = false;
            for (var i = 0; i < $scope.gallaryPic.length; i++) {
                if($scope.gallaryPic[i] && $scope.gallaryPic[i].identifier){
                    _isImageboxNull = false;
                    if(vm.pictureType == 'single'){
                        var _page = angular.copy(vm.signalPage1);
                        for (var i2 = 0; i2 < vm.data.length; i2++) {
                            var _page1 = null;
                            if (vm.data[i2].levels.level) {
                                for (var j = 0; j < vm.data[i2].levels.level.length; j++) {
                                    var _level = vm.data[i2].levels.level[j];
                                    if (_level.imagebox && _level.imagebox.image) {
                                        if (!_level.imagebox.image.resource || !_level.imagebox.image.resource.identifier) {
                                            _page1 = vm.data[i2];
                                            break
                                        }
                                    }
                                }
                            }
                            if(_page1){
                                _isImageboxNull = true;
                                _page = _page1;
                                break
                            }
                        }
                    }else{
                        var _page = null;
                        for (var i3 = 0; i3 < vm.data.length; i3++) {
                            var _page1 = null;
                            for(var i1 = 0; i1<vm.data[i3].length; i1++){
                                var page2 = vm.data[i3][i1];
                                if (page2.levels.level) {
                                    for (var j = 0; j < page2.levels.level.length; j++) {
                                        var _level = page2.levels.level[j];
                                        if (_level.imagebox && _level.imagebox.image) {
                                            if (!_level.imagebox.image.resource || !_level.imagebox.image.resource.identifier) {
                                                _page1 = page2;
                                                break
                                            }
                                        }
                                    }
                                }
                                if(_page1){break}
                            }
                            if(_page1){
                                _isImageboxNull = true;
                                _page = _page1;
                                break
                            }
                        }
                        if(!_isImageboxNull){
                            if(_isSignalPage){
                                _page = angular.copy(vm.signalPage1);
                            }else{
                                _page = angular.copy(vm.signalPage2);
                            }
                        }
                    }
                    var _isImagebox = false;
                    _page.levels.level.forEach(function (level) {
                        if (level.imagebox) {
                            _isImagebox = true;
                            level.imagebox.bokehtype = 'None';
                            level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                            level.imagebox.image = {
                                angle: 0,
                                filter: null,
                                filterResource: null,
                                offsetX: 0,
                                offsetY: 0,
                                resource: {identifier: $scope.gallaryPic[i].identifier, provider: "qiniu"},
                                size: $scope.gallaryPic[i].imgSize,
                                provider: "qiniu",
                                resourceid: $scope.gallaryPic[i].id,
                                scale: 1,
                                width: $scope.gallaryPic[i].width,
                                height: $scope.gallaryPic[i].height,
                                imageInfo: $scope.gallaryPic[i].imageInfo,
                            };
                            level.imagebox.blurredrectangle = {
                                width: '',
                                height: '',
                                x: '',
                                y: '',
                            };
                            if(vm.pictureType == 'single'){
                                getBlurredrectangle(level.imagebox, 0);
                            }else{
                                getBlurredrectangle(level.imagebox, i);
                            }
                        }
                    })
                    if(!_isImageboxNull){
                        if (vm.pictureType == 'single') {
                            _page.quantity = 1;
                            _page.uuid = uuid();
                            vm.data.push(_page)
                        }
                        if (vm.pictureType == 'spread') {
                            _isSignalPage = !_isSignalPage;
                            if(!_isImagebox){
                                i--
                            }
                            _page.quantity = 1;
                            _page.uuid = uuid();
                            if (_data.length == 1) {
                                _page.uuid = _data[0].uuid;
                                _data.push(_page);
                                vm.data.push(_data)
                            } else {
                                _page.uuid = uuid();
                                _data = [];
                                _data.push(_page);
                                if(i == $scope.gallaryPic.length-1){
                                    var _page1 = angular.copy(vm.signalPage2);
                                    _page1.levels.level.forEach(function (level) {
                                        if(level.imagebox){
                                            _isImagebox = true;
                                            level.imagebox.bokehtype = 'None';
                                            level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                                            level.imagebox.image = {
                                                angle: 0,
                                                filter: null,
                                                filterResource: null,
                                                offsetx: 0,
                                                offsety: 0,
                                                resource: {identifier: "", provider: "qiniu"},
                                                size: "",
                                                provider: "qiniu",
                                                resourceid: "",
                                                scale: 1,
                                                width:"",
                                                height:"",
                                                imageInfo:{},
                                            };
                                            level.imagebox.blurredrectangle = {
                                                width:'',
                                                height:'',
                                                x:'',
                                                y:'',
                                            };
                                        }
                                    })
                                    _page1.uuid =_data[0].uuid;
                                    _data.push(_page1);
                                    vm.data.push(_data)
                                }
                            }
                        }
                    }
                }
            }
            $timeout(function () {
                console.log(vm.data)
                if(vm.data.length>0){
                    vm.pageSum = vm.data.length - 1;
                }
                $scope.gallaryPic = [];
                $scope.photos.forEach(function (photo) {
                    photo.select = false;
                })
                pageWarnExamine(vm.data, vm.imgSize, vm.imgDpi)
                vm.threeLoading = false;
                console.log($scope.template)
            },2000)
        }
        function abook1(gallaryPic,callback) {
            var _data = [];
            var _isSignalPage = true;
            for(var i=0; i<gallaryPic.length; i++){
                var index = i+1;
                if(vm.pictureType == 'single'){
                    var _page = angular.copy(vm.signalPage1);
                }else{
                    if(_isSignalPage){
                        var _page = angular.copy(vm.signalPage1);
                    }else{
                        var _page = angular.copy(vm.signalPage2);
                    }
                }
                var _isImagebox = false;
                _page.levels.level.forEach(function (level,i1) {
                    if(level.imagebox){
                        var _gallaryPic = null;
                        if(gallaryPic[i]){
                            _gallaryPic = gallaryPic[i][i1] ? gallaryPic[i][i1] : gallaryPic[i][0]
                        }
                        _isImagebox = true;
                        level.imagebox.bokehtype = 'None';
                        level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                        level.imagebox.image = {
                            angle: 0,
                            filter: null,
                            filterResource: null,
                            offsetx: 0,
                            offsety: 0,
                            resource: {identifier: !_gallaryPic ? "" : _gallaryPic.identifier, provider: "qiniu"},
                            size: !_gallaryPic ? "" : _gallaryPic.imgSize,
                            provider: "qiniu",
                            resourceid: !_gallaryPic ? "" : _gallaryPic.id,
                            scale: 1,
                            width:!_gallaryPic ? "" : _gallaryPic.width,
                            height:!_gallaryPic ? "" : _gallaryPic.height,
                            imageInfo:!_gallaryPic ? {} : _gallaryPic.imageInfo,
                        };
                        level.imagebox.blurredrectangle = {
                            width:'',
                            height:'',
                            x:'',
                            y:'',
                        };
                        if(vm.pictureType == 'single'){
                            getBlurredrectangle(level.imagebox, 0);
                        }else{
                            getBlurredrectangle(level.imagebox, i);
                        }
                    }
                })

                if(vm.pictureType == 'single'){
                    _page.quantity=1;
                    _page.uuid = uuid();
                    vm.data.push(_page)
                }
                if(vm.pictureType == 'spread'){
                    _isSignalPage = !_isSignalPage;
                    if(!_isImagebox){
                        i--
                    }
                    _page.quantity=1;
                    _page.uuid = uuid();
                    if (index % 2 === 0){
                        //偶数
                        _page.uuid =_data[0].uuid;
                        _data.push(_page);
                        vm.data.push(_data)

                    } else if (index % 2 === 1){
                        //奇数
                        _page.uuid = uuid();
                        _data = [];
                        _data.push(_page);
                        if(i == gallaryPic.length-1){
                            var _page1 = angular.copy(vm.signalPage2);
                            _page1.levels.level.forEach(function (level, i1) {
                                if(level.imagebox){
                                    _isImagebox = true;
                                    level.imagebox.bokehtype = 'None';
                                    level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                                    level.imagebox.image = {
                                        angle: 0,
                                        filter: null,
                                        filterResource: null,
                                        offsetx: 0,
                                        offsety: 0,
                                        resource: {identifier: "", provider: "qiniu"},
                                        size: "",
                                        provider: "qiniu",
                                        resourceid: "",
                                        scale: 1,
                                        width:"",
                                        height:"",
                                        imageInfo:{},
                                    };
                                    level.imagebox.blurredrectangle = {
                                        width:'',
                                        height:'',
                                        x:'',
                                        y:'',
                                    };
                                }
                            })
                            _page1.uuid =_data[0].uuid;
                            _data.push(_page1);
                            vm.data.push(_data)
                        }
                    }
                }
            }
            if(vm.data.length>0){
                vm.pageSum = vm.data.length - 1;
            }
            gallaryPic = [];
            $scope.photos.forEach(function (photo) {
                photo.select = false;
            })
            photosNum();
            if(callback){callback()}
        }
        function uuid() {
            var s = [];
            var hexDigits = "0123456789abcdef";
            for (var i = 0; i < 36; i++) {
                s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
            }
            s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
            s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
            s[8] = s[13] = s[18] = s[23] = "-";

            var uuid = s.join("");
            return uuid;
        };
        function getBlurredrectangle(imagebox, index) {
            var item = imagebox;
            if(!item.image){
                return
            }
            addImagebox($http, imagebox, function () {
                var newImg = angular.copy({
                    width: imagebox.image.width,
                    height: imagebox.image.height
                })
                if ((imagebox.geometry.width - imagebox.geometry.height) * (newImg.width - newImg.height) < 0) {
                    if (index % 2 === 0){
                        if(imagebox.flip){
                            imagebox.image.angle = imagebox.image.angle+90;
                        }else{
                            imagebox.image.angle = imagebox.image.angle-90;
                        }
                        imagebox.image.width = newImg.height;
                        imagebox.image.height = newImg.width;
                    }
                    if (index % 2 === 1){
                        if(imagebox.flip){
                            imagebox.image.angle = imagebox.image.angle-90;
                        }else{
                            imagebox.image.angle = imagebox.image.angle+90;
                        }
                        imagebox.image.width = newImg.height;
                        imagebox.image.height = newImg.width;
                    }
                } else {
                    imagebox.image.angle = imagebox.image.angle;
                }
                getBackgroundSource3(imagebox, imagebox.geometry.width, imagebox.geometry.height, imagebox.image, imagebox.bokehtype, $scope);
            });
        }

        $scope.fillImagebox = function () {
            var item = vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.scale = 1;
            vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.blurredrectangle.x = 0;
            vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.blurredrectangle.y = 0;
            if (item.bokehtype == 'None') {
                item.bokehtype = vm.article.productBokehType;
            }
            vm.imageboxLoad = true;
            getBackgroundSource3(item, item.geometry.width, item.geometry.height, item.image, item.bokehtype, $scope, function () {
                vm.imageboxLoad = false;
            });
        }
        $scope.cutImagebox = function () {
            var item = vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.scale = 1;
            vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.blurredrectangle.x = 0;
            vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.blurredrectangle.y = 0;
            item.bokehtype = 'None';
            vm.imageboxLoad = true;
            getBackgroundSource3(item, item.geometry.width, item.geometry.height, item.image, item.bokehtype, $scope, function () {
                vm.imageboxLoad = false;
                // $scope.$apply();
            });
        }
        $scope.previousPage = function () {
            if(vm.pictureType == 'spread'){
                if(!$scope.zfInit()){
                    vm.pageIndex -= 2;
                }else{
                    vm.pageIndex--
                }
            }else{
                vm.pageIndex--;
            }
            if(vm.pageIndex<0){
                vm.pageIndex = 0;
            }
            vm.levelIndex = 0;
        }
        $scope.nextPage = function () {
            if(vm.pictureType == 'spread'){
                if(!$scope.zfInit()){
                    vm.pageIndex += 2;
                }else{
                    vm.pageIndex++;
                }
            }else{
                vm.pageIndex++;
            }

            if(vm.pageIndex>vm.editorPageList.length-1){
                vm.pageIndex = vm.editorPageList.length-1;
            }
            vm.levelIndex = 0;
        }
        $scope.turning = function (index) {
            vm.pageIndex = index;
            vm.levelIndex = null;
        }
        $scope.editorDialogHide = function () {
            vm.editorDialog=false;
            var element = document.getElementById('page'+vm.pageIndex);
            element.scrollIntoView();
        }
        $scope.singleIndexClick = function (item) {
            vm.levelIndex = null;
            if($scope.zfInit() != item){
                if(item){
                    vm.pageIndex++
                }else{
                    vm.pageIndex--
                }
            }
        }
        function js_getDPI() {
            var arrDPI = new Array();
            if (window.screen.deviceXDPI != undefined) {
                arrDPI[0] = window.screen.deviceXDPI;
                arrDPI[1] = window.screen.deviceYDPI;
            }
            else {
                var tmpNode = document.createElement("DIV");
                tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
                document.body.appendChild(tmpNode);
                arrDPI[0] = parseInt(tmpNode.offsetWidth);
                arrDPI[1] = parseInt(tmpNode.offsetHeight);
                tmpNode.parentNode.removeChild(tmpNode);
            }
            vm.windowDpi = arrDPI[1];
            // vm.windowDpi = 300;
            return arrDPI;
        }
        //mm转px
        function transitionPx(item) {
            return item * vm.windowDpi / 25.4;
        }
        //px转mm
        function transitionMm(item) {
            return item * 25.4 / vm.windowDpi;
        }

        /*******************************************  照片框处理  *************************************************************************/
        var isImagebox = false;
        var stickerDragBox = {};
        $scope.moveImageboxStart = function (index, $event) {
            vm.levelIndex = index;
            fn($event);
            var sticker = vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            if(sticker.bokehtype != 'None' || !sticker.image){
                return
            }
            var x = $event.clientX;
            var y = $event.clientY;
            var proW = vm.ratio*sticker.image.scale;
            var proH = vm.ratio*sticker.image.scale;

            var l = sticker.image.offsetx * proW * sticker.image.scale;
            var t = sticker.image.offsety * proH * sticker.image.scale;
            var r = -(sticker.image.width * sticker.image.scale - sticker.geometry.width) * proW;
            var b = - (sticker.image.height * sticker.image.scale - sticker.geometry.height) * proH;
            var angle = Number(sticker.rotation.angle);
            //开关打开
            isImagebox = true;
            vm.isImageboxTrim = true;
            //鼠标移动
            window.onmousemove = function (e) {
                if (isImagebox == false) {
                    return;
                }
                fn(e);
                var cx = (l + r) / 2;
                var cy = (t + b) / 2;
                var pt0 = rotatePoint({x: x, y: y}, {x: cx, y: cy}, -angle);
                var pt1 = rotatePoint({x: e.clientX, y: e.clientY}, {x: cx, y: cy}, -angle);
                var deltaX = pt1.x - pt0.x;
                var deltaY = pt1.y - pt0.y;
                var nl = l + deltaX;
                var nt = t + deltaY;
                if (nl < r) {
                    nl = r
                };
                if (nl > 0) {
                    nl = 0
                };
                if (nt < b) {
                    nt = b
                };
                if (nt > 0) {
                    nt = 0
                };

                sticker.image.offsetx = nl/proW/sticker.image.scale;
                sticker.image.offsety = nt/proH/sticker.image.scale;
                $scope.$digest();
            }
            //鼠标抬起事件
            window.onmouseup = function ($event) {
                isImagebox = false;
                vm.isImageboxTrim = false;
                stickerDragBox = {}
            }
        }
        //防止事件冒泡，默认事件
        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }
        /***********************  左侧菜单  ****************************/
        vm.nav = "picture";
        /***********************  照片  ****************************/
        vm.folderShow = false;
        vm.indexNum = 0;
        vm.itemsPerPage = 40;
        $scope.photos = [];
        $scope.moveGalleryIds = [];
        vm.picLoading = false;
        vm.deleteSure = false;
        vm.folderIds = "";
        vm.folderId = "";
        vm.photosTab='all';
        function loadFolders() {
            Folder.getAllByCondition({
                items: [{key: "type", op: "=", value: "Gallery"}],
                sort: ["id,asc"]
            }, function (resp) {
                $scope.Folders = resp;
            })
        }
        $scope.folderOn = function () {
            vm.folderShow = !vm.folderShow;
        };
        // 增加文件夹
        $scope.addFolder = function () {
            $uibModal.open({
                templateUrl: 'app/entities/sales-order/editor-name-dialog.html',
                controller: 'EditorNameDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            name: '',
                            popName:'新建文件夹'
                        };
                    },
                }
            }).result.then(function(name) {
                Folder.save({
                    name: name,
                    type: 'Gallery'
                }, function (resp) {
                    $scope.Folders.push(resp);
                })
            });
        }
        //选择文件夹
        $scope.showFolderContent = function (folder) {
            vm.indexNum = 0;
            $scope.photos = [];
            $scope.gallaryPic = [];
            vm.folderId = folder ? folder.id : '';
            vm.folderName = folder ? folder.name : '';
            vm.totalPhotoItems = null;
            vm.maxId = null;
            vm.minId = null;
            vm.unusedQuantity = null;

            if (folder) {
                loadAll(folder.id);
            } else {
                loadAll('isNull');
            }

        };
        $scope.deleteShow = function (ids,event) {
            fn(event)
            vm.folderIds=ids;
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'删除',
                msg :'删除文件夹会同时删除文件夹内全部照片, 确认删除？'
            }, function() {
                Folder.batchDelete({ids: vm.folderIds}, function () {
                    vm.indexNum = 0;
                    vm.maxId = null;
                    vm.minId = null;
                    $scope.photos = [];
                    loadAll('isNull');
                    loadFolders();
                    MessageService.success("删除成功");
                }, function () {
                    MessageService.error("删除失败");
                });
            })
        }

        $scope.updateFloders = function(folder,event){
            event.stopPropagation();
            $uibModal.open({
                templateUrl: 'app/entities/sales-order/editor-name-dialog.html',
                controller: 'EditorNameDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            name: folder.name,
                        };
                    },
                }
            }).result.then(function(name) {
                folder.name = name;
                Folder.update(folder, function (resp) {
                    MessageService.success("修改成功");
                },function () {
                    swal.showInputError("请输入正确的文件名!");
                })
            }, function() {

            });
        }
        $scope.photoCb = function () {
            vm.indexNum++;
            loadAll('isNull');
        }

        vm.photoSort = '1';
        $scope.photoSortChange = function (num) {
            vm.indexNum = 0;
            vm.photoSort = num;
            vm.totalPhotoItems = null;
            vm.maxId = null;
            vm.minId = null;
            $scope.photos = [];
            if(vm.folderId){
                loadAll(vm.folderId);
            }else{
                loadAll('isNull');
            }
        }
        vm.totalPhotoItems = null;
        vm.maxId = null;
        vm.minId = null;
        function loadAll(folderId) {
            vm.picLoading = true;
            var sort = ["id,desc"];
            if(vm.photoSort == 1){
                sort = ["id,desc"]
            }else if(vm.photoSort == 2){
                sort = ["orderBySeq,desc","name,desc"]
            }else if(vm.photoSort == 3){
                sort = ["orderBySeq,asc","name,asc"]
            }
            var items = [];
            if (folderId == 'isNull') {
                items.push({key: "folderId", op: "isNull", value: 1})
            }else{
                items.push({key: "folderId", op: "=",value: folderId});
            }
            if (vm.maxId) {
                items.push({key: "id", op: "<=",value: vm.maxId});
            }
            if (vm.minId) {
                items.push({key: "id", op: "<",value: vm.minId});
            }
            if (folderId == 'isNull') {
                $scope.selectFolder = '已传照片';
                Gallery.getPage({
                    items: items,
                    page: vm.indexNum,
                    size: vm.itemsPerPage,
                    sort: sort
                }, onSuccess, onError);
            } else {
                Gallery.getPage({
                    items: items,
                    page: vm.indexNum,
                    size: vm.itemsPerPage,
                    sort: sort
                }, onSuccess, onError);
            }
        }
        function onSuccess(data, headers) {
            if (vm.totalPhotoItems == null) {
                vm.totalPhotoItems = Number(headers('X-Total-Count'));
            }
            if (data.length > 0 && vm.maxId == null && vm.photoSort == 1) {
                vm.maxId = data[0].id;
            }
            vm.picLoading = false;
            var len = $scope.photos.length;
            angular.forEach(data, function (item, i) {
                item.identifier = $scope.domain + item.identifier;
                item.index = i + len;
                item.usenum = 0;
                item.type = 'picture';
                if($scope.gallaryPic.some(function(item1){
                    item1.id === item.id
                })){
                    item.select = true
                }else{
                    item.select = false
                }
                $scope.photos.push(item);
            });
            photosNum();
            if($scope.photos.length < vm.totalPhotoItems){
                vm.isPhotoFinish = false;
            }else {
                vm.isPhotoFinish = true;
            }
            if ($scope.photos && $scope.photos.length>0) {
                newCreateDate = new Date($scope.photos[0].createdDate).getTime();
            }
        }

        function onError(error) {
            vm.picLoading = false;
            MessageService.error("照片库获取失败");
        }
        function getGallery(){
            if(vm.photosTab!='all' && $scope.photos.length < vm.totalPhotoItems){
                var _photoQuantity = 0;
                $scope.photos.forEach(function (item) {
                    if(item.usenum==0||!item.usenum){
                        _photoQuantity++
                    }
                })
                if(_photoQuantity < vm.itemsPerPage){
                    vm.indexNum++;
                    if(vm.folderId){
                        loadAll(vm.folderId);
                    }else{
                        loadAll('isNull');
                    }
                }
            }
        }
        //删除多个文件夹
        $scope.deleteFolders = function () {
            vm.deleteSure = false;
            Folder.batchDelete({ids: vm.folderIds}, function () {
                vm.indexNum = 0;
                vm.maxId = null;
                vm.minId = null;
                $scope.photos = [];
                loadAll('isNull');
                loadFolders();
                MessageService.success("删除成功");
            }, function () {
                MessageService.error("删除失败");
            });
        };
        $scope.choosePhoto = function (photo) {
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            photo.select = !photo.select;
            angular.forEach($scope.photos, function (p, i) {
                if (p.select) {
                    $scope.gallaryPic.push(p);      //选择导入图片的数组
                    $scope.moveGalleryIds.push(p.id);
                }
            });
        }
        $scope.switchBgTab = function (attr) {
            vm.photosTab = attr;
            if(vm.photosTab!='all'){
                vm.unUsedImageboxsNum = 0;
                angular.forEach($scope.photos,function (p) {
                    if(p.usenum==0){
                        vm.unUsedImageboxsNum++;
                    }
                })
                // getGallery();
            }
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            vm.indexNum = 0;
            $scope.photos = [];
            if (vm.folderId) {
                loadAll(vm.folderId);
            } else {
                loadAll('isNull');
            }
            $scope.whetherChoose(false);
        }
        $scope.whetherChoose = function (attr) {
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            $scope.photos.some(function (p, i) {
                if(vm.photosTab!='all'){
                    if(p.usenum==0||!p.usenum){
                        if (attr) {
                            p.select = true;
                            $scope.gallaryPic.push(p);      //选择导入图片的数组
                            $scope.moveGalleryIds.push(p.id);
                        } else {
                            p.select = false;
                            $scope.gallaryPic = [];      //选择导入图片的数组
                            $scope.moveGalleryIds = [];
                        }
                    }
                }else{
                    if (attr) {
                        p.select = true;
                        $scope.gallaryPic.push(p);      //选择导入图片的数组
                        $scope.moveGalleryIds.push(p.id);
                    } else {
                        p.select = false;
                        $scope.gallaryPic = [];      //选择导入图片的数组
                        $scope.moveGalleryIds = [];
                    }
                }
            })
        }

        function removePage (id){
            for(var i=vm.data.length-1;i>=0;i--){
                var _e = false;
                if(vm.pictureType == 'spread'){
                    for(var i1 = 0; i1<vm.data[i].length;i1++){
                        var item = vm.data[i][i1];
                        if(item && item.levels && item.levels.level){
                            angular.forEach(item.levels.level, function (level) {
                                if (level.imagebox && level.imagebox.image && level.imagebox.image.resourceid == id) {
                                    _e = true;
                                }
                            })
                        }
                    }
                }else{
                    var item = vm.data[i];
                    if(item && item.levels && item.levels.level){
                        angular.forEach(item.levels.level, function (level) {
                            if (level.imagebox && level.imagebox.image && level.imagebox.image.resourceid == id) {
                                _e = true;
                            }
                        })
                    }
                }
                if(_e){
                    vm.data.splice(i, 1);
                    vm.pageSum = vm.data.length - 1 < 0 ? 0 : vm.data.length - 1;
                    if(vm.pageIndex > vm.pageSum){
                        vm.pageIndex = vm.pageSum;
                    }
                }
            }
            // angular.forEach(vm.data, function (item, index) {
            //     if(item && item.levels && item.levels.level){
            //         angular.forEach(item.levels.level, function (level) {
            //             if (level.imagebox && level.imagebox.image && level.imagebox.image.resourceid == id) {
            //                 console.log(index)
            //                 vm.data.splice(index, 1);
            //                 vm.pageSum = vm.data.length - 1 < 0 ? 0 : vm.data.length - 1;
            //                 if(vm.pageIndex > vm.pageSum){
            //                     vm.pageIndex = vm.pageSum;
            //                 }
            //                 return;
            //             }
            //         })
            //     }
            // });
        }
        $scope.deleteBatchPhotos = function () {
            if (!$scope.moveGalleryIds.length) {
                MessageService.error("操作失败,请至少选择一个照片");
                return
            }
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'删除',
                msg :'删除照片将移除作品中正在使用的图片，确认删除么'
            }, function(){
                angular.forEach($scope.moveGalleryIds, function(id){
                    removePage(id);
                });
                Gallery.batchDelete($scope.moveGalleryIds, function () {
                    MessageService.success("删除成功！");
                    // 若被选中的pic被删除，就重新生成该数组
                    vm.unusedQuantity = null;

                    vm.maxId = null;
                    vm.minId = null;
                    vm.totalPhotoItems = null;
                    $scope.gallaryPic = [];
                    $scope.moveGalleryIds = [];
                    $scope.photos = [];
                    vm.indexNum = 0;
                    if(vm.folderId){
                        loadAll(vm.folderId);
                    } else {
                        loadAll('isNull');
                    }
                }, function (error) {
                    MessageService.error("删除失败！");
                });
            })
        }
        //一键填充
        $scope.directFill= function(){
            if (vm.pictureType == 'spread' && $scope.gallaryPic.length % 2 === 1){
                MessageService.error("请选择偶数数量的照片");
                return
            }
            if (vm.store.orderWay == 'editorThenOrder' && vm.productActiveSku.jhiPackage && vm.productActiveSku.packageQuantity <= $scope.photoSeveNumber()) {
                MessageService.error("数量已满" + vm.productActiveSku.packageQuantity + "张，无法继续添加");
                return
            }
            vm.threeLoading = true;
            for(var i=0; i<$scope.gallaryPic.length; i++){
                $scope.gallaryPic[i].seq = i+1;
                vm.photoSelectAll.push($scope.gallaryPic[i])
            }
            abook();
            photosNum();
            getGallery();
        }
        function photosNum(){
            for(var i=0; i<$scope.photos.length; i++){
                var _photo = $scope.photos[i];
                _photo.usenum=0;
                angular.forEach(vm.data, function (data, index) {
                    if(vm.pictureType == 'spread'){
                        angular.forEach(data,function (page) {
                            angular.forEach(page.levels.level, function (level, i) {
                                if(level.imagebox && level.imagebox.image && level.imagebox.image.resourceid) {
                                    if (level.imagebox.image.resourceid == _photo.id) {
                                        _photo.usenum++
                                    }
                                }
                            })
                        })
                    }else{
                        angular.forEach(data.levels.level, function (level, i) {
                            if(level.imagebox && level.imagebox.image && level.imagebox.image.resourceid) {
                                if (level.imagebox.image.resourceid == _photo.id) {
                                    _photo.usenum++
                                }
                            }
                        })
                    }
                })
            }
            unusedQuantity();
        }
        vm.unusedQuantity = null;

        function unusedQuantity(){
            var galleryIdList = [];
            angular.forEach(vm.data, function (data, i) {
                if(vm.pictureType == 'spread'){
                    angular.forEach(data,function (page) {
                        angular.forEach(page.levels.level, function (level, i) {
                            if(level.imagebox && level.imagebox.image && level.imagebox.image.resourceid) {
                                galleryIdList.push(level.imagebox.image.resourceid)
                            }
                        })
                    })
                }else{
                    angular.forEach(data.levels.level, function (level, i) {
                        if(level.imagebox && level.imagebox.image && level.imagebox.image.resourceid) {
                            galleryIdList.push(level.imagebox.image.resourceid)
                        }
                    })
                }
            })

            var newArr = galleryIdList.filter(function(item,index){
                return galleryIdList.indexOf(item) === index;  // 因为indexOf 只能查找到第一个
            });

            Gallery.unUsed({
                galleryIdList:newArr,
                folderId: vm.folderId,
            },function (res) {
                if(res.status == 200){
                    vm.unusedQuantity = res.message;
                    getGallery();
                }
            })
        }

        $scope.uploadImage = function(e){
            e.stopPropagation();
            e.preventDefault();
            if (!vm.product.editTip || !vm.product.editTipPic || $localStorage.dotRemindEditorTipPic) {
                $("#uploadImg2").trigger("click");
                return
            }
            $uibModal.open({
                templateUrl: 'app/entities/components/controls/editorTipPicModal.html',
                controller: 'EditorTipPicModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            url: vm.product.editTipPic,
                        };
                    },
                }
            }).result.then(function() {
                $("#uploadImg2").trigger("click");
            });
        }
        // 背景图和素材的上传
        $scope.uploadVerify = function(){
            uploadVerify(null,CustomerUploadAble, vm.storeId, vm.customer.id, function () {
                $("#uploadImg2").trigger("click");
            }, function () {
                getStorageBuy()
            })
        };

        window.updatePic = function ($event) {
            $timeout(function () {
                vm.picLoading = true;
                var len = $scope.photos.length;
                repeatCount = 0;
                var files = [];
                var files1 = [];
                var higherSize = [];
                bigSizePics = [];
                for (var i = 0; i < $event.target.files.length; i++) {
                    var f = $event.target.files[i];
                    f.imageSize = Math.ceil(f.size/1024/1024);
                    files.push(f)
                }
                for (var i = 0; i < files.length; i++) {
                    var file = files[i];
                    if (file.size>=20*1024*1024 ){
                        file.imageSize= Math.ceil(file.size/1024/1024);
                        bigSizePics.push(file);
                    }else{
                        files1.push(files[i])
                    }
                }
                if (bigSizePics.length >0) {
                    thumbnailatorPic();
                }
                if(files1.length>0){
                    for(var i=0; i<files1.length; i++){
                        (function (index) {
                            getBgImg(files1, index);
                        })(i)
                    }
                }
                $event.srcElement.value = ""
            })
        };
        var repeatCount = 0;
        var bigSizePics = [];
        vm.duplicateList = [];
        vm.isDuplicateList = false;
        function getBgImg(files, i, len) {
            var url = window.URL || window.webkitURL;
            var img = new Image();              //手动创建一个Image对象
            img.src = url.createObjectURL(files[i]);//创建Image的对象的url
            img.crossOrigin = "Anonymous";
            ImageService.backendUploadPics(files[i],$scope.domain, vm.token,function (data,f) {
                Gallery.upload({
                    hash: data.hash,
                    identifiers: data.key,
                    provider: "QINIU",
                    fileName: f.name,
                    imgSize: f.size,
                    folderId: vm.folderId ? vm.folderId : ''
                }, function (resp) {
                    if(resp.duplicate){
                        resp.identifier= $scope.domain + resp.identifier;
                        vm.duplicateList.push(resp)
                    }
                    GalleryUploadFinish(files,i)
                }, function () {
                    MessageService.error("上传失败");
                    GalleryUploadFinish(files,i)
                });
            },function (res) {
                GalleryUploadFinish(files,i)
            })
        }
        function GalleryUploadFinish(files,i) {
            repeatCount++
            if(repeatCount == files.length){
                document.getElementById('uploadImg2').value = '';//上传完清空输入框
                vm.picLoading = false;
                $scope.gallaryPic = [];
                $scope.moveGalleryIds = [];
                if(vm.duplicateList.length>0){
                    MessageService.confirm({
                        title :'提示',
                        cancel :'取消',
                        confirm :'确认',
                        msg :'检测到图库中有'+vm.duplicateList.length+'张重复图片，是否需要自动去重？',
                        html: '<span class="themecolor">查看重复图片</span>'
                    }, function(res) {
                        if(res == 'htmlClick'){
                            vm.isDuplicateList = true;
                        }else{
                            var ids = [];
                            vm.duplicateList.forEach(function (item){
                                ids.push(item.id)
                            })
                            Gallery.batchDelete(ids, function () {
                                MessageService.success("去重成功！");
                                vm.maxId = null;
                                vm.minId = null;
                                vm.totalPhotoItems = null;
                                $scope.photos = [];
                                vm.duplicateList=[];
                                vm.indexNum = 0;
                                if(vm.folderId){
                                    loadAll(vm.folderId);
                                } else {
                                    loadAll('isNull');
                                }
                                if (bigSizePics.length >0) {
                                    thumbnailatorPic();
                                }
                            }, function (error) {
                                MessageService.error("删除失败！");
                                vm.duplicateList=[];
                                if (bigSizePics.length >0) {
                                    thumbnailatorPic();
                                }
                            });
                        }
                    },function (){
                        vm.duplicateList=[];
                        if (bigSizePics.length >0) {
                            thumbnailatorPic();
                        }
                    })
                }else{
                    if (bigSizePics.length >0) {
                        thumbnailatorPic();
                    }
                }
            }
        }
        $scope.duplicateChange = function (){
            vm.isDuplicateList = false;
            vm.duplicateList=[];
            vm.maxId = null;
            vm.minId = null;
            vm.totalPhotoItems = null;
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            $scope.photos = [];
            vm.indexNum = 0;
            if(vm.folderId){
                loadAll(vm.folderId);
            } else {
                loadAll('isNull');
            }
            if (bigSizePics.length >0) {
                thumbnailatorPic();
            }
        }

        function thumbnailatorPic(){
            $uibModal.open({
                templateUrl: 'app/entities/page/thumbnailator.html',
                controller: 'ThumbnailatorController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return {
                            files: bigSizePics
                        };
                    }]
                }
            }).result.then(function (values) {
                bigSizePics=[];
                if(values){
                    var _uploadIndex = 0;
                    for (var j = 0; j < values.length; j++) {
                        var value = values[j];
                        var data = {key:value.key,hash:value.hash};
                        Gallery.upload({
                            hash: data.hash,
                            identifiers: data.key,
                            provider: "QINIU",
                            fileName: value.name,
                            imgSize: value.size,
                            width: value.width,
                            height: value.height,
                            folderId: vm.folderId ? vm.folderId : ''
                        }, function (resp) {
                            if(resp.duplicate){
                                resp.identifier= $scope.domain + resp.identifier;
                                vm.duplicateList.push(resp)
                            }
                            _uploadIndex++
                            if(_uploadIndex == values.length){
                                if(vm.duplicateList.length>0){
                                    MessageService.confirm({
                                        title :'提示',
                                        cancel :'取消',
                                        confirm :'确认',
                                        msg :'检测到图库中有'+vm.duplicateList.length+'张重复图片，是否需要自动去重？',
                                        html: '<span class="themecolor">查看重复图片</span>'
                                    }, function(res) {
                                        if(res == 'htmlClick'){
                                            vm.isDuplicateList = true;
                                        }else{
                                            var ids = [];
                                            vm.duplicateList.forEach(function (item){
                                                ids.push(item.id)
                                            })
                                            Gallery.batchDelete(ids, function () {
                                                MessageService.success("去重成功！");
                                                $scope.gallaryPic = [];
                                                $scope.moveGalleryIds = [];
                                                vm.indexNum = 0;
                                                vm.totalPhotoItems = null;
                                                vm.maxId = null;
                                                vm.minId = null;
                                                $scope.photos = [];
                                                vm.duplicateList=[];
                                                if (vm.folderId) {
                                                    loadAll(vm.folderId);
                                                } else {
                                                    loadAll('isNull');
                                                }
                                            }, function (error) {
                                                MessageService.error("删除失败！");
                                                vm.duplicateList=[];
                                            });
                                        }
                                    },function (){
                                        vm.duplicateList=[];
                                    })
                                }else{
                                    $scope.gallaryPic = [];
                                    $scope.moveGalleryIds = [];
                                    vm.indexNum = 0;
                                    vm.totalPhotoItems = null;
                                    vm.maxId = null;
                                    vm.minId = null;
                                    $scope.photos = [];
                                    if (vm.folderId) {
                                        loadAll(vm.folderId);
                                    } else {
                                        loadAll('isNull');
                                    }
                                }
                            }
                        }, function () {
                            vm.picLoading = false;
                            MessageService.error("上传失败");
                        });
                    }
                    vm.picLoading = false;
                    setTimeout(function () {
                        angular.forEach($scope.photos, function (item, z) {
                            item.index = z;
                        });
                    }, 400)
                }
            }, function(){
                vm.picLoading = false;
            });
        }

        //转移照片
        $scope.moveToFolder = function () {
            if ($scope.gallaryPic.length < 1) {
                MessageService.error("请至少选择一张图片");
                return
            }
            // vm.isMoveToFolder = true;
            createModal2('app/entities/web-folder/entity-move-folder.html', 'EntityMoveFolderController', $scope.moveGalleryIds, "Gallery",vm.folderId).result.then(
                function() {
                    Folder.getAllByConditionAndQuantity({type:"Gallery"},function(res){
                            $scope.Folders = res.message;
                            angular.forEach($scope.Folders, function (f, i) {
                                f.selected = false;
                            })
                            vm.unusedQuantity = null;

                            vm.maxId = null;
                            vm.minId = null;
                            vm.totalPhotoItems = null;
                            $scope.gallaryPic = [];
                            $scope.moveGalleryIds = [];
                            vm.indexNum = 0;
                            $scope.photos = [];
                            if (vm.folderId) {
                                loadAll(vm.folderId);
                            } else {
                                loadAll('isNull');
                            }
                    });
                }
            );
        }
        var createModal2 = function(url, controller, ids, type, folderId) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: {
                        type: type,
                        ids: ids,
                        folderId: folderId
                    },
                }
            });
        };
        $scope.chooseFolder = function (folder, index) {
            vm.selectFolderId = null;
            folder.selected = !folder.selected;
            angular.forEach($scope.Folders, function (f, i) {
                if (index != i) {
                    f.selected = false;
                }
            })
            if (folder.selected) {
                vm.selectFolderId = folder.id;
            }
        }
        $scope.saveMoveToFolder = function () {
            if (vm.selectFolderId == null) {
                MessageService.error("请选择文件夹！");
                return
            }
            $scope.moveGalleryIds = [];
            angular.forEach($scope.gallaryPic, function (p, i) {
                $scope.moveGalleryIds.push(p.id);
            })

            Gallery.moveFolder({
                galleries: $scope.moveGalleryIds,
                folderId: vm.selectFolderId
            }, function () {
                angular.forEach($scope.Folders, function (f, i) {
                    f.selected = false;
                })
                $scope.photos = [];
                vm.isMoveToFolder = false;
                if (vm.folderId) {
                    loadAll(vm.folderId);
                } else {
                    loadAll('isNull');
                }
            })
        }

        //手机传图
        vm.qrcodeShow = false;
        vm.fid = 0;
        var qrcode = null;
        var phonetimer = null;
        var newCreateDate = 0;
        $scope.uploadByPhone = function () {
            uploadVerify(null,CustomerUploadAble, vm.storeId, vm.customer.id, function () {
                vm.qrcodeShow = !vm.qrcodeShow;
                if (qrcode != null) {
                    // qrcode.clear();
                    $('#qrcode1').html("");
                }

                //vm.folderId -----某一文件夹信息
                if (vm.folderId == undefined || vm.folderId == null) {
                    vm.fid = 0;
                } else {
                    vm.fid = vm.folderId;
                }

                // 手机传图二维码
                qrcode = $('#qrcode1').qrcode({
                    render: "canvas",
                    width: 150,
                    height: 150,
                    text: '' + vm.platformSetting.storeNotifyUrl + "/#/" + vm.storeId + "/" + vm.fid + "/gallery/" + vm.userId
                });
                // (new Date("2012/12/25 20:11:11")).getTime()

                // if (vm.qrcodeShow) {
                //     phonetimer = setInterval(function () {
                //         var gallery_items = [{key: "storeId", op: "=", value: vm.storeId}];
                //         if (vm.folderId) {
                //             gallery_items.push({key: "folderId", op: "=", value: vm.folderId});
                //         } else {
                //             gallery_items.push({key: "folderId", op: "isNull", value: 1});
                //         }
                //         Gallery.getPage({
                //             items: gallery_items,
                //             page: 0,
                //             size: vm.itemsPerPage,
                //             sort: ["createdDate,desc"]
                //         }, onSuccessToPhone, onError);
                //     }, 3000);
                // } else {
                //     clearInterval(phonetimer);
                //     phonetimer = null;
                // }
            }, function () {
                getStorageBuy()
            })
        }

        $scope.getStorageBuy = getStorageBuy;
        function getStorageBuy(){
            $uibModal.open({
                templateUrl: "app/entities/web-gallery/storage-buy.html",
                controller: "StorageBuyController",
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                }
            }).result.then(function() {
                getByCustomer();
            })
        }
        function onSuccessToPhone(data, headers) {
            vm.totalPhotoItems = headers('X-Total-Count');
            if (data == null || data.length == 0) {
                return;
            }
            var loadCreateDate = new Date(data[0].createdDate).getTime();
            for (var i = 0; i < data.length; i++) {
                var d = data[i];
                if (new Date(d.createdDate).getTime() > newCreateDate) {
                    d.identifier = $scope.domain + d.identifier;
                    d.select = false;
                    d.index = i;
                    $scope.photos.unshift(d);
                } else {
                    newCreateDate = loadCreateDate;
                    break;
                }
            }
            if (newCreateDate == 0) {
                newCreateDate = loadCreateDate;
            }
        }
        $scope.uploadByPhone1 = function(){
            vm.qrcodeShow = false;
            clearInterval(phonetimer);
            $scope.gallaryPic = [];
            $scope.moveGalleryIds = [];
            vm.indexNum = 0;
            vm.maxId = null;
            vm.minId = null;
            $scope.photos = [];
            if (vm.folderId) {
                loadAll(vm.folderId);
            } else {
                loadAll('isNull');
            }
        }
        /***********************  照片结束  ****************************/


        //全屏
        $scope.toggleFullScreen = function(){
            if (!vm.fullFlag) {
                //进入全屏
                var de = document.documentElement;
                if (de.requestFullscreen) {
                    de.requestFullscreen();
                } else if (de.mozRequestFullScreen) {
                    de.mozRequestFullScreen();
                } else if (de.webkitRequestFullScreen) {
                    de.webkitRequestFullScreen();
                }
                vm.fullFlag = true;
            } else {
                //退出全屏
                var de = document;
                if (de.exitFullscreen) {
                    de.exitFullscreen();
                } else if (de.mozCancelFullScreen) {
                    de.mozCancelFullScreen();
                } else if (de.webkitCancelFullScreen) {
                    de.webkitCancelFullScreen();
                }
                vm.fullFlag = false;
            }
        }
        //删除
        $scope.deleteBox = function ($event) {
            vm.data.splice(vm.pageIndex, 1);
            vm.pageSum = vm.data.length - 1 < 0 ? 0 : vm.data.length - 1;
            if(vm.pageIndex > vm.pageSum){
                vm.pageIndex = vm.pageSum;
            }
        }
        $scope.subtractDeleteTab = function($event, index){
            fn($event)
            vm.data.splice(index, 1);
            vm.pageSum = vm.data.length - 1 < 0 ? 0 : vm.data.length - 1;
            if(vm.pageIndex > vm.pageSum){
                vm.pageIndex = vm.pageSum;
            }
            photosNum();
        }

        $scope.quantityChange = function (data) {
            // 正则表达式，匹配正整数
            var regex = /^[1-9]\d*$/;
            // 如果输入值不符合正则表达式，则清空输入
            if (!regex.test(data.quantity)) {
                data.quantity = 1;
            }
        }
        $scope.subtractQuantityTab = function ($event, data) {
            fn($event)
            if(data.quantity>1){
                data.quantity--;
            }
        }
        $scope.pushQuantityTab = function ($event, data) {
            fn($event)
            if (vm.store.orderWay == 'editorThenOrder' && vm.productActiveSku.jhiPackage && vm.productActiveSku.packageQuantity <= $scope.photoSeveNumber()) {
                MessageService.error("数量已满" + vm.productActiveSku.packageQuantity + "张，无法继续添加");
                return
            }
            data.quantity++;
        }
        //复制背面
        vm.isCopyBack = false;
        vm.copyBackIndex == null;
        $scope.copyBack = function($event, index){
            fn($event)
            vm.isCopyBack = true;
            vm.copyBackIndex = index;
            console.log(vm.copyBackIndex)
        }
        $scope.copyHide = function(){
            vm.isCopyBack = false;
            vm.copyBackIndex == null;
        }
        $scope.copyAll = function(){
            vm.data.forEach(function(data,dataIndex){
                if(dataIndex != vm.copyBackIndex){
                    data[1] = JSON.parse(JSON.stringify(vm.data[vm.copyBackIndex][1]));
                    data[1].uuid = uuid();
                }
            })
            MessageService.success("复制成功")
            $scope.copyHide();
            photosNum();
        }
        $scope.editorPageCopyHere = function(index){
            vm.data[index][1] = JSON.parse(JSON.stringify(vm.data[vm.copyBackIndex][1]));
            vm.data[index][1].uuid = uuid();
            MessageService.success("复制成功")
            photosNum();
        }
        //保存
        function articleOverdue(callback){
            if($stateParams.orderArticleId){
                OrderArticle.byUser({id: $stateParams.orderArticleId}, function (article) {
                    if(article.fileName != vm.article.fileName){
                        MessageService.confirm({
                            title:"提示",
                            cancel:"取消",
                            confirm:"刷新",
                            msg:"保存失败，当前作品不是最新版本，请刷新后再操作。"
                        },function(){
                            location.reload();
                        })
                    }else{
                        callback()
                    }
                })
                return
            }
            if(vm.article.id) {
                Article.byUser({id: vm.article.id}, function (article) {
                    if(article.fileName != vm.article.fileName){
                        MessageService.confirm({
                            title:"提示",
                            cancel:"取消",
                            confirm:"刷新",
                            msg:"保存失败，当前作品不是最新版本，请刷新后再操作。"
                        },function(){
                            location.reload();
                        })
                    }else{
                        callback()
                    }
                })
                return
            }
            callback()
        }
        vm.isPageWarnExamine = false;
        $scope.save = function () {
            articleOverdue(function () {
                var _data = [];
                var _data1 = angular.copy(vm.data);
                if(vm.pictureType == 'single'){
                    angular.forEach(_data1, function (item) {
                        for(var i=0; i<item.quantity; i++){
                            var itemData = angular.copy(item);
                            _data.push(itemData)
                        }
                    });
                    _data.forEach(function (data) {
                        data.quantity = 1;
                    })
                }
                if(vm.pictureType == 'spread'){
                    _data1.forEach(function (data) {
                        for(var i=0; i<data[0].quantity; i++){
                            _data.push(data[0])
                            _data.push(data[1])
                        }
                    })
                    _data.forEach(function (data) {
                        data.quantity = 1;
                    })
                }
                for (var i = 0; i < _data.length; i++) {
                    var _page = _data[i];
                    if (_page.levels.level) {
                        for (var j = 0; j < _page.levels.level.length; j++) {
                            var _level = _page.levels.level[j];
                            if (_level.imagebox && _level.imagebox.image) {
                                if (!_level.imagebox.image.resource || !_level.imagebox.image.resource.identifier) {
                                    MessageService.error('有照片框未填充照片，无法保存');
                                    return false
                                }
                            }
                        }
                    }
                }
                var _imageInfoExamine = imageInfoExamine(_data);
                if(_imageInfoExamine.length>0){
                    MessageService.error('系统检测到当前作品第'+_imageInfoExamine.join('，')+'页的图片存在异常，请修正再保存');
                    return
                }
                if(!vm.isPageWarnExamine && pageWarnExamine(_data, vm.imgSize, vm.imgDpi)&& !$stateParams.source){
                    vm.isPageWarnExamine = true;
                    MessageService.confirm({
                        title :'印刷风险检测',
                        cancel :'去修改',
                        confirm :'继续保存',
                        msg :'系统检测到当前作品存在像素不足（不清晰）的图片，请您确认是否保存？'
                    }, function() {
                        $scope.save()
                    })
                    return
                }
                if(vm.store.orderWay == 'orderThenEditor' && !$stateParams.orderArticleId){
                    if(judgeQuantity(_data)){
                        return false
                    }
                }
                // 先编辑模式设置成套判断数量
                if (vm.store.orderWay == 'editorThenOrder' && vm.productActiveSku.jhiPackage) {
                    if (judgeQuantity2(_data)) {
                        return false
                    }
                }
                // 先编辑模式非成套判断数量
                if (vm.store.orderWay == 'editorThenOrder' && !vm.productActiveSku.jhiPackage) {
                    var quantity=0;
                    _data.forEach(function (item) {
                        quantity += item.quantity
                    })
                    if (quantity == 0) {
                        MessageService.error("请先进行定制哦~");
                        return
                    }
                    vm.completed = true;
                }
                if(!vm.placeOrder){
                    return
                }


                vm.deleteList = 0;
                var  gallieryIds = [];
                for (var i = _data.length - 1; i >= 0; i--) {
                    var page = _data[i];
                    if (page.backup || page.levels == null || page.levels.level == null || page.levels.level.length == 0) {
                        continue;
                    }
                    for (var j = page.levels.level.length - 1; j >= 0; j--) {
                        var level = page.levels.level[j];
                        if (level.imagebox == null || level.imagebox.image == null || !level.imagebox.image.resourceid) {
                            continue;
                        }
                        gallieryIds.push(level.imagebox.image.resourceid);
                    }
                }
                if (gallieryIds.length>0 && !$stateParams.orderArticleId) {
                    Gallery.checkDeleted(gallieryIds, function(list){
                        // vm.deleteList = [{id:gallieryIds[0]}];
                        // vm.completed = false;
                        // vm.isDestroyed = true;
                        if (list == null|| list.length ==0) {
                            vm.threeLoading = true;
                            thumbnailInit1(function (){
                                save1()
                            })
                        }else{
                            vm.deleteList = list;
                            vm.completed = false;
                            vm.isDestroyed = true;
                            MessageService.confirm({
                                title :'系统检测',
                                cancel :'去修改',
                                confirm :'继续保存',
                                msg :'检测出'+vm.deleteList.length+'张图片已从图库被删除，作品中相关图片将被清理，是否继续保存作品？'
                            }, function() {
                                $scope.galleryDestroyConfirm(vm.saveflag)
                            },function(){
                                $scope.removeGallery();
                            })
                            return;
                        }
                    }, function(){
                        MessageService.error("检测图片失败，请稍后再试。");
                    })
                }else{
                    vm.threeLoading = true;
                    thumbnailInit1(function (){
                        save1()
                    })
                }
            })
        }

        $scope.removeGallery = function(){
            if (vm.deleteList == null || vm.deleteList.length == 0) {
                return;
            }
            for (var z = vm.deleteList.length - 1; z >= 0; z--) {
                var g = vm.deleteList[z];
                for (var i = $scope.template.pages.page.length - 1; i >= 0; i--) {
                    var page = $scope.template.pages.page[i];
                    if (page.backup || page.levels == null || page.levels.level == null || page.levels.level.length == 0) {
                        continue;
                    }
                    for (var j = page.levels.level.length - 1; j >= 0; j--) {
                        var level = page.levels.level[j];
                        if (level.imagebox == null || level.imagebox.image == null || !level.imagebox.image.resourceid) {
                            continue;
                        }
                        if (level.imagebox.image.resourceid == g.id) {
                            level.imagebox.image.resource.identifier = "";
                            level.imagebox.image.resource.adaptation = "";
                            level.imagebox.image.resource.provider = "";
                            level.imagebox.image.resource.offsetx = 0;
                            level.imagebox.image.resource.offsety = 0;
                            level.imagebox.image.resource.height = "";
                            level.imagebox.image.resource.width = "";
                            level.imagebox.image.resource.scale = 1;
                            level.imagebox.image.resource.imgSize = "";
                            level.imagebox.image.resourceid = null;
                            level.imagebox.image.imageInfo = null;
                            break;
                        }
                    }
                }
            }
            photosNum();
        }

        $scope.galleryDestroyConfirm = function(){
            $scope.removeGallery();
            vm.threeLoading = true;
            thumbnailInit1(function (){
                save1();
            })
        };

        function save1(){
            var _data = [];
            var _data1 = angular.copy(vm.data);
            if(vm.pictureType == 'single'){
                angular.forEach(_data1, function (item) {
                    for(var i=0; i<item.quantity; i++){
                        var itemData = angular.copy(item);
                        _data.push(itemData)
                    }
                });
                _data.forEach(function (data) {
                    data.quantity = 1;
                })
            }
            if(vm.pictureType == 'spread'){
                _data1.forEach(function (data) {
                    for(var i=0; i<data[0].quantity; i++){
                        _data.push(data[0])
                        _data.push(data[1])
                    }
                })
                _data.forEach(function (data) {
                    data.quantity = 1;
                })
            }
            if(_data && _data.length > 0){
                var _page = [];
                angular.forEach(_data, function (item) {
                    for(var i=0; i<item.quantity; i++){
                        var _item = angular.copy(item);
                        _page.push(_item)
                    }
                })
                $scope.template.pages.page = _page;
                $scope.template.hostinfo = hostInfo();
                $scope.template = documentMM($scope.template, $scope.domain);
                vm.article.jhiPackage = vm.productActiveSku.jhiPackage;
                vm.article.packageQuantity = vm.productActiveSku.packageQuantity;
                vm.article.pictureType = vm.pictureType;
                if (vm.isEdit) {
                    vm.article.document.pages.page = $scope.template.pages.page;
                    vm.article.document.galleries.gallery = vm.photoSelectAll;
                    vm.article.completed = vm.completed;
                    if($stateParams.orderArticleId){
                        OrderArticle.update(vm.article, function (resp) {
                            MessageService.success("保存成功");
                            $scope.threeLoading = false;
                            if($stateParams.returnUrl){
                                window.location = $stateParams.returnUrl;
                                return
                            }
                            $state.go("page-view2", {storeId: vm.storeId, orderArticleId: $stateParams.orderArticleId, source: 'external'}, {reload: true});
                        }, function (resp) {
                            $scope.threeLoading = false;
                            MessageService.error("保存失败");
                        });
                        return
                    }
                    Article.update(vm.article, function (resp) {
                        MessageService.success("保存成功");
                        $scope.threeLoading = false;
                        if(vm.store.orderWay == 'orderThenEditor'){
                            works(resp)
                        }
                        $state.go("page-view", {storeId: vm.storeId, aid: resp.id, soId:vm.salesOrderId, plId:vm.productLineId, returnUrl:$stateParams.returnUrl}, {reload: true});
                    }, function (resp) {
                        $scope.threeLoading = false;
                        MessageService.error("保存失败");
                    });
                }
                else{
                    var product=vm.finalArray[vm.currentNavtab].item;
                    var _document = $scope.template;
                    _document.galleries = {gallery:vm.photoSelectAll};
                    _document.replace = true;
                    var uploadData={
                        categoryId:product.categoryId,
                        categoryName: product.categoryName,
                        categoryStoreId: product.categoryStoreId,
                        categoryStoreName: product.categoryStoreName,
                        completed:vm.completed,
                        document:_document,
                        editorType: product.productEditorType,
                        itemValueId: product.id,
                        name: product.productName,
                        productId: product.productId,
                        productName: product.productName,
                        sku: product.sku,
                        skuNumber: product.skuNumber,
                        itemValueLineId: product.itemValueLines[0].id,
                        lineSku: product.itemValueLines[0].sku,
                        lineSkuNumber: product.itemValueLines[0].skuNumber,
                        productType: product.productType,
                        productXmlId: vm.article.productXmlId,
                        productXmlName: vm.article.productXmlName,

                        cover: vm.article.cover,
                        themeClassifyId: vm.article.productXmlThemeId,
                        themeClassifyName: vm.article.productXmlThemeName,
                        musicIdentify: vm.article.musicIdentify,
                        jhiPackage: vm.article.jhiPackage,
                        packageQuantity: vm.article.packageQuantity,
                        pictureType: vm.article.pictureType,
                    }

                    // var itemValue1=vm.typeImageData.itemValues[vm.currentNavtab];
                    // for (var i = 0; i < itemValue1.productXmlLines.length; i++) {
                    //     if (!itemValue1.productXmlLines[i].cancelled) {
                    //         uploadData.cover = itemValue1.productXmlLines[i].coverImg;
                    //         uploadData.themeClassifyId = itemValue1.productXmlLines[i].productXmlThemeId;
                    //         uploadData.themeClassifyName = itemValue1.productXmlLines[i].productXmlThemeName;
                    //         uploadData.productXmlId = itemValue1.productXmlLines[i].productXmlId;
                    //         uploadData.productXmlName = itemValue1.productXmlLines[i].productXmlName;
                    //         uploadData.musicIdentify = itemValue1.productXmlLines[i].musicIdentify;
                    //         break
                    //     }
                    // }
                    Article.save(uploadData, function (resp) {
                        MessageService.success("保存成功");
                        $scope.threeLoading = false;
                        if(vm.store.orderWay == 'orderThenEditor'){
                            works(resp)
                        }
                        $state.go("page-view", {storeId: vm.storeId, aid: resp.id, soId:vm.salesOrderId, plId:vm.productLineId, returnUrl:$stateParams.returnUrl}, {reload: true});
                    }, function (resp) {
                        $scope.threeLoading = false;
                        MessageService.error("保存失败");
                    });
                }

            }
        }

        $scope.isContinue = function (pages) {
            var hasEmpty = true;
            vm.unFilledCount = 0;
            for(var i=0; i<pages.length; i++){
                var _page = pages[i];
                if(_page.levels.level){
                    for(var j=0; j<_page.levels.level.length; j++){
                        var _level = _page.levels.level[j];
                        if(_level.imagebox&&_level.imagebox.image){
                            if(_level.imagebox.image.resource&& _level.imagebox.image.resource.identifier){
                            }else {
                                hasEmpty = false;
                                vm.unFilledCount++
                            }
                        }
                    }
                }
            }
            vm.completed = hasEmpty ? 'COMPLETED' : 'EDITING';
            return hasEmpty;
        };
        function works(article){
            if (vm.isEdit) {
                SalesOrder.updateByArticle({
                    id: vm.salesOrderId,
                    productLineId: vm.productLineId,
                    articleId: article.id
                })
            }else{
                SalesOrder.updateArticle({
                    id: vm.salesOrderId,
                    productLineId: vm.productLineId,
                    articleId: article.id,
                    copy: 1,
                })
            }
        }

        //先下单后编辑 判断照片数量是否超过打印数量
        function judgeQuantity(data) {
            var retuenType = false;
            var quantity=0;
            data.forEach(function (item) {
                quantity += (item.quantity - 0)
            })
            if(vm.pictureType == 'spread'){
                quantity = quantity/2
            }
            if(quantity > vm.productLine.quantity){
                retuenType = true;
                MessageService.error("订单可提交冲印数量为"+vm.productLine.quantity+"张，当前数量为"+quantity+"，请删除多余照片再保存。");
            }
            if(quantity == vm.productLine.quantity){
                vm.completed = true;
            }else{
                vm.completed = false;
            }
            return retuenType
        }
        //編輯编辑 判断照片数量是否满足条件
        function judgeQuantity2(data) {
            var retuenType = false;
            var quantity=0;
            data.forEach(function (item) {
                quantity += item.quantity
            })
            if (quantity == 0) {
                MessageService.error("请先进行定制哦~");
                return
            }
            if(vm.pictureType == 'spread'){
                quantity = quantity/2
            }
            if(quantity != vm.productActiveSku.packageQuantity){
                vm.completed = false;
                retuenType = true;
                MessageService.error("冲印数量需等于" + vm.productActiveSku.packageQuantity + "张，无法提交保存");
            }else{
                vm.completed = true;
            }
            return retuenType
        }
        $scope.exitWork = function (e) {
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'退出',
                msg :'确认要退出吗？'
            }, function() {
                $scope.exitPage()
            })
        }
        $scope.exitPage = function () {
            localStorage.removeItem("photoSelectAll");
            if($stateParams.orderArticleId){
                window.opener=null;
                window.open('','_self');
                window.close();
                // $state.go("page-view2", {storeId: vm.storeId, orderArticleId: $stateParams.orderArticleId, source: 'external'}, {reload: true});
                return
            }
            if($stateParams.returnUrl){
                window.location = $stateParams.returnUrl;
                return
            }
            if(vm.store.orderWay == 'orderThenEditor'){
                $state.go("sales-order-manager", {id:vm.salesOrderId, plid:vm.productLineId, storeId: vm.storeId});
                return;
            }
            window.history.go(-1);
        }
        //像素不足提醒
        vm.imgSize = 150;
        vm.imgDpi = 150;
        StoreConfig.getAllByTypes({storeId: vm.storeId,type: ["IMGSIZE", "IMGDPI"]}, function (res) {
            if(res.status == 200){
                res.message.forEach(function (item) {
                    if(item.type === 'IMGSIZE'){
                        vm.imgSize = item.value;
                    }
                    if(item.type === 'IMGDPI'){
                        vm.imgDpi = item.value;
                    }
                })
            }
        });
        //生成缩略图
        function thumbnailInit1(callback) {
            $timeout(function (){
                html2canvas_adapt(document.getElementById('thumbnail-view'), {
                    onrendered: function (canvas) {
                        if(!canvas){
                            vm.article.covered=false;
                            vm.article.cover=null;
                            callback();
                            return
                        }
                        var url = canvas.toDataURL("image/png");
                        var blob = dataURLtoBlob(url);
                        var formData = new FormData();
                        formData.append('file', blob);
                        formData.append('key', '3dThumbnail-' + UidService.get());
                        formData.append('token', vm.token);
                        $.ajax({
                            cache: true,
                            type: "POST",
                            url: 'https://upload.qiniup.com/',
                            data: formData,
                            async: false,
                            contentType: false,
                            processData: false,
                            success: function (data) {
                                var identifier = data.key;
                                vm.article.covered=true;
                                vm.article.cover=identifier;
                                callback(identifier)
                            }
                        })
                    },
                    letterRendering: true,
                    useCORS: true,
                    width: vm.pageWidth * vm.ratio1,
                    height: vm.pageHeight * vm.ratio1,
                    windowWidth: document.body.scrollWidth,
                    windowHeight: document.body.scrollHeight,
                    x: 0,
                    y: 0,
                    backgroundColor: "rgba(0, 0, 0, 0)"
                });
            })
        };
        //边框
        $scope.imageboxW = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.width) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.width
                    // return Number(imagebox.geometry.width) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100)
                }
            } else {
                return imagebox.geometry.width
            }
        }
        $scope.imageboxH = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.height) + Number(imagebox.border.lineWidth) * 2
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.height
                    // return Number(imagebox.geometry.height) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100) + (Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100)
                }
            } else {
                return imagebox.geometry.height
            }
        }
        $scope.imageboxL = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.x) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.x
                    // return Number(imagebox.geometry.x) - (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100)
                }
            } else {
                return imagebox.geometry.x
            }
        }
        $scope.imageboxT = function (imagebox) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.geometry.y) - Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    return imagebox.geometry.y
                    // return Number(imagebox.geometry.y) - (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100)
                }
            } else {
                return imagebox.geometry.y
            }
        }
        $scope.imageboxWEdge = function (imagebox, edge) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                if (imagebox.border.type == 'Color') {
                    return Number(imagebox.border.lineWidth)
                }
                if (imagebox.border.type == 'Pic') {
                    if (edge == 'left') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100
                    }
                    if (edge == 'top') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100
                    }
                    if (edge == 'right') {
                        return Number(imagebox.geometry.width) * Number(imagebox.border.edge.right) / 100
                    }
                    if (edge == 'bottom') {
                        return Number(imagebox.geometry.height) * Number(imagebox.border.edge.bottom) / 100
                    }
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius = function (imagebox, radius, ratio, ratio1) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var _ratio = 1;
                if(ratio && ratio1){
                    if(imagebox.geometry.width > imagebox.geometry.height){
                        _ratio = ratio1
                    }else{
                        _ratio = ratio
                    }
                }
                if(ratio && !ratio1){
                    _ratio = ratio
                }
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return _radius > 0 ?  (_radius + Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return _r > 0 ? (_r + Number(imagebox.border.lineWidth)/2) * _ratio : 0
                }
            } else {
                return 0
            }
        }
        $scope.imageboxWRadius1 = function (imagebox, radius, ratio, ratio1) {
            if (!imagebox) {
                return
            }
            if (imagebox.border) {
                var _ratio = 1;
                if(ratio && ratio1){
                    if(imagebox.geometry.width > imagebox.geometry.height){
                        _ratio = ratio1
                    }else{
                        _ratio = ratio
                    }
                }
                if(ratio && !ratio1){
                    _ratio = ratio
                }
                if (imagebox.border.shape == 'Ellipse') {
                    return '50%'
                }
                if (imagebox.border.radius.filletType == 'Fixed') {//圆角固定值
                    var _radius = imagebox.border.radius[radius];
                    return  (_radius - Number(imagebox.border.lineWidth)/2) > 0 ? (_radius - Number(imagebox.border.lineWidth)/2) * _ratio : 0;
                }else{
                    var radiusW = imagebox.geometry.width > imagebox.geometry.height ? imagebox.geometry.height : imagebox.geometry.width;
                    radiusW = radiusW + imagebox.border.lineWidth;
                    var _r = radiusW * imagebox.border.radius[radius] / 100;
                    return (_r - Number(imagebox.border.lineWidth)/2) > 0 ? (_r - Number(imagebox.border.lineWidth)/2) * _ratio : 0
                }
            } else {
                return 0
            }
        }
        $scope.dropShadow = function(imagebox,ratio,ratio1){
            if (imagebox.shadow == null) {
                return
            }
            var width = $scope.imageboxW(imagebox) * ratio;
            var height =$scope.imageboxH(imagebox) * ratio1;
            return dropShadow(imagebox,width,height)
        }
        $('#navId').on('scroll', function(event){
            if(vm.nav=='picture'){
                var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
                if($scope.photos.length < vm.totalPhotoItems){
                    if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                        vm.indexNum++;
                        if(vm.folderId){
                            loadAll(vm.folderId);
                        } else {
                            loadAll('isNull');
                        }
                    }
                }
            }
        });
        $scope.editorPageDialog = function (index,i) {
            vm.editorPageList = [];
            if(vm.pictureType == 'single'){
                vm.editorPageList = vm.data;
                vm.pageIndex = index+i;
            }
            if(vm.pictureType == 'spread'){
                vm.data.forEach(function (data) {
                    data.forEach(function (page) {
                        vm.editorPageList.push(page)
                    })
                })
                vm.pageIndex = index*2+i;
            }
            if(vm.article.editorType == 'PICTURE_PRO'){
                vm.editorDialog1 = true;
            }else{
                vm.editorDialog = true;
                vm.levelIndex = 0;
            }
        }
        $scope.pagePhotoCb = function (text){
            vm.editorDialog1 = false;
            if(text){
                if(vm.pictureType == 'single'){
                    vm.data[vm.pageIndex] = JSON.parse(JSON.stringify(text))
                }
                if(vm.pictureType == 'spread'){
                    var i=0;
                    vm.data.forEach(function (data) {
                        for(var j=0;j<2;j++){
                            if(i == vm.pageIndex){
                                data[j] = JSON.parse(JSON.stringify(text))
                            }
                            i++
                        }
                    })
                }
                photosNum();
            }
            ondragenter();
        }
        $scope.zfInit = function(){
            if(vm.pictureType == 'spread'){
                if (vm.pageIndex % 2 === 0){
                    return false
                } else if (vm.pageIndex % 2 === 1){
                    return true
                }
            }
        }
        //图片框缩放
        window.bbimg = function (delta) {
            if (!vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex] || vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.bokehtype != 'None') {
                return
            }
            var _image = angular.copy(vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image);
            if (delta < 0 || delta == undefined && event.wheelDelta > 0) {
                vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.scale *= 1.01;
            } else {
                vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.scale /= 1.01;
                if (vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.scale <= 1) {
                    vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.image.scale = 1;
                }
            }
            ImageboxCb(_image, vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox, vm.data[vm.pageIndex]);
            $scope.$apply();
            return false;
        };
        //图片框的操作
        $scope.imageboxSite = function (item) {
            if (!vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex] || vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox.bokehtype != 'None') {
                return
            }
            var _imagebox = vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox;
            if(item == 'center'){
                _imagebox.image.offsetx = -(_imagebox.image.width*_imagebox.image.scale - _imagebox.geometry.width)/2/_imagebox.image.scale;
                _imagebox.image.offsety = -(_imagebox.image.height*_imagebox.image.scale - _imagebox.geometry.height)/2/_imagebox.image.scale;
            }
            if(_imagebox.image.offsetx > 0){
                _imagebox.image.offsetx = 0;
            }
            if(_imagebox.image.offsety > 0){
                _imagebox.image.offsety = 0;
            }
        }
        $scope.rotateImagebox = function () {
            imageboxRotate($http,$scope, vm.editorPageList[vm.pageIndex].levels.level[vm.levelIndex].imagebox,!$scope.zfInit());
        }

        //拖拽图片
        ondragenter();
        function ondragenter(){
            $timeout(function () {
                var content = $(".editor-photo-body")[0]
                if(content){
                    content.ondragenter = ignoreDrag;
                    // 拖动文件的鼠标指针位置放置区之上时发生
                    content.ondragover = ignoreDrag;
                    content.ondrop = drop;
                }
            },1000)
    
            window.dragStart = function (event) {
                var target = event.target;
                event.dataTransfer.setData("img", target.id);
                $timeout(function () {
                    vm.dragDom = true;
                })
            };
            window.dragOn = function(event){
                $timeout(function () {
                    vm.dragDom = false;
                })
            }
        }
        function ignoreDrag(e) {
            // 确保其他元素不会取得该事件
            e.stopPropagation();
            e.preventDefault();
            var dataset = e.target.dataset;
            if ((e.target.nodeName == 'IMG')&&dataset.imgindex) {
                var textIndex = dataset.imgindex;
                $(".imageboxView").removeClass('selected-blue')
                var pageIndex2 = $(e.target).parents('.c-page-view-li-img').attr('page-index2');
                $(".imageboxView"+pageIndex2+textIndex).addClass('selected-blue');
            }else{
                $(".imageboxView").removeClass('selected-blue')
            }
        }
        vm.replacePageIndex = 0;
        vm.replacePageArrIndex = 0;
        vm.replaceTextIndex = 0;
        vm.replacePageIndex2 = 0;
        function drop(e) {
            var data = e.dataTransfer.getData("img");
            $timeout(function () {
                var _pageArrIndex = $(e.target).parents('.c-page-view-li-img').attr('data-pageArrIndex');
                var _pageIndex = $(e.target).parents('.c-page-view-li-img').attr('data-pageIndex');
                var _pageIndex2 = $(e.target).parents('.c-page-view-li-img').attr('page-index2');
                var dataset = e.target.dataset;
                var textIndex = dataset.imgindex;
                vm.replacePageIndex = _pageIndex;
                vm.replacePageArrIndex = _pageArrIndex;
                vm.replaceTextIndex = textIndex;
                vm.replacePageIndex2 =_pageIndex2;
                if(!_pageIndex && !_pageIndex2){
                    return
                }
                ignoreDrag(e);
                $(".imageboxView").removeClass('selected-blue');
                //拖拽图片进入占位框
                if (data.indexOf('dragtargetPhoto') != '-1') {
                    var imgindex = e.target.dataset.imgindex;
                    if(imgindex == null){
                        return
                    }else {
                        vm.levelIndex = imgindex;
                        var item = JSON.parse($('#' + data).attr('data-item'));
                        // var _imagebox = vm.data[_pageArrIndex][_pageIndex].levels.level[vm.levelIndex].imagebox;
                        // var identifier = _imagebox.mask.resource.identifier;
                        replaceImagebox(item);
                        $timeout(function () {
                            photosNum();
                        },500)
                    }
                }
            })
        }

        function replaceImagebox(data){
            var item = angular.copy(data);
            if(vm.pictureType == 'single'){
                var _imagebox = vm.data[vm.replacePageIndex2].levels.level[vm.replaceTextIndex].imagebox;
            }else{
                var _imagebox = vm.data[vm.replacePageArrIndex][vm.replacePageIndex].levels.level[vm.replaceTextIndex].imagebox;
            }
            if(!_imagebox.bokehtype){
                _imagebox.bokehtype = 'None';
            }
            _imagebox.image = {
                resource: {
                    identifier: item.identifier,
                    provider: 'qiniu'
                },
                filterResource: {
                    identifier: '',
                    provider: 'qiniu'
                },
                offsetx:0,
                offsety:0,
                resourceid:item.id,
                width: item.width,
                height: item.height,
                imageInfo: item.imageInfo,
                imgSize: item.imgSize,
                index: item.seq,
                parameter: angular.fromJson(item.parameter),
                scale: 1,
                angle: 0,
            }
            if(vm.pictureType == 'single'){
                getBlurredrectangle(_imagebox,0)

            }else{
                getBlurredrectangle(_imagebox,vm.replacePageIndex)

            }
        }


        $scope.productXmlLineClick = function(item){
            if (item.productXmlId === vm.article.productXmlId) {
                MessageService.error('已是当前模板，无法更换')
                return
            }
            vm.temporaryTemplate = item;
            MessageService.confirm({
                title :'替换模版',
                cancel :'关闭',
                confirm :'确定',
                msg :'替换模板将会保留图片，确定整体替换模板吗？'
            }, function() {
                $scope.templateReplace(vm.temporaryTemplate)
            })
        };
        $scope.templateReplace = function(data){
            var singleData = angular.copy(vm.data);
            vm.loading = true;
            vm.isTemplate = false;
            vm.templateReplace = null;
            vm.article.productXmlId = vm.temporaryTemplate.productXmlId;
            getXmlToDucoment(vm.temporaryTemplate,function () {
                // $scope.photos = [];
                vm.data = [];
                var _photo = [];
                if (vm.pictureType == 'single') {
                    singleData.forEach(function (data) {
                        var _photo1 = [];
                        data.levels.level.forEach(function (level) {
                            if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                                var photo = {
                                    identifier:level.imagebox.image.resource.identifier,
                                    width:level.imagebox.image.imgWidth,
                                    height:level.imagebox.image.imgHeight,
                                    imgSize:level.imagebox.image.size,
                                    imageInfo:level.imagebox.image.imageInfo,
                                    quantity:data.quantity,
                                    uuid:data.uuid,
                                    resourceid:level.imagebox.image.resourceid,
                                    id:level.imagebox.image.resourceid
                                }
                                _photo1.push(photo)
                            }
                        })
                        _photo.push(_photo1)
                    })
                }
                if (vm.pictureType == 'spread') {
                    singleData.forEach(function (singleData1) {
                        singleData1.forEach(function (data) {
                            var _photo1 = [];
                            data.levels.level.forEach(function (level) {
                                if(level.imagebox && level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                                    var photo = {
                                        identifier:level.imagebox.image.resource.identifier,
                                        width:level.imagebox.image.imgWidth,
                                        height:level.imagebox.image.imgHeight,
                                        imgSize:level.imagebox.image.size,
                                        imageInfo:level.imagebox.image.imageInfo,
                                        quantity:data.quantity,
                                        uuid:data.uuid,
                                        resourceid:level.imagebox.image.resourceid,
                                        id:level.imagebox.image.resourceid
                                    }
                                    _photo1.push(photo)
                                }
                            })
                            _photo.push(_photo1)
                        })
                    })
                }
                abook1(_photo,function () {
                    if (vm.pictureType == 'spread') {
                        vm.data.forEach(function (item, index) {
                            if(singleData[index]){
                                item[0].quantity = singleData[index][0].quantity;
                                item[1].quantity = singleData[index][1].quantity;
                            }else{
                                item[0].quantity = 1;
                                item[1].quantity = 1;
                            }
                        })
                    }
                    if (vm.pictureType == 'single') {
                        vm.data.forEach(function (item, index) {
                            item.quantity = singleData[index] ? singleData[index].quantity : 1;
                        })
                    }
                    $timeout(function () {
                        vm.loading = false;
                        vm.page1 = true;
                        vm.page3 = false;
                        vm.levelIndex = null;
                        console.log(vm.ratio)
                    }, 1000)
                })
            });
        }

        function photoNum(templatePhotoNum,_photo) {
            _photo.forEach(function(p){
                if (p.length < templatePhotoNum) {
                    p.push(p[0])
                    photoNum(templatePhotoNum,_photo)
                }
            })
        }

        function getXmlToDucoment1(_photo){
            var _data = [];
            _photo.forEach(function (photo, photoIndex) {
                (function (_photo, photoIndex) {
                    var _page = angular.copy($scope.template.pages.page[0]);
                    _page.levels.level.forEach(function (levels, index) {
                        (function (level, index) {
                            if(level.imagebox){
                                level.imagebox.bokehtype = 'None';
                                level.imagebox.blurredrectangle = {
                                    width:'',
                                    height:'',
                                    x:'',
                                    y:'',
                                };
                                if(photo[index] && photo[index].identifier){
                                    level.imagebox.image = {
                                        angle: 0,
                                        filter: null,
                                        filterResource: null,
                                        offsetX: 0,
                                        offsetY: 0,
                                        resource: {identifier: photo[index].identifier, provider: "qiniu"},
                                        size: photo[index].imgSize,
                                        provider: "qiniu",
                                        resourceid: photo[index].resourceid,
                                        scale: 1,
                                        width:photo[index].imgWidth,
                                        height:photo[index].imgHeight,
                                    };
                                    getBlurredrectangle(level.imagebox,0);
                                }
                            }
                        })(levels, index)
                    })
                    _page.quantity=photo[0].quantity;
                    _page.uuid = photo[0].uuid;
                    ratioInfo(_page)
                    vm.data.push(_page)
                })(_photo, photoIndex)
            })

        }

        vm.isWatchName = false;
        $scope.watchGalleryName = function(state){
            vm.isWatchName = state;
        }

        $scope.pxInsufficient = function (item) {
            // return throttledResize(item);
            return pxInsufficient(item, vm.imgSize, vm.imgDpi);
        }
        $scope.photoSeveNumber = function(){
            var num = 0;
            if(vm.pictureType == 'single'){
                angular.forEach(vm.data, function (item) {
                    num += Number(item.quantity)
                });
            }
            if(vm.pictureType == 'spread'){
                vm.data.forEach(function (data) {
                    var _num = 0;
                    data.forEach(function (item,index){
                        if(index == 0){
                            _num += Number(item.quantity)
                        }
                    })
                    num = num+_num;
                })
            }
            return num
        }

        $scope.buySize = function() {
            var createModal2 = function(url, controller) {
                return $uibModal.open({
                    templateUrl: url,
                    controller: controller,
                    backdrop: 'static',
                    keyboard: true,
                    windowClass: "jh-modal-md",
                    controllerAs: 'vm',
                    resolve: {
                    }
                });
            };
            createModal2('app/entities/web-gallery/storage-buy.html', 'StorageBuyController').result.then(function() {
                if(vm.userId){
                    CustomerStorages.getByCustomer({customerId: vm.userId}, function(data){
                        progressInit(data);
                    });
                }
            });
        }

        function websocket(){
            var urlws = vm.platformSetting.editorNotifyUrl.split("://")[1];
            urlws = urlws.split("/")[0];
            // var ws = $websocket("ws://localhost:8096/ws/websocket?uid=Gallery"+vm.userId)
            var ws = $websocket("wss://"+ urlws +"/widget/ws/websocket?uid=Gallery"+vm.userId);

            ws.onOpen(function() {
                console.log('WebSocket连接已打开, url:' + ws.url);
            });

            ws.onMessage(function(message) {
                var photo = JSON.parse(message.data);
                console.log(photo);
                if (photo.folderId == vm.folderId || (photo.folderId == null && vm.folderId == '')) {
                    vm.totalPhotoItems++;
                    vm.unusedQuantity++;
                    var len = $scope.photos.length;
                    photo.identifier = $scope.domain + photo.identifier;
                    photo.select = false;
                    photo.index = len;
                    photo.usenum = 0;
                    photo.type = 'picture';
                    $scope.photos.unshift(photo);
                    if (vm.photoSort != 1 && vm.minId==null) {
                        vm.minId = photo.id;
                    }
                }
            });

            ws.onError(function(error) {
                console.log('WebSocket错误：', error);
            });

            ws.onClose(function() {
                console.log('WebSocket连接已关闭');
            });
        }
    }

})();
