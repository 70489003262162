(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('ArticleController', ArticleController);

    ArticleController.$inject = ['$state', '$q', 'Article', '$scope', '$location', 'paginationConstants', 'pagingParams', '$stateParams', '$localStorage',
         'Folder', '$uibModal', 'MessageService', 'DateUtils', 'ListService', 'ItemValue', 'ProductXml', 'Editors'];

    function ArticleController($state, $q, Article, $scope, $location, paginationConstants, pagingParams, $stateParams, $localStorage,
         Folder, $uibModal, MessageService, DateUtils, ListService, ItemValue, ProductXml, Editors) {

        var vm = this;
        vm.search = search;
        vm.articleStatus = "";
        $(".wrap").show();
        $(".navbar").show();

        // 是否点击移动按钮
        $scope.showAllFolder = false;
        $scope.folderId = null;
        $scope.selectAllArticle = false;
        $scope.folderManager = false;
        $scope.articleManager = false;
        $scope.moveArticleIds = [];
        $scope.folderIds = [];
        vm.articles = [];
        vm.storeId = $stateParams.storeId || $localStorage.storeId;
        //vm.loadPage = loadPage;
        //vm.jump = jump;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        //vm.transition = transition;
        // vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.itemsPerPage = 20;
        vm.page = 0;

        vm.searchQuery = pagingParams.search;
        vm.completed = pagingParams.completed;
        vm.currentSearch = pagingParams.search;

        vm.store = $localStorage.store;
        $scope.domain = $localStorage.domain;
        $scope.folders = Folder.getAllByConditionAndQuantity({type:"Article"});

        $q.all([$scope.folders.$promise]).then(function () {
            //vm.page = 0;
            loadAll();
            $scope.folders = $scope.folders.message;
        });

        //检查作品是否能够操作
        $scope.articleCheck = function(article){
            var xmlLineCancelled = true;
            if (vm.itemValue.cancelled) {
                MessageService.error("该模板已被下架，不能进行该操作！");
                return false;
            }
            if (vm.itemValue.productXmlLines && vm.itemValue.productXmlLines.length>0) {
                for (var i = vm.itemValue.productXmlLines.length - 1; i >= 0; i--) {
                    var xmlLine = vm.itemValue.productXmlLines[i];
                    if (xmlLine.productXmlId == article.productXmlId && !xmlLine.cancelled) {
                        xmlLineCancelled = false;
                        break;
                    }
                }
            }
            if (vm.producxml.cancelled|| xmlLineCancelled || vm.itemValue.productCancelled || vm.itemValue.cancelled || !vm.itemValue.productSaleable) {
                MessageService.error("该模板已被下架，不能进行该操作！");
                return false;
            }
            return true;
        };

        $scope.order = function (article) {
            if (!article.itemValueId) {
                MessageService.error("产品信息不全，请重新制作后再尝试下单");
                return;
            }
            if (!article.completed) {
                MessageService.error("该作品还未完成，不能下单");
                return;
            }
            var isPic = swalEditorType(article);
            if(!isPic){
                return
            }

            vm.producxml = ProductXml.get({id:article.productXmlId});
            vm.itemValue = ItemValue.get({id:article.itemValueId});
            $q.all([vm.producxml.$promise, vm.itemValue.$promise]).then(function(){
                if (!$scope.articleCheck(article)) {
                    return;
                }
                var data = {title:"立即下单", itemValueId:article.itemValueId, pageCount:article.pageCount , orderWay: vm.store.orderWay, article: article };
                createModal3('app/entities/sales-order/confirm-property2.html', 'ConfirmPropertyController', data).result.then(
                    function(value) {
                        $state.go("sales-order-reorder", {
                            storeId: vm.storeId,
                            articleId: article.id,
                            productId:article.productId,
                            itemValueLineId: value.itemValueLineId,
                            lineSku:value.lineSku ,
                            lineSkuNumber:value.lineSkuNumber,
                            copy:value.copy,
                            quantity:article.pageCount,
                            itemValueId:article.itemValueId,
                            productXmlId:article.productXmlId,
                        });
                    }
                )
            })
        }

        var createModal3 = function(url, controller, data) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: data
                }
            });
        };


        // function jump() {
        //     vm.page = vm.jumpText<=0?1:vm.jumpText;
        //     loadPage(vm.page);
        // }

        $scope.managerFolder = function(){
            $scope.folderManager = true;
            $scope.cancelArticleManager();
        }

        $scope.managerArticle = function(){
            $scope.articleManager = true;
            $scope.cancelManagerFolder();
        }

        $scope.cancelManagerFolder = function(){
            $scope.folderIds = [];
            $scope.folderManager = false;
            vm.folderAll = false;
            for (var i = $scope.folders.length - 1; i >= 0; i--) {
                var f = $scope.folders[i];
                if (f.selected) {
                    f.selected = false;
                }
            }
        }

        $scope.cancelArticleManager = function(){
            $scope.moveArticleIds = [];
            $scope.articleManager = false;
            for (var i = vm.articles.length - 1; i >= 0; i--) {
                var a = vm.articles[i];
                if (a.selected) {
                    a.selected = false;
                }
            }
        }

        $scope.view = function (article) {
            if ( article.productType == 'StandardPrint' ||  article.productType == 'Standard'
                || article.productType == 'IPDerive') {
                MessageService.error("本客户端暂不支持该品类预览，请在制作端预览");
                return;
            }
            vm.producxml = ProductXml.get({id:article.productXmlId});
            vm.itemValue = ItemValue.get({id:article.itemValueId});
            $q.all([vm.producxml.$promise, vm.itemValue.$promise]).then(function(){
                if (!$scope.articleCheck(article)) {
                    return;
                }
                $state.go("page-view" , {storeId:vm.storeId, aid:article.id, returnUrl: window.location.href});
            });
        }

        $scope.edit = function(article){
            if ( article.productType == 'StandardPrint' ||  article.productType == 'Standard'
                || article.productType == 'IPDerive') {
                MessageService.error("本客户端暂不支持该品类预览，请在制作端预览");
                return;
            }
            vm.producxml = ProductXml.get({id:article.productXmlId});
            vm.itemValue = ItemValue.get({id:article.itemValueId});
            $q.all([vm.producxml.$promise, vm.itemValue.$promise]).then(function(){
                if (!$scope.articleCheck(article)) {
                    return;
                }
                if(Editors.belongToEditorAll(article.editorType)){
                    $state.go('editorAllEdit', {storeId:vm.storeId,aid:article.id, returnUrl: window.location.href});
                }else if (article.editorType== "PICTURE"||article.editorType== "PICTURE_PRO") {
                    $state.go("editorPhotoWebEdit", {storeId:vm.storeId,aid:article.id, returnUrl: window.location.href});
                }else {
                    $state.go("page-my-suffix-remake", {storeId:vm.storeId, aid:article.id, returnUrl: window.location.href} )
                }
            })
        }

        $scope.goFolder = function(f){
            if ($scope.folderManager) {
                $scope.folderSelected(f)
                return;
            }
            $state.go("webArticleFolder", {storeId:vm.storeId, folderId:f.id});
        }

        $scope.upDown = function(){
            $scope.showAllFolder = !$scope.showAllFolder;
        }


        $scope.editName = function (article) {
            if ($scope.folderManager) {
                return;
            }
            // vm.producxml = ProductXml.get({id:article.productXmlId});
            // vm.itemValue = ItemValue.get({id:article.itemValueId});
            // $q.all([vm.producxml.$promise, vm.itemValue.$promise]).then(function(){
                // if (!$scope.articleCheck(article)) {
                //     return;
                // }
                createModal('app/entities/web-article/article-dialog.html', 'ArticleDialogController', article.id, article.name).result.then(
                    function () {
                        vm.articles= [];
                        vm.page = 0
                        loadAll();
                    });
            // })
        }

        $scope.addFolder = function (id, event) {
            if (event) {
                event.stopPropagation()
            }
            if ($scope.folderManager) {
                return;
            }
            createModal('app/entities/web-folder/folder-dialog.html', 'FolderDialogController', id, "Article").result.then(
                function () {
                    Folder.getAllByConditionAndQuantity({type:"Article"},function(res) {
                        $scope.folders = res.message;
                    });
                });
        }


        var createModal = function (url, controller, id, name) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity:{
                        type:name,
                        id:id
                    },
                }
            });
        };

        $scope.moveToFolder = function () {
            if (!$scope.moveArticleIds.length) {
                MessageService.error("移动失败,请至少选择一个作品");
                return
            }
            createModal2('app/entities/web-folder/entity-move-folder.html', 'EntityMoveFolderController', $scope.moveArticleIds, "Article",null).result.then(
                function () {
                    vm.page = 0;
                    vm.articles = [];
                    $scope.moveArticleIds = [];
                    $scope.articleManager = false;
                    loadAll()
                    Folder.getAllByConditionAndQuantity({type:"Article"},function(res){
                        $scope.folders = res.message;
                    });
                });
        }

        $scope.moveToFolderOne = function(f,event) {
            event.stopPropagation();
            var ids=[];
            ids.push(f.id);
            createModal2('app/entities/web-folder/entity-move-folder.html', 'EntityMoveFolderController', ids, "Article",null).result.then(
                function() {
                    vm.page = 0;
                    vm.articles = [];
                    loadAll()
                    Folder.getAllByConditionAndQuantity({type:"Article"},function(res){
                        $scope.folders = res.message;
                    });
                });
        }

        var createModal2 = function (url, controller, ids, type,folderId) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity:{
                        type:type,
                        ids:ids,
                        folderId:folderId
                    },
                }
            });
        };

        function search(){
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = vm.searchQuery;
            vm.transition();
        }

        $scope.folderSelected = function(folder,$event) {
            if (event) {
                event.stopPropagation()
            }
            if (!$scope.folderManager) {
                return;
            }
            if (folder != null) {
                folder.selected = !folder.selected;
                if (folder.selected) {
                    $scope.folderIds.push(folder.id)
                    if ($scope.folderIds.length == $scope.folders.length) {
                        vm.folderAll = true;
                    }
                }else{
                    $scope.folderIds = $scope.folderIds.filter(function (item) {
                        return folder.id != item
                    })
                    vm.folderAll = false;
                }
            }
        }
        vm.folderAll = false;
        $scope.folderAll = function() {
            if (vm.folderAll) {
                $scope.folders.forEach(function(folder) {
                    folder.selected = false;
                })
                $scope.folderIds = [];
                vm.folderAll = false;
            }else{
                $scope.folders.forEach(function(folder) {
                    folder.selected = true;
                    $scope.folderIds.push(folder.id)
                })
                vm.folderAll = true;
            }

        }

        $scope.deleteFolder = function() {
            if (!$scope.folderIds.length > 0) {
                MessageService.error("操作失败,请选择文件夹");
                return
            }
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'确认',
                msg :'删除文件夹会同时删除文件夹内的作品，删除后无法找回，确认删除吗？'
            }, function() {
                Folder.batchDelete({
                    ids: $scope.folderIds
                }, function() {
                    MessageService.success("删除成功");
                    $scope.folderIds = [];
                    $scope.folderManager = false;
                    vm.folderAll = false;

                    Folder.getAllByConditionAndQuantity({type:"Article"}, function (resp) {
                        $scope.folders = resp.message;
                    });
                }, function() {
                    MessageService.error("删除失败");
                })
            });
        }
        $scope.deleteFolderOne = function(f,event) {
            event.stopPropagation()
            MessageService.confirm({
                title :'提示',
                cancel :'取消',
                confirm :'删除',
                msg :'删除文件夹会同时删除文件夹内的作品，删除后无法找回，确认删除吗？'
            }, function() {
                Folder.batchDelete({
                    ids: f.id
                }, function() {
                    MessageService.success("删除成功");
                    Folder.getAllByConditionAndQuantity({type:"Article"},function(res){
                        $scope.folders = res.message;
                    });
                }, function() {
                    MessageService.error("删除失败");
                })
            });
        }


        // $scope.delete = function (id) {
        //     Article.delete({id: id}, function () {
        //         MessageService.success("您的作品已成功删除");
        //         loadAll();
        //     }, function () {
        //         MessageService.error("操作失败");
        //     });
        // }

        $scope.editor = function (article) {
            localStorage.Production = "view";
            //清除关于作品的localStorage
            localStorage.removeItem("choose");
            localStorage.removeItem("index");
            localStorage.removeItem("i");
            localStorage.removeItem("marginLeft");
            localStorage.removeItem("single");
            localStorage.removeItem("pages");
            localStorage.removeItem("gallery");
            localStorage.removeItem("galleryCopy");
            localStorage.removeItem("reviewPages");
            localStorage.removeItem("saveOldPage");
            localStorage.removeItem("pageAllPx");
            localStorage.removeItem("pageAllMm");
            localStorage.removeItem("view-scrollTop");

        }



        function swalEditorType(article) {
            // if(article.editorType=='PICTURE'){
            //     MessageService.error("对不起，当前客户端不支持此类作品的操作与订购。");
            //     return false
            // }
            return true
        }
        vm.articleFilterName = '全部';
        $scope.articleFilter = function (flag){
            vm.articleStatus = flag;
            if (vm.articleStatus == 1) {
                vm.articleFilterName = '已完成';
            }else if (vm.articleStatus == 2) {
                vm.articleFilterName = '未完成';
            }else if (vm.articleStatus == 3){
                vm.articleFilterName = '已失效';
            }else{
                vm.articleFilterName = '全部';
            }
            vm.page = 0;
            vm.articles = [];
            loadAll();
        }
        function loadAll() {
            $scope.selectAllArticle = false;
            var items=[{key:"productType", op:"=", value:"CustomPrint"}];
            console.log(vm.articleStatus)
            if (vm.articleStatus == 1) {
                items.push({key:"completed", op:"=", value:"true"});
                items.push({key:"invalid", op:"=", value:"false"});
            }else if (vm.articleStatus == 2) {
                items.push({key:"completed", op:"=", value:"false"});
                items.push({key:"invalid", op:"=", value:"false"});
            }else if (vm.articleStatus == 3){
                items.push({key:"invalid", op:"=", value:"true"});
            } 

            if ($scope.folderId == null || $scope.folderId == "") {
                items.push({key: "folderId", op: "isNull", value: ''})
            }else{
                items.push({key: "folderId", op: "=", value: $scope.folderId });
            }

            Article.byCondition({
                items: items,
                page: vm.page,
                size: 20,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                return ["createdDate,desc"]
            }

            function onSuccess(data, headers) {
                data.forEach(function(g) {
                    vm.articles.push(g)
                })
                console.log(data)
                //  vm.dataArticles = [];
                // for (var i = 0; i < vm.articles.length; i++) {
                //     var g = vm.articles[i];
                //
                //     g.createdDate = DateUtils.convertLocalDateToServer(g.createdDate);
                //     var result = ListService.inList(g, vm.dataArticles, ["createdDate"] );
                //     if (!result) {
                //         vm.dataArticles.push({createdDate:g.createdDate, articles:[g]});
                //     }else{
                //         result.articles.push(g);
                //     }
                // }

                vm.totalItems = headers('X-Total-Count');
                // vm.queryCount = vm.totalItems;
                // vm.page = pagingParams.page;
                // vm.transition();
            }

            function onError(error) {
            }
        }

        // function loadPage(page) {
        //     vm.page = page;
        //     vm.transition();
        // }
        //
        // function transition() {
        //     $state.transitionTo($state.$current, {
        //         page: vm.page,
        //         storeId:vm.storeId,
        //         sort: "createdDate,desc",
        //         search: vm.currentSearch,
        //         completed:vm.completed
        //     });
        // }

        $scope.selectSingle = function (article,event) {
            event.stopPropagation();
            if(!$scope.articleManager){
                return
            }
            article.selected = !article.selected;
            if (article.selected){
                $scope.moveArticleIds.push(article.id);
            }else{
                var inde1 = $scope.moveArticleIds.indexOf(article.id);
                $scope.moveArticleIds.splice(inde1,1);
            }
            if ($scope.moveArticleIds.length == vm.articles.length) {
                $scope.selectAllArticle = true;
            }else{
                $scope.selectAllArticle = false;
            }
        }


        /**
         * 全选
         * @param flag-----  true：文件夹     false:作品
         */
        $scope.chooseAll = function () {
            $scope.selectAllArticle = !$scope.selectAllArticle;
            if($scope.selectAllArticle){
                $scope.moveArticleIds = [];
            }
            angular.forEach(vm.articles, function (article, i) {
                if ($scope.selectAllArticle) {
                    article.selected = true;
                    $scope.moveArticleIds.push(article.id);
                } else {
                    article.selected = false;
                    $scope.moveArticleIds = [];
                }
            })
        }

        $scope.deleteArticles = function() {
            if (!$scope.moveArticleIds.length) {
                MessageService.error("操作失败,请至少选择一个作品");

                return
            }
            MessageService.confirm({
                title :'删除',
                cancel :'取消',
                confirm :'确定',
                msg :'删除作品后无法找回，确认删除吗？'
            },function(){
                Article.batchDelete({
                    ids: $scope.moveArticleIds
                }, function() {
                    vm.page = 0;
                    vm.articles = [];
                    $scope.moveArticleIds = [];
                    $scope.articleManager = false;
                    loadAll();
                    MessageService.success("操作成功,您的作品已成功删除")
                }, function() {
                    MessageService.error("操作失败,请稍后操作或者联系管理员")
                })
            });
        }
        $scope.deleteArticlesOne = function(article,event) {
            event.stopPropagation()
            var ids = [];
            ids.push(article.id)
            MessageService.confirm({
                title :'删除',
                cancel :'取消',
                confirm :'确定',
                msg :'删除作品后无法找回，确认删除吗？'
            }, function() {
                Article.batchDelete({
                    ids: ids
                }, function() {
                    vm.page = 0;
                    vm.articles = [];
                    loadAll()
                    MessageService.success("操作成功,您的作品已成功删除")
                }, function() {
                    MessageService.error("操作失败,请稍后操作或者联系管理员")
                })
            });
        }

        // 滑动加载更多
        $('.home-body').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(vm.articles.length < vm.totalItems){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.page++;
                    loadAll()
                }
            }
        });
        $scope.folderSelectedActive= function(event){
            event.preventDefault();
            event.stopPropagation();
        }

    }
})();
