(function () {
    'use strict';

    angular
        .module('editorApp')
        .controller('EditorFormController', EditorFormController);

    EditorFormController.$inject = ['$state', '$scope', '$q', '$stateParams', 'Convert', 'Qinius', 'WxJS', 'Gallery', 'ItemValue', 'SweetAlert', 'Theme', '$localStorage', '$timeout', 'FontManagement', '$rootScope'
        , 'ImageService', 'ClipArt', 'PlatformWechatSetting', 'UidService', 'Store', 'Article', 'CommonService', '$http', 'Folder', 'MessageNotification', 'ThemeClassify', 'entity', 'Product', 'ProductXml', 'DateUtils',
        'Iphone','SalesOrder','AuthServerProvider','FilterOrderRecords', 'CustomerUploadAble','Customer', 'StoreConfig','RecommendPics','MessageService','$websocket'];

    function EditorFormController($state, $scope, $q, $stateParams, Convert, Qinius, WxJS, Gallery, ItemValue, SweetAlert, Theme, $localStorage, $timeout, FontManagement, $rootScope
        , ImageService, ClipArt, PlatformWechatSetting, UidService, Store, Article, CommonService, $http, Folder, MessageNotification, ThemeClassify, entity, Product, ProductXml, DateUtils,
                                  Iphone, SalesOrder, AuthServerProvider, FilterOrderRecords, CustomerUploadAble,Customer, StoreConfig, RecommendPics,MessageService, $websocket) {
        //初始化
        $(".navbar-gallery").hide();
        $(".wrap").addClass('no-margins');
        $(".well").css({'margin-bottom': 0});
        $scope.dWidth = document.documentElement.clientWidth;
        $("body").css({width: $scope.dWidth, 'min-width': $scope.dWidth});

        var vm = this;
        if ($localStorage.user !=null) {
            vm.userId = $localStorage.user.id
        }
        localStorage.removeItem('flag');
        if (window.location.href.indexOf("?") > -1) {
            localStorage.setItem('themeFlag', 'two');
            // localStorage.removeItem('guide-step');
            var r = window.location.href.split('?')[1];
            var typeFlag = r.split('&')[0].split('=')[1];
            var authorize = r.split('&')[1].split('=')[1];
            var productionType = r.split('&')[2].split('=')[1];
            var formLibrary = r.split('&')[3].split('=')[1];
            var orderOrigin = null;
            if (r.split('&').length>=5) {
                orderOrigin = r.split('&')[4].split('=')[1];
            }

            orderOrigin = decodeURIComponent(orderOrigin);
            var distinguish = decodeURIComponent(typeFlag);
            var tempAuthorization = decodeURIComponent(authorize);
            var authorization = tempAuthorization.slice(7, tempAuthorization.length);
            var showFlag = decodeURIComponent(productionType);
            var editLibrary = decodeURIComponent(formLibrary);
            if (distinguish == 'weChatApplet' || distinguish == 'weChatAppletMake' || distinguish == 'toutiaoApp' || distinguish == 'jdAppPlugins' || distinguish == 'alipayApp' || distinguish == 'jdApp') {
                vm.uploadType = distinguish;
                localStorage.setItem('orderOrigin', orderOrigin);
                localStorage.setItem('distinguish', distinguish);
                $localStorage.authenticationToken = authorization;
                var workSave = JSON.parse(localStorage.getItem("work-save"));
                var pid = editLibrary.split(',')[0];
                var vid = editLibrary.split(',')[1];
                var xmlId = editLibrary.split(',')[2];
                if (workSave == null) {
                    var obj = {
                        pid: pid,
                        vid: vid,
                        xmlId:xmlId,
                        date: new Date().getTime()
                    };
                    localStorage.setItem("work-save", JSON.stringify(obj));
                    localStorage.removeItem("pages");
                    localStorage.removeItem("gallery");
                    localStorage.removeItem("galleryCopy");
                } else {
                    var currentDate = new Date().getTime();
                    var flag = (currentDate - workSave.date) > 3 * 3600 * 1000 ? true : false;
                    if (workSave.pid != pid || workSave.vid != vid || flag) {
                        localStorage.removeItem("pages");
                        localStorage.removeItem("gallery");
                        localStorage.removeItem("galleryCopy");
                        var obj = {
                            pid: pid,
                            vid: vid,
                            xmlId:xmlId,
                            date: new Date().getTime()
                        };
                        localStorage.setItem("work-save", JSON.stringify(obj));
                    }
                }
            }
            vm.target=getQueryVariable('target');
            localStorage.setItem('target', vm.target);
            vm.flag=getQueryVariable('flag');
            localStorage.setItem('flag', vm.flag);
        }
        var isWx = false;
        var browser = CommonService.browserVersions();
        if (browser.versions.mobile) {//判断是否是移动设备打开。browser代码在下面
            var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                //在微信中打开
                isWx = true;
            }else if(browser.versions.android){
                //是否在安卓浏览器打开
                isWx = false;
            }
            if (ua.match(/WeiBo/i) == "weibo") {
                //在新浪微博客户端打开
            }
            if (ua.match(/QQ/i) == "qq") {
                //在QQ空间打开
            }
            if (browser.versions.ios) {
                //是否在IOS浏览器打开
                isWx = false;
            }
        } else {
            //否则就是PC浏览器打开
        }
        vm.isIPhone6 = isIPhone6();
        vm.isEdit = entity.type == 'edit' ? true : false;
        vm.storeId = $stateParams.storeId;
        vm.pid = $stateParams.pid;
        vm.aid = $stateParams.aid;
        vm.vid = $stateParams.vid;
        vm.xmlId = $stateParams.xmlId;
        vm.salesOrderId = $stateParams.soid ? $stateParams.soid : null;
        vm.productLineId = $stateParams.plid ? $stateParams.plid : null;
        vm.counts = 0;
        vm.indexNum = 0;
        vm.itemsPerPage = 20;
        vm.photoAll = [];
        $scope.domains = Qinius.getDomain1();
        vm.tokenTime = 360000;
        Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"},function (msg) {
            vm.token = msg.token;
            vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
        });
        vm.platformSetting = PlatformWechatSetting.get();
        vm.photoArray = [];
        vm.photoSelectAll = localStorage.getItem("photoSelectAll") ? JSON.parse(localStorage.getItem("photoSelectAll")) : [];
        vm.isPhone = false;
        vm.threeIsModal = false;
        vm.threeModalText = "";
        vm.threeLoading = true;
        vm.threeLoadingText='加载中...';
        vm.threeError = false;
        vm.threeErrorText="加载中";
        vm.store = Store.get({id: vm.storeId});
        vm.customer = Customer.current();
        vm.fontList = [];
        //阻止微信端长按赋值保存图片
        document.oncontextmenu = function (e) {
            //或者return false;
            e.preventDefault();
        };
        /**
         * wechat api
         */
        if (wx) {
            var url = "";
            if (!!window.location.hash ) {
                url = window.location.href.slice(0, -window.location.hash.length)
            }else{
                url =  window.location.href;
            }
            WxJS.get({url: url}, function (config) {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: config.appId, // 必填，公众号的唯一标识
                    timestamp: config.timestamp, // 必填，生成签名的时间戳
                    nonceStr: config.nonceStr, // 必填，生成签名的随机串
                    signature: config.signature,// 必填，签名，见附录1
                    jsApiList: ['chooseImage', 'uploadImage', 'hideMenuItems'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                });
                wx.ready(function () {

                    wx.hideMenuItems({
                        menuList: ["menuItem:copyUrl", "menuItem:share:timeline", "menuItem:share:appMessage", "menuItem:share:qq", "menuItem:share:appMessage", "menuItem:share:QZone"]
                    });
                });
                wx.error(function (res) {
                });
            }, function (res) {
            });
        }

        //像素不足提醒
        vm.imgSize = 150;
        vm.imgDpi = 150;
        StoreConfig.getAllByTypes({storeId: vm.storeId,type: ["IMGSIZE", "IMGDPI"]}, function (res) {
            if(res.status == 200){
                res.message.forEach(function (item) {
                    if(item.type === 'IMGSIZE'){
                        vm.imgSize = item.value;
                    }
                    if(item.type === 'IMGDPI'){
                        vm.imgDpi = item.value;
                    }
                })
            }
        });
        init()
        function init() {
            if (!vm.isEdit) {
                getProduct(vm.pid,function () {
                    ItemValue.get({id: $stateParams.vid}, function (res) {
                        $("body").toggleClass('bg');
                        $scope.status = localStorage.getItem('Production');
                        angular.forEach(res.productXmlLines, function (line) {
                            if (line.id == vm.xmlId) {
                                vm.productXmlLine = angular.copy(line);
                                vm.modelBlendentUuid=vm.productXmlLine.modelBlendentUuid;
                            }
                        })
                        vm.article = {
                            modelBlendentUuid: vm.productXmlLine.modelBlendentUuid,
                            cover: vm.productXmlLine.coverImg,
                            themeClassifyId: vm.productXmlLine.productXmlThemeId,
                            themeClassifyName: vm.productXmlLine.productXmlThemeName,
                            productXmlId: vm.productXmlLine.productXmlId,
                            productXmlName: vm.productXmlLine.productXmlName,
                            musicIdentify: vm.productXmlLine ? vm.productXmlLine.musicIdentify : res.musicIdentify,
                            sku: res.sku,
                            skuNumber: res.skuNumber,
                            name: res.productName,
                            productId: res.productId,
                            productName: res.productName,
                            categoryId: res.categoryId,
                            categoryName: res.categoryName,
                            categoryStoreId: res.categoryStoreId,
                            categoryStoreName: res.categoryStoreName,
                            editorType: res.productEditorType,
                            productBokeh: res.productBokeh,
                            productBokehType: res.productBokehType || 'None',
                            itemValueId: res.itemValueId || vm.vid
                        };
                        if (!res.productBokeh) {
                            vm.article.bokehtype = 'None';
                        } else {
                            vm.article.bokehtype = res.productBokehType || 'None';
                        }

                        Convert.xmlToDocument2({
                            identifier: vm.productXmlLine ? vm.productXmlLine.productXmlIdentifier : res.productXmlIdentifier,
                            provider: "qiniu",
                            editorType:vm.productXmlLine.productXmlVersion == "4.0" ? "" : vm.article.editorType,
                            patternType:vm.product.patternType,
                        }, function (res) {
                            vm.counts = 0;
                            $scope.pages = res;
                            angular.forEach(res.pages.page, function (page, i) {
                                if(!page.backup) {
                                    angular.forEach(page.levels.level, function (level) {
                                        if (level.imagebox && level.imagebox.geometry) {
                                            vm.counts++;
                                        }
                                        if (level.textbox && level.textbox.geometry) {
                                            level.textbox.maxLength = level.textbox.text.length>20 ? level.textbox.text.length : 20;
                                            // if(level.textbox.text){
                                            //     level.textbox.maxLength = level.textbox.text.length;
                                            // }
                                        }
                                    })
                                }
                            });
                            abook();
                        });
                    });
                });
            } else {
                vm.page8 = true;
                Article.get({id: $stateParams.aid}, function (res) {
                    vm.article = res;
                    getProduct(vm.article.productId)
                    angular.forEach(res.document.pages.page, function (page, i) {
                        if(!page.backup) {
                            angular.forEach(page.levels.level, function (level) {
                                if (level.imagebox && level.imagebox.geometry) {
                                    vm.counts++;
                                }
                            })
                        }
                    });
                    abook();
                })
            }
        }

        function getProduct(productId,callback) {
            Product.get({id: productId, cancelled: false}, function (res) {
                vm.product = res;
                if(callback){callback()}
            })
        }

        $q.all([$scope.domains.$promise, vm.platformSetting.$promise, vm.customer.$promise]).then(function () {
            $scope.domain = 'https://' + $scope.domains.domains[0] + '/';
            setTimeout(function fn() {
                Qinius.tokenWithPolicy({"mimeLimit":"image/jpeg;image/png"},function (msg) {
                    vm.token = msg.token;
                    vm.tokenTime = parseInt((msg.expireAt - new Date().getTime()));
                });
                setTimeout(fn, vm.tokenTime);
            }, vm.tokenTime);
            //获取照片旋转角度使用
            //判断是ios or Android
            var u = navigator.userAgent;
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if(vm.uploadType=='alipayApp' || vm.uploadType=='toutiaoApp'){
                $scope.agentIos = true;
            }else if(!isWx){
                $scope.agentIos = false;
            } else if (isAndroid == true && isiOS == false) {
                $scope.agentIos = true;
            } else if (isAndroid == false && isiOS == true) {
                $scope.agentIos = false;
            }

            //获取图片库
            loadAll('isNull');
            platformImg();
            vm.isIPhoneX = Iphone;
            if(!vm.userId){
                vm.userId = vm.customer.id
            }
            websocket();
        });

        $scope.uploadPhoto = function () {
            uploadVerify(vm.platformSetting, CustomerUploadAble, vm.storeId, vm.customer.id, function (res) {
                if($scope.agentIos){
                    $rootScope.$broadcast('clickAndroidUpload',true);
                }else{
                    $timeout(function () {
                        $("#uploadImageMutipleId").trigger("click");
                    })
                }
            })
        }
        vm.uploadPhotoItem=[];
        $scope.$on('uoloadFinished', function(event, args) {
            if(args){
                angular.forEach(vm.photoAll,function (qin) {
                    if(qin.blobCover){
                        qin.blobCover = null;
                    }
                })
                // updatePic();
            }
        });
        $scope.$on('uoloadDuplicate', function(event, res) {
            updatePic();
        });
        vm.maxId = null;
        // 上传图片后更新图片库/文件夹
        function updatePic() {
            vm.photoAll=[];
            vm.indexNum = 0;
            vm.maxId = null;
            vm.photoCompleted = false;
            loadAll();
        }
        function loadAll() {
            Gallery.getPage({
                items: [{key: "folderId", op: "isNull", value: ''}],
                page: vm.indexNum,
                size: vm.itemsPerPage,
                sort: sorts()
            }, onSuccess, onError);
        }

        function sorts() {
            return ["id,desc"];
        }

        function onSuccess(res) {
            for (var i = 0; i < res.length; i++) {
                if (!res[i].cancelled) {
                    res[i].identifier = $scope.domain + res[i].identifier;
                    vm.photoAll.push(res[i])
                }
            }
            if (vm.maxId == null && res.length > 0) {
                vm.maxId = res[0].id;
            }
            if (res.length < 20) {
                vm.photoCompleted = true;
            }
        }

        function onError(data) {
            MessageService.hint("网络错误，请切换网络重试");
        }


        //获取dpi
        js_getDPI(function (dpi) {
            vm.windowDpi = dpi;
        });
        vm.pageData = null;
        vm.pageIndex = 0;
        $scope.template = null;
        function abook() {
            productXmlGet();
            if (vm.isEdit) {
                $scope.template = angular.copy(vm.article.document);
                $scope.template.pages.page=[];
                vm.article.document.pages.page.forEach(function (page) {
                    if(!page.backup){
                        var _page = page;
                        _page.seq = $scope.template.pages.page.length;
                        $scope.template.pages.page.push(_page);
                    }
                })
            } else {
                $scope.template = angular.copy($scope.pages);
                $scope.template.pages.page=[];
                $scope.pages.pages.page.forEach(function (page) {
                    if(!page.backup){
                        var _page = page;
                        _page.seq = $scope.template.pages.page.length;
                        $scope.template.pages.page.push(_page);
                    }
                })
            }
            documentAnalysis($scope, $http, vm, $scope.template,FontManagement,'other', function(page){
                ratioInfo(page)
            }, function () {
                vm.pageData = [];
                $timeout(function () {
                    vm.pageData = $scope.template.pages.page;
                    vm.pageSum = $scope.template.pages.page.length - 1;
                    vm.pageIndex = 0;
                    vm.threeLoading = false;
                }, 1000)
            })
        }
        function productXmlGet(){
            ProductXml.get({id:vm.article.productXmlId}, function (productXml) {
                vm.productXmlData = productXml;
            })
        }
        vm.windowWidth = document.body.clientWidth;
        vm.windowHeight = document.body.clientHeight;
        vm.windowHeight1 = 350;
        function ratioInfo(item) {
            var ratio = item.custom.width / item.custom.height;
            var ratio1 = vm.windowWidth / vm.windowHeight1;
            if (ratio >= ratio1) {
                item.custom.ratio1 = vm.windowWidth / item.custom.width;
            } else {
                item.custom.ratio1 = vm.windowHeight1 / item.custom.height;
            }
            item.custom.thumbnailScale = 300 / item.custom.width;
        }

        $scope.previousPage = function () {
            thumbnailInit(function () {
                vm.pageIndex--;
                ratioInfo(vm.pageData[vm.pageIndex]);
            })
        }
        $scope.nextPage = function () {
            thumbnailInit(function () {
                vm.pageIndex++;
                ratioInfo(vm.pageData[vm.pageIndex]);
            })
        }
        $scope.goPageContent = function (index) {
            vm.isDocumentTextDetection=false;
            thumbnailInit(function () {
                vm.pageIndex = index;
                ratioInfo(vm.pageData[vm.pageIndex]);
            })
        }
        /*******************************************点击图片库*************************************************************************/
        $scope.imageboxCb = function(imagebox){
            vm.isGallery = true;
            vm.isPlatformPhoto = false;
            vm.imageboxItem = imagebox;
        };
        $scope.imageboxHide = function(){
            vm.isGallery = false;
            vm.isPlatformPhoto = false;
            vm.imageboxItem = null;
        };
        $scope.mapDepot = function(item){
            if(item){
                vm.isGallery = true;
                vm.isPlatformPhoto=false;
            }else{
                vm.isGallery = false;
                vm.isPlatformPhoto=true;
            }
        }
        vm.recommendPicIndex = 0;
        vm.recommendPicSize = 20;
        vm.recommendPicCompleted = false;
        vm.recommendPics=[];
        function platformImg(){
            RecommendPics.byCondition2({
                items: [],
                page: vm.recommendPicIndex,
                size: vm.recommendPicSize,
                sort: ["seq,asc"]
            }, function (res, headers) {
                var _sum = headers('X-Total-Count');
                for (var i = 0; i < res.length; i++) {
                    if (!res[i].cancelled) {
                        res[i].identifier = $scope.domain + res[i].identifier;
                        vm.recommendPics.push(res[i])
                    }
                }
                if(vm.recommendPics.length>= _sum){
                    vm.recommendPicCompleted = true;
                }
            }, function () {

            });
        }
        $('#photoGalleryId').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(!vm.photoCompleted){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.indexNum++
                    loadAll()
                }
            }
        });
        $('#platformPhotoId').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(!vm.recommendPicCompleted){
                if((event.currentTarget.scrollHeight-1)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+1)){
                    vm.recommendPicIndex++
                    platformImg()
                }
            }
        });


        $scope.addImageboxImg = function (item) {
            replaceImagebox(item)
        };
        function replaceImagebox(data) {
            var item = angular.copy(data);
            var _imagebox = vm.imageboxItem;
            _imagebox.image = {
                resource: {
                    identifier: item.identifier,
                    provider: 'qiniu'
                },
                resourceid:item.id,
                filterResource: {
                    identifier: '',
                    provider: 'qiniu'
                },
                imageInfo:item.imageInfo,
                width: item.width,
                height: item.height,
                imgSize: item.imgSize,
                index: item.seq,
                parameter: angular.fromJson(item.parameter),
                scale: 1,
                angle: 0
            }
            addImagebox($http, _imagebox, function () {
                imageSelectInit();
                $scope.imageboxHide();
            });
        }

        function imageSelectInit() {
            vm.photoSelectAll = [];
            for (var i = 0; i < vm.pageData.length; i++) {
                for (var j = 0; j < vm.pageData[i].levels.level.length; j++) {
                    var _level = vm.pageData[i].levels.level[j];
                    if (_level.imagebox) {
                        for (var k = 0; k < vm.photoAll.length; k++) {
                            if (_level.imagebox.image.resourceId == vm.photoAll[k].id) {
                                vm.photoSelectAll.push(vm.photoAll[k]);
                                break
                            }
                        }
                    }
                }
            }
        }
        //文字编辑
        $scope.textboxFocus = function(textbox){
            $timeout(function () {
                vm.textbox = textbox;
            })
        };
        $scope.textboxBlur = function(textbox){
            $timeout(function () {
                vm.textbox = null;
            })
        };
        $scope.textboxChange = function(textbox){
            if(textbox.text.length > textbox.maxLength){
                textbox.text = textbox.text.substring(0, textbox.maxLength)
            }
            sizeHeight()
        }

        function sizeHeight() {
            $timeout(function () {
                var _h = Number($("#c-xml-page-material-fontId1 span").height()) + Number(10);
                var _w = Number($("#c-xml-page-material-fontId1 span").width()) + Number(10);
                if(!writingmodeInit(vm.textbox.style.writingmode)){
                    vm.textbox.geometry.height = _h;
                }
                if(writingmodeInit(vm.textbox.style.writingmode)){
                    vm.textbox.geometry.width = _w;
                }
            })
        }
        //保存
        $scope.isSave = false;
        vm.isDocumentTextDetection = false;
        $scope.save = function () {
            var _documentTextDetection = documentTextDetection1($scope.template.pages.page);
            if(!_documentTextDetection.pass){
                vm.isDocumentTextDetection = true;
                vm.documentTextDetection = _documentTextDetection.pages;
                return;
            }
            if ($scope.isSave) {
                return;
            }
            //判断是否有图片未上传  且是否继续保存
            if (!$scope.isContinue()) {
                // vm.completed = false;
                setTimeout(function(){
                    $(".sweet-alert").addClass('whiteBg');
                })
                SweetAlert.swal({
                    title: '当前作品没有制作完成，确认保存？',
                    // imageUrl: Theme.sweetHintImg(),
                    showCancelButton: true,
                    confirmButtonText: "确认",
                    cancelButtonText: "取消",
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function (isConfirm) {
                    setTimeout(function(){
                        $(".sweet-alert").removeClass('whiteBg');
                    },200)

                    if (isConfirm) {
                        $timeout(function () {
                            generateJsonFile();
                        }, 500);
                        return;
                    }
                })
            } else {
                // vm.completed = true;
                generateJsonFile();
            }
        }
        $scope.save1 = generateJsonFile;
        vm.isPageWarnExamine = false;
        function generateJsonFile() {
            vm.deleteList = 0;
            var  gallieryIds = [];
            for (var i = $scope.template.pages.page.length - 1; i >= 0; i--) {
                var page = $scope.template.pages.page[i];
                if (page.backup || page.levels == null || page.levels.level == null || page.levels.level.length == 0) {
                    continue;
                }
                for (var j = page.levels.level.length - 1; j >= 0; j--) {
                    var level = page.levels.level[j];
                    if (level.imagebox == null || level.imagebox.image == null || !level.imagebox.image.resourceid) {
                        continue;
                    }
                    gallieryIds.push(level.imagebox.image.resourceid);
                }
            }
            if (gallieryIds.length>0) {
                Gallery.checkDeleted(gallieryIds, function(list){
                    // vm.deleteList = [{id:gallieryIds[0]}];
                    // vm.completed = false;
                    // vm.isDestroyed = true;
                    if (list == null|| list.length ==0) {
                        generateJsonFile1(flag)
                    }else{
                        vm.deleteList = list;
                        vm.completed = false;
                        vm.isDestroyed = true;
                        return;
                    }
                }, function(){
                    MessageService.error("检测图片失败，请稍后再试。");
                })
            }else{
                generateJsonFile1(flag)
            }
        }

        $scope.removeGallery = function(){
            if (vm.deleteList == null || vm.deleteList.length == 0) {
                return;
            }
            for (var z = vm.deleteList.length - 1; z >= 0; z--) {
                var g = vm.deleteList[z];
                for (var i = $scope.template.pages.page.length - 1; i >= 0; i--) {
                    var page = $scope.template.pages.page[i];
                    if (page.backup || page.levels == null || page.levels.level == null || page.levels.level.length == 0) {
                        continue;
                    }
                    for (var j = page.levels.level.length - 1; j >= 0; j--) {
                        var level = page.levels.level[j];
                        if (level.imagebox == null || level.imagebox.image == null || !level.imagebox.image.resourceid) {
                            continue;
                        }
                        if (level.imagebox.image.resourceid == g.id) {
                            level.imagebox.image.resource.identifier = "";
                            level.imagebox.image.resource.adaptation = "";
                            level.imagebox.image.resource.provider = "";
                            level.imagebox.image.resource.offsetx = 0;
                            level.imagebox.image.resource.offsety = 0;
                            level.imagebox.image.resource.height = "";
                            level.imagebox.image.resource.width = "";
                            level.imagebox.image.resource.scale = 1;
                            level.imagebox.image.resource.imgSize = "";
                            level.imagebox.image.resourceid = null;
                            level.imagebox.image.imageInfo = null;
                            break;
                        }
                    }
                }
            }
            imageSelectInit();
        }

        $scope.galleryDestroyConfirm = function(){
            $scope.removeGallery();
            generateJsonFile1(vm.saveflag);
        };

        function generateJsonFile1(flag){
            if(!vm.isPageWarnExamine && pageWarnExamine(vm.pageData, vm.imgSize, vm.imgDpi)){
                vm.isPageWarnExamine = true;
                return
            }
            vm.isPageWarnExamine = false;
            $scope.isSave = true;

            thumbnailInit(function (){
                vm.article.document = saveData();
                vm.article.document.hostinfo = hostInfo();
                if (vm.isEdit) {
                    vm.article.completed = vm.completed;
                    vm.article.unFilledCount = vm.unFilledCount;
                    Article.update(vm.article, function (resp) {
                        $scope.isSave = false;
                        swal({
                            title: "修改成功",
                            text: "作品已同步到我的作品",
                            imageUrl: Theme.sweetSuccedImg(),
                            timer: 2000,
                            showConfirmButton: false
                        });
                        if(vm.store.orderWay == 'editorThenOrder' || !vm.store.orderWay){
                            $state.go('product.view5',{storeId: vm.storeId, aid: article.id, soid: vm.salesOrderId, plid: vm.productLineId});
                        }
                        if(vm.store.orderWay == 'orderThenEditor'){
                            works(resp)
                        }
                    }, function () {
                        $scope.isSave = false;
                        swal({
                            title: "保存失败",
                            text: "保存出错了，请稍后再试，或者联系工作人员",
                            imageUrl: Theme.sweetHintImg(),
                            timer: 2000,
                            showConfirmButton: false
                        });
                    })
                } else {
                    //格式化数据
                    var data = {
                        id: vm.article.id,
                        name: vm.article.name,
                        completed: vm.completed,
                        sku: vm.article.sku,
                        skuNumber: vm.article.skuNumber,
                        cover: vm.article.cover,
                        document: vm.article.document,
                        productType: 'CustomPrint',
                        fileName: vm.article.fileName,
                        productId: vm.article.productId,
                        productName: vm.article.productName,
                        categoryId: vm.article.categoryId,
                        categoryName: vm.article.categoryName,
                        categoryStoreId: vm.article.categoryStoreId,
                        categoryStoreName: vm.article.categoryStoreName,
                        folderId: vm.article.folderId,
                        itemValueId: vm.article.itemValueId,
                        themeClassifyId: vm.article.themeClassifyId,
                        themeClassifyName: vm.article.themeClassifyName,
                        productXmlId: vm.article.productXmlId,
                        productXmlName: vm.article.productXmlName,
                        musicIdentify: vm.article.musicIdentify,
                        editorType: vm.article.editorType,
                        unFilledCount: vm.unFilledCount,
                        modelBlendentUuid: vm.article.modelBlendentUuid,
                    }
                    Article.save(data, function (resp) {
                        swal({
                            title: "保存成功",
                            text: "作品已同步到我的作品",
                            imageUrl: Theme.sweetSuccedImg(),
                            timer: 2000,
                            showConfirmButton: false
                        });
                        $scope.isSave = false;
                        if(vm.store.orderWay == 'editorThenOrder' || !vm.store.orderWay){
                            $state.go('product.view5',{storeId: vm.storeId, aid: article.id, soid: vm.salesOrderId, plid: vm.productLineId});
                        }
                        if(vm.store.orderWay == 'orderThenEditor'){
                            works(resp)
                        }
                    }, function (resp) {
                        $scope.isSave = false;
                        swal({
                            title: "保存失败",
                            text: "保存出错了，请稍后再试，或者联系工作人员",
                            imageUrl: Theme.sweetHintImg(),
                            timer: 3000,
                            showConfirmButton: false
                        });

                    });
                }
                localStorage.removeItem("photoSelectAll");
            })
        }

        function works(article){
            if (vm.isEdit) {
                SalesOrder.updateByArticle({
                    id: vm.salesOrderId,
                    productLineId: vm.productLineId,
                    articleId: article.id
                }, function (res) {
                    $state.go('product.view5',{storeId: vm.storeId, aid: article.id, soid: vm.salesOrderId, plid: vm.productLineId});
                }, function (res) {
                    $state.go('product.view5',{storeId: vm.storeId, aid: article.id, soid: vm.salesOrderId, plid: vm.productLineId});
                })
            }else{
                SalesOrder.updateArticle({
                    id: vm.salesOrderId,
                    productLineId: vm.productLineId,
                    articleId: article.id,
                    copy: 1,
                }, function (res) {
                    $state.go('product.view5',{storeId: vm.storeId, aid: article.id, soid: vm.salesOrderId, plid: vm.productLineId});
                }, function (res) {
                    $state.go('product.view5',{storeId: vm.storeId, aid: article.id, soid: vm.salesOrderId, plid: vm.productLineId});
                })
            }
        }

        $scope.isContinue = function () {
            var hasEmpty = true;
            vm.unFilledCount = 0;
            for (var i = 0; i < $scope.template.pages.page.length; i++) {
                var _page = $scope.template.pages.page[i];
                if (_page.levels.level) {
                    for (var j = 0; j < _page.levels.level.length; j++) {
                        var _level = _page.levels.level[j];
                        if (_level.imagebox) {
                            if (!_level.imagebox.image || !_level.imagebox.image.resource || !_level.imagebox.image.resource.identifier) {
                                hasEmpty = false;
                                vm.unFilledCount++;
                            }
                        }
                    }
                }
            }
            vm.completed = hasEmpty;
            return hasEmpty;
        };

        function saveData() {
            return documentMM($scope.template, $scope.domain);
        }

        function fn($event) {
            var $event = $event || window.event;
            if ($event && $event.preventDefault) {
                $event.preventDefault();
            } else {
                window.event.returnValue = false;
                return false;
            }
            $event.stopPropagation();
        }

        //移除画布透明效果
        $scope.opacityElement = function(mediabox, geometry){
            var opacity = 1;
            if(!geometry || !mediabox){
                return 1
            }
            if(geometry.x > -geometry.width && geometry.y > -geometry.height && geometry.x < mediabox.width && geometry.y < mediabox.height){
                opacity = 1;
            }else {
                opacity = 0.3;
            }
            return opacity
        }
        //生成缩略图
        vm.isHtml2canvas = false;
        vm.isThreeView3d1 = false;
        vm.isThreeView3d = true;
        vm.threeView3dW=110;
        vm.threeView3dH=110;
        vm.pageData1 = [];
        vm.threeView3d={
            top: 50,
            right: 5
        }

        $scope.threeView3d1Cb = function () {
            vm.isThreeView3d1=true;
            vm.threeView3dW=$(window).width();
            vm.threeView3dH=400;
        }
        $scope.threeView3d1CbHide = function () {
            vm.isThreeView3d1=false;
            vm.threeView3dW=110;
            vm.threeView3dH=110;
        };
        function thumbnailInit(callback) {
            if(vm.productXmlData && vm.productXmlData.modelType === 'THREE'){
                vm.threeLoading = true;
                $timeout(function () {
                    vm.isCover = false;
                    $timeout(function () {
                        if(!vm.isCover){
                            vm.isCover = true;
                            vm.article.covered=false;
                            vm.article.cover=null;
                            if(callback){callback()};
                        }
                    },5000)
                    html2canvas_adapt(document.getElementById('thumbnail-view'), {
                        onrendered: function (canvas) {
                            var url = canvas.toDataURL("image/png");
                            var blob = dataURLtoBlob(url);
                            var formData = new FormData();
                            formData.append('file', blob);
                            formData.append('key', '3dThumbnail-' + UidService.get());
                            formData.append('token', vm.token);
                            $.ajax({
                                cache: true,
                                type: "POST",
                                url: 'https://upload.qiniup.com/',
                                data: formData,
                                async: false,
                                contentType: false,
                                processData: false,
                                success: function (data) {
                                    if(!vm.isCover){
                                        vm.isCover = true;
                                        vm.threeLoading = false;
                                        vm.pageData[vm.pageIndex].pagecover = {
                                            identifier: $scope.domain + data.key,
                                            provider: 'qiniu'
                                        }
                                        if(vm.pageIndex == 0){
                                            vm.article.covered=true;
                                            vm.article.cover=data.key;
                                        }
                                        vm.isHtml2canvas = true;
                                        vm.pageData1 = angular.copy(vm.pageData);
                                        if(callback){callback()};
                                    }
                                }
                            })
                        },
                        error:function(){
                            vm.article.covered=false;
                            vm.article.cover=null;
                            if (callback) {callback()};
                        },
                        letterRendering: true,
                        useCORS: true,
                        width: vm.pageData[0].custom.width*vm.pageData[0].custom.thumbnailScale,
                        height: vm.pageData[0].custom.height*vm.pageData[0].custom.thumbnailScale,
                        windowWidth: document.body.scrollWidth,
                        windowHeight: document.body.scrollHeight,
                        x: 0,
                        y: 0,
                        backgroundColor: "rgba(0, 0, 0, 0)"
                    });
                })
            }else{
                if(callback){callback()}
            }
        }

        window.threeView3dStart = function (e) {
            vm.threeView3d1 = angular.copy(vm.threeView3d);
            vm.threeView3d1.x = e.changedTouches[0].clientX != null ? e.changedTouches[0].clientX: 0;          //鼠标到页面左边的距离
            vm.threeView3d1.y = e.changedTouches[0].clientY != null ? e.changedTouches[0].clientY: 0;           //鼠标到页面顶部的距离
            $scope.$digest();
        };
        window.threeView3dOn = function (e) {
            $timeout(function () {
                var nx = e.changedTouches[0].clientX != null ? e.changedTouches[0].clientX: 0;
                var ny = e.changedTouches[0].clientY != null ? e.changedTouches[0].clientY: 0;
                //计算移动后的左偏移量和顶部的偏移量
                var nl = nx - vm.threeView3d1.x;
                var nt = ny - vm.threeView3d1.y;
                vm.threeView3d.right = vm.threeView3d1.right - nl;
                vm.threeView3d.top = vm.threeView3d1.top + nt;
            })
        }
        //部件名
        $scope.partName = function (partid) {
            var partName = "";
            for(var i=0; i<$scope.template.config.parts.list.length; i++){
                if($scope.template.config.parts.list[i].id == partid){
                    partName = $scope.template.config.parts.list[i].name;
                }
            }
            return partName
        }

        // 调出手机键盘导致输入框不可见问题修复
        var element = document.getElementById('pageHeight');
        var elementHeight = element.clientHeight; // 获取元素的客户端高度
        vm.textFouched = false;
        // 监测窗口大小变化
        window.addEventListener('resize', function(event) {
            $timeout(function(){
                if (element.clientHeight < elementHeight) {
                    vm.textFouched = true;
                }else{
                    vm.textFouched = false;
                }
            })
        });

        function websocket(){
            var urlws = vm.platformSetting.editorNotifyUrl.split("://")[1];
            urlws = urlws.split("/")[0];
            // var ws = $websocket("ws://localhost:8096/ws/websocket?uid=Gallery"+vm.userId)
            var ws = $websocket("wss://"+ urlws +"/widget/ws/websocket?uid=Gallery"+vm.userId);

            ws.onOpen(function() {
                console.log('WebSocket连接已打开, url:' + ws.url);
            });

            ws.onMessage(function(message) {
                var photo = JSON.parse(message.data);
                console.log(photo);
                if (photo.folderId == null) {
                    photo.identifier = $scope.domain + photo.identifier;
                    vm.photoAll.unshift(photo)
                }
            });

            ws.onError(function(error) {
                console.log('WebSocket错误：', error);
            });

            ws.onClose(function() {
                console.log('WebSocket连接已关闭');
            });
        }
    }
})();
